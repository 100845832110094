import React, {useEffect, useMemo, useState} from "react"
import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Button,
    Paper,
    Box
} from "@mui/material";

//MUI Templates - Data Grid
import {
    GridToolbar
} from '@mui/x-data-grid-premium';
import {DataGrid, trTR} from '@mui/x-data-grid' ;
import {getStorageData} from "../../../utils/usePersistedState";
import {fetchExpeditionCompare} from "../../../services/client/reports/expeditionCompare/expeditionCompare.service";
import {useAppSelector} from "../../../store";
import SearchBar from "../../../components/form/controllers/search.input";
const DATA = [{}]

const ExpeditionComparePage = () => {
    const userInfo = useAppSelector((state) => state.user);


    const [getLoading, setLoading] = useState<boolean>(true)

    const [getExistingData, setExistingData] = useState([]);
    const [getSiberData, setSiberData] = useState([]);
    const [getDifferencesData, setDifferencesData] = useState([])
    const [getChangesData, setChangesData] = useState([])

    //@ts-ignore
    const _getChanges = (changes: object, id: number, object_repr: string, action: number, date: string) => {
        DATA.pop()

        interface Item {
            [key: string]: string;
        }

        const map = new Map(Object.entries(changes));

        map.forEach((value: string, key: string) => {
            let item: Item = {}
            item[key] = value
            //@ts-ignore
            item.id = id
            //@ts-ignore
            item.object_repr = key
            //@ts-ignore
            item.action = action
            //@ts-ignore
            item.date = date
            //@ts-ignore
            DATA.push(item)
        });
    }

    const _fetchExpeditionCompare = async () => {
        getStorageData("authState",false).then(auth => (
            //@ts-ignore
            fetchExpeditionCompare(auth.status.access_token,userInfo.data.work_profile.corporate.siber, userInfo.data.work_profile.branch.siber).then((res) => {
                setChangesData([])
                setSiberData(res.siber_data)
                setExistingData(res.existing_data)
                setDifferencesData(res.differences)
                res.differences.map((item: any, index: number) => {
                    _getChanges(JSON.parse(item.changes), item.id, item.object_repr, item.action, item.timestamp)
                })
                setLoading(false)
            })
        ))
    }

    useEffect(() => {
        _fetchExpeditionCompare()



    }, [])

    const siberColumns = useMemo(() => [
        {field: 'pozisyonid', headerName: 'Pozisyon ID', width: 200},
        {
            field: 'seferid',
            headerName: 'Sefer ID',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {
            field: 'sirketid',
            headerName: 'Sirket ID',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {
            field: 'subeid',
            headerName: 'Sube ID',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {
            field: 'seferno',
            headerName: 'Sefer No',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {field: 'durumid', headerName: 'Durum ID', width: 200},
        {field: 'durumad', headerName: 'Durum Ad', width: 200},
        {field: 'isturu', headerName: 'İs Türü', width: 200},
        {field: 'isturuad', headerName: 'İs Türü Ad', width: 200},
        {
            field: 'hafta',
            headerName: 'Hafta',
            width: 200,
            valueFormatter: (params: any) => params.value?.city?.toString()
        },
        {
            field: 'yuklemetip',
            headerName: 'Yükleme Tip',
            width: 200,
            valueFormatter: (params: any) => params.value?.city?.toString()
        },
        {field: 'yuklemetipad', headerName: 'Yükleme Tip Ad', width: 200},
    ], [])

    const existingColumns = useMemo(() => [
        {
            field: 'expedition_status',
            headerName: 'Expedition Status',
            width: 200
        },
        {
            field: 'expedition_no',
            headerName: 'Expedition No',
            width: 200
        },
        {
            field: 'is_completed',
            headerName: 'Is Completed',
            width: 200
        },
    ], [])

    const differencesColumns = useMemo(() => [
        {field: 'id', headerName: 'ID', width: 200},
        {field: 'object_repr', headerName: 'Obje', width: 200},
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            valueGetter: (params: any) => {
                if (params.row.action == 1) {
                    return "UPDATE"
                }
                if (params.row.action == 2) {
                    return "DELETE"
                }
                if (params.row.action == 3) {
                    return "ACCESS"
                }
                return null
            }
        },
        {
            field: 'trailer',
            headerName: 'From',
            width: 200,
            valueGetter: (params: any) => {
                if (params.row.trailer) {
                    return params.row.trailer[0]
                }
                if (params.row.truck) {
                    return params.row.truck[0]
                }
                if (params.row.descriptions) {
                    return params.row.descriptions[0]
                }
                return null
            }
        },
        {
            field: '',
            headerName: 'To',
            width: 200,
            valueGetter: (params: any) => {
                if (params.row.trailer) {
                    return params.row.trailer[1]
                }
                if (params.row.truck) {
                    return params.row.truck[1]
                }
                if (params.row.descriptions) {
                    return params.row.descriptions[1]
                }
                return null
            }
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 200,
            valueGetter: (params: any) => {
                if (params.row.date) {
                    return params.row.date.split("T", 2, ".", 1)
                }
                return null
            }
        },
    ], [])

    return (
        getLoading ? <></> :
            <Box>
                <Grid container md={12}>
                    <Grid md={6}>
                        <DataGrid
                            sx={{
                                height: 400,
                                margin: "10px"
                            }}
                            columns={siberColumns}
                            rows={getSiberData}
                            getRowId={(row: any) => row.pozisyonid + row.seferid}
                            slots={{toolbar: GridToolbar}}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: {debounceMs: 500},
                                },
                            }}
                            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Grid>
                    <Grid md={6}>
                        <DataGrid
                            sx={{
                                height: 400,
                                margin: "10px"
                            }}
                            columns={existingColumns}
                            rows={getExistingData}
                            slots={{toolbar: GridToolbar}}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: {debounceMs: 500},
                                },
                            }}
                            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <DataGrid
                        sx={{
                            height: 400,
                            width: "100%",
                            marginBottom: "30px"
                        }}
                        columns={differencesColumns}
                        rows={DATA}
                        getRowId={(row: any) => row.trailer + row.descriptions}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: {debounceMs: 500},
                            },
                        }}
                        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Grid>
            </Box>
    )
}

export default ExpeditionComparePage

import React from "react";

//Components
import {
    Grid,
    Breadcrumbs,
    Button,
    Typography
} from '@mui/material';
import {Link} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import GrainIcon from "@mui/icons-material/Grain";

//Icons
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';

const UserPage = () => {
    return(
        <>
        </>
    )
}

export default UserPage

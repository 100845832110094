import React, { } from "react";
import { Avatar, Badge, Card, CardContent, CardHeader, Grid, Stack, Typography } from "@mui/material";
import PieChartComponent from "../../components/charts/pie/pie.chart";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
    linearProgressClasses
} from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import LineChartComponent from "../../components/charts/line/line.chart";
import HighlightChartComponent from "../../components/charts/highlight/highlight.chart";
import ExpeditionsGrid from "../../components/grid/expeditions/expeditions.grid";
import SearchBar from "../../components/form/controllers/search.input";
import CustomerBalanceCard from "../../components/card/pages/dashboard/customer/customer.balance.card";
import DashboardCustomerExpeditionsStatusCard from "../../components/card/pages/dashboard/customer/dashboard.customer.expeditions.status.card";
import CustomerWaitingRatings from "../../components/card/pages/dashboard/customer/customer.waiting.ratings";


const HoldersData = [
    { id: 0, value: 18, label: 'Gönderici' },
    { id: 1, value: 107, label: 'Alıcı' },
    { id: 2, value: 80, label: 'Taşıyıcı' },
];


const CausesData = [
    { id: 0, value: 18, label: 'Araç Arızası' },
    { id: 1, value: 107, label: 'Yol İzin Belgesi' },
    { id: 3, value: 80, label: 'Yol Yasağı' },
    { id: 4, value: 80, label: 'Bayram Tatili' },
    { id: 5, value: 80, label: 'Dorse Bekliyor' },
    { id: 6, value: 80, label: 'Gümrük Beklemesi' },
]

const Expeditions = [
    { label: 'İthalat ', value: 120 },
    { label: 'İhracat', value: 50 },
    { label: 'Transit', value: 25 },
    { label: 'Yurt içi', value: 0 },
];

const ExpeditionStatus = [
    { label: 'Triesta Limanda ', value: 45 },
    { label: 'Gemide', value: 20 },
    { label: 'Boşaltma adresi yolunda', value: 8 },
    { label: 'Bekleyen araçlar', value: 32 },
    { label: 'Yolda', value: 57 },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"
    }
}));

const DashboardCustomer = () => {
    return (
        <Grid md={12}>
            <Grid container md={12}>
                <Grid md={6} xs={12}>
                    <DashboardCustomerExpeditionsStatusCard />
                </Grid>
                <Grid md={6} xs={12}>
                    <CustomerBalanceCard />
                </Grid>
            </Grid>
            <Grid container md={12}>
                <Grid md={8}>
                    <Card sx={{
                        margin: "1rem",
                        boxShadow: "none",
                        backgroundColor: "transparent"
                    }}>
                        <CardContent>
                            <ExpeditionsGrid />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid md={4}>
                    <Card sx={{
                        margin: "1rem",
                        boxShadow: "none",
                        backgroundColor: "transparent"
                    }}>
                        <CardContent>
                            <CustomerWaitingRatings />
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid md={4}>
                    <Card >
                        <CardContent>
                            <Grid md={12}>
                                <Typography sx={{
                                    fontSize: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    height: "50px",
                                    color: "#535353"
                                }}>
                                    Sefer Bildirimleri
                                </Typography>
                                <Grid container md={12}>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
        </Grid>
    )
}

export default DashboardCustomer

import axios, { AxiosResponse } from "axios";
import {slug} from "../../../../config";

interface IDemandsService {
    fetchDemandsProducts(extra:{apiUrl:string, accessToken:string}):Promise<AxiosResponse>;
}

const ApiDemandsProductsService: IDemandsService = {
    fetchDemandsProducts: async (extra: { apiUrl: string; accessToken: string }): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${extra.accessToken}`,
        }
        try {
            const response = await axios.get(extra.apiUrl+slug.client.demands.products.url, {headers})
            return response
        }
        catch (e){
            throw e
        }
    }
}

export default ApiDemandsProductsService

import React from "react";


import {UserPermission} from "../permissionProvider/types/permissionProvider.type"

// Tanımlanan UserPermission tipine dayanarak PermissionContextType tipini oluşturuyoruz.
type PermissionContextType = {
    isAllowedTo:(permission: UserPermission) => Promise<boolean>;
}

// Varsayılan davranışı tanımlıyoruz.
const defaultBehaviour: PermissionContextType = {
    isAllowedTo: () => Promise.resolve(false)
}

// React.createContext ile PermissionContext tipinde bir bağlam (context) oluşturuyoruz.
const PermissionContext = React.createContext<PermissionContextType>(
    defaultBehaviour
)


export default PermissionContext


import React, { useState, useEffect, useMemo } from 'react'

//MaterialUI
import {
    Box, Card, CardContent, Grid, Typography, List, useTheme, DialogContent, Dialog, Tab, Avatar, DialogProps
} from "@mui/material";
import Button from "@mui/material/Button";
import { TabContext, TabList } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorageData } from '../../utils/usePersistedState';
import { DocumentService } from '../../services/client/documents/documents.service';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';

import { SnackbarWrapper } from '../snackbar/snackbar';
import { autoHideDuration, horizontal, severity, vertical } from "../../types/enums/components/snackbar/snackbar.enums";

import { useAppSelector } from '../../store';

import { UserRoles } from "../../types/enums/reducers/user.enums"
import PermissionProvider from '../../utils/permissionProvider/permissionProvider';
import { permissionControl } from '../../utils/permissionControl';
import PermissionRestricted from '../../utils/permissionProvider/permissionRestricted';
import PermissionsTypes from "../../types/models/components/permissions.default.model"
import Checkbox from '@mui/material/Checkbox';
import { DocumentFormUpload } from '../../types/types/components/forms/documentUpload.form.type';

interface IDocumentUpload {
    loadId: number
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

interface DocumentType {
    id: string;
    is_active: boolean;
    slug: string;
    text: string;
    file: string; // Add this property to represent the document URL
}

const DocumentUpload = (props: IDocumentUpload) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [open, setOpen] = React.useState(false);
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<string[]>([]);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [dragging, setDragging] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [severityType, setSeverityType] = useState(severity.warning)
    const [documentTypesUrl, setdocumentTypesUrl] = useState<DocumentType[]>([])

    // const {
    //     register,
    //     handleSubmit,
    //     control,
    //     watch,
    //     formState: {
    //         errors
    //     }
    // } = useForm<DocumentFormUpload>()

    const userInfo = useAppSelector((state) => state.user);
    useEffect(() => {
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                DocumentService.fetchDocumentTypes({ apiUrl: api.url, accessToken: auth.status.access_token })
                    .then((res: any) => {
                        console.log(res, "sonuc")
                        //@ts-ignore
                        setdocumentTypesUrl(res.data.results)
                    })
                    .catch((error: any) => {
                        console.error(error)
                        setSeverityType(severity.error)
                        setSnackbarMessage("Döküman türü yüklenirken hata oluştu.")
                        setAlertOpen(true)
                    }
                    );
            });
        })
    }, []);
    console.log(props)
    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file.name.length > 30) {
            setSnackbarMessage("Dosya ismi 30 karakterden fazla olamaz.")
            setSeverityType(severity.warning)
            setAlertOpen(true);
            return;
        }
        console.log(file.name.length)
        if (file) {
            const allowedExtensions = ['.pdf'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (allowedExtensions.includes(`.${fileExtension}`)) {
                setSelectedFile(file);
            } else {
                console.error("Invalid file format. Please select a valid file.");
                setSnackbarMessage("Uygun olmayan dosya formatı seçtiniz.")
                setSeverityType(severity.warning)
                setAlertOpen(true);
            }
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    console.log(selectedDocumentTypes)


    const handleClose = () => {
        setOpen(false);
        setSelectedFile(null)
        setSelectedDocumentTypes([])
    };

    const handleDeleteClick = () => {
        setSelectedFile(null);
        setSelectedDocumentTypes([])
    };

    const handleSubmit = () => {
        if (!selectedDocumentTypes) {
            console.error("Please select a document type");
            setSnackbarMessage("Lütfen belge türü seçiniz.")
            setSeverityType(severity.warning)
            setAlertOpen(true)
            return;
        }
        if (selectedFile) {
            const fileSizeMB = selectedFile.size / (1024 * 1024); // Convert file size to MB
            if (fileSizeMB > 25) {
                console.error("File size exceeds the limit (25MB). Please select a smaller file.");
                setSnackbarMessage("Dosya boyutu sınırı aşıyor (25MB). Lütfen daha küçük bir dosya seçin.");
                setSeverityType(severity.warning)
                setAlertOpen(true);
                return;
            }

        }
        const formData = new FormData();
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        selectedDocumentTypes.forEach(documentType => {
            formData.append('_type', documentType);
        });

        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then(api => {
                props.setLoading(true)
                //@ts-ignore
                DocumentService.postDocument(api.url, auth.status.access_token, props.loadId, formData)
                    .then((res: any) => {
                        console.log(res)
                        setOpen(false)
                        setSelectedFile(null)
                        props.setLoading(false)
                        setSelectedDocumentTypes([])
                        setSeverityType(severity.success)
                        setSnackbarMessage("Belge başarıyla yüklendi.")
                        setAlertOpen(true);
                    })
                    .catch((error: any) => {
                        console.error(error)
                        setSeverityType(severity.error)
                        setSnackbarMessage("Belge yüklenirken hata oluştu")
                        setAlertOpen(true);
                        props.setLoading(false);
                    });
            })

        });
    }
    const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false);
    };


    const handleCheckboxToggle = (documentTypeId: string) => {
        setSelectedDocumentTypes((prevSelectedDocumentTypes) => {
            if (prevSelectedDocumentTypes.includes(documentTypeId)) {
                return prevSelectedDocumentTypes.filter(id => id !== documentTypeId);
            } else {
                return [...prevSelectedDocumentTypes, documentTypeId];
            }
        });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;

    };

    const iframeSrc = useMemo(() => {
        if (selectedFile) {
            return URL.createObjectURL(selectedFile);
        }
        return '';
    }, [selectedFile]);

    return (
        <PermissionProvider fetchPermission={
            //@ts-ignore
            permissionControl(userInfo.data, "_document")}>
            <PermissionRestricted to={[PermissionsTypes.permission.add]} fallback={
                <SnackbarWrapper
                    open={alertOpen}
                    autoHideDuration={autoHideDuration.ms6000}
                    severity={severity.error}
                    message={"Döküman yükleme yetkiniz bulunmamaktadır."}
                    anchorOrigin={{ vertical: vertical.bottom, horizontal: horizontal.right }}
                    close={(status) => setAlertOpen(status)}
                />
            }>

                <Card>
                    <CardContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>



                        <SnackbarWrapper
                            open={alertOpen}
                            autoHideDuration={autoHideDuration.ms6000}
                            severity={severityType}
                            message={snackbarMessage}
                            anchorOrigin={{ vertical: vertical.bottom, horizontal: horizontal.center }}
                            close={(status) => setAlertOpen(status)}

                        />


                        <>
                            <Button sx={{ marginRight: "10px" }} variant="outlined" onClick={handleClickOpen}>
                                Belge Yükleme
                            </Button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                PaperProps={{
                                    style: {
                                        height: selectedFile ? '550px' : "400px", // Set the desired height here
                                        width: "800px"
                                    },
                                }}

                                maxWidth={selectedFile ? "md" : "sm"}
                                sx={{
                                    '& .MuiBackdrop-root': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)'
                                    }
                                }}
                            >{selectedFile ?
                                <DialogContent >
                                    <Box>
                                        <Grid container spacing={3}>
                                            <Grid item sm={6}   >
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: "flex-start",
                                                        alignItems: 'center',
                                                        fontSize: '17px'

                                                    }}
                                                >
                                                    Seçilen Dosya: {selectedFile &&
                                                        //@ts-ignore
                                                        selectedFile.name}
                                                </Typography>
                                                {selectedFile && (
                                                    <>
                                                        <div style={{ width: '100%' }}>
                                                            <iframe
                                                                src={iframeSrc}
                                                                height={350}
                                                                title=" Viewer"
                                                                loading='eager'
                                                            />
                                                        </div>
                                                        <DeleteIcon sx={{
                                                            color: 'red', // Text color
                                                            // backgroundColor: '#fed4d4', // Background color
                                                            borderRadius: '20%', // Optional: Add a border radius for a circular shape
                                                            cursor: 'pointer', // Optional: Add a pointer cursor for better usability

                                                        }} onClick={handleDeleteClick} />
                                                    </>
                                                )}
                                            </Grid>
                                            <Grid item sm={6} sx={{ padding: "0 10px" }}>
                                                <FormControl>
                                                    <FormLabel id="demo-checkbox-group-label">
                                                        Belge türü seçiniz:
                                                    </FormLabel>
                                                    <FormGroup
                                                        aria-labelledby="demo-checkbox-group-label"

                                                    >
                                                        {documentTypesUrl ? documentTypesUrl.map((documentTypeUrl => {
                                                            return (
                                                                <Box sx={{ display: "flex" }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="medium"
                                                                                checked={selectedDocumentTypes.includes(documentTypeUrl.id)}
                                                                                onChange={() => handleCheckboxToggle(documentTypeUrl.id)}
                                                                                sx={{
                                                                                    color: '#41b0f4',
                                                                                    '&.Mui-checked': {
                                                                                        color: '#41b0f4',
                                                                                    },
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={documentTypeUrl.text}
                                                                    />
                                                                </Box>
                                                            );
                                                        })) : <></>}
                                                    </FormGroup>
                                                </FormControl>;


                                            </Grid>

                                        </Grid>
                                        {props.loading ?
                                            <Box sx={{ display: 'flex', justifyContent: "center", }}>
                                                <CircularProgress />
                                            </Box> : <></>}
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: "30px" }}>
                                            <Button sx={{ marginRight: "40px" }} onClick={handleClose}>İptal</Button>
                                            <Button sx={{
                                                fontWeight: "bold",
                                                color: '#1070cf'
                                            }} onClick={handleSubmit} >Gönder</Button>
                                        </Box>

                                    </Box>

                                </DialogContent> :
                                <DialogContent>
                                    <Box sx={{ width: '100%', typography: 'body1' }}>
                                        <TabContext value={"1"}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <TabList aria-label="lab API tabs example">
                                                    <Tab label="Standart Yükleme" value="1" />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="1">
                                                <Box
                                                    sx={{
                                                        p: 2,
                                                        border: '1px dashed grey',
                                                        height: '200px',
                                                        width: '520px',
                                                    }}
                                                    onDragOver={handleDragOver}
                                                    onDrop={handleFileDrop}
                                                    onDragLeave={handleDragLeave}
                                                    className={dragging ? 'dragging' : ''}
                                                >
                                                    <div>
                                                        <Typography
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                fontSize: '17px',
                                                            }}
                                                        >
                                                            {dragging ? 'Dosyayı Bırakın' : 'Yüklemek İçin Dosyayı Sürükle'}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignContent: 'center',
                                                                margin: '1rem',
                                                            }}
                                                        >
                                                            <CloudUploadIcon sx={{ fontSize: '80px' }} />
                                                        </Box>
                                                        <Stack
                                                            alignItems="center"
                                                            spacing={2}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignContent: 'center',
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                sx={{
                                                                    color: 'white',
                                                                    backgroundColor: '#1070CF',
                                                                }}
                                                            >
                                                                GÖZAT
                                                                <input hidden accept=".pdf, .xlsx, .docx"  // Specify the accepted file types
                                                                    multiple type="file" onChange={handleFileChange} />
                                                            </Button>
                                                        </Stack>
                                                    </div>

                                                </Box>
                                            </TabPanel>
                                        </TabContext>
                                    </Box>
                                </DialogContent>
                                }

                            </Dialog >
                        </>





                    </CardContent>
                </Card>


            </PermissionRestricted>
        </PermissionProvider>

    )
}

export default DocumentUpload
import packageJson from "../../../package.json"
import {getStorageData, setStorageData} from "../usePersistedState";
import oAuthInitialState from "../../types/models/storage/auth/oAuth.default.model"


interface IAppControl {
    versionControl(params: { message: (res: string) => void; }): Promise<boolean>;
}

const AppControl: IAppControl = {
    versionControl: async (params): Promise<boolean> => {

        return getStorageData("app", false).then((app) => {
            if (app == null) {
                setStorageData("authState", oAuthInitialState, true).then((res) => {
                    setStorageData("app", {version: packageJson.version}, false).then((res) => {
                        return false;
                    })
                })
            } else {
                //@ts-ignore
                if (packageJson.version > app.version) {
                    params.message(" Sürüm Güncelleniyor")
                    setStorageData("authState", oAuthInitialState, true).then((res) => {
                        setStorageData("app", {version: packageJson.version}, false).then((res) => {
                            return false;
                        })
                    })
                } else {
                    return false;
                }
            }
            return false;
        });

    }
}

export default AppControl;

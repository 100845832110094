import React from "react"
import axios, {AxiosResponse} from "axios";
import {slug} from "../../../config";

interface IntegrationsHoldersService {
    apiUrl?:string
    fetchIntegrationsHoldersService(accessToken: string, holderID?:string): Promise<AxiosResponse>;
    createIntegrationsHoldersService(accessToken: string, data: any): Promise<AxiosResponse>;
    updateIntegrationsHoldersService(accessToken: string, data: any, holderID: string): Promise<AxiosResponse>;
    deleteIntegrationsHoldersService(accessToken: string, holderID: string): Promise<AxiosResponse>;
}

const apiUrl = slug.client.integrations.holder.url

export const ApiIntegrationsHoldersService: IntegrationsHoldersService = {
    /**
     *
     * @param accessToken
     * @param holderID - Holder ID holder detail alınmak istenildiğinde gönderilmelidir.
     */
    fetchIntegrationsHoldersService: async (accessToken: string, holderID?:string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };
        try {
            if (holderID != null){
                const response= await axios.get(apiUrl+"/"+holderID, {headers});
                return response.data
            }
            else {
                const response= await axios.get(apiUrl, {headers});
                return response
            }
        } catch (error) {
            throw error
        }
    },
    createIntegrationsHoldersService: async (accessToken: string, data: any): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.post(apiUrl, {headers});
            return response
        } catch (error) {
            throw error
        }
    },
    updateIntegrationsHoldersService: async (accessToken: string, data: any, holderID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.put(apiUrl, data, {headers})
            return response
        } catch (error) {
            throw error
        }
    },
    deleteIntegrationsHoldersService: async (accessToken: string, holderID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.delete(apiUrl+holderID, {headers})
            return response
        } catch (error) {
            throw error
        }
    }
}

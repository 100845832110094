import React, { useEffect, useState } from "react"
import { useTheme } from '@mui/material/styles';

import { Grid, } from "@mui/material";
import 'leaflet/dist/leaflet.css';
import OpenStreetMap from "../../components/geomap/openStreet.map";
import GeoMap from "../../components/geomap/geo.map";

import { slug } from "../../config";
import { useAppSelector } from "../../store";

import PageOtsLeftDrawer from "../../components/navigations/drawer/page/page.ots.left.drawer";
import PageOtsBottomDrawer from "../../components/navigations/drawer/page/page.ots.bottom.drawer";

import { expeditionDetailComponentTypes } from "../../types/enums/pages/expeditions/expeditions.page.enum";

import { IExpeditionsModel } from "../../types/models/services/expeditions/expeditions.model";
import { IVehiclesModel } from "../../types/models/services/vehicles/vehicles.model";


interface Drawer {
    display: boolean
}

interface IExpeditionDrawer extends Drawer {
    componentTypeToDisplay: expeditionDetailComponentTypes
}

const ExpeditionsPage = () => {
    const theme = useTheme();
    const userInfo = useAppSelector((state) => state.user);

    const [markerVehicleId, setMarkerVehicleId] = useState<string>("")
    const [activityUrl, setActivityUrl] = useState(slug.client.mobiliz.url);
    const [mapShow, setMapShow] = useState<boolean>(true)

    const [eventTime, setEventTime] = useState<[string, string]>(["", ""])

    /**
     * Alt Çekmecenin Durum Kontrolü
     */
    const [bottomDrawerStatus, setBottomDrawerStatus] = useState<boolean>(false)

    /**
     * Alt Çekmecenin Görünüm Kontrolü
     */
    const [bottomDrawerType, setBottomDrawerType] = useState<expeditionDetailComponentTypes>(expeditionDetailComponentTypes.default)

    /**
     * Sağ Çekmecenin Durum Kontrolü
     */
    const [leftDrawerDisplay, setLeftDrawerDisplay] = useState<boolean>(true)

    /**
     * Seferler Durum Kontrolü
     */
    const [expedition, setExpedition] = useState<IExpeditionsModel>({
        count: 0,
        next: "",
        previous: "",
        results: [{
            id: null,
            is_completed: false,
            trailer: {
                id: null,
                id_no: null
            },
            truck: {
                id: null,
                id_no: null
            },
            loads: [{
                trailer: {
                    id_no: null
                }
            }],
            votes: []
        }]
    });

    const [vehicle, setVehicle] = useState<IVehiclesModel>({
        count: null,
        next: "",
        previous: "",
        results: [
            {
                id: null
            }
        ]
    })

    interface IMap {
        height: string,
    }

    const Map = (props: IMap) => {
        return (
            <GeoMap map={
                <OpenStreetMap
                    leftDrawerDisplay={() => setLeftDrawerDisplay(!leftDrawerDisplay)}
                    clusterDisplay={()=>setExpedition({
                        count: 0,
                        next: "",
                        previous: "",
                        results: [{
                            id: null,
                            is_completed: false,
                            trailer: {
                                id: null,
                                id_no: null
                            },
                            truck: {
                                id: null,
                                id_no: null
                            },
                            loads: [{
                                trailer:{
                                    id_no: null
                                }
                            }],
                            votes: []
                        }]
                    })}
                    event={{ firstTime: eventTime[0], lastTime: eventTime[1] }}
                    activityUrl={activityUrl}
                    markerVehicle={(id) => setMarkerVehicleId(id)}
                    data={{
                        expedition: {
                            results: [{
                                id: expedition.results[0].id,
                                start_date: expedition.results[0].start_date,
                                end_date: expedition.results[0].end_date,
                                position_no: expedition.results[0].position_no,
                                status_histories: expedition.results[0].status_histories,
                                trailer: {
                                    id: expedition.results[0].trailer.id,
                                    id_no: expedition.results[0].trailer.id_no,
                                    plate: expedition.results[0].trailer.plate
                                },
                                truck: {
                                    id: expedition.results[0].truck.id,
                                    id_no: expedition.results[0].truck.id_no,
                                    plate: expedition.results[0].truck.plate
                                },
                                is_completed: expedition.results[0].is_completed,
                                loads: expedition.results[0].loads,
                                votes: []
                            }]
                        },
                        vehicle: {
                            results: [{
                                id: null,
                            }]
                        }
                    }}
                    styles={{
                        height: props.height
                    }}
                />
            } />
        )
    }

    useEffect(() => {
        console.log('%c{expedition.page.tsx}-Expedition State', 'color: black; background-color: white',
            expedition
        );
        if (expedition.results[0].id) {
            setBottomDrawerStatus(true)
            setBottomDrawerType(expeditionDetailComponentTypes.expeditionDetail)
        }
        else if (expedition.results[0].loads[0].id) {
            console.log("YÜK BİLGİLERİ")
            setBottomDrawerStatus(true)
            setBottomDrawerType(expeditionDetailComponentTypes.loadDetail)
        }
        else{
            setBottomDrawerStatus(false)
        }
    }, [vehicle, expedition]);

    return (
        <Grid container md={12} sx={{ marginTop: "1px" }}>
            <Grid md={4} xs={4} sx={{
                height: "calc(100vh - 65px)",
                display: leftDrawerDisplay ? "block" : "none",
            }}>
                <PageOtsLeftDrawer
                    isOpen={leftDrawerDisplay}
                    onClose={() => console.log("")}
                    activity={(url: any) => setActivityUrl(url)}
                    data={{
                        expedition: (params) =>
                            setExpedition({
                                results: [{
                                    id: params.results[0].id,
                                    start_date: params.results[0].start_date,
                                    end_date: params.results[0].end_date,
                                    position_no: params.results[0].position_no,
                                    status_histories: params.results[0].status_histories,
                                    trailer: {
                                        id_no: params.results[0].trailer?.id_no,
                                        plate: params.results[0].trailer?.plate
                                    },
                                    truck: {
                                        id_no: params.results[0].truck?.id_no,
                                        plate: params.results[0].truck?.plate
                                    },
                                    loads: params.results[0].loads,
                                    is_user_voted: params.results[0].is_user_voted,
                                    votes: params.results[0].votes
                                }]
                            }),
                        vehicle: (params) => console.log(params, "PAGE OTS LEFT DRAWER - vehicle"),
                    }}
                />
            </Grid>
            <Grid md={leftDrawerDisplay ? 8 : 12} xs={8}>
                <Grid md={12} xs={12}>
                    <Grid md={12}>
                        <Map height={bottomDrawerStatus ? "380px" : "65px"} />
                    </Grid>
                    <Grid md={12} xs={false} sx={{
                        display: bottomDrawerStatus ? "block" : "none"
                    }}>
                        <PageOtsBottomDrawer
                            isOpen={bottomDrawerStatus}
                            onClose={() => setBottomDrawerStatus(false)}
                            type={bottomDrawerType}
                            style={{
                                height: bottomDrawerStatus ? "400px" : "0px",
                                display: bottomDrawerStatus ? "block" : "none"
                            }}
                            data={{
                                expedition: {
                                    results: [{
                                        id: expedition.results[0].id,
                                        trailer: {
                                            id_no: null
                                        },
                                        truck: {
                                            id_no: null
                                        },
                                        loads: expedition.results[0].loads,
                                        is_user_voted: expedition.results[0].is_user_voted,
                                        votes: expedition.results[0].votes
                                    }]
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ExpeditionsPage

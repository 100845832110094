import React from "react"
import {useParams} from "react-router-dom";
import AdvertsCategoryIndex from "./pages/adverts/category/adverts.category.index";
import Offers from "./pages/offers/offers";
import {Grid} from "@mui/material";

// @ts-ignore
import ProductUnitsPage from "./pages/products/units/product.units.page";
import IntegrationsHoldersPage from "./pages/integrations/holders/integrations.holders.page";
// @ts-ignore
import IntegrationsTaxesPage from "./pages/integrations/taxes/integrations.taxes.page";
import IntegrationsDueoptionsPage from "./pages/integrations/dueoptions/integrations.dueoptions.page";
import IntegrationsCurrenciesPage from "./pages/integrations/currencies/integrations.currencies.page";
import IntegtrationsCausesPage from "./pages/integrations/causes/integtrations.causes.page";
import PermissionProvider from "./utils/permissionProvider/permissionProvider";
import {permissionControl} from "./utils/permissionControl";
import PermissionRestricted from "./utils/permissionProvider/permissionRestricted";
import PermissionsTypes from "./types/models/components/permissions.default.model";
import Notfound from "./pages/notfound";
import {useAppSelector} from "./store";

import {
    DFDSCompaniesPage,
    DFDSDashboardPage,
    DFDSDiscountsPage,
    DFDSVehiclesPage
} from "./pages/dfds/dfds.page";
import DFDSBookingsPage from "./pages/dfds/bookings/dfds.bookings.page";

const AppModelView = () => {
    const userInfo = useAppSelector((state) => state.user);
    const {app, model} = useParams();

    return (
        <Grid md={12}>
            <Grid container md={12} sx={{
                marginBottom: "1em"
            }}>
                <Grid md={10} sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center"
                }}>

                </Grid>
                <Grid md={2} sx={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>

                </Grid>
            </Grid>
            {
                app == "bookings" && model == "dashboard" ?  <DFDSDashboardPage/> : <Notfound/> &&
                app == "bookings" && model == "discounts" ?  <DFDSDiscountsPage/> : <Notfound/> &&
                app == "bookings" && model == "companies" ?  <DFDSCompaniesPage/> : <Notfound/> &&
                app == "bookings" && model == "vehicles" ?  <DFDSVehiclesPage/> : <Notfound/> &&
                app == "bookings" && model == "bookings" ?  <DFDSBookingsPage/> : <Notfound/> &&

                app == "adverts" && model == "categories" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_advertcategory")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>Kullanıcı İzni Yok</>}>
                        {
                            <AdvertsCategoryIndex/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "adverts" && model == "offers" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_offer")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>Kullanıcı İzni Yok</>}>
                        {
                            <Offers/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "integrations" && model == "holders" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_holder")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<Notfound/>}>
                        {
                            <IntegrationsHoldersPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "integrations" && model == "taxes" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_tax")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<Notfound/>}>
                        {
                            <IntegrationsTaxesPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "integrations" && model == "dueoptions" ?

                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_dueoption")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<Notfound/>}>
                        {
                            <IntegrationsDueoptionsPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider>


                    : <Notfound/> &&

                app == "integrations" && model == "dueoptions" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_dueoption")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<Notfound/>}>
                        {
                            <IntegrationsDueoptionsPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "integrations" && model == "currencies" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_currency")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<Notfound/>}>
                        {
                            <IntegrationsCurrenciesPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "integrations" && model == "causes" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_cause")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<Notfound/>}>
                        {
                            <IntegtrationsCausesPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/>
            }
        </Grid>
    )
}

export default AppModelView

import React, { useState } from 'react'
import DocumentDisplay from './documentDisplay'
import DocumentUpload from './documentUpload';
import { useAppSelector } from '../../store';
import { UserRoles } from "../../types/enums/reducers/user.enums"


interface IDocuments {
    loadId: number
}

const Documents = (props: IDocuments) => {
    const [loading, setLoading] = useState(false)
    const userInfo = useAppSelector((state) => state.user);


    return (
        <>
            {//@ts-ignore
                userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.MANAGER || userInfo.data.role == UserRoles.EMPLOYEE ?
                    <DocumentUpload loadId={props.loadId} loading={loading} setLoading={setLoading} />
                    : <></>}
            {
                //@ts-ignore
                userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.EMPLOYEE || userInfo.data.role == UserRoles.MANAGER || userInfo.data.role == UserRoles.CUSTOMER || userInfo.data.role == UserRoles.DEVELOPER
                    ?
                    <DocumentDisplay loadId={props.loadId} loading={loading} setLoading={setLoading} />
                    : <></>
            }
        </>
    )
}

export default Documents

import axios, { AxiosResponse } from "axios";
import {slug} from "../../../config";

interface Device {
    // Cihaz veri yapısı
}

interface DeviceService {
    apiUrl?: string;
    fetchDevices(accessToken: string): Promise<AxiosResponse>;
    createDevice(accessToken: string): Promise<AxiosResponse>;
    updateDevice(accessToken: string, data: string, deviceID: string): Promise<AxiosResponse>;
    deleteDevice(accessToken: string, deviceID: string): Promise<AxiosResponse>;
}

const apiUrl = slug.client.devices.url

const ApiDeviceService: DeviceService = {

    fetchDevices: async (accessToken: string): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            const response = await axios.get(apiUrl, { headers });
            return response;
        } catch (error) {
            throw error;
        }
    },

    createDevice: async (accessToken: string): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            const response = ""; // create işlemi için uygun veri sağlanmalı
            //@ts-ignore
            return response;
        } catch (error) {
            throw error;
        }
    },

    updateDevice: async (accessToken: string, data: string, deviceID: string): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            const response = await axios.patch(apiUrl + deviceID, data, { headers });
            return response;
        } catch (error) {
            throw error;
        }
    },

    deleteDevice: async (accessToken: string, deviceID: string): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            const response = await axios.delete(apiUrl + deviceID, { headers });
            return response;
        } catch (error) {
            throw error;
        }
    },
}

export default ApiDeviceService

import React from "react"
import Box from '@mui/material/Box';
import {Skeleton, Stack, Stepper, StepLabel} from '@mui/material';
import Step from "@mui/material/Step";

export const WaitingActivitySkeleton = () => {
    return (
        <Stack spacing={1}>
            <Skeleton animation="wave" height={"400px"}/>
            <Skeleton animation="wave" height={"400px"}/>
        </Stack>
    )
}

export const WaitingActivityCommentsSkeleton = () => {
    return (
        <Stack spacing={1}>
            <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
            <Skeleton variant="circular" width={40} height={40}/>
            <Skeleton variant="rectangular" width={"100%"} height={60}/>
            <Skeleton variant="rounded" width={"100%"} height={60}/>
        </Stack>
    )
}

export const LoadPositionStepperSkeleton = () => {
    return (
        <Stepper orientation="vertical">
            <Step>
                <StepLabel>
                    <Skeleton animation="wave" variant="text" width={100}/>
                </StepLabel>
            </Step>
            <Step>
                <StepLabel>
                    <Skeleton animation="wave" variant="text" width={100}/>
                </StepLabel>
            </Step>
        </Stepper>
    )
}

import React, { useState } from "react"
import { Box, InputBase } from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import useDebounce from "../../utils/debounce";

import ExpeditionsListOrderingMenus from "../menu/expeditions/expeditions.list.ordering.menu";
import ExpeditionsListFilterMenus from "../menu/expeditions/expeditions.list.filter.menu";
import {useTheme} from "@mui/material/styles";

interface ISearch {
    placeholder?: string
    inputProps?: object
    debounce: (text: string) => void
    debounceDelay: number,
    OrderingOptionFields: JSX.Element[] | JSX.Element
    expedition?: (status:boolean) => void
}

const Search = ({ placeholder, inputProps, debounce, debounceDelay, OrderingOptionFields, expedition }: ISearch) => {
    const theme = useTheme();
    const [searchTxt, setsearchTxt] = useState("")
    debounce(useDebounce(searchTxt, debounceDelay))

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: "100%",
                borderRadius:"6px",
                border:"solid",
                borderWidth:"1px",
                borderColor:`${theme.palette.grey["400"]}`,
        }}>
            <Box sx={{ p: '10px' }}>
                <SearchIcon sx={{
                    color: `${theme.palette.grey["400"]}` }}
                />
            </Box>

            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={placeholder}
                inputProps={inputProps}
                onChange={e => setsearchTxt(e.target.value)}
            />

            <ExpeditionsListFilterMenus expedition={(status)=> expedition ? expedition(status) : {}}/>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <ExpeditionsListOrderingMenus fields={OrderingOptionFields} />
        </Box>
    )
}

export default Search

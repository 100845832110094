import React, {useState} from "react";

import {Permission, UserModel} from "../types/models/reducers/user/user.model";

import {GroupPermission, UserPermission} from "./permissionProvider/types/permissionProvider.type";

const fetchUserPermission = (user: UserModel, componentName: string) => async (permission: UserPermission): Promise<boolean> => {
    await new Promise((resolve: any) => setTimeout(resolve, 1));
    //@ts-ignore
    return user.user_permissions.filter(e => e.codename === permission + componentName).length > 0;
}


const fetchGroupPermission = (user: UserModel, componentName: string) => async (permission: GroupPermission): Promise<boolean> => {
    await new Promise((resolve: any) => setTimeout(resolve, 1));
    const permissions: Permission[] = []
    user.groups.map((res:any)=>{
        permissions.push(...res.permissions)
    })
    return permissions.filter(res => res.codename === permission + componentName).length > 0
}

export const permissionControl = (user:UserModel, componentName:string) =>  async (permission: GroupPermission): Promise<boolean> => {
    const [getState, setState] = useState<boolean>(false)

    await new Promise((resolve: any) => setTimeout(resolve, 1));
    const _fetchPermission = await fetchUserPermission(user, componentName)
    const _fetchGroupPermission = await fetchGroupPermission(user, componentName)

    _fetchPermission(permission).then(resz=>{
        resz ? setState(true) : _fetchGroupPermission(permission).then(res=>{
            setState(res)
        })
    })

    return getState
}

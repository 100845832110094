import React from "react"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { ISnackbar } from "../../types/interfaces/components/snackbar/snackbar.interface";
import CloseIcon from '@mui/icons-material/Close';

const alertStyles: {
    [key: string]: {
        backgroundColor: string;
    };
} = {
    error: {
        backgroundColor: 'rgba(242, 5, 5, 0.8)', // Red with 80% opacity
    },
    warning: {
        backgroundColor: 'rgba(237, 108, 2, 0.8)', // Orange with 80% opacity
    },
    info: {
        backgroundColor: 'rgba(5, 84, 242, 0.8)', // Blue with 80% opacity
    },
    success: {
        backgroundColor: 'rgba(78, 203, 113, 0.8)', // Green with 80% opacity
    },
};


export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} style={alertStyles[props.severity ? props.severity : "success"] || {}} />;
});

export const SnackbarWrapper = (props: ISnackbar) => {
    return (
        <Snackbar
            anchorOrigin={props.anchorOrigin}
            open={props.open}
            autoHideDuration={props.autoHideDuration}
            message={props.message}
            onClose={() => props.close(false)}
        >
            <Alert
                severity={props.severity}
                sx={{ width: '100%' }}
            >
                {props.message} {
                    <CloseIcon sx={{
                        '&:hover': {
                            cursor: "pointer"
                        },
                    }} onClick={() => props.close(false)}
                    />
                }
            </Alert>
        </Snackbar>
    )
}

import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const ActiveSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" fill="${encodeURIComponent(
                theme.palette.success.main
            )}" height="16" width="16" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" fill="${encodeURIComponent(
                theme.palette.error.main
            )}" height="16" width="16" viewBox="0 0 24 24" data-testid="CancelIcon"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"></path></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
        backgroundColor:"white"
    },
}));

interface IVehicleBookingActiveSwitch {
    defaultChecked:boolean
    active:(status:string)=>void
}

const VehicleBookingActiveSwitch = (props:IVehicleBookingActiveSwitch) => {
    const theme = useTheme();

    const handleChange = (event:any) => {
        props.active(event.target.checked)
    };


    return (
        <FormGroup>
            <FormControlLabel
                control={
                <ActiveSwitch
                    defaultChecked={props.defaultChecked}
                    onChange={handleChange}
                />
                }
                label="Aktif"
                labelPlacement="start"
                sx={{
                    margin:1
                }}
                slotProps={{
                    typography:{
                        color: `${theme.palette.text.primary}`,
                        fontWeight: 600,
                        fontSize: "16px",
                    }
                }}
            />
        </FormGroup>
    );
}

export default VehicleBookingActiveSwitch

import {useState, useEffect, Dispatch, SetStateAction } from 'react';
import CryptoJS from 'crypto-js';
import {da} from "date-fns/locale";

type Response<T> = [
    T,
    Dispatch<SetStateAction<T>>,
]

// AES şifreleme işlemi
//@ts-ignore
function encryptData(data) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), "secret-key").toString();
    return encryptedData;
}

// AES deşifreleme işlemi
//@ts-ignore
function decryptData(encryptedData) {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, "secret-key").toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
}

export function usePersistedState<T>(key: string, initialState: T, encrypted:boolean): Response<T> {
    const [state, setState] = useState(() => {
        const storageValue = localStorage.getItem(key);
        if (storageValue) {
            if(encrypted) {
                const decryptedValue = decryptData(storageValue);
                return decryptedValue || initialState;
            }
            else{
                return JSON.parse(storageValue);
            }
        } else {
            return initialState;
        }
    });

    useEffect(() => {
        const encryptedValue = encryptData(state);
        localStorage.setItem(key, encryptedValue);
    }, [state, setState]);

    return [state, setState];
}

export async function getStorageData(key: string, encrypted:boolean): Promise<string> {
    if(encrypted){
        try {
            const encryptedData = await localStorage.getItem(key);
            if (encryptedData) {
                const decryptedData = decryptData(encryptedData);
                return decryptedData || null;
            } else {
                return "null";
            }
        } catch (error) {
            return "error";
        }
    }
    else {
        try {
            const data = await localStorage.getItem(key)
            return data != null ? JSON.parse(data) :null;
        }
        catch (error:any) {
            return error
        }
    }
}


export async function removeValue(key:string){
    try {
        await localStorage.removeItem(key)
    }
    catch (error:any){
        return error
    }
}

export async function setStorageData(key: string, data: object, encrypted: boolean): Promise<any> {
    if (encrypted) {
        try {
            const encryptedValue = encryptData(data);
            localStorage.setItem(key, encryptedValue);
        } catch (error) {
            throw error;
        }
    } else {
        try {
            const value = JSON.stringify(data);
            await localStorage.setItem(key, value);
        } catch (error) {
            throw error;
        }
    }
}
import axios, { AxiosResponse } from "axios";
import { slug } from "../../../config";

interface DocumentService {
    postDocument(apiUrl: string, accessToken: string, data: {}, expedition_id: string): Promise<AxiosResponse>;
    fetchDocumentTypes(extra: { apiUrl: string, accessToken: string }): Promise<AxiosResponse>;
    getDocuments(apiUrl: string, accessToken: string, expedition_id: string): Promise<AxiosResponse>;
}

export const DocumentService: DocumentService = {

    fetchDocumentTypes: async (extra: { apiUrl: string, accessToken: string }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${extra.accessToken}`,
            }
        }
        try {
            const response = await axios.get(extra.apiUrl + slug.client.loads.documents.documentTypesUrl, config)
            return response
        }
        catch (error) {
            throw error
        }
    },
    postDocument: async (apiUrl: string, accessToken: string, expedition_id, data): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': "multipart/form-data"

        };

        try {
            const response = await axios.post(apiUrl + slug.client.loads.documents.url + expedition_id + "/documents", data, {
                headers
            })
            return response;
        } catch (error) {
            throw error;
        }
    },
    getDocuments: async (apiUrl: string, accessToken: string, expedition_id): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': "multipart/form-data"
            // 'Content-Type': 'application/json'
        };

        try {
            const response = await axios.get(apiUrl + slug.client.loads.documents.url + expedition_id + "/documents", {
                headers
            })
            return response;
        } catch (error) {
            throw error;
        }
    },
}

export default DocumentService

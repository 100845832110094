import React, {useEffect, useState} from "react";
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import {FieldValues, UseFormRegister} from "react-hook-form";
import { useTheme } from "@mui/material/styles";

interface IExpeditionSortingSelector {
    label: string,
    register: UseFormRegister<FieldValues>,
    inputName: string,
    defaultValue:string,
}

const status = [
    { id: 1, status: 'new'},
    { id: 2, status: 'processing'},
    { id: 3, status: 'standby'},
    { id: 4, status: 'ready'},
    { id: 5, status: 'partial-delivered'},
    { id: 6, status: 'delivered'},
];

const DemandStatusSelector = (props: IExpeditionSortingSelector) => {

    const theme = useTheme();

    return (
        <FormControl sx={{
            width: "100%"
        }}>
            <InputLabel sx={{
                color: `${theme.palette.text.primary}`
            }}>
                {props.label}
            </InputLabel>
            <Select
                defaultValue={props.defaultValue}
                //@ts-ignore
                {...props.register((`${props.inputName}`), {required: false})}
            >
                {
                    status.map((res, index) => (
                        <MenuItem
                            key={index}
                            value={res.status}
                        >
                            {
                                res.status == "new" ? "Yeni" :
                                    res.status == "processing" ? "İşlemde" :
                                        res.status == "standby" ? "Beklemede" :
                                            res.status == "ready" ? "Hazır" :
                                                res.status == "partial-delivered" ? "Kısmı Teslimat" :
                                                    res.status == "delivered" ? "Teslim Edildi" : ""
                            }
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default DemandStatusSelector

import axios, { AxiosResponse } from "axios";
import { slug } from "../../../config";

interface Expedition {
    // Sefer veri yapısı
}

interface ExpeditionService {
    fetchExpeditions(
        extra: {
            apiUrl?: string,
            accessToken?: string,
            offset?: number,
            limit?: string,
            search?: string,
            expeditionId?: string,
            ordering?: string,
            position_type?: number,
            is_completed?: boolean,
            is_voted?: boolean,
            is_user_voted?: boolean
        }): Promise<AxiosResponse>;
    fetchQuickExpeditions(extra: { apiUrl: string, accessToken: string }): Promise<AxiosResponse>;
    // createExpedition(accessToken: string): Promise<AxiosResponse>;
    // updateExpedition(accessToken: string, data: string, expeditionID:string): Promise<AxiosResponse>;
    // deleteExpedition(accessToken: string, expeditionID:string): Promise<AxiosResponse>;
    fetchExpeditionsDurations(extra: {
        apiUrl: string,
        offset?: number,
        accessToken: string,
        endDateGte?: string,
        endDateLte?: string,
        isCompleted?: string,
        positionType?: number,
        limit?: number
    }): Promise<AxiosResponse>;
}

export const ApiExpeditionsService: ExpeditionService = {
    fetchExpeditions: async (extra: {
        apiUrl: string,
        accessToken: string,
        is_completed: boolean,
        offset?: number,
        limit?: string,
        search?: string,
        expeditionId?: string,
        ordering?: string,
        position_type?: number,
        is_voted?: boolean,
        is_user_voted?: boolean
    }): Promise<AxiosResponse> => {
        const config = {
            params: {
                ...(extra.offset ? { offset: extra.offset } : {}),
                //...(extra.limit ? {limit:extra.limit} : {}),
                limit: 50,
                ...(extra.search ? { search: extra.search } : {}),
                ...(extra.position_type == null || undefined ? {} : { position_type: extra.position_type }),
                ...(extra.ordering ? { ordering: extra.ordering } : {}),
                ...(extra.is_completed == null || undefined ? {} : {is_completed: extra.is_completed}),
                ...(extra.is_voted ? { is_voted: extra.is_voted } : {}),
                ...(extra.is_user_voted ? { is_user_voted: extra.is_user_voted } : {})
            },
            headers: {
                Authorization: `Bearer ${extra.accessToken}`,
            }
        };
        try {
            const response = await axios.get(extra.expeditionId ? extra.apiUrl + slug.client.expeditions.url + extra.expeditionId : extra.apiUrl + slug.client.expeditions.url, config)
            console.log(response, "HEEEEEEEY")
            return response;
        } catch (error) {
            throw error;
        }
    },

    fetchQuickExpeditions: async (extra: { apiUrl: string, accessToken: string }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${extra.accessToken}`,
            }
        }
        try {
            const response = await axios.get(extra.apiUrl + slug.client.expeditions.quick.url, config)
            return response
        }
        catch (error) {
            throw error
        }
    },

    fetchExpeditionsDurations: async (extra: {
        apiUrl: string,
        offset: number,
        accessToken: string,
        endDateGte: string,
        endDateLte: string,
        isCompleted: string,
        positionType?: number,
        limit?: number
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${extra.accessToken}`,
            },
            params: {
                ...(extra.offset ? { offset: extra.offset } : {}),
                //...(extra.limit ? {limit:extra.limit} : {}),
                limit: extra.limit ? extra.limit : {},
                end_date__gte: extra.endDateGte ? extra.endDateGte : {},
                end_date__lte: extra.endDateLte ? extra.endDateLte : {},
                position_type: extra.positionType !== null || undefined ? extra.positionType : {},
                is_completed: extra.isCompleted,

            }
        }
        try {
            const response = await axios.post(extra.apiUrl + slug.client.expeditions.duration.url, {}, config)
            return response
        }
        catch (e) {
            throw e
        }
    }
}

export default ApiExpeditionsService

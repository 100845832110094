import React from "react";
import axios from "axios";
import {slug} from "../../../config";


export const fetchCurrencyList = async (token:string) => {
    const response = await axios.get(slug.client.integrations.url +  slug.client.integrations.currencies.url, {
        headers: {
            'Authorization': 'Bearer '+token.valueOf()
        }
    })

    return response
}





export const mersinAndTriestePortRouteCoordinates = [
    [34.645792, 36.797066],
    [34.647183, 36.795676],
    [34.64583, 36.793395],
    [34.642196, 36.789757],
    [34.647601, 36.7817],
    [34.662046, 36.769224],
    [34.670244, 36.755828],
    [34.672194, 36.741513],
    [34.670812, 36.61783],
    [34.666099, 36.384779],
    [34.537168, 36.209621],
    [34.284019, 36.092358],
    [34.096085, 36.021081],
    [33.973365, 35.99579],
    [33.863333, 35.991028],
    [33.76599, 36.006793],
    [33.667188, 36.006382],
    [33.566929, 35.989794],
    [33.385896, 35.889031],
    [33.124089, 35.704094],
    [32.804364, 35.660394],
    [32.426721, 35.757931],
    [32.157686, 35.799018],
    [31.997258, 35.783655],
    [31.836894, 35.768078],
    [31.676592, 35.752289],
    [31.516354, 35.736287],
    [31.356181, 35.720072],
    [31.196073, 35.703646],
    [31.036032, 35.687008],
    [30.876059, 35.670158],
    [30.716153, 35.653096],
    [30.556316, 35.635823],
    [30.396548, 35.618339],
    [30.23685, 35.600644],
    [30.077224, 35.582739],
    [29.917669, 35.564623],
    [29.758187, 35.546297],
    [29.598779, 35.527762],
    [29.439444, 35.509016],
    [29.280184, 35.490062],
    [29.120999, 35.470898],
    [28.625583, 35.578784],
    [27.793934, 35.813722],
    [27.257157, 35.954635],
    [27.015252, 36.001523],
    [26.776074, 36.057535],
    [26.539623, 36.122668],
    [26.306178, 36.151657],
    [26.075737, 36.144501],
    [25.797573, 36.138299],
    [25.471684, 36.133052],
    [25.260967, 36.126285],
    [25.165419, 36.117998],
    [25.062858, 36.110969],
    [24.953284, 36.105196],
    [24.878879, 36.101282],
    [24.839644, 36.099226],
    [24.80041, 36.097157],
    [24.761178, 36.095076],
    [24.721949, 36.092981],
    [24.682722, 36.090874],
    [24.643496, 36.088754],
    [24.604273, 36.086622],
    [24.565052, 36.084476],
    [24.525833, 36.082318],
    [24.486616, 36.080147],
    [24.447402, 36.077963],
    [24.40819, 36.075767],
    [24.368979, 36.073557],
    [24.329771, 36.071335],
    [24.290566, 36.0691],
    [24.251362, 36.066853],
    [24.212161, 36.064592],
    [24.172962, 36.062319],
    [24.133765, 36.060033],
    [23.884523, 36.052754],
    [23.425235, 36.040483],
    [23.110444, 36.03051],
    [22.940148, 36.022837],
    [22.751739, 36.025086],
    [22.545218, 36.037258],
    [22.333304, 36.106532],
    [22.115997, 36.232908],
    [21.986249, 36.323428],
    [21.944063, 36.378094],
    [21.584921, 36.447812],
    [20.908824, 36.532581],
    [20.554751, 36.609265],
    [20.522701, 36.677864],
    [20.490593, 36.746455],
    [20.458429, 36.815037],
    [20.426206, 36.883611],
    [20.393926, 36.952176],
    [20.361588, 37.020732],
    [20.329191, 37.089279],
    [20.296735, 37.157817],
    [20.264221, 37.226347],
    [20.231647, 37.294868],
    [20.199015, 37.363379],
    [20.166322, 37.431882],
    [20.13357, 37.500376],
    [20.100757, 37.56886],
    [20.067884, 37.637336],
    [20.03495, 37.705802],
    [20.001956, 37.77426],
    [19.9689, 37.842708],
    [19.935783, 37.911146],
    [19.890529, 38.010442],
    [19.833137, 38.140594],
    [19.77554, 38.270718],
    [19.717737, 38.400814],
    [19.659724, 38.530882],
    [19.601503, 38.66092],
    [19.543068, 38.79093],
    [19.484421, 38.92091],
    [19.425558, 39.050861],
    [19.366479, 39.180783],
    [19.30718, 39.310674],
    [19.247661, 39.440535],
    [19.187919, 39.570366],
    [19.127954, 39.700166],
    [19.067762, 39.829935],
    [19.007344, 39.959673],
    [18.946694, 40.089379],
    [18.885814, 40.219054],
    [18.8247, 40.348697],
    [18.763351, 40.478307],
    [18.541909, 40.709358],
    [18.160375, 41.041851],
    [17.82325, 41.362318],
    [17.530536, 41.670761],
    [17.222295, 41.946965],
    [16.898527, 42.190929],
    [16.227174, 42.636346],
    [15.208237, 43.283214],
    [14.617196, 43.680307],
    [14.454052, 43.827624],
    [14.311783, 43.958354],
    [14.129692, 44.129569],
    [13.62675, 44.700719],
    [13.41207, 44.911247],
    [13.245607, 45.171462],
    [13.174011, 45.37796],
    [13.3554, 45.499318],
    [13.533413, 45.579143],
    [13.61886, 45.617605],
    [13.709874, 45.616177],
    [13.747361, 45.618916],
    [13.761659, 45.625346],
    [13.770517, 45.630271],
    [13.775643, 45.635401]
];

import React, {FC, useEffect} from 'react';
import {Link} from "react-router-dom";

//Components
import {
    Grid,
    Typography,
    Box,
    Card,
    CardContent,
    Tooltip,
    IconButton,
    Badge
} from '@mui/material';

//Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import MailIcon from '@mui/icons-material/Mail';
//Services
import { advertDeleteService } from "../../services/client/adverts/adverts.service";

//Storage
import { getStorageData } from "../../utils/usePersistedState";

import logo from "../../assets/potenza-s001.png"

import {permissionControl} from "../../utils/permissionControl";
import {useAppSelector} from "../../store";
import PermissionProvider from "../../utils/permissionProvider/permissionProvider";
import PermissionRestricted from "../../utils/permissionProvider/permissionRestricted";

import PermissionsTypes from "../../types/models/components/permissions.default.model";
import AdDelNoticeModal from "../modal/ad.del.notice.modal";

interface IAdvertCard {
    data:object
}

const AdvertCard:FC<IAdvertCard> = (props, context) => {

    const userInfo = useAppSelector((state) => state.user);

    useEffect(()=>{

    },[])


    return (
        <Box sx={{margin:"1em"}}>
            <Badge color="primary" badgeContent={0}>
                <Card>
                    <CardContent>
                        <Grid container md={12}>
                            <Grid md={6}>
                                <Typography sx={{
                                    fontWeight: "bold",
                                    fontSize: "15px"
                                }}>
                                    {
                                        //@ts-ignore
                                        props.data.advert_no
                                    }
                                </Typography>
                                <Typography style={{
                                    fontSize: "medium",
                                }} variant="body2" color="text.secondary">
                                    {
                                        //@ts-ignore
                                        props.data.title
                                    }
                                </Typography>
                                <Typography style={{
                                    fontWeight: "normal",
                                    fontSize: "medium",

                                }} variant="body2">
                                    {
                                        //@ts-ignore
                                        props.data.description
                                    }
                                </Typography>
                            </Grid>
                            <Grid md={6}>
                                <Typography style={{
                                    fontSize: "small",
                                    textAlign:"end"
                                }} variant="subtitle1" component="div">
                                    12.10.2023
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid md={12} sx={{}}>
                            <Box sx={{
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                            }}
                            >
                                <img src={logo} style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: "200px",
                                    height: "100%",
                                    margin:50,
                                }}>
                                </img>
                            </Box>
                        </Grid>
                        <Grid>
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs sx={{ fontWeight:"bold"}}>
                                        Toplam: {
                                        //@ts-ignore
                                        props.data.total_price + " " + props.data.currency
                                    }
                                    </Grid>
                                </Grid>
                                <Grid item>

                                    <PermissionProvider
                                        //@ts-ignore
                                        fetchPermission={permissionControl(userInfo.data, "_offer")}>
                                        <PermissionRestricted to={[PermissionsTypes.permission.add]}>
                                            <Link to="/advert/offer/create/" state={
                                                //@ts-ignore
                                                props.data.id
                                            }>
                                                <Tooltip title="Teklif Ver">
                                                    <IconButton>
                                                        <LocalOfferTwoToneIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                        </PermissionRestricted>
                                    </PermissionProvider>

                                    <PermissionProvider
                                        //@ts-ignore
                                        fetchPermission={permissionControl(userInfo.data, "_advert")}>
                                        <PermissionRestricted to={[PermissionsTypes.permission.add]}>
                                            <Link to="/adverts/advert/edit/" state={
                                                //@ts-ignore
                                                props.data.id
                                            }>
                                                <Tooltip title="Güncellee">
                                                    <IconButton>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                        </PermissionRestricted>
                                    </PermissionProvider>

                                    <PermissionProvider
                                        //@ts-ignore
                                        fetchPermission={permissionControl(userInfo.data, "_advert")}>
                                        <PermissionRestricted to={[PermissionsTypes.permission.delete]}>
                                            <AdDelNoticeModal
                                                //@ts-ignore
                                                advertId={props.data.id}
                                            />
                                        </PermissionRestricted>
                                    </PermissionProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Badge>
        </Box>
    );
}

export default AdvertCard

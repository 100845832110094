import React, {FC, useEffect, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import { useTheme } from "@mui/material/styles";

import AppLeftDrawerNavigation from "./components/navigations/drawer/app/app.left.drawer.navigation"
import AppBarNavigation from "../src/components/navigations/appbar/appbar.navigation"


import {lightTheme, darkTheme} from "./styled/themes.index";

import {ThemeProvider} from "@mui/material";

import {getStorageData, setStorageData} from "./utils/usePersistedState";
import {SnackbarWrapper} from "./components/snackbar/snackbar";
import {closeSnackbar} from "./reducers/snackbar.slice";

//Redux
import {useAppDispatch, useAppSelector} from "./store";

interface IAppContent {
    children: JSX.Element[] | JSX.Element
    toggleTheme():void
}

//@ts-ignore
const AppContent:FC<IAppContent> = (props, context) => {
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const snackbar = useAppSelector((state) => state.snackbar);

    const dispatch = useAppDispatch();

    return (
        <Box sx={{display: 'flex'}}>
            <SnackbarWrapper
                open={snackbar.open}
                autoHideDuration={snackbar.autoHideDuration}
                severity={snackbar.severity}
                message={snackbar.message}
                anchorOrigin={snackbar.anchorOrigin}
                close={(status: any) => dispatch(closeSnackbar())}
            />
            <CssBaseline/>
            <AppBarNavigation open={open} toggleDrawer={() => toggleDrawer()} toggleTheme={props.toggleTheme}/>
            <AppLeftDrawerNavigation isOpen={open} onClose={()=>toggleDrawer()}/>
            <Box
                component="main"
                sx={{
                    backgroundColor: `${theme.palette.background.default}`,
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>
                <Box>
                    {props.children}
                </Box>
            </Box>
        </Box>
    );
}

interface IAppWrapper {
    children: JSX.Element[] | JSX.Element
}

//@ts-ignore
const AppWrapper:FC<IAppWrapper> = (props, context) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const theme = isDarkMode ? darkTheme : lightTheme;

    useEffect(()=>{
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        getStorageData('theme', false).then((res:any)=>{
            //@ts-ignore
            if(res.isDarkMode){
                //@ts-ignore
                setIsDarkMode(res.isDarkMode)
            }else{
                //@ts-ignore
                setIsDarkMode(res.isDarkMode)
            }
        }).catch((e:any)=>{
            darkModeMediaQuery.matches ? setIsDarkMode(true) : setIsDarkMode(false)
            setStorageData("theme", {isDarkMode:darkModeMediaQuery.matches}, false)
        })
    },[])

    const toggleTheme = () => {
        setStorageData("theme", {isDarkMode:!isDarkMode}, false)
        setIsDarkMode(!isDarkMode);
    };


    return (
        <ThemeProvider theme={theme}>
            <AppContent toggleTheme={()=>toggleTheme()}>
                {props.children}
            </AppContent>
        </ThemeProvider>
    )
}

export default AppWrapper


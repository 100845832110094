import React from "react"

interface IGeoMap {
    map: JSX.Element[] | JSX.Element
}

const GeoMap = ({map}:IGeoMap) => {
    return(
        <>
            {map}
        </>
    )
}

export default GeoMap

import React from "react";

import {Box, InputLabel} from "@mui/material";

import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";


interface IDateController {
    label:string,
    date: (date: any) => void
}

export const DateSelector = ({ label, date }: IDateController) => {
    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <DatePicker
                    onChange={(value, context) => date(value)}/>
            </LocalizationProvider>
        </Box>
    )
}

import React, {useEffect, useState} from "react"
import {GridColDef, GridColumnHeaderParams, GridToolbar} from "@mui/x-data-grid-premium";
import {Box, Card, CardContent, CardHeader, Chip, Grid, IconButton, Stack, Typography} from "@mui/material";
import {SiGmail} from "react-icons/si";
import {TfiReload} from "react-icons/tfi";
import {MdCallSplit} from "react-icons/md";
import {BsTruckFront} from "react-icons/bs";
import ApiDFDSService from "../../../services/client/dfds/dfds.service";
import {getStorageData} from "../../../utils/usePersistedState";

import SearchBar from "../../../components/form/controllers/search.input";

import {DataGrid, trTR} from "@mui/x-data-grid";

const DFDSCompaniesPage = () => {
    const [companies, setCompanies] = useState([])
    const [companiesCount, setCompaniesCount] = useState<number>(0)
    const [offset, setOffset] = useState(50);
    const [refreshing, setRefreshing] = useState<boolean>(true)

    const [loading, setLoading] = useState<boolean>(true)

    const [debouncedValue, setDebouncedValue] = useState<string>("");

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });

    const handleChange = (e: any) => {
        setDebouncedValue(e);
    };

    const columns: GridColDef[] = [
        {
            field: "name", headerName: "Firma", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.formattedValue}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Firma '}
                </strong>
            )
        },
    /*{
        field: "col2",
        headerName: "",
        minWidth: 110, flex: 1,
renderCell: (params: any) => (
  <Box sx={{
       display: "flex",
       justifyContent: "flex-end",
       alignItems: "center",
       width: "100%",
       paddingRight:"30px"
   }}>
       <Stack direction="row" spacing={4}>
           <IconButton aria-label="fingerprint" color="secondary" sx={{
               backgroundColor: "#F3F3F3",
               width: "36px",
               height: "36px"
           }}>
               <SiGmail/>
           </IconButton>
           <IconButton aria-label="fingerprint" color="secondary" sx={{
               backgroundColor: "#0099FF",
               width: "36px",
               height: "36px"
           }}>
               <TfiReload style={{
                   color: "white"
               }}/>
           </IconButton>
           <IconButton aria-label="fingerprint" color="secondary" sx={{
               backgroundColor: "#00E396",
               width: "36px",
               height: "36px"
           }}>
               <MdCallSplit style={{
                   color: "white"
               }}/>
           </IconButton>
           <IconButton aria-label="fingerprint" color="secondary" sx={{
               backgroundColor: "#FEB019",
               width: "36px",
               height: "36px"
           }}>
               <BsTruckFront style={{
                   color: "white",
               }}/>
           </IconButton>
       </Stack>
   </Box>
        ),
        }*/
    ];

    useEffect(() => {
        const fetchCompanies = (params:{
            accessToken:string,
            search?:string,
            offset?:number,
            limit:number
        }) => {
            setLoading(true);
            setCompanies([])
            ApiDFDSService.fetchCompanies({
                accessToken:params.accessToken,
                search:params.search,
                offset:params.offset,
                limit:params.limit
            }).then((res) => {
                //@ts-ignore
                setCompanies(prevVehicles => [...prevVehicles, ...res.results])
                //@ts-ignore
                setCompaniesCount(res.count)
                setLoading(false)
            });
        };

        getStorageData("authState", true).then((auth) => {
            fetchCompanies({
                //@ts-ignore
                accessToken: auth.status.access_token,
                search:debouncedValue,
                offset: (paginationModel.page * paginationModel.pageSize),
                limit: paginationModel.pageSize,
            })
        })
    }, [paginationModel]);

    useEffect(() => {
        setCompanies([])
        setPaginationModel({
            page:0,
            pageSize: 50
        })
    }, [debouncedValue, refreshing ]);


    return (
        <Grid md={12}>
            <Card sx={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                boxShadow: "none"
            }}>
                <CardHeader sx={{
                    marginLeft:1,
                    marginRight:1
                }} title={
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography sx={{
                            fontSize: "18px",
                            fontWeight: 600,
                        }}>
                            Firmalar <Chip label={companiesCount ? companiesCount + " Firma" : 0 + " Firma"} color="primary" variant="outlined" />
                        </Typography>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <SearchBar tooltipTitle={"Firma Ara"} onChangeText={(text)=>handleChange(text)} placeHolder={"Ara"}/>
                        </Stack>
                    </Stack>
                }
                />
                <CardContent>
                    <DataGrid
                        columns={columns}
                        rows={companies}
                        getRowId={(row: any) => row.id + row.plate}
                        sx={{
                            height: "calc(100vh - 200px)",
                            width: "100%",
                            '& .MuiDataGrid-virtualScroller': {
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {height: 5, WebkitAppearance: 'none', width: 5},
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: 8,
                                    border: '1px solid',
                                    borderColor: 'white',
                                    backgroundColor: 'rgba(0 0 0 / 0.5)',
                                },
                            },
                        }}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                        pagination
                        paginationMode="server"
                        pageSizeOptions={[25, 50, 75, 100]}
                        rowCount={companiesCount}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        loading={loading}
                    />
                </CardContent>
            </Card>
        </Grid>
    )
}

export default DFDSCompaniesPage

import React from "react";

import { UserPermission } from "./types/permissionProvider.type";

import usePermission from "./usePermission";

/**
 * PermissionRestricted bileşeni için Props.
 *
 * @typedef {Object} Props
 * @property {UserPermission} to - Bileşeni render etmek için gereken izin.
 * @property {JSX.Element | string} [fallback] - İzin verilmediğinde render edilecek yedek bileşen veya mesaj.
 * @property {JSX.Element | string} [loadingComponent] - İzin kontrolü yapılırken render edilecek bileşen veya mesaj.
 * @property {*} children - İzin verildiyse render edilecek içerik.
 */
type TypePermissionRestrictedProps = {
    to: UserPermission;
    fallback?:JSX.Element | string;
    loadingComponent?: JSX.Element | string;
    children: any;
}

/**
 * PermissionRestricted bileşeni.
 *
 * @component
 * @param {Props} props - Bileşen props'ları.
 * @returns {React.ReactNode} - İzin durumuna göre render edilen bileşen.
 */
const PermissionRestricted = ({to, fallback, loadingComponent, children}:TypePermissionRestrictedProps) => {
    const [loading, allowed] = usePermission(to);

    if(loading) {
        return <>{loadingComponent}</>
    }
    else if(allowed){
        return <>{children}</>
    }
    else if (!allowed){
        return <>{fallback}</>
    }
    else {
        return  <>{loadingComponent}</>
    }
};

export default PermissionRestricted

import {
    Box,
    Card,
    CardActions,
    CardContent,
    Grid,
    Tooltip,
    Typography
} from "@mui/material"

import React, {useEffect, useState} from "react"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {useTheme} from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import ApiWarehousesService from "../../../services/client/warehouses/warehouses.service";
import {getStorageData} from "../../../utils/usePersistedState";
import {EWarehouseCardAction} from "../../../types/enums/components/card/warehouse/warehouse.card.enum";

/**
 * action : Show,Create,Edit,Delete
 */
interface IWarehouseCard {
    onclick: () => void
    name: string
    id: string,
    action: EWarehouseCardAction
    process:{
        cancelCreation:()=>void
    }
}

const WarehouseCard = (props: IWarehouseCard) => {
    const theme = useTheme();

    const [warehouseCardAction, setWarehouseCardAction] = useState<EWarehouseCardAction>(EWarehouseCardAction.Show)
    const [actionLoading, setActionLoading] = useState<boolean>(true)

    const changeWarehousesAction = (action: EWarehouseCardAction) => {
        setWarehouseCardAction(action)
    }

    const handleWarehousesAction = (action: EWarehouseCardAction) => {
        switch (action) {
            case EWarehouseCardAction.Show: {
                return (
                    <>
                    </>
                )
            }
            case EWarehouseCardAction.Edit: {
                getStorageData("authState", true).then((auth) => {
                    getStorageData("api", false).then((api) => {
                        ApiWarehousesService.updateWarehouses({
                            //@ts-ignore
                            apiUrl: api.url,
                            //@ts-ignore
                            accessToken: auth.status.access_token,
                            data: {}
                        }).then(() => {
                            setWarehouseCardAction(EWarehouseCardAction.Show)
                        })
                    })
                })
                return (
                    <></>
                )
            }
            case EWarehouseCardAction.Delete: {
                getStorageData("authState", true).then((auth) => {
                    getStorageData("api", false).then((api) => {
                        ApiWarehousesService.deleteWarehouses({
                            //@ts-ignore
                            apiUrl: api.url,
                            //@ts-ignore
                            accessToken: auth.status.access_token,
                            id: props.id
                        })
                    })
                })
            }
        }
    }

    useEffect(() => {
        setWarehouseCardAction(props.action)
    }, []);

    switch (warehouseCardAction) {
        case EWarehouseCardAction.Show: {
            return (
                <Card
                    sx={{
                        marginTop: "1rem !important",
                        maxWidth: 350,
                        maxHeight: 450,
                        textAlign: "center",
                    }}
                >
                    <CardContent>
                        <Grid md={12}>
                            <Grid md={8}>
                                <Box
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: "transparent",
                                            cursor: "pointer"
                                        },
                                    }}
                                    onClick={() => props.onclick()}>
                                    <img
                                        src={process.env.PUBLIC_URL + "images/Image_not_available.png"}
                                        style={{
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            width: "200px",
                                            height: "100%",
                                            margin: 50,
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid md={4}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    textAlign: "left"
                                }}>
                                    <Typography sx={{
                                        fontSize: "1rem",
                                        fontWeight: 500,
                                        color: `${theme.palette.text.primary}`,
                                    }}>
                                        {props.name + " Depo"}
                                    </Typography>
                                    <Tooltip
                                        title={"Bu depo kategorisi, ofis malzemeleri, kırtasiye ürünleri ve benzeri ihtiyaçların depolandığı alandır. Depoyu seçerek stokları kontrol edebilirsiniz."}>
                                        <Typography sx={{
                                            color: `${theme.palette.text.disabled}`,
                                            fontSize: "0.875rem"
                                        }}>
                                            Bu depo kategorisi, ofis malzemeleri, kırt...
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions sx={{
                        padding: "0 !important",
                        margin: "0 !important"
                    }}>
                        <Grid container md={12}>
                            <Grid md={4}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        '&:hover': {
                                            backgroundColor: `${theme.palette.text.disabled}`,
                                            cursor: "pointer"
                                        },
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={() => changeWarehousesAction(EWarehouseCardAction.Delete)}
                                >
                                    <DeleteOutlineOutlinedIcon fontSize={"small"}/>
                                    <Typography>
                                        Sil
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid md={8} sx={{
                                backgroundColor: `${theme.palette.info.main}`,
                            }}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "50px",
                                        color: `${theme.palette.text.secondary}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: `${theme.palette.text.disabled}`,
                                            cursor: "pointer"
                                        },
                                    }}
                                    onClick={() => changeWarehousesAction(EWarehouseCardAction.Edit)}
                                >
                                    Düzenle
                                </Box>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            )
        }
        case EWarehouseCardAction.Edit: {
            return (
                <Card
                    sx={{
                        marginTop: "1rem !important",
                        maxWidth: 350,
                        maxHeight: 450,
                        textAlign: "center",
                    }}
                >
                    <CardContent>
                        <Grid md={12}>
                            <Grid md={8}>
                                <Box>
                                    <input
                                        style={{
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            width: "200px",
                                            height: "100%",
                                            margin: 50,
                                        }}
                                        accept="image/*"

                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                    />
                                </Box>
                            </Grid>
                            <Grid md={4}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    textAlign: "left"
                                }}>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                        }}
                                        value={props.name + " Depo"}
                                    />
                                    <TextField
                                        sx={{
                                            width: "100%",
                                        }}
                                        value={"Bu depo kategorisi, ofis malzemeleri, kırt..."}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions sx={{
                        padding: "0 !important",
                        margin: "0 !important"
                    }}>
                        <Grid container md={12}>
                            <Grid md={4}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        '&:hover': {
                                            backgroundColor: `${theme.palette.text.disabled}`,
                                            cursor: "pointer"
                                        },
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={() => setWarehouseCardAction(EWarehouseCardAction.Show)}
                                >
                                    <DeleteOutlineOutlinedIcon fontSize={"small"}/>
                                    <Typography>
                                        İptal
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid md={8} sx={{
                                backgroundColor: `${theme.palette.info.main}`,
                            }}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "50px",
                                        color: `${theme.palette.text.secondary}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: `${theme.palette.text.disabled}`,
                                            cursor: "pointer"
                                        },
                                    }}
                                    onClick={() => handleWarehousesAction(EWarehouseCardAction.Edit)}
                                >
                                    Onayla
                                </Box>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            )
        }
        case EWarehouseCardAction.Delete: {
            return (
                <Card
                    sx={{
                        marginTop: "1rem !important",
                        maxWidth: 350,
                        maxHeight: 450,
                        textAlign: "center",
                    }}
                >
                    <CardContent>
                        <Grid md={12}>
                            <Grid md={8}>
                                <Box>
                                    <input
                                        style={{
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            width: "200px",
                                            height: "100%",
                                            margin: 50,
                                        }}
                                        accept="image/*"

                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                    />
                                </Box>
                            </Grid>
                            <Grid md={4}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    textAlign: "left"
                                }}>
                                    <TextField
                                        disabled
                                        sx={{
                                            width: "100%",
                                        }}
                                        value={props.name + " Depo"}
                                    />
                                    <TextField
                                        disabled
                                        sx={{
                                            width: "100%",
                                        }}
                                        value={"Bu depo kategorisi, ofis malzemeleri, kırt..."}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions sx={{
                        padding: "0 !important",
                        margin: "0 !important"
                    }}>
                        <Grid container md={12}>
                            <Grid md={4}>
                                <Box sx={{
                                    width: "100%",
                                    height: "100%",
                                    '&:hover': {
                                        backgroundColor: `${theme.palette.text.disabled}`,
                                        cursor: "pointer"
                                    },
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <DeleteOutlineOutlinedIcon fontSize={"small"}/>
                                    <Typography>
                                        İptal
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid md={8} sx={{
                                backgroundColor: `${theme.palette.info.main}`,
                            }}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "50px",
                                        color: `${theme.palette.text.secondary}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: 500
                                    }}
                                    onClick={() => handleWarehousesAction(EWarehouseCardAction.Show)}
                                >
                                    Depoyu Sil
                                </Box>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            )
        }
        case EWarehouseCardAction.Create: {
            return (
                <Card
                    sx={{
                        marginTop: "1rem !important",
                        maxWidth: 350,
                        maxHeight: 450,
                        textAlign: "center",
                    }}
                >
                    <CardContent>
                        <Grid md={12}>
                            <Grid md={8}>
                                <Box>
                                    <input
                                        style={{
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            width: "200px",
                                            height: "100%",
                                            margin: 50,
                                        }}
                                        accept="image/*"

                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                    />
                                </Box>
                            </Grid>
                            <Grid md={4}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    textAlign: "left"
                                }}>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            width: "100%",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions sx={{
                        padding: "0 !important",
                        margin: "0 !important"
                    }}>
                        <Grid container md={12}>
                            <Grid md={4}>
                                <Box
                                    sx={{
                                    width: "100%",
                                    height: "100%",
                                    '&:hover': {
                                        backgroundColor: `${theme.palette.text.disabled}`,
                                        cursor: "pointer"
                                    },
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                    onClick={()=>props.process.cancelCreation()}
                                >
                                    Iptal Et
                                </Box>
                            </Grid>
                            <Grid md={8} sx={{
                                backgroundColor: `${theme.palette.info.main}`,
                            }}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "50px",
                                        color: `${theme.palette.text.secondary}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: 500
                                    }}
                                >
                                    Depoyu Oluştur
                                </Box>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            )
        }
    }
}

export default WarehouseCard

//React
import React, {useEffect, useMemo, useState} from "react";


//React - Redux
import {useAppSelector, useAppDispatch, RootState} from "../../../store";

//MUI Templates
import {Box, Container, SwipeableDrawer, Alert} from '@mui/material';
import {DataGrid, GridToolbar, trTR} from '@mui/x-data-grid' ;

//Local Storage
import {getStorageData} from "../../../utils/usePersistedState";

import {WaitingActivitySkeleton} from "../../../components/skeletons/skeletons";

//Services
import {
    fetchWaitingActivity,
    fetchWaitingActivityParents
} from "../../../services/client/reports/waitingActivity/waitingActivity.service";

import WaitingActivityCommentsCard from "../../../components/card/waitingActivity/waitingActivity.comments.card";
import AlertTitle from "@mui/material/AlertTitle";

import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';

import {
    dateFormatting
} from "../../../utils/dateProvider/dateFormatting";

export default function WaitingActivityPage() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector((state: RootState) => state.waitingActivity.loading)
    const data = useAppSelector((state: RootState) => state.waitingActivity.data)

    //Parents Loading Control
    const [getParentsLoading, setParentsLoading] = useState<boolean>(false)

    //Waiting Activity Parents
    const [getWaitingActivityParents, setWaitingActivityParents] = useState([])

    const [getWaitingActivityId, setWaitingActivityId] = useState<any>()

    //Bottom Drawer State
    const [getDrawerOpen, setDrawerOpen] = React.useState(false);

    const [rowCountState, setRowState] = useState(0)

    const [paginationModel, setPaginationModel] = useState({
        pageSize:50,
        page:0
    })

    //Bottom Drawer Status
    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerOpen(newOpen);
    };

    const container = window !== undefined ? () => window.document.body : undefined;

    //Fetch Waiting Activity Parents
    const _fetchWaitingActivityParents = (vehicle_id: any) => {
        getStorageData("authState",true).then(auth => (
            dispatch(fetchWaitingActivityParents({
                    //@ts-ignore
                    access_token: auth.status.access_token,
                    vehicle_id: vehicle_id
                })
            ).then((res: any) => {
                setParentsLoading(false)
                setWaitingActivityParents(res.payload)
            })
        ))
    }

    useEffect(() => {
        getStorageData("authState",true).then(auth => (
            //@ts-ignore
            dispatch(fetchWaitingActivity({accessToken: auth.status.access_token, offset: paginationModel.page, limit: paginationModel.pageSize})).then(e=>{
                //@ts-ignore
                setRowState(e.payload.count)
            })
        ))
    }, [paginationModel])



    const columns = useMemo(() => [
        {
            field: 'plate',
            headerName: 'Plaka',
            width: 200
        },
        {
            field: 'driver',
            headerName: 'Sürücü',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {
            field: 'expedition_no',
            headerName: 'Sefer No',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {
            field: 'expedition_type',
            headerName: 'Sefer Tipi',
            width: 200,
            valueFormatter: (params: any) =>
                params.value == 0 ? "İhracat" : "Bilgi Bulanamadı" &&
                params.value == 1 ? "İthalat" : "Bilgi Bulanamadı" &&
                params.value == 2 ? "Transit" : "Bilgi Bulanamadı" &&
                params.value == 3 ? "Yurtiçi" : "Bilgi Bulanamadı" &&
                params.value ? params.value : "Bilgi Bulanamadı"
        },
        {
            field: 'status_name',
            headerName: 'Sefer Durumu',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {
            field: 'first_event_time',
            headerName: 'Bekleme Başlangıcı',
            width: 200,
            valueFormatter: (params: any) => {
                return dateFormatting(params.value)
            }
        },
        {
            field: 'last_event_time',
            headerName: 'Bekleme Bitişi',
            width: 200,
            valueFormatter: (params: any) => {
                return dateFormatting(params.value)
            }
        },
        {
            field: 'km_metric',
            headerName: 'KM Metrik',
            width: 200,
            valueFormatter: (params: any) => params.value  + " km"
        },
        {
            field: 'location_based_km_difference',
            headerName: 'Lokasyon Km',
            width: 200
        },
        {
            field: 'without_records_passing_time',
            headerName: 'Gerçekteki Km',
            width: 200
        },
        {
            field: 'first_location',
            headerName: 'İlk Konum',
            width: 200,
            valueFormatter: (params: any) => params.value?.city ? params.value?.city : "Bilinmiyor"
        },
        {
            field: 'last_location',
            headerName: 'Son Konum',
            width: 200,
            valueFormatter: (params: any) => params.value?.city ? params.value?.city : "Bilinmiyor"
        },
        {field: 'vehicle_type', headerName: 'Araç Tipi', width: 200}
    ], [])

    const parentColumns = useMemo(() => [
        {
            field: 'passing_day',
            headerName: 'Bekleme',
            width: 200,
            renderCell: (params: any) => (
                params.value > 3 ?
                    <Alert severity="error" icon={<DepartureBoardIcon/>}>
                        <AlertTitle>{params.value} Gün</AlertTitle>
                    </Alert> : <Alert severity="info" icon={<DepartureBoardIcon/>}>
                        <AlertTitle>{params.value} Gün</AlertTitle>
                    </Alert> && params.value > 2 ? <Alert severity="warning" icon={<DepartureBoardIcon/>}>
                        <AlertTitle>{params.value} Gün</AlertTitle>
                    </Alert> : <Alert severity="info" icon={<DepartureBoardIcon/>}>
                        <AlertTitle>{params.value} Gün</AlertTitle>
                    </Alert>
            ),
        },
        {
            field: 'plate',
            headerName: 'Plaka',
            width: 200
        },
        {
            field: 'driver',
            headerName: 'Sürücü',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {
            field: 'expedition_no',
            headerName: 'Sefer No',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {
            field: 'expedition_type',
            headerName: 'Sefer Tipi',
            width: 200,
            valueFormatter: (params: any) =>
                params.value == 0 ? "İhracat" : "Bilgi Bulanamadı" &&
                params.value == 1 ? "İthalat" : "Bilgi Bulanamadı" &&
                params.value == 2 ? "Transit" : "Bilgi Bulanamadı" &&
                params.value == 3 ? "Yurtiçi" : "Bilgi Bulanamadı" &&
                params.value ? params.value : "Bilgi Bulanamadı"
        },
        {
            field: 'status_name',
            headerName: 'Sefer Durumu',
            width: 200,
            valueFormatter: (params: any) => params.value ? params.value : "Bilgi bulunamadı!"
        },
        {
            field: 'first_event_time',
            headerName: 'Bekleme Başlangıcı',
            width: 200,
            hide: true,
            valueFormatter: (params: any) => {
                return dateFormatting(params.value)
            }
        },
        {
            field: 'last_event_time',
            headerName: 'Bekleme Bitişi',
            width: 200,
            valueFormatter: (params: any) => {
                return dateFormatting(params.value)
            }
        },
        {
            field: 'km_metric',
            headerName: 'KM Metrik',
            width: 200,
            valueFormatter: (params: any) => params.value  + " km"
        },
        {
            field: 'location_based_km_difference',
            headerName: 'Lokasyon Km',
            width: 200,
            valueFormatter: (params: any) => params.value  + " km"
        },
        {
            field: 'without_records_passing_time',
            headerName: 'Gerçekteki Km',
            width: 200,
            valueFormatter: (params: any) => params.value  + " km"
        },
        {
            field: 'first_location',
            headerName: 'İlk Konum',
            width: 200,
            valueFormatter: (params: any) => params.value?.city ? params.value?.city : "Bilinmiyor"
        },
        {
            field: 'last_location',
            headerName: 'Son Konum',
            width: 200,
            valueFormatter: (params: any) => params.value?.city ? params.value?.city : "Bilinmiyor"
        },

    ], [])

    if (loading || data == null) {
        return (
            <WaitingActivitySkeleton/>
        )
    } else {
        return (
            <Box>
                <DataGrid
                    sx={{
                        height: 400,
                        width: "100%",
                        marginBottom: "1rem"
                    }}
                    columns={columns}
                    rows={data.results}
                    getRowId={(row: any) => row.log_id}
                    onCellClick={(item: any) => {
                        setParentsLoading(true)
                        _fetchWaitingActivityParents(item.row.vehicle_id)
                    }}
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {debounceMs: 500},
                        },
                    }}
                    rowCount={rowCountState}
                    pageSizeOptions={[10,25,50]}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    density={"compact"}
                    localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                />

                <SwipeableDrawer
                    container={container}
                    anchor="right"
                    open={getDrawerOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    swipeAreaWidth={0}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <Container sx={{
                        marginTop:"5rem"
                    }}>
                        <Box sx={{
                            width:"400px"
                        }}>
                            <WaitingActivityCommentsCard id={getWaitingActivityId} fallbackStatus={(status:any)=>setDrawerOpen(status)}/>
                        </Box>
                    </Container>
                </SwipeableDrawer>

                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{field: 'passing_day', sort: 'desc'}]
                        },
                        columns: {
                            columnVisibilityModel: {
                                plate: false,
                                driver:false,
                                expedition_no:false,
                                expedition_type:false,
                                status_name:false
                            },
                        },
                    }}
                    sx={{
                        height: 500,
                        width: "100%"
                    }}
                    columns={parentColumns}
                    rows={getWaitingActivityParents}
                    loading={getParentsLoading}
                    slots={{toolbar: GridToolbar}}
                    onCellClick={(e) => {
                        setDrawerOpen(true)
                        setWaitingActivityId(e.id)

                    }}
                    density={"compact"}
                    localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
        )
    }
}

import React, {useEffect, useState} from "react"
import {Box, Stack, Typography} from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LiaFileInvoiceSolid } from "react-icons/lia";


interface IDiscountDetail {
    discount: object
}


const DFDSDiscountDetail = (props: IDiscountDetail) => {
    const [loading, setLoading] = useState(true)
    const [groupedDiscounts, setGroupedDiscounts] = useState({});


    useEffect(() => {
        //@ts-ignore
        console.log(Object.keys(props.discount).length,"Discount")

        setLoading(true)

        if (Object.keys(props.discount).length > 0){
           setLoading(false)
        }

        //@ts-ignore
        const updatedGroupedDiscounts = {};

        //@ts-ignore
        props.discount?.criteria?.forEach((res: any) => {
            const groupKey = res.group || "default";
            //@ts-ignore
            if (!updatedGroupedDiscounts[groupKey]) {
                //@ts-ignore
                updatedGroupedDiscounts[groupKey] = [];
            }

            //@ts-ignore
            updatedGroupedDiscounts[groupKey].push(res);
        });

        setGroupedDiscounts(updatedGroupedDiscounts);

    }, [props.discount]);


    const calculateTotal = (groupKey: string) => {
        let totalBookingCount = 0;
        let totalDiscountTotal = 0;

        //@ts-ignore
        groupedDiscounts[groupKey].forEach((res: any) => {
            totalBookingCount += res.booking_count || 0;
            totalDiscountTotal += res.discount_total || 0;
        });
        return { totalBookingCount, totalDiscountTotal };
    };


    return (
        loading ?
            <Box sx={{
                display: 'flex',
                justifyContent: 'center', // Yatayda ortala
                alignItems: 'center', // Dikeyde ortala
                height:"100%"
            }}>
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                >
                    <LiaFileInvoiceSolid style={{
                        fontSize:"150px",
                        color:"rgba(1,1,1,0.1)"
                    }}/>
                    <Typography>
                        Henüz bir fatura seçimi yapmadınız.
                    </Typography>
                </Stack>
            </Box> :
            <Box sx={{
                width: "100%",
                height: "calc(100vh - 205px)",
            }}>
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={0}
                    sx={{
                        margin: "10px"
                    }}
                >
                    <Box>
                        <Typography sx={{
                            fontSize: "32px",
                            fontWeight: 500,
                            lineHeight: "32px"
                        }}>
                            {
                                //@ts-ignore
                                props.discount.company_name
                            }
                        </Typography>
                    </Box>
                    <TableContainer sx={{
                        border: "1px solid rgba(233, 233, 233, 1)",
                        borderRadius: "5px",
                        overflowX: "auto",
                        overflowY: "auto",
                        width: "100%",
                        height: "calc(100vh - 205px)",
                    }}>
                        <Table sx={{ minWidth: 650, minHeight:310 }} aria-label="simple table">
                            <TableHead sx={{
                                position:"sticky",
                                top:0,
                                backgroundColor:"white"
                            }}>
                                <TableRow>
                                    <TableCell sx={{ minWidth: 150, position: "sticky" }}>Kriter</TableCell>
                                    <TableCell sx={{ minWidth: 150 }} align="right">Araç Sayısı</TableCell>
                                    <TableCell sx={{ minWidth: 150 }} align="right">İndirim</TableCell>
                                    <TableCell sx={{ minWidth: 150 }} align="right">İndirim Tutarı</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ minHeight: 310 }}>
                                {Object.keys(groupedDiscounts).sort((a, b) => (a === "default" ? 1 : b === "default" ? -1 : a.localeCompare(b)))
                                    .map((groupKey) => (
                                    <React.Fragment key={groupKey}>
                                        <TableRow sx={{
                                            height:"5px",
                                            backgroundColor:"rgba(0,0,0,0.1)",
                                        }}>
                                            <TableCell
                                                colSpan={4}
                                                align="left"
                                                sx={{
                                                    fontSize:"14px",
                                                    fontWeight:600
                                                }}
                                            >
                                                {groupKey === "default" ? "Diğer" : groupKey}
                                            </TableCell>
                                        </TableRow>
                                        {
                                            //@ts-ignore
                                            groupedDiscounts[groupKey].map((res:any) => (
                                            <TableRow key={res.id} sx={{
                                                height:"5px"
                                            }}>
                                                <TableCell align="left">{res.criterion_label}</TableCell>
                                                <TableCell align="right">{res.booking_count}</TableCell>
                                                <TableCell align="right">{res.discount} €</TableCell>
                                                <TableCell align="right">{res.discount_total} €</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow sx={{
                                            height:"5px"
                                        }}>
                                            <TableCell align="left" sx={{
                                                fontSize:"14px",
                                                fontWeight:600
                                            }}>İndirim Toplamı</TableCell>
                                            <TableCell align="right" sx={{
                                                fontSize:"14px",
                                                fontWeight:600
                                            }}>{calculateTotal(groupKey).totalBookingCount}</TableCell>
                                            <TableCell colSpan={4} align="right" sx={{
                                                fontSize:"14px",
                                                fontWeight:600
                                            }}>{calculateTotal(groupKey).totalDiscountTotal} €</TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/*<Typography sx={{
                    fontSize:"16px",
                    fontWeight:500,
                    marginTop:2
                }}>
                    İndirim Fatura Email
                </Typography>
                <TableContainer sx={{
                    border:"1px solid rgba(233, 233, 233, 1)",
                    borderRadius:"5px"
                }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography>
                                        GF Logistics
                                    </Typography>
                                    <Typography>
                                        İndirim tutarı
                                    </Typography>
                                    <Typography>
                                        Merhaba Charlie,
                                        Aralık ayında Mersin'den Trieste'ye yapılan gemi seferleri nedeniyle,
                                        faturalandırılan 5 tam araç için 425 Euro tutarında kısmi bir iade talep ediyoruz.
                                        Bu bağlamda, lütfen gerekli belgeleri düzenleyin, bunlar arasında kısmi iade
                                        faturası ve açıklaması bulunmaktadır, ve iade süreci için bunları tarafımıza
                                        iletmek üzere lütfen düzenleyin. Bu konuya gösterdiğiniz hızlı yanıtınız büyük
                                        ölçüde takdir edilmektedir.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>*/}
                </Stack>
            </Box>
    )
}

export default DFDSDiscountDetail

import * as React from 'react';
import {PieChart, pieArcLabelClasses} from '@mui/x-charts/PieChart';

import {useEffect, useState} from "react";
import {Box} from "@mui/material";

interface IPieChartComponent {
    data: any,
    series: {
        innerRadius: number,
        outerRadius: number,
        paddingAngle: number,
        cornerRadius: number,
        startAngle: number,
        endAngle: number,
        cx: number,
        cy: number,
    }
    legend: {
        hidden: boolean,
        position: {
            vertical: string
            horizontal: string
        }
        direction: string,
        offset: {
            x?: number,
            y?: number
        }
    }
}

export default function PieChartComponent(props: IPieChartComponent) {
    const [centerTitle, setCenterTitle] = useState(0)

    useEffect(() => {
        props.data.map((res: any) => {
            setCenterTitle(centerTitle + res.value)
        })
    }, []);
    return (
        <div style={{
            position: 'relative',
            textAlign: 'center',
            width: "100%",
        }}>
            {/*<div style={{*/}
            {/*    position: 'absolute',*/}
            {/*    top: '50%',*/}
            {/*    left: '10%',*/}
            {/*    //transform: 'translate(-50%, -50%)',*/}
            {/*}}>*/}
            {/*    0000*/}
            {/*</div>*/}
            <Box>
                <PieChart
                    series={[
                        {

                            data: props.data,
                            innerRadius: props.series.innerRadius,
                            outerRadius: props.series.outerRadius,
                            paddingAngle: props.series.paddingAngle,
                            cornerRadius: props.series.cornerRadius,
                            startAngle: props.series.startAngle,
                            endAngle: props.series.endAngle,
                            cx: props.series.cx,
                            cy: props.series.cy
                        },
                    ]}
                    width={600}
                    height={280}
                    legend={{
                        hidden: props.legend.hidden,
                        position: {
                            //@ts-ignore
                            vertical: props.legend.position.vertical,
                            //@ts-ignore
                            horizontal: props.legend.position.horizontal
                        },
                        //@ts-ignore
                        direction: props.legend.direction,
                        offset: {
                            x: props.legend.offset.x,
                            y: props.legend.offset.y
                        },
                    }}
                    sx={{
                        [`& .${pieArcLabelClasses.faded}`]: {
                            fill: 'red',
                            fontWeight: 'bold',
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "center",
                            color:"red",
                        },
                    }}
                />
            </Box>

        </div>
    );
}

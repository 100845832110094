interface SortingOption {
    label: string;
    positiveValue: string;
    negativeValue: string;
    isActive: boolean;
}

/**
 * Sefer sıralama seçenekleri.
 */
export const ExpeditionSortingOptions:SortingOption[] = [
    {
        label: "Sefer Numarası",
        positiveValue: "position_no",
        negativeValue: "-position_no",
        isActive: false,
    },
    {
        label: "Sefer Türü",
        positiveValue: "position_type",
        negativeValue: "-position_type",
        isActive: false,
    },
    {
        label: "Sefer Durumu",
        positiveValue: "position_status",
        negativeValue: "-position_status",
        isActive: false,
    },
    {
        label: "Günceleme Tarihi",
        positiveValue: "updated_date",
        negativeValue: "-updated_date",
        isActive: false,
    },
    {
        label: "Oluşturma Tarihi",
        positiveValue: "created_date",
        negativeValue: "-created_date",
        isActive: false,
    },
];

/**
 * Yük sıralama seçenekleri.
 */
export const LoadsSortingOptions: SortingOption[] = [
    {
        label: "Yük Numarası",
        positiveValue: "load_no",
        negativeValue: "-load_no",
        isActive: false,
    },
    {
        label: "Yük Tipi",
        positiveValue: "load_type",
        negativeValue: "-load_type",
        isActive: false,
    },
    {
        label: "Yük Durumu",
        positiveValue: "load_status",
        negativeValue: "-load_status",
        isActive: false,
    },
    {
        label: "Günceleme Tarihi",
        positiveValue: "updated_date",
        negativeValue: "-updated_date",
        isActive: false,
    },
    {
        label: "Oluşturma Tarihi",
        positiveValue: "created_date",
        negativeValue: "-created_date",
        isActive: false,
    },
];

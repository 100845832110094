import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {IExpeditions} from "../../../types/interfaces/reducers/expeditions/expeditions.interface";
import {slug} from "../../../config";

interface ExpeditionsService {
    fetchExpeditions(extra: { access_token: string; apiUrl: string; }): Promise<AxiosResponse<IExpeditions>>;
}

const fetchExpeditions = createAsyncThunk(
    "fetchExpeditions",
    async (extra: { access_token: string; apiUrl: string; }) => {
        const response = await axios.get<IExpeditions>(
            extra.apiUrl + slug.client.expeditions.url,
            {
                params: {
                    //limit:250
                },
                headers: {
                    "Content-type": "application/json",
                    // @ts-ignore
                    Authorization: "Bearer " + extra.access_token,
                },
            }
        );
        return response;
    }
);


export const ApiExpeditionsService: ExpeditionsService = {
    //@ts-ignore
    fetchExpeditions:fetchExpeditions
};

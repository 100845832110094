import React from "react";
import axios from "axios";

//API Config
import { slug } from "../../../config";

/**
 * Fetch Adverts Service
 * @param token
 */
export const advertFetchService = async (token:string) => {
    const response = await axios.get(slug.client.adverts.url, {
        headers: {
            'Authorization': 'Bearer ' +token.valueOf()
        }
    })
    return response.data.results;
}

/**
 * Advert Create Service
 * @param data
 * @param token
 */
export const advertCreateService = (data:object, token:string) => {
    const response = axios.post(slug.client.adverts.url, data, {
        headers:{
            'Authorization': 'Bearer '+token.valueOf()
        }
    });
    return response;
}

/**
 * Advert Delete Service
 * @param accessToken
 * @param advertCategoryId
 */
export const advertDeleteService = async (accessToken:string, advertCategoryId:string) => {
    const response = await axios.delete(slug.client.adverts.url+advertCategoryId,{
        headers: {
            "Content-type": "application/json",
            //@ts-ignore
            'Authorization': 'Bearer '+accessToken.valueOf()
        }
    })
    return response
}

/**
 * Advert Edit Service
 * @param data
 * @param accessToken
 * @param advertCategoryId
 */
export const advertEditService =  async (data:any, accessToken:string,advertCategoryId:string) => {
    const response = await axios.put(slug.client.adverts.url+advertCategoryId, data,{
        headers: {
            "Content-type": "application/json",
            //@ts-ignore
            'Authorization': 'Bearer '+accessToken.valueOf()
        }
    })
    return response
}

/**
 * Advert Detail Service
 * @param accessToken
 * @param advertId
 */
export const advertDetailService = async (accessToken:string, advertId:string)=> {
    const response = await axios.get(slug.client.adverts.url+advertId,{
        headers: {
            "Content-type": "application/json",
            //@ts-ignore
            'Authorization': 'Bearer '+accessToken.valueOf()
        }
    })
    return response.data
}

/**
 * Advert Update Service
 * @param accessToken
 * @param data
 * @param advertId
 */
export const advertUpdateService = async (accessToken:string, data:any, advertId:string) => {
    const response = await axios.put(slug.client.adverts.url+advertId, data,{
        headers: {
            "Content-type": "application/json",
            //@ts-ignore
            'Authorization': 'Bearer '+accessToken.valueOf()
        }
    })
    return response
}

/**
 * Advert Item Update Service
 * @param accessToken
 * @param data
 * @param advertId
 */
export const advertItemUpdateService = async (accessToken:string, data:any, advertId:string) => {
    const response = await axios.put(
        slug.client.adverts.url + advertId + slug.client.adverts.items.url + "multiple",
        data,{
        headers: {
            "Content-type": "application/json",
            'Authorization': 'Bearer '+accessToken.valueOf()
        }
    })
    return response
}

/**
 *
 * @param accessToken
 * @param advertId
 */
export const advertItemFetchService = async (accessToken:string, advertId:string) => {
    const response = await axios.get(`http://apitest.abcws.prod/tr/adverts/`+advertId+`/items`, {
        headers:{
            "Content-type": "application/json",
            'Authorization': 'Bearer '+accessToken.valueOf()
        }
    })
    return response
}


/**
 * Fetch Advert Categories
 * @param token
 */
export const advertCategoryFetchService = async (token:string) => {
    const response = await axios.get(slug.client.adverts.categories.url, {
        headers: {
            'Authorization': 'Bearer '+token.valueOf()
        }
    })
    return response
}

/**
 * Create Advert Category
 * @param data
 * @param token
 */
export const advertCategoryCreateService = (data:any, token:string) => {
    const response = axios.post("", data, {
        headers: {
            'Authorization': 'Bearer '+token.valueOf()
        }
    });
    return response;
}

/**
 * Delete Advert Category
 * @param accessToken
 * @param advertCategoryId
 */
export const advertCategoryDeleteService = async (accessToken:string, advertCategoryId:string) => {
    const response = await axios.delete("" + advertCategoryId,{
        headers: {
            "Content-type": "application/json",
            //@ts-ignore
            'Authorization': 'Bearer '+accessToken.valueOf()
        }
    })
    return response
}

/**
 * Edit Advert Category
 * @param data
 * @param accessToken
 * @param advertCategoryId
 */
export const advertCategoryEditService = async (data:any, accessToken:string,advertCategoryId:string) => {
    const response = await axios.put(slug.client.adverts.categories.url+"/"+advertCategoryId, data,{
        headers: {
            "Content-type": "application/json",
            //@ts-ignore
            'Authorization': 'Bearer '+accessToken.valueOf()
        }
    })
    return response
}

/**
 * Detail Advert Category
 * @param accessToken
 * @param advertCategoryId
 */
export const advertCategoryDetailService = async (accessToken:string, advertCategoryId:string)=> {
    const response = await axios.get(slug.client.adverts.categories.url+"/"+advertCategoryId,{
        headers: {
            "Content-type": "application/json",
            //@ts-ignore
            'Authorization': 'Bearer '+accessToken.valueOf()
        }
    })
    return response.data
}



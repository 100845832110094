import React, { useContext, useState } from "react";


import { UserPermission } from "./types/permissionProvider.type";

import PermissionContext from "./permissionContext";

const usePermission = (permission: UserPermission) => {
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed ] = useState<boolean>()

    const { isAllowedTo } = useContext(PermissionContext);

    isAllowedTo(permission).then((allowed) => {
        setLoading(false)
        setAllowed(allowed)
    })
    return [loading, allowed]
}

export default usePermission

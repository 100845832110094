import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import { useEffect, useMemo, useState } from "react";
import { Button, Stack, useTheme } from '@mui/material';
import { GridToolbarContainer, trTR } from "@mui/x-data-grid";
import { dateFormatting } from '../../../utils/dateProvider/dateFormatting';
import SearchBar from '../../form/controllers/search.input';
import { getStorageData } from '../../../utils/usePersistedState';
import ApiQuickDataExpeditionsService from '../../../services/client/reports/quickData/quickData.service';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Avatar, Card, CardContent, CardHeader, Grid, Tooltip, Typography } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DateRangePickerComponent from '../../datepicker/datePickers';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import dayjs, { Dayjs } from 'dayjs';

// interface SubRowData {
//     id: number;
//     load_no: string;
//     truck: string;
//     trailer: string,
//     start_date: string,
// }

interface Load {
    load_no: string;
    customer: string;
    load_city: string;
    load_country: string;
    unload_city: string;
    unload_country: string;
    freight: number;
    load_postal_code: string;
    customer_representative: string;
    load_type: number;
    previous_load_distance: number;
    unload_sequence_no: number;
    unload_postal_code: string
}

interface Auth {
    isLoggedIn: boolean;
    status: {
        access_token: string;
        expires_in: number;
        token_type: string;
        scope: string;
        refresh_token: string;
    };
}

export default function ExpeditionDistancesGrid() {
    const theme = useTheme();
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [distanceİnfo, setDistanceİnfo] = useState<object[]>([])
    const [openPopup, setOpenPopup] = useState(false);
    const [loadDetails, setLoadDetails] = useState([]);
    const [firstTime, setFirstTime] = useState("")
    const [lastTime, setLastTime] = useState("")
    const [defaultValueDateRange, setDefaultValueDateRange] = useState([dayjs().subtract(7, 'day'), dayjs()])


    const itemStyle = {
        border: "2px solid #EBEBEB",
        borderRadius: "10px",
        padding: "5px",
        marginBottom: "10px",
        color: "black",
        '&:hover': {
            borderColor: `${theme.palette.info.light}`  // Change border color on hover to blue

        },
    }

    const handleShowLoadDetails = (load: any) => {
        setLoadDetails(load);
        setOpenPopup(true);
    };

    const handleShowLoadDetailsIM = (load: any) => {
        setLoadDetails(load);
        setOpenPopup(true);
    };

    const handleGoogleRedirectEx = (load: any) => {
        const link = `${load.ex_google_map_url}`
        window.open(link, '_blank');
    };

    const handleGoogleRedirectIm = (load: any) => {
        const link = `${load.tr_im_google_map_url}`
        window.open(link, '_blank');
    };

    const handleGoogleRedirectKmDifference = (load: any) => {
        const link = `${load.km_difference_google_map_url}`
        window.open(link, '_blank');
    };

    const handleGoogleRedirectSlazburg = (load: any) => {
        const link = `${load.salzburg_google_map_url}`
        window.open(link, '_blank');
    };

    const handleGoogleRedirectSete = (load: any) => {
        const link = `${load.sete_google_map_url}`
        window.open(link, '_blank');
    };

    const handleGoogleRedirectFernetti = (load: any) => {
        const link = `${load.fernetti_google_map_url}`
        window.open(link, '_blank');
    };

    const handleGoogleRedirectCoccau = (load: any) => {
        const link = `${load.coccau_valico_google_map_url}`
        window.open(link, '_blank');
    };

    const handleGoogleRedirectFullDistance = (load: any) => {
        const link = `${load.full_distance_google_map_url}`
        window.open(link, '_blank');
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    // Render the "Show Loads" button in the cell
    const renderShowLoadsButtonEX = (params: any) => (
        <div>
            <Button
                onClick={() => handleShowLoadDetails(params.row.ex_loads)}
                sx={{
                    cursor: 'pointer', color: `rgba(12, 154, 242, 1)`, backgroundColor: `rgba(12, 154, 242, 0.21)
                    `, borderRadius: "20px", fontSize: "11px", boxShadow: 'none', '&:hover': {
                        backgroundColor: `rgba(12, 154, 242, 0.21)`, boxShadow: 'none'
                    },
                }}
                variant='contained'
            >
                Yükü Göster ({params.row.ex_loads.length})
            </Button>
        </div>
    );

    const renderShowLoadsButtonIM = (params: any) => (
        <div>
            <Button
                onClick={() => handleShowLoadDetailsIM(params.row.tr_im_loads)}
                sx={{
                    cursor: 'pointer', color: `rgba(12, 154, 242, 1)`, backgroundColor: `rgba(12, 154, 242, 0.21)
                    `, boxShadow: 'none', borderRadius: "20px", fontSize: "11px", '&:hover': {
                        backgroundColor: `rgba(12, 154, 242, 0.21)                        `,
                        boxShadow: 'none',
                    },
                }}
                variant='contained'
            >
                Yükü Göster ({params.row.tr_im_loads.length})
            </Button>
        </div>
    );

    const redirectToGoogleEx = (params: any) => (
        <div>
            <button
                onClick={() => handleGoogleRedirectEx(params.row)}
                style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'blue', display: "flex", alignItems: 'center', }}
            >
                <span style={{ marginRight: '1px' }}>Haritada gör</span>
                <Box>
                    <FiExternalLink size={19} style={{ marginLeft: '5px' }} />
                </Box>
            </button>
        </div>
    );

    const redirectToGoogleIm = (params: any) => (
        <div>
            <button
                onClick={() => handleGoogleRedirectIm(params.row)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'blue'
                }}
            >
                <span style={{ marginRight: '1px' }}>Haritada gör</span>
                <Box>
                    <FiExternalLink size={19} style={{ marginLeft: '5px' }} />
                </Box>
            </button>
        </div>
    );

    const handleRedirectGoogleMap = (params: any) => {
        return (
            <Box style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <span onClick={() => handleGoogleRedirectKmDifference(params.row)}
                    style={{ color: "blue", marginRight: '5px' }}>{params.value.toFixed(1)}
                    <FiExternalLink size={19} style={{ color: "blue", marginLeft: "5px", }} />
                </span>
            </Box>
        )

    };

    const handleRedirectSalzburg = (params: any) => {
        return (
            <Box style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <span onClick={() => handleGoogleRedirectSlazburg(params.row)}
                    style={{ color: "blue", marginRight: '5px' }}>{params.value.toFixed(1)}
                    <FiExternalLink size={19} style={{ color: "blue", marginLeft: "5px", }} />
                </span>
            </Box>
        )

    };

    const handleRedirectSete = (params: any) => {
        return (
            <Box style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <span onClick={() => handleGoogleRedirectSete(params.row)}
                    style={{ color: "blue", marginRight: '5px' }}>{params.value.toFixed(1)}
                    <FiExternalLink size={19} style={{ color: "blue", marginLeft: "5px", }} />
                </span>
            </Box>
        )

    };

    const handleRedirectFernetti = (params: any) => {
        return (
            <Box style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <span onClick={() => handleGoogleRedirectFernetti(params.row)}
                    style={{ color: "blue", marginRight: '5px' }}>{params.value.toFixed(1)}
                    <FiExternalLink size={19} style={{ color: "blue", marginLeft: "5px", }} />
                </span>
            </Box>
        )

    };

    const handleRedirectCoccau = (params: any) => {
        return (
            <Box style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <span onClick={() => handleGoogleRedirectCoccau(params.row)}
                    style={{ color: "blue", marginRight: '5px' }}>{params.value.toFixed(1)}
                    <FiExternalLink size={19} style={{ color: "blue", marginLeft: "5px", }} />
                </span>
            </Box>
        )

    };

    const handleFullDisance = (params: any) => (
        <Box style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <span onClick={() => handleGoogleRedirectFullDistance(params.row)}
                style={{ color: "blue", marginRight: '5px' }}>Ringi Gör
                <FiExternalLink size={19} style={{ color: "blue", marginLeft: "5px", }} />
            </span>
        </Box>
    );

    const handleClickDateRangePicker = (firstTime: string, lastTime: string) => {
        setFirstTime(firstTime)
        setLastTime(lastTime)
    }

    useEffect(() => {
        setDataLoading(true)
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                ApiQuickDataExpeditionsService.fetchCorporateDistance({
                    //@ts-ignore
                    apiUrl: api.url,
                    //@ts-ignore
                    accessToken: auth.status.access_token,
                    //@ts-ignore
                    trImLoadDateGte: firstTime,
                    trImLoadDateLte: lastTime,
                }).then((res: any) => {
                    //@ts-ignore
                    const dataWithIds = res.data.km_travel_out_of_expeditions.series[0].value.map((item: any, index: number) => ({
                        ...item,
                        id: index + 1, // Generate a unique id for each row (you can use a different logic if needed)
                    }));
                    console.log(dataWithIds)
                    setDistanceİnfo(dataWithIds);
                    setDataLoading(false)
                })
            })
        })

    }, [paginationModel, lastTime]);


    // useEffect(() => {
    //     if (firstLocation && lastLocation) {
    //         const googleMapsUrl = `https://www.google.com/maps/dir/${firstLocation}/${lastLocation}`;

    //         // Redirect to Google Maps after a delay

    //         window.open(googleMapsUrl, '_blank');
    //     }
    // }, [firstLocation, lastLocation]);

    const columns = useMemo(() => [
        {
            field: 'plate',
            headerName: 'Plaka',
            width: 150,

        },
        {
            field: 'ex_driver',
            headerName: 'EX Sürücü',
            width: 150,

        },
        {
            field: 'ex_expedition_no',
            headerName: 'EX Sefer No',
            width: 150,

        },
        {
            field: 'ex_unloading_city',
            headerName: 'EX Boşaltma Şehri',
            width: 150,

        },
        {
            field: 'ex_end_time',
            headerName: 'EX Bitiş Tarihi',
            width: 150,
            renderCell: (params: any) => (
                params.formattedValue ? dateFormatting(params.formattedValue) : "Bilgi bulunamadı!"
            )
        },
        {
            field: 'ex_loads',
            headerName: 'Detay',
            width: 140,
            renderCell: renderShowLoadsButtonEX
        },

        {
            field: 'ex_google_map_url',
            width: 150,
            renderCell: redirectToGoogleEx,
            renderHeader: () => (
                <>
                    <Typography sx={{ fontSize: "15px", fontWeight: "medium" }}>
                        Google map url
                    </Typography>
                </>
            ),

        },
        {
            field: 'tr_im_driver',
            headerName: 'IM Sürücü',
            width: 150,

        },

        {
            field: 'tr_im_expedition_no',
            headerName: 'TR-IM Sefer No',
            width: 150,

        },
        {
            field: 'tr_im_loading_city',
            headerName: 'TR Yükleme Şehri',
            width: 150,
        },

        {
            field: 'tr_im_load_time',
            headerName: 'TR-IM Yükleme Tarihi',
            width: 150,
            renderCell: (params: any) => (
                params.formattedValue ? dateFormatting(params.formattedValue) : "Bilgi bulunamadı!"
            )
        },
        {
            field: 'tr_im_loads',
            headerName: 'TR İM Yük',
            width: 150,
            renderCell: renderShowLoadsButtonIM,
            renderHeader: () => (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <Typography sx={{ fontSize: "15px", fontWeight: "medium" }}>Detay</Typography>
                </Box>
            ),
        },

        {
            field: 'tr_im_google_map_url',
            width: 150,
            renderCell: redirectToGoogleIm,
            renderHeader: () => (
                <>
                    <Typography sx={{ fontSize: "15px", fontWeight: "medium" }}>
                        Google map url
                    </Typography>
                </>
            ),

        },

        {
            field: 'distance',
            width: 150,
            renderCell: (params: any) => (
                params.value ? params.value.toFixed(1) : "!"

            ),
            renderHeader: () => (
                <>
                    <Tooltip
                        title={"İhracat boşaltma tarihi ile ithalat veya transit yükleme tarihi arasındaki araç takip sisteminden alınan toplam km bilgisi "}>
                        <Avatar sx={{
                            backgroundColor: "transparent",
                            width: "20px",
                            height: "20px"
                        }}>
                            <InfoOutlinedIcon fontSize={"small"} sx={{
                                color: "black"
                            }} />
                        </Avatar>
                    </Tooltip>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        Araç Km'si
                    </Typography>
                </>
            ),
        },
        {
            field: 'km_difference',
            width: 150,
            renderCell: handleRedirectGoogleMap,
            renderHeader: () => (
                <>
                    <Tooltip
                        title={"İhracat son konumu ile ithalat ya da transit son yükleme konumu arasındaki koordinat bilgileri kullanılarak ölçülen mesafe"}>
                        <Avatar sx={{
                            backgroundColor: "transparent",
                            width: "20px",
                            height: "20px"
                        }}>
                            <InfoOutlinedIcon fontSize={"small"} sx={{
                                color: "black"
                            }} />
                        </Avatar>
                    </Tooltip>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        Boşaltma-Yükleme Km Farkı
                        <FiExternalLink />
                    </Typography>
                </>
            ),

        },

        {
            field: 'salzburg_km_difference',
            width: 150,
            renderCell: handleRedirectSalzburg,
            renderHeader: () => (
                <>
                    <Tooltip
                        title={"İhracat son boşaltma adresiden Salzburg'a kadar enlem,boylam kullanılarak ölçülen mesafe"}>
                        <Avatar sx={{
                            backgroundColor: "transparent",
                            width: "20px",
                            height: "20px"
                        }}>
                            <InfoOutlinedIcon fontSize={"small"} sx={{
                                color: "black"
                            }} />
                        </Avatar>
                    </Tooltip>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        Salzburg Km Farkı
                    </Typography>
                </>
            ),
        },
        {
            field: 'sete_km_difference',
            width: 150,
            renderCell: handleRedirectSete,
            renderHeader: () => (
                <>
                    <Tooltip
                        title={"İhracat son boşaltma adresiden Sete limanına kadar enlem,boylam kullanılarak ölçülen mesafe"}>
                        <Avatar sx={{
                            backgroundColor: "transparent",
                            width: "20px",
                            height: "20px"
                        }}>
                            <InfoOutlinedIcon fontSize={"small"} sx={{
                                color: "black"
                            }} />
                        </Avatar>
                    </Tooltip>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        Sete Km Farkı
                    </Typography>
                </>
            ),
        },
        {
            field: 'fernetti_km_difference',
            width: 150,
            renderCell: handleRedirectFernetti,
            renderHeader: () => (
                <>
                    <Tooltip
                        title={"İhracat son boşaltma adresiden Trieste limanı'na kadar enlem,boylam kullanılarak ölçülen mesafe"}>
                        <Avatar sx={{
                            backgroundColor: "transparent",
                            width: "20px",
                            height: "20px"
                        }}>
                            <InfoOutlinedIcon fontSize={"small"} sx={{
                                color: "black"
                            }} />
                        </Avatar>
                    </Tooltip>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        Fernetti Km Farkı
                    </Typography>
                </>
            ),
        },
        {
            field: 'coccau_valico_km_difference',
            width: 150,
            renderCell: handleRedirectCoccau,
            renderHeader: () => (
                <>
                    <Tooltip
                        title={"İhracat son boşaltma adresiden coccau_valico'a kadar enlem,boylam kullanılarak ölçülen mesafe"}>
                        <Avatar sx={{
                            backgroundColor: "transparent",
                            width: "20px",
                            height: "20px"
                        }}>
                            <InfoOutlinedIcon fontSize={"small"} sx={{
                                color: "black"
                            }} />
                        </Avatar>
                    </Tooltip>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        Coccau Valico Km Farkı
                    </Typography>
                </>
            ),
        },
        {
            field: 'full_distance_google_map_url',
            width: 150,
            renderCell: handleFullDisance,
            renderHeader: () => (
                <>
                    <Tooltip
                        title={"İhracat ilk boşaltma adresinden transit veya ithalat son yükleme adresine kadar gidilen yol"}>
                        <Avatar sx={{
                            backgroundColor: "transparent",
                            width: "20px",
                            height: "20px"
                        }}>
                            <InfoOutlinedIcon fontSize={"small"} sx={{
                                color: "black"
                            }} />
                        </Avatar>
                    </Tooltip>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        Adresler
                    </Typography>
                </>
            ),
        },

    ], [])

    function EditToolbar() {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <GridToolbar />
                <Tooltip title="Burası açıklama bölümü olacak">
                    <HelpCenterIcon style={{ fontSize: 30, margin: "10px" }} />
                </Tooltip>
                {/* Rest of the toolbar content */}

            </div>
        );
    }

    return (
        <Grid md={12}>
            <Card sx={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                boxShadow: "none"
            }}>
                <CardHeader sx={{
                    padding: "16px 16px 0 16px"
                }} title={
                    <Grid container md={12}>
                        <Grid md={2} sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "1rem"
                        }}>
                            <Typography sx={{
                                fontSize: "18px",
                                fontWeight: 600
                            }}>
                                Sefer Dışı Yapılan Km
                            </Typography>
                        </Grid>
                        <Grid md={10} sx={{
                            display: "flex",
                            flexDirection: "row-reverse"
                        }}>
                            <Stack direction="row" spacing={1} sx={{
                                '& .MuiFormLabel-root': {
                                    fontSize: "12px",
                                    fontWeight: 600
                                },
                            }}>

                                <Box sx={{ width: "300px" }}>
                                    <DateRangePickerComponent
                                        event={(firstTime, lastTime) => handleClickDateRangePicker(firstTime, lastTime)} defaultValueDateRange={defaultValueDateRange} />
                                </Box>

                            </Stack>
                        </Grid>
                    </Grid>
                } />
                <CardContent>

                    <Box sx={{ height: "calc(100vh - 145px)", width: '100%' }}>
                        <DataGridPremium

                            sx={{
                                borderRadius: "10px 10px 0px 0px",
                                backgroundColor: `${theme.palette.background.default}`,
                                '& .MuiDataGrid-virtualScroller': {
                                    overflow: 'auto',
                                    '&::-webkit-scrollbar': { height: 10, WebkitAppearance: 'none', width: 10 },
                                    '&::-webkit-scrollbar-thumb': {
                                        borderRadius: 8,
                                        border: '1px solid',
                                        borderColor: 'white',
                                        backgroundColor: 'rgba(0 0 0 / 0.5)',
                                    },
                                },

                            }}
                            rows={distanceİnfo}
                            //@ts-ignore
                            columns={columns}
                            slots={{
                                toolbar: EditToolbar,
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'distance', sort: 'desc' }]
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        load_no: false
                                    },
                                },

                            }}
                            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                            pagination
                            pageSizeOptions={[50, 100]}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            loading={dataLoading}
                        />

                        <Dialog open={openPopup} onClose={handleClosePopup}>
                            <DialogContent sx={{ minWidth: '600px' }}>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClosePopup}
                                    sx={{
                                        position: 'absolute',
                                        right: 5,
                                        top: 5,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <div>
                                    <Typography variant='h4'
                                        sx={{ fontWeight: 'medium', color: "black", marginBottom: "15px" }}>Yük
                                        Detayı</Typography>
                                    {loadDetails.length > 0 ?
                                        //@ts-ignore
                                        loadDetails?.map((load: Load, index) => (
                                            <div key={index}>

                                                <Box sx={{
                                                    display: 'flex', marginBottom: "15px"
                                                }}>
                                                    <Typography sx={{
                                                        border: "2px solid #EBEBEB",
                                                        borderRadius: "10px",
                                                        padding: " 5px",
                                                        marginBottom: "5px",
                                                        color: "black",
                                                        width: "30%",
                                                        marginRight: "10px"
                                                    }}>Yük No:  <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>{load.load_no ? load.load_no : ""}</Typography></Typography>
                                                    <Typography sx={{
                                                        backgroundColor: "lightblue",
                                                        border: "2px solid #EBEBEB",
                                                        borderRadius: "10px",
                                                        padding: "5px",
                                                        marginBottom: "5px",
                                                        color: `${theme.palette.info.light}`,
                                                        width: "15%",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>{load.load_type == 0 ? "İhracat" : load.load_type == 1 ? "İthalat" : load.load_type == 2 ? "Transit" : load.load_type == 3 ? "Yurtiçi" : "Bilgi bulunamadı"}</Typography>
                                                </Box>

                                                <Typography
                                                    sx={itemStyle}>
                                                    Müşteri: <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>
                                                        {load.customer ? load.customer : ""}
                                                    </Typography>{" "}

                                                </Typography>
                                                <Typography sx={itemStyle}>Müşteri
                                                    Temsilcisi: <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}> {load.customer_representative ? load.customer_representative : ""}</Typography></Typography>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: "space-between",
                                                    border: "2px solid #EBEBEB",
                                                    borderRadius: "10px",
                                                    padding: "5px",
                                                    marginBottom: "10px",
                                                    color: "black",
                                                    '&:hover': {
                                                        borderColor: `${theme.palette.info.light}`,  // Change border color on hover to blue
                                                    },
                                                }}>
                                                    <Typography>
                                                        Yükleme Yeri:
                                                        <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>
                                                            {load.load_city && load.load_country ? `${load.load_city} / ${load.load_country}` : ""}
                                                        </Typography>
                                                    </Typography>
                                                    <Typography>
                                                        Posta Kodu:
                                                        <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>
                                                            {load.load_postal_code ? load.load_postal_code : ""}
                                                        </Typography>
                                                    </Typography>
                                                </Box>

                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: "space-between",
                                                    border: "2px solid #EBEBEB",
                                                    borderRadius: "10px",
                                                    padding: "5px",
                                                    marginBottom: "10px",
                                                    color: "black",
                                                    '&:hover': {
                                                        borderColor: `${theme.palette.info.light}`,  // Change border color on hover to blue
                                                    },
                                                }}>
                                                    <Typography>
                                                        Boşaltma Yeri:
                                                        <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>
                                                            {load.unload_city && load.unload_country ? load.unload_city + "/" + load.unload_country : ""}
                                                        </Typography>
                                                    </Typography>
                                                    <Typography>
                                                        Posta Kodu:
                                                        <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>
                                                            {load.unload_postal_code ? load.unload_postal_code : ""}
                                                        </Typography>
                                                    </Typography>

                                                </Box>
                                                {load.previous_load_distance != null ? <Typography sx={itemStyle}>Önceki mesafeye
                                                    uzaklık:
                                                    <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>
                                                        {load.previous_load_distance.toFixed(0) + " km"}</Typography></Typography> : null}

                                                <Typography sx={itemStyle}>Boşaltma sıra
                                                    numarası: <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>{load.unload_sequence_no ? load.unload_sequence_no : 1}</Typography></Typography>
                                                <Typography sx={itemStyle}>Navlun: <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>{load.freight} Euro</Typography></Typography>
                                                {loadDetails.length > 1 && <hr />}
                                            </div>
                                        )) : <h5>Yük Bilgisi Bulunamadı</h5>}
                                </div>
                            </DialogContent>
                        </Dialog>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
}

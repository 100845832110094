import React, {useEffect, useState} from "react"
import {Box, IconButton, Stack, Grid, CardHeader, Typography, Card, CardContent, Chip} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridColumnHeaderParams,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport, trTR
} from "@mui/x-data-grid";

import ApiDFDSService from "../../../services/client/dfds/dfds.service";

import {getStorageData} from "../../../utils/usePersistedState";

import SearchBar from "../../../components/form/controllers/search.input";

import CustomNoRowsOverlay from "../../../components/dataGrid/noRowsOverlay";

import BookingMenu from "../../../components/menu/bookings/booking.menu";
import IsActiveSelector from "../../../components/selectors/dfds/isActive.selector";
import CompanyIsNullSelector from "../../../components/selectors/dfds/company.isNull.selector";
import RefreshIcon from '@mui/icons-material/Refresh';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme} from "@mui/material/styles";



const DFDSVehiclesPage = () => {
    const theme = useTheme();

    const [loading, setLoading] = useState<boolean>(true)
    const [vehicles, setVehicles] = useState([])
    const [refreshing, setRefreshing] = useState<boolean>(true)

    const [ vehicleCount, setVehicleCount ] = useState<number>(0)

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });

    const [offset, setOffset] = useState(50);

    const [ search, setSearch ] = useState("")
    const [ isActive, setIsActive ] = useState("")
    const [ companyIsNull, setCompanyIsNull ] = useState("")

    const columns: GridColDef[] = [
        {
            field: "plate", headerName: "Plaka", minWidth: 110, flex: 0,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    fontWeight:500
                }}>
                    {params.formattedValue}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Plaka '}
                </strong>
            )
        },
        {
            field: "company_id", headerName: "Firma", minWidth: 110, flex: 2,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    fontWeight:500
                }}>
                    {params.row?.company?.company_name ? params.row?.company?.company_name : "Firma bilgisi bulunamadı."}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Firma '}
                </strong>
            )
        },
        {
            field: "is_active", headerName: "Aktif / Pasif",  minWidth: 110, flex: 0,
            renderCell: (params: any) => (
                params.value ? <CheckCircleIcon sx={{
                    color:`${theme.palette.success.main}`,
                }}/> : <CancelIcon sx={{
                    color:`${theme.palette.error.main}`,
                }}/>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                        {'Aktif'}
                </strong>
            )
        },
        {
            field: "col2",
            headerName: "İşlemler",
            minWidth: 110, flex: 0,
            renderCell: (value: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    paddingRight:"30px"
                }}>
                    <Stack direction="row" spacing={4}>
                        <BookingMenu rowValue={value} refreshing={()=>setRefreshing(!refreshing)}/>
                    </Stack>
                </Box>
            ),
        }
    ];

    const CustomToolbar = React.memo(() => {
        return (
            <GridToolbarContainer>
                <Grid container spacing={2} sx={{
                    position:"absolute",
                    top:"-40px"
                }}>
                    <Grid item xs={8}>
                        <GridToolbarColumnsButton/>
                        <GridToolbarFilterButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        )
    })

    useEffect(() => {
        //@ts-ignore
        const fetchVehicles = (params) => {
            setLoading(true);
            setVehicles([])
            ApiDFDSService.fetchVehicles(params).then((res) => {
                //@ts-ignore
                setVehicles(prevVehicles => [...prevVehicles, ...res.results]);
                //@ts-ignore
                setVehicleCount(res.count)
                setLoading(false);
            });
        };

        getStorageData("authState", true).then((auth) => {
            fetchVehicles({
                //@ts-ignore
                accessToken: auth.status.access_token,
                search,
                offset: (paginationModel.page * paginationModel.pageSize),
                limit: paginationModel.pageSize,
                isActive,
                companyIsNull
            });
        });

    }, [paginationModel]);


    useEffect(() => {
        setVehicles([])
        setPaginationModel({
            page:0,
            pageSize: 50
        })
    }, [search, isActive, companyIsNull, refreshing]);


    return(
        <Grid md={12} margin="0">
            <Card sx={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                boxShadow: "none"
            }}>
                <CardHeader
                    title={
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        margin={1}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            margin={1}
                        >
                            <Typography sx={{
                                fontSize: "18px",
                                fontWeight: 600
                            }}>
                                Araçlar <Chip label={vehicleCount ? vehicleCount + " Araç" : 0 + " Araç"} color="primary" variant="outlined" />
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <SearchBar tooltipTitle="Araç ve Firma Ara" placeHolder={"Ara"} onChangeText={(text) =>setSearch(text)}/>
                            <IsActiveSelector vehicle={(status)=>setIsActive(status)}/>
                            <CompanyIsNullSelector company={(status)=>setCompanyIsNull(status)}/>
                            <IconButton
                                sx={{ color:`${theme.palette.grey["A700"]}`}}
                                aria-label="add to shopping cart"
                                onClick={()=>setRefreshing(!refreshing)}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                }
                />
                <CardContent>
                    <Box>
                        <DataGrid
                            columns={columns}
                            rows={vehicles}
                            getRowId={(row: any) => row.id + row.plate}
                            slots={{
                                toolbar: CustomToolbar,
                                noRowsOverlay: () => <CustomNoRowsOverlay title="Arac Bulunamadı" />
                            }}
                            sx={{
                                height: "calc(100vh - 230px)",
                                width: "100%",
                                '& .MuiDataGrid-virtualScroller': {
                                    overflow: 'auto',
                                    '&::-webkit-scrollbar': {height: 5, WebkitAppearance: 'none', width: 5},
                                    '&::-webkit-scrollbar-thumb': {
                                        borderRadius: 8,
                                        border: '1px solid',
                                        borderColor: 'white',
                                        backgroundColor: 'rgba(0 0 0 / 0.5)',
                                    },
                                },
                            }}
                            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                            pagination
                            paginationMode="server"
                            pageSizeOptions={[25, 50, 75, 100]}
                            rowCount={vehicleCount}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            loading={loading}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default DFDSVehiclesPage

import axios, {AxiosResponse} from "axios";


interface Bookings {

}

interface IBookingVehiclesService {
    updateCompany(params:{
        accessToken:string,
        companyId:string,
        vehicleId:string
    }):Promise<AxiosResponse>;
    addCompany(params:{
        accessToken:string,
        companyId:string,
        vehicleId:string
    }):Promise<AxiosResponse>;
    updateVehicle(params:{
        accessToken:string,
        vehicleId:string,
        isActive:string,
    }):Promise<AxiosResponse>;
}

const ApiBookingVehiclesService: IBookingVehiclesService = {
    updateCompany: async (params: {
        accessToken: string,
        companyId:string,
        vehicleId:string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            }
        }
        try {
            const response = await axios.post(`https://booking.abclojistik.com/en/vehicles/${params.vehicleId}/change-company`, {
                "new_company_id":params.companyId
            }, config).then((res:any)=>{
                return res
            })
            return response
        }
        catch (e){
            throw e
        }
    },
    addCompany: async (params: { accessToken: string; companyId: string; vehicleId: string }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            }
        }
        try {
            const response = await axios.post(`https://booking.abclojistik.com/en/vehicles/${params.vehicleId}/assign-company`, {
                "company_id":params.companyId
            }, config).then((res:any)=>{
                return res
            })
            return response
        }
        catch (e){
            throw e
        }
    },
    updateVehicle: async (params: {
        accessToken: string;
        vehicleId: string;
        isActive: string;
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            }
        }
        try{
            const response = await axios.patch(`https://booking.abclojistik.com/en/vehicles/${params.vehicleId}`,{
                "is_active": params.isActive
            }, config).then((res:any)=>{
                return res
            })

            return response
        }
        catch (e){
            throw e
        }


    }
}

export default ApiBookingVehiclesService

import React, {useEffect, useState} from "react"
import {Card, CardActions, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import ApiWarehousesService from "../../services/client/warehouses/warehouses.service";
import {getStorageData} from "../../utils/usePersistedState";

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import {EWarehousesAction} from "../../types/enums/pages/warehouses/warehouses.page.enum";
import {EWarehouseStocksAction} from "../../types/enums/pages/warehouses/stocks/warehouses.stocks.page.enum";
import ApiWareHouseStockService from "../../services/client/warehouses/stock/warehouses.stock.service";
import InventoryIcon from '@mui/icons-material/Inventory';
import TextField from "@mui/material/TextField";
import {SubmitHandler, useForm} from "react-hook-form";
import {WarehouseFormType} from "../../types/types/components/forms/warehouses/warehouse.form.type";

import WarehouseStocksProductsFormController
    from "../../components/form/controllers/warehouses/stocks/warehouse.stocks.products.form.controller";
import WarehousesBarNavigation from "../../components/navigations/warehouses/warehouses.bar.navigation";
import WarehouseCard from "../../components/card/warehouse/warehouse.card";
import {EWarehouseCardAction} from "../../types/enums/components/card/warehouse/warehouse.card.enum";
import WarehouseProductStockCard from "../../components/card/warehouse/product/warehouse.product.stock.card";

interface IWarehouse {
    id: string
}


const WarehousePage = () => {

    const [warehouses, setWarehouses] = useState<object[]>([])
    const [warehousesAction, setWareHousesAction] = useState<EWarehousesAction>(EWarehousesAction.Show)
    const [warehousesLoading, setWarehousesLoading] = useState<boolean>(true)
    const [warehouse, setWarehouse] = useState<IWarehouse>({
        id: ""
    })

    const [warehousesStocksLoading, setWarehousesStocksLoading] = useState<boolean>(true)
    const [warehousesStocksAction, setWarehousesStocksAction] = useState<EWarehouseStocksAction>()
    const [warehouseStocks, setWarehouseStocks] = useState<object[]>([])

    const [warehouseCardAction, setWarehouseCardAction] = useState<EWarehouseCardAction>(EWarehouseCardAction.Show)


    const [canCreateNewWarehouse, setCanCreateNewWarehouse] = useState<boolean>(true); // Yeni veri oluşturulabilir mi?


    const handleWarehouseStocksAction = (params: {
        action: EWarehouseStocksAction | undefined,
        warehouse: IWarehouse
    }) => {
        setWarehouse(params.warehouse)
        setWarehousesStocksAction(params.action)
    }

    const handleWarehousesAction = (params: { action: EWarehousesAction | undefined, warehouse: IWarehouse }) => {
        //setWareHousesAction(params.action)
    }

    const addNewWarehouse = () => {
        if (canCreateNewWarehouse){
            setWarehouses(prevWarehouses => [...prevWarehouses, {}]);
            setCanCreateNewWarehouse(false); // Yeni veri oluşturma bayrağını devre dışı bırak
            setWarehouseCardAction(EWarehouseCardAction.Create)
            console.log(warehouses)
        }
        else{

        }
    };

    const removeLastWarehouse = () => {
        if (warehouses.length > 0) {
            const updatedWarehouses = [...warehouses]; // Mevcut depo dizisinin kopyası
            updatedWarehouses.pop(); // Son depoyu diziden çıkar
            setWarehouses(updatedWarehouses); // Güncellenmiş depo dizisini set et
            setCanCreateNewWarehouse(true); // Yeni veri oluşturma bayrağını etkinleştir
            setWarehouseCardAction(EWarehouseCardAction.Show);
        }
    };

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: {
            errors
        }
    } = useForm<WarehouseFormType>()

    const onSubmitForm: SubmitHandler<WarehouseFormType> = (data, event) => {
        data.unit = 1
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                ApiWareHouseStockService.createWarehouseStock({
                    //@ts-ignore
                    apiUrl: api.url,
                    //@ts-ignore
                    accessToken: auth.status.access_token,
                    warehouseId: warehouse.id,
                    data: data
                }).then(res => {
                    if (res.status == 201) {
                        handleWarehouseStocksAction({
                            action: EWarehouseStocksAction.Show,
                            warehouse: {id: warehouse.id}
                        })
                    }
                })
            })
        })
    }

    useEffect(() => {
        switch (warehousesAction) {
            case EWarehousesAction.Show: {
                switch (warehousesStocksAction) {
                    case EWarehouseStocksAction.Show: {
                        getStorageData("authState", true).then((auth) => {
                            getStorageData("api", false).then((api) => {
                                ApiWareHouseStockService.fetchWarehouseStock({
                                    //@ts-ignore
                                    apiUrl: api.url,
                                    //@ts-ignore
                                    accessToken: auth.status.access_token,
                                    warehouseId: warehouse.id
                                }).then((wareHouseStock) => {
                                    setWarehouseStocks(wareHouseStock.data.results)
                                })
                                setWarehousesStocksLoading(false)
                            })
                        })
                    }
                }
                getStorageData("authState", true).then((auth) => {
                    getStorageData("api", false).then((api) => {
                        //@ts-ignore
                        ApiWarehousesService.fetchWarehouses({apiUrl: api.url, accessToken: auth.status.access_token})
                            .then((warehouse) => {
                                setWarehousesLoading(false)
                                setWarehouses(warehouse.data.results)
                            })
                    })
                })
            }
        }

    }, [warehousesLoading, warehousesStocksAction]);


    switch (warehousesAction) {
        case EWarehousesAction.Show: {
            switch (warehousesStocksAction) {
                case EWarehouseStocksAction.Show: {
                    return (
                        warehousesStocksLoading ? <>"Stocklar Yükleniyor"</> :
                            <Container maxWidth="xl">
                                <Grid container md={12}>
                                    <Grid md={6}>
                                        <IconButton onClick={() => handleWarehouseStocksAction({
                                            action: undefined,
                                            warehouse: {id: " warehouse.id"}
                                        })}>
                                            <WarehouseRoundedIcon/>
                                        </IconButton>
                                    </Grid>
                                    <Grid md={6} display="flex" flexDirection="row-reverse">
                                        <Tooltip title={"Yeni Ürün Stok Oluştur"}>
                                            <IconButton
                                                onClick={() => handleWarehouseStocksAction({
                                                    action: EWarehouseStocksAction.Create,
                                                    warehouse: {id: warehouse.id}
                                                })}>
                                                <InventoryIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid md={12}>
                                    <Stack direction="row" spacing={1}>
                                        {
                                            warehouseStocks.map((warehouseStock) => (
                                                <WarehouseProductStockCard/>
                                                // <Card sx={{
                                                //     marginTop: "1rem !important",
                                                //     maxWidth: 350,
                                                //     maxHeight: 450,
                                                //     textAlign: "center",
                                                //     '&:hover': {
                                                //         backgroundColor: "transparent",
                                                //         cursor: "pointer"
                                                //     },
                                                // }}
                                                // >
                                                //     <CardHeader
                                                //         //@ts-ignore
                                                //         title={warehouseStock.product.name}
                                                //         titleTypographyProps={{
                                                //             style: {
                                                //                 fontWeight: 400,
                                                //                 fontSize: "0.875rem",
                                                //                 lineHeight: 1.43,
                                                //                 letterSpacing: "0.01071em",
                                                //                 display: "block",
                                                //                 margin: 0
                                                //             }
                                                //         }}
                                                //     />
                                                //     <CardContent>
                                                //         <img
                                                //             src={process.env.PUBLIC_URL + "images/Image_not_available.png"}
                                                //             style={{
                                                //                 backgroundRepeat: 'no-repeat',
                                                //                 backgroundSize: 'cover',
                                                //                 backgroundPosition: 'center',
                                                //                 width: "200px",
                                                //                 height: "100%",
                                                //                 margin: 50,
                                                //             }}/>
                                                //         <Typography>
                                                //             {
                                                //                 //@ts-ignore
                                                //                 warehouseStock.quantity + "Adet"
                                                //             }
                                                //         </Typography>
                                                //     </CardContent>
                                                //     <CardActions disableSpacing>
                                                //         <Tooltip title={"Depo Düzenle"}>
                                                //             <IconButton aria-label="add to favorites"
                                                //                         onClick={() => console.log("depo düzenle")}>
                                                //                 <EditRoundedIcon/>
                                                //             </IconButton>
                                                //         </Tooltip>
                                                //         <IconButton aria-label="share">
                                                //             <DeleteRoundedIcon/>
                                                //         </IconButton>
                                                //     </CardActions>
                                                // </Card>
                                            ))
                                        }
                                    </Stack>
                                </Grid>
                            </Container>
                    )
                }
                case EWarehouseStocksAction.Create: {
                    return (
                        <Container maxWidth="xl">
                            <Card
                                component="form"
                                sx={{
                                    marginTop: "1rem !important",
                                    maxWidth: 350,
                                    maxHeight: 450,
                                    textAlign: "center",
                                }}>
                                <CardHeader
                                    title={
                                        <WarehouseStocksProductsFormController
                                            label={"Ürünler"}
                                            //@ts-ignore
                                            productId={(id) => console.log(id)}
                                            //@ts-ignore
                                            register={register}
                                        />}
                                />
                                <CardContent>
                                    <TextField
                                        type="number"
                                        id="outlined-basic"
                                        label="Outlined"
                                        variant="outlined"
                                        {...register(`quantity`)}
                                        placeholder={"Ürün Miktarı"}/>
                                </CardContent>
                                <CardActions>
                                    <IconButton onClick={handleSubmit(onSubmitForm)}>
                                        Ekle
                                    </IconButton>
                                    <IconButton onClick={() => handleWarehouseStocksAction({
                                        action: EWarehouseStocksAction.Show,
                                        warehouse: {id: warehouse.id}
                                    })}>
                                        Iptal
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </Container>
                    )
                }
            }
            return (
                warehousesLoading ? <>Depolar Yükleniyor</> :
                    <Grid md={12}>
                        <Container maxWidth="xl">
                            <WarehousesBarNavigation warehouses={action => addNewWarehouse()}/>
                            <Stack direction="row" spacing={1}>
                                {
                                    warehouses.map((item) => (
                                        <WarehouseCard
                                            //@ts-ignore
                                            name={item.name}
                                            //@ts-ignore
                                            id={item.id}
                                            onclick={() => handleWarehouseStocksAction(
                                                {
                                                    action: EWarehouseStocksAction.Show,
                                                    warehouse: {
                                                        //@ts-ignore
                                                        id: item.id
                                                    }
                                                }
                                            )}
                                            action={warehouseCardAction}
                                            process={{cancelCreation:()=>removeLastWarehouse()}}
                                        />
                                    ))
                                }
                            </Stack>
                        </Container>
                    </Grid>
            )
        }
        default: {
            return (
                <>
                </>
            )
        }
    }
}

export default WarehousePage

import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Divider,
    Avatar,
    Typography, Box
} from '@mui/material';

interface ICommentsItemCard {
    firstName:string,
    lastName:string
    text:string,
    holderName:string,
    causeName:string,
    createdDate:string
}

const WaitingActivityCommentsItemCard:React.FC<ICommentsItemCard> = (props, context) => {
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary={props.holderName+" / "+props.causeName}
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {props.firstName+" "+props.lastName}
                            </Typography>
                            {" — " + props.text }
                            <Box>
                                <Typography>
                                    {props.createdDate}
                                </Typography>
                            </Box>
                        </React.Fragment>
                    }

                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </List>
    );
}

export default WaitingActivityCommentsItemCard;

import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import {Box, Button, Card, CardContent, Typography} from '@mui/material';

const Notfound = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(()=>{
        handleGoBack()
    },[])

    return (
        <Box sx={{
            display:"flex",
            flexDirection: 'column',
            alignItems:"center",
            justifyContent:"center",
        }}>
            <Card>
                <CardContent sx={{
                    display:"flex",
                    flexDirection: 'column',
                    alignItems:"center",
                    justifyContent:"center",
                }}>
                    <Typography variant="h4" gutterBottom>
                        404 - Sayfa Bulunamadı
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Aradığınız sayfa bulunamadı.
                    </Typography>
                </CardContent>
            </Card>

        </Box>
    );
}

export default Notfound;

import React, { useEffect, useState } from "react";
import { getStorageData } from "../../../../../utils/usePersistedState";
import ApiExpeditionsService from "../../../../../services/client/expeditions/expeditions.service";
import { Box, Button, Grid, List, Typography } from "@mui/material";
import RatingCustomerModal from "../../../../modal/rating.customer.modal";
import { autoHideDuration, horizontal, severity, vertical } from "../../../../../types/enums/components/snackbar/snackbar.enums";
import { SnackbarWrapper } from "../../../../snackbar/snackbar";
import CircularProgress from '@mui/material/CircularProgress';

interface IUnRatedExpeditions {
    id: string,
    is_user_voted: boolean,
    votes: [],
    position_no: string,
    trailer: {
        plate: string
    },
}

const styleButton = {
    backgroundColor: "rgba(243, 190, 2, 0.15)",
    color: "rgba(243, 190, 2, 1)",
    textTransform: "none",
    borderRadius: "6px",
    padding: "2px 14px",
    '&:hover': {
        background: "rgba(243, 190, 2, 0.15)",
    },

}

const CustomerWaitingRatings = () => {
    const [unRatedExpeditions, setUnRatedExpeditions] = useState([])
    const [render, reRender] = useState<any>(false)
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severityType, setSeverityType] = React.useState(severity.success); // Initialize severity state with error
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [loading, setLoading] = useState(false);

    const toggleReRender = () => {
        reRender(!render)
    }
    useEffect(() => {
        setLoading(true)
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                ApiExpeditionsService.fetchExpeditions({
                    //@ts-ignore
                    apiUrl: api.url,
                    //@ts-ignore
                    accessToken: auth.status.access_token,
                    is_user_voted: false
                }).then((res: any) => {
                    setUnRatedExpeditions(res.data.results)
                }).catch((res:any) => {
                }).finally(() => {
                    setLoading(false)
                });
            })
        });
    }, [render])
    return (
        <Box>
            {

                <SnackbarWrapper
                    open={alertOpen}
                    autoHideDuration={autoHideDuration.ms6000}
                    severity={severityType}
                    message={snackbarMessage}
                    anchorOrigin={{ vertical: vertical.bottom, horizontal: horizontal.right }}
                    close={(status: any) => setAlertOpen(status)}
                />
            }
            <Typography variant="h5" sx={{ fontWeight: "medium", marginBottom: "10px" }} >
                Değerlendirme Bekleyen Seferlerim
            </Typography>
            <List
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: "calc(100vh - 145px)",
                    '& ul': { padding: 0 },
                    '&::-webkit-scrollbar': {
                        display: "none",
                        msOverflowStyle: "none"
                    },
                    padding: "10px"
                }}
                subheader={<li />}
            >
                {
                    loading == false ?
                        <li>
                            <ul>
                                {
                                    //@ts-ignore
                                    unRatedExpeditions.length == 0 ? (
                                        <Grid container md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "200px", textAlign: "center" }}>
                                            <Grid md={12}>
                                                <Typography sx={{ color: "grey" }}>Değerlendirilmemiş seferiniz bulunmamaktadır.</Typography>
                                            </Grid>
                                        </Grid>
                                    ) : <>
                                        <Grid container md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Grid md={4}>
                                                <Typography variant="h6" sx={{}}>Sefer No</Typography>
                                            </Grid>
                                            <Grid md={4}>
                                                <Typography variant="h6">Plaka</Typography>
                                            </Grid>
                                            <Grid md={4}>
                                                <Typography></Typography>
                                            </Grid>

                                        </Grid>
                                    </>}


                                {unRatedExpeditions.map((unRatedExpedition: IUnRatedExpeditions) => {
                                    return (
                                        <>
                                            {unRatedExpedition.is_user_voted == false ?
                                                <>

                                                    <Grid container md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "5px" }}>
                                                        <Grid md={4}>
                                                            <Typography > {unRatedExpedition?.position_no}</Typography>
                                                        </Grid>
                                                        <Grid md={4}>
                                                            <Typography> {unRatedExpedition?.trailer?.plate}</Typography>
                                                        </Grid>
                                                        <Grid md={4}>
                                                            {/* <Button sx={{
                                                    backgroundColor: "rgba(243, 190, 2, 0.15)",
                                                    color: "rgba(243, 190, 2, 1)",
                                                    textTransform: "none",
                                                    borderRadius: "6px",
                                                    padding: "2px 14px",
                                                    '&:hover': {
                                                        background: "rgba(243, 190, 2, 0.15)",
                                                    },

                                                }}
                                                    onClick={handleRedirectToRate}
                                                >
                                                    Değerlendir
                                                </Button> */}
                                                            {
                                                                <RatingCustomerModal
                                                                    expeditionId={unRatedExpedition.id}
                                                                    votes={unRatedExpedition.votes}
                                                                    isUserVoted={unRatedExpedition.is_user_voted}
                                                                    toggleReRender={toggleReRender}
                                                                    setSnackbarMessage={setSnackbarMessage}
                                                                    setSeverityType={setSeverityType}
                                                                    setAlertOpen={setAlertOpen}
                                                                    styleButton={styleButton}
                                                                />
                                                            }
                                                        </Grid>
                                                        <hr />
                                                    </Grid>
                                                </>
                                                : <>

                                                </>}

                                        </>

                                    )
                                })}
                            </ul>
                        </li>
                        :
                        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>
                }
            </List>
        </Box>
    )
}

export default CustomerWaitingRatings

import React, { useEffect, useState } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

import { IOpenStreetMapTool } from "../../../../types/interfaces/components/geoMaps/openSteet.map.tool.interface";
import { Box, Typography } from "@mui/material";
import { FiUpload } from "react-icons/fi";
import { SiVolvo } from "react-icons/si";
import { FaTrailer } from "react-icons/fa6";

const OpenStreetMapTool = (props: IOpenStreetMapTool) => {

    const map = useMap()

    useEffect(() => {

        console.log(props.expedition_no,"AAA")

        const customControl = L.Control.extend({
            options: {
                position: "topleft",
                className: "custom-buttons leaflet-bar",
            },
            onAdd: function () {
                const container = L.DomUtil.create("div", "leaflet-bar leaflet-control");

                // SVG kodunu içeren bir div öğesi oluşturun
                const leftDrawerControlBtn = document.createElement("div");

                leftDrawerControlBtn.setAttribute(
                    "style",
                    "width:33px; height:33px; justify-content: center; display: flex; cursor: pointer; background-color: white; border: 2px solid rgba(0,0,0,0.2);"
                )
                leftDrawerControlBtn.innerHTML = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ChevronLeftRoundedIcon"><path d="M14.71 6.71a.9959.9959 0 0 0-1.41 0L8.71 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41z"></path></svg>`;

                // SVG div öğesini btn içine yerleştirin

                leftDrawerControlBtn.onclick = function () {
                    props.leftDrawerDisplay()
                };

                container.appendChild(leftDrawerControlBtn);

                const clusterControlBtn = document.createElement("div");

                clusterControlBtn.setAttribute(
                    "style",
                    "width:100%; height:33px; justify-content: center; display: flex; cursor: pointer; background-color: white; margin-left:1rem; border: 2px solid rgba(0,0,0,0.2); align-items: center; font-family: 'Roboto'; font-size: 13px; font-weight: 600;"
                )
                //clusterControlBtn.innerHTML = `<text>Kümelemeye Göster</text>`;

                clusterControlBtn.onclick = function () {
                    props.clusterDisplay()
                }

                container.appendChild(clusterControlBtn);

                container.setAttribute(
                    "style",
                    "margin-top: 0; left: 0; display: flex; justify-content: space-between; background-color: transparent; border:none"
                );

                return container;
            },
        });

        map.addControl(new customControl());
    }, [props.expedition_no]);
    return (
        // kontrol yapılarak id olabilir display gösterilebilir veya gösterilmeyebilir.
        // üstteki javascrip kodlarını kullanmaya gerek yok diye düşünüyorum . return den sonra react kullanılabilir.
        <Box sx={{
            position: 'absolute',
            top: '10px', // Adjust the top position as needed
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '1000', // Ensure it's above the map
            textAlign: 'left',
            width: '550px',
            height: '60px',
            backgroundColor: "white",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        }}>
            <Box sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
            }}>
                <FiUpload size={24} />
                <Box
                    sx={{ marginLeft: "7px" }}>
                    <Typography sx={{
                        color: "black",
                        fontWeight:"500",
                        fontSize:"14px",
                    }}>
                        Sefer Numarası
                    </Typography>
                    <Typography sx={{ color: "#7E7E7E", fontSize:"13px" }}>{props.expedition_no}</Typography>
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
            }}>
                <SiVolvo size={24} />
                <Box sx={{ marginLeft: "7px" }}>
                    <Typography sx={{
                        color: "black",
                        fontWeight:"500",
                        fontSize:"14px"
                    }}>Çekici Plaka</Typography>
                    <Typography sx={{ color: "#7E7E7E", fontSize:"13px" }}>{props.truck?.plate}</Typography>
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
            }}>
                <FaTrailer size={24} />
                <Box sx={{ marginLeft: "7px" }}>
                    <Typography sx={{
                        color: "black",
                        fontWeight:"500",
                        fontSize:"14px"
                    }}>Römork Plaka</Typography>
                    <Typography
                        sx={{
                            color: "#7E7E7E",
                            fontSize:"13px"
                    }}>
                        {props.trailer?.plate}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )

}

export default OpenStreetMapTool

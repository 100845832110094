import axios, { AxiosResponse } from 'axios';
import {slug} from "../../../config";

interface ProductUnit {
    // Ürün birimi veri yapısı
}

interface ProductService {
    apiUrl?: string;
    fetchProductUnit(accessToken: string): Promise<AxiosResponse>;
    createProductUnit(accessToken: string, data:any): Promise<AxiosResponse>;
    updateProductUnit(accessToken: string, data: any, unitID: string): Promise<AxiosResponse>;
    deleteProductUnit(accessToken: string, unitID: string): Promise<AxiosResponse>;
}

const apiUrl = slug.client.product.unit.url;

export const ApiProductUnitService: ProductService = {

    fetchProductUnit: async (accessToken: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };

        try {
            const response = await axios.get(apiUrl, { headers });
            return response;
        } catch (error) {
            throw error;
        }
    },

    createProductUnit: async (accessToken: string, data:any): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };

        try {
            const response = await axios.post(apiUrl, data, { headers })
            return response;
        }
        catch (error) {
            throw error
        }
    },

    updateProductUnit: async (accessToken: string, data: any, unitID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };

        try {
            const response = await axios.put(apiUrl+unitID, data, {headers});
            return response
        }
        catch (error)  {
            throw error
        }
    },

    deleteProductUnit: async (accessToken: string, unitID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };

        try {
            const response = await axios.delete(apiUrl+unitID, { headers });
            return response
        } catch (error) {
            throw error
        }
    }
}


import React, { useEffect, useRef, useState } from "react"
import {
    Badge,
    Box,
    Card,
    CardContent, Collapse,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon, ListItemText,
    Tabs,
    Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../../../store";
import { PlaceholderTexts } from "../../../../types/enums/components/placeholder.enum";
import { getStorageData, setStorageData } from "../../../../utils/usePersistedState";
import { dateFormatting } from "../../../../utils/dateProvider/dateFormatting";
import ApiExpeditionsService from "../../../../services/client/expeditions/expeditions.service";
import { UserRoles } from "../../../../types/enums/reducers/user.enums";
import ApiLoadsService from "../../../../services/client/loads/loads.service";
import ApiVehiclesService from "../../../../services/client/vehicles/vehicles.service";
import Search from "../../../search/search";
import ExpeditionSortingSelector from "../../../selectors/expedition/expeditionSorting.selector";
import {
    ExpeditionSortingOptions,
    LoadsSortingOptions
} from "../../../../types/interfaces/components/selectors/expeditionSorting.selector.interface";
import Tab from "@mui/material/Tab";
import ExpeditionTypeSelector from "../../../selectors/expedition/expeditionType.selector";
import { ComponentView } from "../../../../types/enums/components/component.enum";
import CachedIcon from "@mui/icons-material/Cached";
import CircularProgress from "@mui/material/CircularProgress";
import { FiBox } from "@react-icons/all-files/fi/FiBox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { FaTrailer } from "@react-icons/all-files/fa/FaTrailer";
import ExpeditionActivitySelector from "../../../selectors/expedition/expeditionActivity.selector";

import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';

import Stack from "@mui/material/Stack";
import {
    OtsLeftDrawerNavigationInterface
} from "../../../../types/interfaces/components/navigations/drawers/ots/ots.left.drawer.navigation.interface";
import { IExpeditionsModel } from "../../../../types/models/services/expeditions/expeditions.model";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


const VehiclesSortingOptions = [
    {
        label: "Plaka",
        positiveValue: "plate",
        negativeValue: "-plate",
        isActive: false,
    },
]

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const PageOtsLeftDrawer = (props: OtsLeftDrawerNavigationInterface) => {

    const theme = useTheme();

    const userInfo = useAppSelector((state) => state.user);

    /**
     * TabPanel içindeki seferler liste öğesine referans alır.
     * @type {React.MutableRefObject<HTMLUListElement | null>}
     */
    const expeditionsListRef = useRef<HTMLUListElement>(null);

    /**
     * TabPanel içindeki yükler liste öğesine referans alır.
     * @type {React.MutableRefObject<HTMLUListElement | null>}
     */
    const loadsListRef = useRef<HTMLUListElement>(null);

    /**
     * TabPanel içindeki araçlar liste öğesine referans alır.
     * @type {React.MutableRefObject<HTMLUListElement | null>}
     */
    const vehiclesListRef = useRef<HTMLUListElement>(null);

    /**
     * Expedition API için kullanılacak olan offset değerini tutar.
     * @type {number}
     */
    const [expeditionsOffset, setExpeditionsOffset] = useState(0);

    /**
     * Yükler API için kullanılacak olan offset değerini tutar.
     * @type {number}
     */
    const [loadsOffset, setLoadsOffset] = useState(0);

    /**
     * Yükler API için kullanılacak olan offset değerini tutar.
     * @type {number}
     */
    const [vehiclesOffset, setVehiclesOffset] = useState(0);


    const [debouncedValue, setDebouncedValue] = useState<string>("");
    const [tab, setTab] = useState(0);

    const [expeditionsSearch, setExpeditionsSearch] = useState<any>([])
    const [expeditionsLoading, setExpeditionsLoading] = useState(true)

    const [loadsSearch, setLoadsSearch] = useState([])
    const [loadsLoading, setLoadsLoading] = useState(true)

    const [vehiclesSearch, setVehiclesSearch] = useState([])
    const [vehiclesLoading, setVehiclesLoading] = useState(true)


    const [type, setType] = useState<number>(0)

    const [count, setCount] = useState<string>("")

    const [openList, setOpenList] = useState<Array<boolean>>([]);

    const [refresh, setRefresh] = useState<boolean>(false)

    const [isCompleted, setIsCompleted] = useState<boolean>(false)

    useEffect(() => {
        setExpeditionsSearch([])
        console.log(isCompleted)
    }, [isCompleted]);


    /**
     * Bir seferin seçilmiş dizinini temsil eder.
     *
     * @typedef {any} ExpeditionSelectedIndex
     */
    const [expeditionSelectedIndex, setExpeditionSelectedIndex] = useState<any>(null);

    /**
     * Bir seferin seçilmiş alt dizinini temsil eder.
     *
     * @typedef {any} ExpeditionSubSelected
     */
    const [expeditionSubSelectedIndex, setExpeditionSubSelectedIndex] = useState<any>(null)

    /**
     * Bir yükün seçilmiş dizinini temsil eder.
     *
     * @typedef {any} LoadSelectedIndex
     */
    const [loadSelectedIndex, setLoadSelectedIndex] = useState<any>(null)

    /**
     * Bir aracın seçilmiş dizinini temsil eder.
     *
     * @typedef {any} LoadSelectedIndex
     */
    const [vehiclesSelectedIndex, setVehiclesSelectedIndex] = useState<any>(null)

    const [ordering, setOrdering] = useState("");

    const [updateTime, setUpdateTime] = useState<string>("")

    const [totalExpeditions, setTotalExpeditions] = useState<string>("")

    const [totalLoad, setTotalLoad] = useState<string>("")

    const [totalVehicles, setTotalVehicles] = useState<string>("")

    const [scrollLoading, setScrollLoading] = useState<boolean>(false)
    //placeholder ın değişmesi için stateler
    const [currentTextPlaceholderIndex, setCurrentTextPlaceholderIndex] = useState(0);
    const [displayPlaceholderText, setDisplayPlaceholderText] = useState("");
    const [indexPlaceholder, setIndexPlaceholder] = useState(0);

    //placeholder enum yapısından gelen text yazıları
    const texts = [
        PlaceholderTexts.FIRST,
        PlaceholderTexts.SECOND,
        PlaceholderTexts.THIRD,
        PlaceholderTexts.FOURTH,
    ];

    // Placeholderın dinamik değişmesi için
    useEffect(() => {
        const typingInterval = setInterval(() => {
            if (indexPlaceholder < texts[currentTextPlaceholderIndex].length) {
                setDisplayPlaceholderText((prevText) => prevText + texts[currentTextPlaceholderIndex].charAt(indexPlaceholder));
                setIndexPlaceholder((prevIndex) => prevIndex + 1);
            } else {
                clearInterval(typingInterval);
                // Bir mesaj yazdıktan sonra bir süre bekle ve ardından bir sonraki mesaja geç.
                setTimeout(() => {
                    setDisplayPlaceholderText(""); // current texti temizle
                    setCurrentTextPlaceholderIndex((prevIndex) => (prevIndex + 1) % texts.length); // Bir sonraki mesaja geç (sıfıra döndürerek sonsuz döngü sağlar)
                    setIndexPlaceholder(0); // bir sonraki mesaj için index'i sıfırla
                }, 1000); // mesajlar arasındaki erteleme (in milliseconds)
            }
        }, 80); // gecikmeyi değiştirerek yazma hızını ayarla (in milliseconds)

        return () => {
            clearInterval(typingInterval);
        };
    }, [indexPlaceholder, texts, currentTextPlaceholderIndex]);


    /**
     * Sonraki sayfaya geçmek için expeditionOffset değerini artırır.
     * Örnek olarak 1 artırabilirsiniz.
     * @returns {void}
     */
    const handleExpeditionListNextPage = (): void => {
        setExpeditionsOffset(expeditionsOffset + 50);
        setScrollLoading(true)
    };

    /**
     * Sonraki sayfaya geçmek için loadOffset değerini artırır.
     * Örnek olarak 1 artırabilirsiniz.
     * @returns {void}
     */
    const handleLoadListNextPage = (): void => {
        setLoadsOffset(loadsOffset + 50);
        setScrollLoading(true)
    }

    /**
     * Sonraki sayfaya geçmek için vehicleOffset değerini artırır.
     * Örnek olarak 1 artırabilirsiniz.
     * @returns {void}
     */
    const handleVehicleListNextPage = (): void => {
        setVehiclesOffset(vehiclesOffset + 50);
        setScrollLoading(true)
    }

    /**
     * Sefer öğesi işleyicisi.
     *
     * @param {number} index - Seçilen seferin dizini.
     * @returns {void}
     */
    const handleExpeditionListItem = (index: number): void => {
        setExpeditionSelectedIndex(index);
        setExpeditionSubSelectedIndex(null)
    };

    /**
     * Alt sefer öğesi işleyicisi.
     *
     * @param {number} index - Seçilen alt seferin dizini.
     * @returns {void}
     */
    const handleExpeditionSubListItem = (index: number): void => {
        setExpeditionSubSelectedIndex(index)
    }

    /**
     * Yük öğesi işleyicisi.
     *
     * @param {number} index - Seçilen yükün dizini.
     * @returns {void}
     */
    const handleLoadListItem = (index: number): void => {
        setLoadSelectedIndex(index)
    }

    const handleVehicleLoadListItem = (index: number) => {
        setVehiclesSelectedIndex(index)
    }

    const handleExpeditionClick = (index: number) => {
        setOpenList((prevState) => {
            const newState = [...prevState];
            newState.pop()
            newState[index] = !newState[index];
            return newState;
        });
        handleExpeditionListItem(index)
    };


    const handleExpeditionSubClick = (params: {
        expeditionLoadItemIndex: number,
        newOpen: boolean,
        // loadNo: string,
        // id: string,
        // trailer_id: string,
        // trailer_no: string,
        expedition: IExpeditionsModel
    }) => {
        handleExpeditionSubListItem(params.expeditionLoadItemIndex)
        props.data.expedition({
            results: [{
                trailer: {
                    id_no: null
                },
                truck: {
                    id_no: null
                },

                loads: [{
                    id: params.expedition.results[0].loads[0].id,
                    //@ts-ignore
                    trailer: {
                        id_no: params.expedition.results[0].loads[0].trailer.id_no
                    }
                }],
                votes: []

            }]
        })
    }

    const handleExpeditionItemText = (params: {
        expedition: IExpeditionsModel
    }) => {
        props.data.expedition({
            results:[{
                id:params.expedition.results[0].id,
                start_date:params.expedition.results[0].start_date,
                end_date:params.expedition.results[0].end_date,
                position_no:params.expedition.results[0].position_no,
                status_histories:params.expedition.results[0].status_histories,
                trailer:{
                    id_no:params.expedition.results[0].trailer.id_no,
                    plate:params.expedition.results[0].trailer.plate
                },
                truck: {
                    id_no: params.expedition.results[0].truck.id_no,
                    plate: params.expedition.results[0].truck.plate
                },
                loads: params.expedition.results[0].loads,
                is_user_voted: params.expedition.results[0].is_user_voted,
                votes: params.expedition.results[0].votes
            }]
        })
    }

    const handleLoadItem = (params: {
        loadItemIndex: number,
        expedition: IExpeditionsModel
    }) => {
        handleLoadListItem(params.loadItemIndex)
        props.data.expedition({
            results: [{

                trailer: {
                    id_no: null
                },
                truck: {
                    id_no: null
                },
                loads: [{
                    id: params.expedition.results[0].loads[0].id,
                    //@ts-ignore
                    trailer: {
                        id_no: params.expedition.results[0].loads[0].trailer.id_no
                    },
                }],
                votes:[]
            }]
        })
    }
    const toggleExpeditionDetailDrawer = (
        newOpen: boolean,
        expeditionID_NO: string,
        trailerID_NO: string,
        trailer_ID: string,
        truck_no: string,
        is_completed: boolean,
        no: string,
        expeditions: IExpeditionsModel
    ) => () => {
        // props.data.expedition (
        //     {
        //         id: expeditionID_NO,
        //         isCompleted: is_completed,
        //         truck: {
        //             id: truck_no,
        //             no: truck_no
        //         },
        //         trailer: {
        //             id: trailerID_NO,
        //             no: trailerID_NO
        //         },
        //         detailDrawerStatus: newOpen,
        //         no: no
        //     }
        // )
        //
        //
        // props.data.vehicle(
        //     {
        //         id: trailer_ID,
        //         detailDrawerStatus: false
        //     }
        // )
    }

    const handleVehicleClick = (index: number) => {
        handleVehicleLoadListItem(index)
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
        setLoadsLoading(true)
    };

    const handleChange = (e: string) => {
        setDebouncedValue(e);
    };

    const currentDate = new Date()

    const handleExpeditionsListScroll = () => {
        if (expeditionsListRef.current) {
            const isScrollAtBottom =
                expeditionsListRef.current.scrollHeight
                - expeditionsListRef.current.scrollTop
                - expeditionsListRef.current.clientHeight;
            if (isScrollAtBottom <= 2) {
                handleExpeditionListNextPage()
            }
        }
    }

    const handleLoadsListScroll = () => {
        if (loadsListRef.current) {
            const isScrollAtBottom =
                loadsListRef.current.scrollHeight
                - loadsListRef.current.scrollTop
                - loadsListRef.current.clientHeight;
            if (isScrollAtBottom <= 2) {
                handleLoadListNextPage()
            }
        }
    }

    const handleVehiclesListScroll = () => {
        if (vehiclesListRef.current) {
            const isScrollAtBottom =
                vehiclesListRef.current.scrollHeight
                - vehiclesListRef.current.scrollTop
                - vehiclesListRef.current.clientHeight;
            if (isScrollAtBottom <= 2) {
                handleVehicleListNextPage()
            }
        }
    }

    const handleRefreshListData = () => {
        setExpeditionsOffset(0)
        setExpeditionsSearch([])
        setExpeditionsLoading(true)
        setLoadsOffset(0)
        setLoadsSearch([])
        setLoadsLoading(true)
        setVehiclesOffset(0)
        setVehiclesSearch([])
        setVehiclesLoading(true)
        setRefresh(!refresh)
    }

    const handleOrderingListData = (order: string) => {
        setExpeditionsOffset(0)
        setExpeditionsSearch([])
        setExpeditionsLoading(true)
        setLoadsOffset(0)
        setLoadsSearch([])
        setLoadsLoading(true)
        setVehiclesOffset(0)
        setVehiclesSearch([])
        setVehiclesLoading(true)
        setOrdering(order)
    }

    const handleTypeListData = (type: number) => {
        //setExpeditionsOffset(0)
        setExpeditionsSearch([])
        setExpeditionsLoading(true)
        //setLoadsOffset(0)
        setLoadsSearch([])
        setLoadsLoading(true)
        //setVehiclesOffset(0)
        setVehiclesSearch([])
        setVehiclesLoading(true)
        setType(type)
    }


    useEffect(() => {
        setStorageData("updateTime", { mapApi: dateFormatting(currentDate) }, false).then(() => getStorageData("updateTime", false).then((res) => {
            //@ts-ignore
            setUpdateTime(res.mapApi)
        }
        ))

        setCount("")
        setExpeditionSelectedIndex(null)
        setTotalExpeditions("")
        setTotalLoad("")
        setTotalVehicles("")
        setExpeditionsLoading(true)

        if (debouncedValue.length >= 3) {
            getStorageData("authState", true).then((auth) => {
                getStorageData("api", false).then((api) => {
                    //@ts-ignore
                    ApiExpeditionsService.fetchExpeditions({
                        //@ts-ignore
                        apiUrl: api.url,
                        //@ts-ignore
                        accessToken: auth.status.access_token,
                        search: debouncedValue,
                        ordering: ordering,
                        //@ts-ignore
                        position_type: type,
                        is_completed:isCompleted
                    }).then((res: any) => {
                        //@ts-ignore
                        setExpeditionsSearch(res.data.results);
                        setExpeditionsLoading(false)
                        setTotalExpeditions(res.data.count);
                    });
                })
            });
        } else {
            if (tab === 0) {
                getStorageData("authState", true).then(auth => {
                    getStorageData("api", false).then((api) => {
                        //@ts-ignore
                        ApiExpeditionsService.fetchExpeditions({
                            //@ts-ignore
                            apiUrl: api.url,
                            //@ts-ignore
                            accessToken: auth.status.access_token,
                            offset: expeditionsOffset,
                            ordering: ordering,
                            is_completed: isCompleted,
                            //@ts-ignore
                            position_type: userInfo.data.role == UserRoles.EMPLOYEE || userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.DEVELOPER ? type : null
                        }).then((res: any) => {
                            setScrollLoading(false)
                            //@ts-ignore
                            setExpeditionsLoading(false)
                            setCount(res.data.count)
                            //@ts-ignore
                            setExpeditionsSearch(prevExpeditions => [...prevExpeditions, ...res.data.results]);
                            if (res.data.results.length > 0) {
                                //@ts-ignore
                                if (expeditionsListRef.current) {
                                    expeditionsListRef.current.addEventListener('scroll', handleExpeditionsListScroll, { passive: true });
                                }
                            }
                        })
                    })
                })
            }
        }

        if (debouncedValue.length >= 3) {
            getStorageData("authState", true).then(auth => {
                getStorageData("api", false).then((api) => {
                    ApiLoadsService.fetchLoadsService({
                        //@ts-ignore
                        url: api.url,
                        //@ts-ignore
                        accessToken: auth.status.access_token,
                        search: debouncedValue,
                        ordering: ordering,
                        load_type: type
                    }).then((res) => {
                        setLoadsSearch(res.data.results)
                        setLoadsLoading(false)
                        setTotalLoad(res.data.count)
                    })
                })
            })
        } else {
            if (tab === 1) {
                getStorageData("authState", true).then(auth => {
                    getStorageData("api", false).then((api) => {
                        //@ts-ignore
                        ApiLoadsService.fetchLoadsService({
                            //@ts-ignore
                            url: api.url,
                            //@ts-ignore
                            accessToken: auth.status.access_token,
                            offset: loadsOffset,
                            ordering: ordering,
                            //@ts-ignore
                            load_type: UserRoles.EMPLOYEE || userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.DEVELOPER ? type : null
                        }).then((res) => {
                            //@ts-ignore
                            setLoadsSearch(prevLoads => [...prevLoads, ...res.data.results]);
                            setScrollLoading(false)
                            setLoadsLoading(false)
                            setCount(res.data.count)
                            if (res.data.results.length > 0) {
                                if (loadsListRef.current) {
                                    loadsListRef.current.addEventListener('scroll', handleLoadsListScroll, { passive: true });
                                }
                            }
                        })
                    })
                })
            }
        }

        if (debouncedValue.length >= 3) {
            getStorageData("authState", true).then(auth => {
                getStorageData("api", false).then((api) => {
                    //@ts-ignore
                    ApiVehiclesService.fetchVehiclesService({
                        //@ts-ignore
                        url: api.url,
                        //@ts-ignore
                        accessToken: auth.status.access_token,
                        search: debouncedValue,
                    }).then((res) => {
                        setVehiclesSearch(res.data.results)
                        setVehiclesLoading(false)
                        setTotalVehicles(res.data.count)
                    })
                })
            })
        } else {
            if (tab === 2) {
                getStorageData("authState", true).then(auth => {
                    getStorageData("api", false).then((api) => {
                        //@ts-ignore
                        ApiVehiclesService.fetchVehiclesService({
                            //@ts-ignore
                            url: api.url,
                            offset: vehiclesOffset,
                            //@ts-ignore
                            accessToken: auth.status.access_token
                        }).then((res) => {
                            //@ts-ignore
                            setVehiclesSearch(prevVehicles => [...prevVehicles, ...res.data.results]);
                            setScrollLoading(false)
                            setVehiclesLoading(false)
                            setCount(res.data.count)
                            if (res.data.results.length > 0) {
                                if (vehiclesListRef.current) {
                                    vehiclesListRef.current.addEventListener('scroll', handleVehiclesListScroll, { passive: true });
                                }
                            }
                        })
                    })
                })
            }
        }

        return () => {
            if (expeditionsListRef.current) {
                expeditionsListRef.current.removeEventListener('scroll', handleExpeditionsListScroll)
            }
            if (loadsListRef.current) {
                loadsListRef.current.removeEventListener('scroll', handleLoadsListScroll)
            }
            if (vehiclesListRef.current) {
                vehiclesListRef.current.removeEventListener('scroll', handleVehiclesListScroll)
            }
        }
    }, [
        isCompleted,
        debouncedValue,
        expeditionsOffset,
        loadsOffset,
        vehiclesOffset,
        tab,
        type,
        refresh,
        ordering,
    ]);


    const toggleVehicleDetailDrawer = (newOpen: boolean, vehicle_id: string, id_no: string) => () => {
        // props.data.expedition({
        //     id: "",
        //     detailDrawerStatus: false,
        //     isCompleted: false,
        //     no: ""
        // })
        // props.data.vehicle(
        //     {
        //         id: vehicle_id,
        //         detailDrawerStatus: newOpen,
        //         trailer: {
        //             id: id_no
        //         }
        //     }
        // )
    }

    interface IExpeditionListPrimaryContent {
        expeditionNo: string,
        plate: string
    }

    interface IExpeditionSubListPrimaryContent {
        loadNo: string,
        plate: string
    }

    const expeditionListPrimaryContent = (props: IExpeditionListPrimaryContent) => (
        <React.Fragment>
            <Grid container md={12} sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Grid md={7}>
                    {props.expeditionNo}
                </Grid>
                <Grid md={5}>
                    <Typography sx={{
                        fontSize: "12px",
                        color: "#0C9AF2"
                    }}>
                        {props.plate}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );

    const expeditionSubListPrimaryContent = (props: IExpeditionSubListPrimaryContent) => (
        <React.Fragment>
            <Grid container md={12} sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Grid md={7}>
                    {props.loadNo}
                </Grid>
                <Grid md={5}>
                    <Typography sx={{
                        fontSize: "12px",
                        color: "#0C9AF2"
                    }}>
                        {props.plate}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )


    /**
     * Araç ayrıntılarının çekmecesini açıp kapatan bir işlev.
     *
     * @param {string} id_no - Araç için benzersiz bir kimlik numarası.
     * @returns {Function} - Çekmecenin açılmasını ve araç ayrıntılarının getirilmesini sağlayan bir işlev.
     */
    return (
        <Card sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            zIndex: 500,
            background: `${theme.palette.background.default}`,
            opacity: 1,
            borderRadius: 0,
            boxShadow: 0
        }}>
            <CardContent>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <ExpeditionActivitySelector fallback={(status) => props.activity(status)} />
                </Stack>
                <Search
                    debounce={(text) => (handleChange(text))}
                    debounceDelay={500}
                    OrderingOptionFields={
                        tab == 0 ? <ExpeditionSortingSelector fallback={(status) => handleOrderingListData(status)}
                            sortingOptions={ExpeditionSortingOptions} /> : <></> &&
                                tab == 1 ? <ExpeditionSortingSelector fallback={(status) => handleOrderingListData(status)}
                                    sortingOptions={LoadsSortingOptions} /> : <></> &&
                                        tab == 2 ? <ExpeditionSortingSelector fallback={(status) => handleOrderingListData(status)}
                                            sortingOptions={VehiclesSortingOptions} /> : <></>
                    }
                    expedition={(status)=>setIsCompleted(status)}
                />
                <Box sx={{ width: '100%', color: "white" }}>
                    {
                        /*
                         * USER rölüne göre sekmeler
                         **/
                        //@ts-ignore
                        userInfo.data.role == UserRoles.EMPLOYEE || userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.DEVELOPER ?
                            <Tabs value={tab} onChange={handleTabChange} centered sx={{
                                margin: "1rem"
                            }}>
                                <Tab label={
                                    debouncedValue && totalLoad ?
                                        <Badge color="secondary"
                                            badgeContent={totalLoad}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                            sx={{
                                                padding: "0.3rem",
                                                position: "absolute"
                                            }}
                                        >
                                            Seferler
                                        </Badge> : "Seferler"
                                }
                                    sx={{
                                        color: "palette.text.primary",
                                        backgroundColor: `${theme.palette.grey["400"]}`,
                                        width: "40px",
                                        height: "15px",
                                        borderRadius: "4px",
                                        margin: "0.1rem",
                                    }} />
                                <Tab label={
                                    debouncedValue && totalLoad ?
                                        <Badge color="secondary"
                                            badgeContent={totalLoad}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                            sx={{
                                                padding: "0.3rem"
                                            }}
                                        >
                                            Yükler
                                        </Badge> : "Yükler"
                                }
                                    sx={{
                                        color: "palette.text.primary",
                                        backgroundColor: `${theme.palette.grey["400"]}`,
                                        borderRadius: "4px",
                                        margin: "0.1rem",
                                        width: "40px",
                                        height: "15px",
                                    }} />

                                <Tab label={
                                    debouncedValue && totalVehicles ?
                                        <Badge
                                            color="secondary"
                                            badgeContent={totalVehicles}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                            sx={{
                                                padding: "0.3rem"
                                            }}
                                        >
                                            Araçlar
                                        </Badge> : "Araçlar"
                                }
                                    sx={{
                                        color: "palette.text.primary",
                                        backgroundColor: `${theme.palette.grey["400"]}`,
                                        borderRadius: "4px",
                                        margin: "0.1rem"
                                    }} />
                            </Tabs> : <></> ||
                                /*
                                * CUSTOMER rölüne göre gösterilecek sekmeler
                                * */
                                //@ts-ignore
                                userInfo.data.role == UserRoles.CUSTOMER ?
                                <Tabs value={tab} onChange={handleTabChange} centered>
                                    <Tab label="Seferler" sx={{ color: "palette.text.primary" }} />
                                </Tabs> : <></>
                    }
                    {
                        //@ts-ignore
                        userInfo.data.role == UserRoles.EMPLOYEE || userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.DEVELOPER && tab != 2 ?
                            <Grid md={12}>
                                <ExpeditionTypeSelector
                                    fallback={(type) => handleTypeListData(type)}
                                    componentView={ComponentView.TAB}
                                />
                            </Grid>
                            : <></>
                    }
                    <Grid container md={12} sx={{
                        marginTop: "1rem"
                    }}>
                        <Box sx={{
                            width: "100%",
                            height: "30px",
                            borderRadius: 2
                        }}>
                            <Box>
                                <Grid container md={12} sx={{
                                    justifyContent: "center",
                                }}>
                                    <Grid md={4} sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Typography sx={{
                                            color: "rgba(166, 166, 166, 1)",
                                            fontSize: "10px",
                                            marginRight: "0.1rem"
                                        }}>
                                            Listelenen öğe:{count} kayıt
                                        </Typography>
                                    </Grid>
                                    <Grid md={7} sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Typography sx={{
                                            color: "rgba(166, 166, 166, 1)",
                                            fontSize: "10px",
                                        }}>
                                            Güncellenme zamanı:{updateTime}
                                        </Typography>
                                    </Grid>
                                    <Grid md={1} sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "flex-end",
                                    }}>
                                        <IconButton size="small" onClick={() => handleRefreshListData()}>
                                            <CachedIcon sx={{ color: "black" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <TabPanel value={tab} index={0}>
                        <Grid md={12}>
                            {
                                <List
                                    sx={{
                                        width: '100%',
                                        position: 'relative',
                                        maxHeight: '800px',
                                        paddingBottom: "400px",
                                        overflow: 'auto',
                                        '&::-webkit-scrollbar': { height: 5, WebkitAppearance: 'none', width: 5 },
                                        '&::-webkit-scrollbar-thumb': {
                                            borderRadius: 8,
                                            border: '1px solid',
                                            borderColor: 'white',
                                            backgroundColor: 'rgba(0 0 0 / 0.5)',
                                        },
                                    }}
                                    ref={expeditionsListRef}
                                >
                                    {
                                        expeditionsLoading ? <Box sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <CircularProgress />
                                        </Box> :
                                            /**
                                             * Seferlerin ayrıntılarını içeren bir liste oluşturur.
                                             *
                                             * @param {Array} expeditionsSearch - Render edilecek sefer bir dizisi.
                                             * @returns {React.Component} - Seferler listesini render eden bir bileşen.
                                             */
                                            <>
                                                {
                                                    expeditionsSearch.map((res: any, index: number) => (
                                                        <List key={index}
                                                            component="nav"
                                                            aria-labelledby="nested-list-subheader"
                                                        >
                                                            <ListItemButton
                                                                onClick={() => handleExpeditionClick(index)}
                                                                selected={expeditionSelectedIndex === index}
                                                                sx={{
                                                                    backgroundColor: res.is_completed ? "rgba(0, 0, 0, 0.03)" : "rgba(0,0,0,0)"
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <Badge badgeContent={res.loads.length}
                                                                        color="primary">
                                                                        <FiBox style={{
                                                                            height: "25px",
                                                                            width: "25px"
                                                                        }} />
                                                                    </Badge>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    onClick={()=>
                                                                    handleExpeditionItemText({
                                                                            expedition:{
                                                                                results:[{
                                                                                    id:res.id,
                                                                                    start_date:res.start_date,
                                                                                    end_date:res.end_date,
                                                                                    is_completed:res.is_completed,
                                                                                    position_no:res.position_no,
                                                                                    status_histories:res.status_histories,
                                                                                    truck:{
                                                                                        id_no: res.truck?.id_no,
                                                                                        plate: res.truck?.plate
                                                                                    },
                                                                                    trailer: {
                                                                                        id_no: res.trailer?.id_no,
                                                                                        plate: res.trailer?.plate
                                                                                    },
                                                                                    loads: res.loads,
                                                                                    is_user_voted: res.is_user_voted,
                                                                                    votes: res.votes
                                                                                }]
                                                                            }
                                                                        })
                                                                    }
                                                                    sx={{
                                                                        color: 'text.primary'
                                                                    }}
                                                                    primary={expeditionListPrimaryContent({
                                                                        //@ts-ignore
                                                                        expeditionNo: res.position_no,
                                                                        //@ts-ignore
                                                                        plate: res?.trailer?.plate ? res?.trailer?.plate : " "
                                                                    })}
                                                                    secondary={res.position_status}
                                                                />
                                                                {
                                                                    res.loads >= 0 ? <></>
                                                                        : openList[index] ?
                                                                            <ExpandLess sx={{ color: 'text.primary' }} /> :
                                                                            <ExpandMore sx={{ color: 'text.primary' }} />
                                                                }
                                                            </ListItemButton>
                                                            <Collapse in={openList[index]} timeout="auto" unmountOnExit>
                                                                {
                                                                    res.loads.map((load: any, loadIndex: number) => (
                                                                        <List component="div" disablePadding
                                                                              key={loadIndex}>
                                                                            <ListItemButton sx={{pl: 4}}
                                                                                            onClick={() =>
                                                                                                handleExpeditionSubClick({
                                                                                                    expeditionLoadItemIndex: loadIndex,
                                                                                                    newOpen: true,
                                                                                                    expedition: {
                                                                                                        results: [{
                                                                                                            truck: {},
                                                                                                            trailer: {},
                                                                                                            loads: [{
                                                                                                                id: load.id,
                                                                                                                trailer: {
                                                                                                                    id_no: load?.trailer?.id_no
                                                                                                                }
                                                                                                            }],
                                                                                                            votes: []
                                                                                                        }]
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                            selected={expeditionSelectedIndex === index && expeditionSubSelectedIndex === loadIndex}>
                                                                                <ListItemIcon>
                                                                                    <FiBox style={{
                                                                                        height: "25px",
                                                                                        width: "25px"
                                                                                    }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText
                                                                                    sx={{ color: 'text.primary' }}
                                                                                    primary={expeditionSubListPrimaryContent({
                                                                                        loadNo: load.load_no ? load?.load_no : <> Yük
                                                                                            Bilgisi Bulunamadı</>,
                                                                                        plate: load.trailer ? load.trailer.plate : " "
                                                                                    })}
                                                                                />
                                                                            </ListItemButton>
                                                                        </List>
                                                                    ))
                                                                }
                                                            </Collapse>
                                                        </List>
                                                    ))
                                                }
                                                {
                                                    scrollLoading ? <Box sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        <CircularProgress />
                                                    </Box> : ""
                                                }
                                            </>
                                    }

                                </List>
                            }
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        {
                            <List sx={{
                                width: '100%',
                                position: 'relative',
                                maxHeight: '800px',
                                paddingBottom: "400px",
                                overflow: 'auto',
                                '&::-webkit-scrollbar': { height: 5, WebkitAppearance: 'none', width: 5 },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: 8,
                                    border: '1px solid',
                                    borderColor: 'white',
                                    backgroundColor: 'rgba(0 0 0 / 0.5)',
                                },
                            }}
                                ref={loadsListRef}
                            >
                                {
                                    loadsLoading ? <Box sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <CircularProgress />
                                    </Box> :
                                        /**
                                         * Yüklerin ayrıntılarını içeren bir liste oluşturur.
                                         *
                                         * @param {Array} loadsSearch - Render edilecek yüklerin bir dizisi.
                                         * @returns {React.Component} - Yüklerin listesini render eden bir bileşen.
                                         */
                                        <>
                                            {
                                                loadsSearch.map((load: any, index: number) => (
                                                    <List
                                                        component="nav"
                                                        aria-labelledby="nested-list-subheader"
                                                        key={index}
                                                    >
                                                        <ListItemButton
                                                            onClick={() => handleLoadItem({
                                                                loadItemIndex: index,
                                                                expedition: {
                                                                    results: [{
                                                                        truck: {},
                                                                        trailer: {},
                                                                        loads: [{
                                                                            id: load.id,
                                                                            trailer: {
                                                                                id_no: load?.trailer?.id_no
                                                                            }
                                                                        }],
                                                                        votes: []
                                                                    }]
                                                                }
                                                            })}
                                                            selected={loadSelectedIndex === index}>
                                                            <ListItemIcon>
                                                                <FiBox style={{
                                                                    height: "25px",
                                                                    width: "25px"
                                                                }} />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                sx={{
                                                                    color: 'text.primary'
                                                                }} primary={expeditionSubListPrimaryContent({
                                                                    loadNo: load.load_no ? load?.load_no : <> Yük Bilgisi
                                                                        Bulunamadı</>,
                                                                    plate: load.trailer ? load.trailer.plate : " "
                                                                })} secondary={load.load_status} />
                                                        </ListItemButton>
                                                    </List>
                                                ))
                                            }
                                            {
                                                scrollLoading ? <Box sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <CircularProgress />
                                                </Box> : ""
                                            }
                                        </>
                                }
                            </List>
                        }
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        {
                            <List sx={{
                                width: '100%',
                                position: 'relative',
                                maxHeight: '800px',
                                paddingBottom: "400px",
                                overflow: 'auto',
                                '&::-webkit-scrollbar': { height: 5, WebkitAppearance: 'none', width: 5 },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: 8,
                                    border: '1px solid',
                                    borderColor: 'white',
                                    backgroundColor: 'rgba(0 0 0 / 0.5)',
                                },
                            }}
                                ref={vehiclesListRef}
                            >
                                {
                                    vehiclesLoading ? <Box sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <CircularProgress />
                                    </Box> :
                                        /**
                                         * Araçların ayrıntılarını içeren bir liste oluşturur.
                                         *
                                         * @param {Array} vehiclesSearch - Render edilecek araçların bir dizisi.
                                         * @returns {React.Component} - Araçların listesini render eden bir bileşen.
                                         */
                                        <>
                                            {
                                                vehiclesSearch.map((vehicle, index) => (
                                                    <List component="nav" aria-labelledby="nested-list-subheader"
                                                        key={index}>
                                                        <ListItemButton onClick={() => handleVehicleClick(index)}
                                                            selected={vehiclesSelectedIndex === index}
                                                        >
                                                            {
                                                                //@ts-ignore
                                                                vehicle.vehicle_type._type == "truck" ?
                                                                    <ListItemIcon>
                                                                        <LocalShippingIcon />
                                                                    </ListItemIcon>
                                                                    : <ListItemIcon>
                                                                        < FaTrailer style={{
                                                                            height: "25px",
                                                                            width: "25px"
                                                                        }} />
                                                                    </ListItemIcon>
                                                            }
                                                            <ListItemText
                                                                onClick={
                                                                    //@ts-ignore
                                                                    toggleVehicleDetailDrawer(true, vehicle?.id, vehicle?.id_no)
                                                                }
                                                                sx={{
                                                                    color: 'text.primary'
                                                                }} primary={
                                                                    //@ts-ignore
                                                                    vehicle.plate
                                                                } secondary={
                                                                    //@ts-ignore
                                                                    vehicle.vehicle_type.name
                                                                } />
                                                        </ListItemButton>
                                                    </List>
                                                ))
                                            }
                                            {
                                                scrollLoading ? <Box sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <CircularProgress />
                                                </Box> : ""
                                            }
                                        </>
                                }
                            </List>
                        }
                    </TabPanel>
                </Box>
            </CardContent>
        </Card>
    )
}

export default PageOtsLeftDrawer

import React, {useEffect, useState} from "react"
import {FieldValues, UseFormRegister} from "react-hook-form";
import {FormControl, InputLabel, MenuItem} from "@mui/material";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {getStorageData} from "../../../utils/usePersistedState";

import {ApiProductsService} from "../../../services/client/products/products.service";

interface IProductSelector {
    label:string,
    index:number,
    productId:(id:string) => void,
    register: UseFormRegister<FieldValues>,
}

export const ProductSelector = ({label, index, productId, register}: IProductSelector) => {

    const [getProduct, setProduct] = useState([])

    useEffect(()=>{
        //@ts-ignore
        getStorageData("authState").then(auth=> {
            //@ts-ignore
            ApiProductsService.fetchProductsService(auth.status.access_token).then((res:any)=> {
                setProduct(res.data.results)
            })
        })
    },[])

    const productHandleChange = (event: SelectChangeEvent) => {
        productId(event.target.value as string)
    }

    return(
        <FormControl sx={{
            width:"100%"
        }}>
            <InputLabel
                id="demo-simple-select-label"
            >
                {
                    label
                }
            </InputLabel>
            <Select
                //@ts-ignore
                ref={register}
                //@ts-ignore
                {...register((`advert_items.${index}.product`), {required:true})}
            >
                {
                    getProduct.map((res, index)=>(
                        <MenuItem
                            key={index}
                            value={
                                //@ts-ignore
                                res.id
                            }
                        >
                            {
                                //@ts-ignore
                                res.name
                            }
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default ProductSelector

import React from "react"

import axios from "axios"

import { createAsyncThunk } from "@reduxjs/toolkit";

import { slug } from "../../config"
import {IOAuthRegisterConfig} from "../../types/interfaces/services/auth/auth.config.interface";
import {AxiosResponse} from "axios/index";

export const fetchUserInfo = createAsyncThunk (
    "fetchUserInfo",
    async (extra:{apiUrl: string, accessToken: string}) => {
        const response = await axios.get(extra.apiUrl + slug.client.user.info, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+extra.accessToken.valueOf()
            }
        })
        return response.data
    })

export const fetchUserVCardInfo = async (username:string) => {
    const response = await axios.get(slug.client.user.vcard+username,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return response.data
}

const loginUrl = slug.auth.login
const registerUrl = slug.auth.register

interface UsersService {
    apiUrl?:string
    fetchUser(clientToken:string) : Promise<AxiosResponse>;
    createUser(clientToken:string, data:IOAuthRegisterConfig ) : Promise<AxiosResponse>;
}

export const ApiUsersService: UsersService = {
    fetchUser: async (clientToken:string): Promise<AxiosResponse> => {
        const headers =  {
            'Content-Type': 'application/json',
        };

        try {
            throw null
        } catch (error){
            throw error
        }
    },
    createUser : async (clientToken: string, data:IOAuthRegisterConfig): Promise<AxiosResponse> => {
        const headers =  {
            'Authorization': `Bearer ${clientToken}`,
            'Content-Type': 'application/json',
        };
        try {
            const response = await axios.post(registerUrl, {...data}, {headers})
            return response
        } catch (error){
            throw error
        }
    }
}


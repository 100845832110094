import React, {useEffect, useState} from "react"
import {getStorageData} from "../../../utils/usePersistedState";
import {useAppDispatch, useAppSelector} from "../../../store";
import {fetchWaitingActivityDetails} from "../../../services/client/reports/waitingActivity/waitingActivity.service";
import {WaitingActivityCommentsSkeleton} from "../../skeletons/skeletons";
import WaitingActivityCommentsItemCard from "./waitingActivity.comments.item.card";
import {Box, Card, CardContent} from "@mui/material";
import CommentForm from "../../form/comments/comment.form";
import Alert from '@mui/material/Alert';

import {ApiIntegrationsHoldersService} from "../../../services/client/integrations/integrations.holders.service";
import PermissionProvider from "../../../utils/permissionProvider/permissionProvider";
import {permissionControl} from "../../../utils/permissionControl";
import PermissionRestricted from "../../../utils/permissionProvider/permissionRestricted";
import PermissionsTypes from "../../../types/models/components/permissions.default.model";
import {dateFormatting} from "../../../utils/dateProvider/dateFormatting";

interface IWaitingActivityCommentsCard {
    id: any,
    fallbackStatus?: (status: boolean) => void
}

const WaitingActivityCommentsCard = (props: IWaitingActivityCommentsCard) => {
    const userInfo = useAppSelector((state) => state.user);

    const dispatch = useAppDispatch();

    const [getLoading, setLoading] = useState(true)

    const [getParentDetails, setParentDetails] = useState<any>()

    const [getWaitReasons, setWaitReasons] = useState<any[]>([])

    const [getCanYouComment, setCanYouComment] = useState<boolean>(false)

    const [getHolders, setHolders] = useState<any>([])

    // Fetch Waiting Activity Details
    const _fetchWaitingActivityDetails = () => {

        getStorageData("authState", true).then(auth => (
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                dispatch(fetchWaitingActivityDetails({apiUrl: api.url, access_token: auth.status.access_token, id: props.id})).then(res => {
                    setParentDetails(res.payload)
                    //@ts-ignore
                    setWaitReasons(res.payload.wait_reasons)
                    //@ts-ignore
                    if (res.payload.wait_reasons.length > 0) {
                        setCanYouComment(false)
                    } else {
                        setCanYouComment(true)
                        //@ts-ignore
                        ApiIntegrationsHoldersService.fetchIntegrationsHoldersService(auth.status.access_token).then((res:any) => {
                            setHolders(res.data.results)
                        })
                    }
                })
            })
        ))
        //@ts-ignore
    }

    useEffect(() => {

        if (props.id != undefined) {
            setLoading(false)
            _fetchWaitingActivityDetails()
        } else {

        }
        //@ts-ignore
    }, [props.id])

    return (
        getLoading ?
            <WaitingActivityCommentsSkeleton/> : <>
                <PermissionProvider
                    //@ts-ignore
                    fetchPermission={permissionControl(userInfo.data, "_waitreason")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>İzniniz Bulunmamamaktadır</>}>
                        {
                            getCanYouComment ?
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    margin: "30px",
                                }}>
                                    <Alert severity="warning">Henüz bir yorum bulunmamaktadır..!</Alert>
                                </Box>
                                :
                                getWaitReasons?.map((res: any) => (
                                    <WaitingActivityCommentsItemCard
                                        text={res.comment}
                                        firstName={res.author.first_name}
                                        //@ts-ignore
                                        lastName={res.author.last_name}
                                        //@ts-ignore
                                        holderName={res.holder.name}
                                        //@ts-ignore
                                        causeName={res.cause.name}
                                        //@ts-ignore
                                        createdDate={dateFormatting(res.created_date)}
                                    />
                                ))
                        }
                    </PermissionRestricted>
                </PermissionProvider>
                <PermissionProvider
                    //@ts-ignore
                    fetchPermission={permissionControl(userInfo.data, "_waitreason")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.add]} fallback={<>İzniniz Bulunmamamaktadır</>}>
                        {
                            getParentDetails?.wait_reasons.length > 0 ?
                                getParentDetails.wait_reasons.map((res: any) => (
                                    //@ts-ignore
                                    res.author.id == userInfo.data.id ? <Card>
                                            <CardContent>
                                                <Alert severity="error">Yorum yapamazsınız, bir yorumunuz
                                                    bulunmaktadır..!</Alert>
                                            </CardContent>
                                        </Card> :
                                        <Box>
                                            <CommentForm
                                                id={getParentDetails?.id}
                                                holderChoices={getHolders}
                                                //@ts-ignore
                                                fallbackStatus={(r) => props.fallbackStatus(r)}
                                                popupStatus={(r) => console.log(r)}
                                            />
                                        </Box>
                                ))
                                :
                                <Box sx={{
                                    position: "absolute",
                                    width: "95%",
                                    bottom: 0
                                }}>
                                    <CommentForm
                                        id={getParentDetails?.id}
                                        holderChoices={getHolders}
                                        //@ts-ignore
                                        fallbackStatus={(r) => props.fallbackStatus(r)}
                                        popupStatus={(r) => console.log(r)}
                                    />
                                </Box>

                        }
                    </PermissionRestricted>
                </PermissionProvider>
            </>
    )
}

export default WaitingActivityCommentsCard

import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import { Card, Grid, TextareaAutosize } from "@mui/material";

import ApiRatingService from "../../services/client/rates/rates.service";
import { getStorageData } from '../../utils/usePersistedState';

import { SnackbarWrapper } from '../snackbar/snackbar';
import { autoHideDuration, horizontal, severity, vertical } from "../../types/enums/components/snackbar/snackbar.enums";

import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../store";
import { UserRoles } from "../../types/enums/reducers/user.enums";
import { permissionControl } from "../../utils/permissionControl";
import PermissionRestricted from "../../utils/permissionProvider/permissionRestricted";
import PermissionsTypes from "../../types/models/components/permissions.default.model";
import PermissionProvider from "../../utils/permissionProvider/permissionProvider";

interface IRatingCustomerModel {
    expeditionId?: any,
    isUserVoted: boolean | undefined,
    toggleReRender: () => void,
    votes?: [],
    setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>,
    setSeverityType: React.Dispatch<React.SetStateAction<severity>>,
    setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>,
    styleButton?: Object,
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function RatingCustomerModal(props: IRatingCustomerModel) {

    const theme = useTheme();

    const userInfo = useAppSelector((state) => state.user);

    const [open, setOpen] = React.useState(false);

    const [customerRepresentatives, setCustomerRepresentatives] = React.useState<any[]>([]);

    const [comment, setComment] = React.useState("")

    const [rateCriteria, setRateCriteria] = React.useState([] as any[]);

    const [ratingHover, setRatingHover] = React.useState<number[]>(new Array(rateCriteria.length).fill(-1));

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false)
        setCustomerRepresentatives([])
        setComment("")
    };

    useEffect(() => {
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                ApiRatingService.fetchRatingCriterias({ apiUrl: api.url, accessToken: auth.status.access_token })
                    .then((res: any) => {
                        setRateCriteria(res.data.results)
                        console.log(res.data.results)
                    })
                    .catch((error: any) => console.error(error));
            });
        })
    }, []);

    const labels: { [index: string]: string } = {
        0: " ",
        0.5: 'Çok kötü',
        1: 'Çok kötü',
        1.5: 'Kötü',
        2: 'Kötü',
        2.5: 'Orta',
        3: 'Orta',
        3.5: 'İyi',
        4: 'İyi',
        4.5: 'Çok iyi',
        5: 'Çok iyi',
    };

    const handleChangeRating = (event: React.ChangeEvent<{}>, newValue: number | null, index: number) => {
        const updatedRepresentatives = [...customerRepresentatives];
        updatedRepresentatives[index] = newValue;
        setCustomerRepresentatives(updatedRepresentatives);
    };

    const handleSubmitButton = () => {

        const criterias = customerRepresentatives.map((score, index) => ({
            id: rateCriteria[index]?.id,
            score: score
        }));

        const data = {
            comment,
            criterias
        };

        const isAllCriteriaSelected = data.criterias.every((criteria) => criteria.score > 0);
        if (isAllCriteriaSelected && data.criterias.length === rateCriteria.length) {

            getStorageData("authState", true).then((auth) => {
                getStorageData("api", false).then(api => {
                    //@ts-ignore
                    ApiRatingService.createRating(api.url, auth.status.access_token, props.expeditionId, data)
                        .then((res: any) => {
                            props.setSnackbarMessage("Degerlendirmeniz için teşekkür ederiz.");
                            props.setSeverityType(severity.success)
                            setOpen(false)
                            props.setAlertOpen(true)
                            if (props.toggleReRender) {
                                props.toggleReRender();
                            }
                        })
                        .catch((error: any) => {
                            console.error(error)
                            props.setSnackbarMessage("Bir hata oluştu. Değerlendirme gönderilemedi.");
                            props.setSeverityType(severity.error)
                            props.setAlertOpen(true)
                        });
                })
            });
        } else {
            props.setSnackbarMessage("Lütfen tüm kriterleri değerlendirin.");
            props.setSeverityType(severity.warning)
            props.setAlertOpen(true)
        }

    };

    return (
        <PermissionProvider fetchPermission={
            //@ts-ignore
            permissionControl(userInfo.data, "_rate")}>
            <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={
                <SnackbarWrapper
                    open={false}
                    autoHideDuration={autoHideDuration.ms6000}
                    severity={severity.error}
                    message={"Seferleri Değerlendiremezsiniz"}
                    anchorOrigin={{ vertical: vertical.bottom, horizontal: horizontal.right }}
                    close={(status) => props.setAlertOpen(status)}

                />
            }>
                {
                    //@ts-ignore
                    userInfo.data.role == UserRoles.CUSTOMER && rateCriteria.length > 0 ?
                        <div>
                            {
                                props.isUserVoted ?
                                    <>
                                        <Button sx={{
                                            border: '1px solid #000',
                                            textTransform: "none"
                                        }} onClick={handleOpen}>
                                            Değerlendirmeyi Gör
                                        </Button>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style}>
                                                <Grid md={12}>
                                                    <Grid md={6}>

                                                        {props.votes?.map((vote: any, index: any) => (
                                                            <Grid md={12}>
                                                                <Typography>{vote?.criteria}</Typography>
                                                                <Grid md={4}>
                                                                    <Rating disabled={true} name={`half-rating-${index}`}
                                                                        precision={0.5} value={vote.score} max={5}
                                                                        key={index} />
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                    <Grid md={12} style={{ marginTop: "10px" }}>
                                                        <Card>
                                                            <TextareaAutosize
                                                                style={{
                                                                    background: `${theme.palette.background.paper}`, width: "100%", fontStyle: "italic",
                                                                    color: `${theme.palette.text.primary}`,
                                                                    fontSize: "13px"
                                                                }}
                                                                minRows={3}
                                                                disabled={true}
                                                                //@ts-ignore
                                                                value={props.votes[0]?.comment ? props.votes[0].comment : "Yorumunuz bulunmamaktadır"} />

                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                                <Grid container md={10} style={{
                                                    display: "flex",
                                                    alignItems: "end",
                                                    justifyContent: "end",
                                                    marginTop: "8px"
                                                }}>
                                                </Grid>
                                            </Box>
                                        </Modal>
                                    </>
                                    :
                                    <>
                                        <Button sx={props.styleButton ? props.styleButton : {
                                            border: '1px solid #000',
                                            marginTop: "10px",
                                            textTransform: "none"
                                        }} onClick={handleOpen}>Değerlendir</Button>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            //model için backgroundColorın blur unu değiştiriyor.
                                            sx={{
                                                '& .MuiBackdrop-root': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.06)'
                                                }
                                            }}
                                        >
                                            <Box sx={style}>
                                                <Grid md={12}>

                                                    {rateCriteria?.map((rating, index) => (
                                                        <Grid md={12} key={index}>
                                                            <Typography>{rating?.text}</Typography>
                                                            <Grid md={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Rating
                                                                    name={`half-rating-${index}`}
                                                                    precision={0.5}
                                                                    value={customerRepresentatives[index] || 0}
                                                                    onChange={(event, newValue) => handleChangeRating(event, newValue, index)}
                                                                    max={5}
                                                                    onChangeActive={(event, newHover) => {
                                                                        // Update the hover state for the current rating index
                                                                        const updatedRatingHover = [...ratingHover];
                                                                        updatedRatingHover[index] = newHover;
                                                                        setRatingHover(updatedRatingHover);
                                                                    }}
                                                                />
                                                                <Box ml={3}>
                                                                    {labels[ratingHover[index] !== -1 ? ratingHover[index] : customerRepresentatives[index]]}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    <Grid md={12} style={{ marginTop: "10px" }}>
                                                        <TextareaAutosize style={{
                                                            background: `${theme.palette.background.paper}`, width: "100%", fontStyle: "italic",
                                                            color: `${theme.palette.text.primary}`,
                                                            fontSize: "13px"
                                                        }} minRows={3} placeholder="Görüşlerinizi bizimle paylaşır mısınız?"
                                                            value={comment}
                                                            onChange={(e) => setComment(e.target.value)} />


                                                    </Grid>
                                                </Grid>
                                                <Grid container md={10} style={{
                                                    display: "flex",
                                                    alignItems: "end",
                                                    justifyContent: "end",
                                                    marginTop: "8px"
                                                }}>
                                                    <Grid md={3}>
                                                        <Button onClick={handleClose}>
                                                            <Typography style={{
                                                                color: "grey",
                                                                fontSize: "12px",
                                                                fontWeight: "bold"
                                                            }}>
                                                                İptal
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid md={1}>
                                                        <Button onClick={handleSubmitButton}>
                                                            <Typography style={{
                                                                color: "green",
                                                                fontSize: "12px",
                                                                fontWeight: "bold"
                                                            }}>
                                                                Gönder
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Modal>
                                    </>
                            }
                        </div> : <></>
                }
            </PermissionRestricted>
        </PermissionProvider>
        //@ts-ignore
    );
}

import axios, {AxiosResponse} from "axios";
import {slug} from "../../../config";

/**
 * Interface for the VehiclesService.
 */
interface VehiclesService {
    /**
     * Fetches vehicles data from the API.
     * @param extra - Additional parameters for the request.
     * @returns {Promise<AxiosResponse>} - The promise resolving to the AxiosResponse.
     */
    fetchVehiclesService(extra: {
        url:string,
        accessToken: string,
        search?:string,
        offset?:number,
    }): Promise<AxiosResponse>;
}

/**
 * Service implementation for fetching vehicles data from the API.
 */
const apiSlug = slug.client.vehicles.url

export const ApiVehiclesService: VehiclesService = {
    /**
     * Fetches vehicles data from the API.
     * @param extra - Additional parameters for the request.
     * @returns {Promise<AxiosResponse>} - The promise resolving to the AxiosResponse.
     */
    fetchVehiclesService: async (extra: {
        url:string,
        accessToken: string,
        search?:string,
        offset?:number
    }): Promise<AxiosResponse> => {
        const config = {
            params:{
                ...(extra.search ? { search:extra.search } : {}),
                limit:1000,
                ...(extra.offset ? { offset:extra.offset } : {}),
            },
            headers:{
                Authorization: `Bearer ${extra.accessToken}`,
            }
        };
        try {
            const response = await axios.get(extra.url+apiSlug, config)
            return response
        }
        catch (error) {
            throw error
        }
    }
}

export default ApiVehiclesService

import React, {useEffect, useState} from "react";
import L from "leaflet";
import {useMap} from "react-leaflet";
import Button from "@mui/material/Button";


const OpenStreetMapDrawTool = () => {

    const map = useMap()

    const [drawnItems, setDrawnItems] = useState<L.FeatureGroup>(L.featureGroup());

    const markerOptions = {
        icon: new L.Icon.Default(), // Özelleştirmek için uygun bir marker ikonu belirleyin
        repeatMode: true, // Çizim tamamlandığında otomatik olarak tekrar çizim yapılmasını sağlar
    };

    useEffect(() => {

        const editableLayers = L.featureGroup();
        map.addLayer(editableLayers);
        setDrawnItems(editableLayers);

        // const drawControl = new L.Control.Draw({
        //     edit: {
        //         featureGroup: drawnItems,
        //         edit: {
        //             selectedPathOptions: {
        //                 fill: true,
        //                 opacity: 0.3
        //             }
        //         }
        //     },
        //     draw: {
        //         marker: markerOptions,
        //         polygon: false,
        //         circlemarker: false,
        //         rectangle: false,
        //         circle: markerOptions,
        //     },
        //     position: 'topleft',
        // })

        var drawControlSettings = {
            position: 'topright',
            draw: {
                marker: markerOptions,
                polygon: false,
                circlemarker: true,
                rectangle: false,
                circle: markerOptions,
            },
            edit: {
                featureGroup: drawnItems,
                edit: {
                    selectedPathOptions: {
                        fill: true,
                        opacity: 0.3
                    }
                }
            },
        };
        L.DrawToolbar.include({
            getModeHandlers: function(map:any) {
                return [
                    {
                        enabled: this.options.polyline,
                        handler: new L.Draw.Polyline(map, this.options.polyline),
                        title: L.drawLocal.draw.toolbar.buttons.polyline
                    },
                    {
                        enabled: this.options.polygon,
                        handler: new L.Draw.Polygon(map, this.options.polygon),
                        title: L.drawLocal.draw.toolbar.buttons.polygon
                    },
                    {
                        enabled: this.options.rectangle,
                        handler: new L.Draw.Rectangle(map, this.options.rectangle),
                        title: L.drawLocal.draw.toolbar.buttons.rectangle
                    },
                    {
                        enabled: this.options.circle,
                        handler: new L.Draw.Circle(map, this.options.circle),
                        title: L.drawLocal.draw.toolbar.buttons.circle
                    },
                    {
                        enabled: this.options.marker,
                        handler: new L.Draw.Marker(map, this.options.marker),
                        title: L.drawLocal.draw.toolbar.buttons.marker
                    },
                    {
                        handler: new L.Draw.Marker(map, this.options.marker),
                        title: L.drawLocal.draw.toolbar.buttons.marker,
                    },
                ];
            }
        });

        //@ts-ignore
        const drawControl = new L.Control.Draw({
            edit: {
                featureGroup: editableLayers
            }
        }).addTo(map);

        map.addControl(drawControl)

        map.on(L.Draw.Event.CREATED, function (e) {
            var layer = e.layer
            // Do whatever else you need to. (save to db, add to map etc)
            map.addLayer(layer);
            console.log("Draw Created Event!", e);
            console.log(editableLayers.getLayers());
        });

        map.on(L.Draw.Event.DELETED, function (e) {
            e.layer.eachLayer((layer: any) => {
                editableLayers.getLayers()
            })
        });

    }, [map]);

    return null
}

export default OpenStreetMapDrawTool

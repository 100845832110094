import { configureStore } from '@reduxjs/toolkit'
import {useDispatch, TypedUseSelectorHook, useSelector, } from "react-redux";

import authSlice from "../reducers/auth.slice";
import userSlice from "../reducers/user.slice";
import waitingActivitySlice from "../reducers/waitingActivity.slice";
import expeditionsSlice from "../reducers/expeditions.slice";
import snackbarSlice from "../reducers/snackbar.slice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        user: userSlice,
        waitingActivity: waitingActivitySlice,
        expeditions:expeditionsSlice,
        snackbar:snackbarSlice
    }
})

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


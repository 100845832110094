import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {IWaitingActivityState, IWaitingActivity} from "../types/interfaces/reducers/reports/waitingActivity/waitingActivity.interface";
import { fetchWaitingActivity} from "../services/client/reports/waitingActivity/waitingActivity.service";

const initialState: IWaitingActivityState = {
    //@ts-ignore
    data: null,
    loading:false,
    error: ""
}

const waitingActivitySlice = createSlice({
    name:"waitingActivity",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWaitingActivity.pending, (state, action)=>{
            state.loading = true;
        });
        builder.addCase(fetchWaitingActivity.fulfilled, (state, action:PayloadAction<IWaitingActivity>)=>{
            state.loading = false;
            //@ts-ignore
            state.data = action.payload;
        })
        builder.addCase(fetchWaitingActivity.rejected, (state, action)=>{
            state.error = "error";
        })
    }
})

export default waitingActivitySlice.reducer;

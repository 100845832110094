import axios, {AxiosResponse} from "axios";

import {slug} from "../../../config";
import DateProvider from "../../../utils/dateProvider/dateProvider";

interface MobilizService {
    fetchActivityDetailService(
        params: {
            accessToken: string,
            id_no: number | null | undefined,
            startTime:string | null | undefined
            endTime:string | null | undefined
        }): Promise<AxiosResponse>;
    fetchTotalService(apiUrl:string, accessToken:string): Promise<AxiosResponse>;
}


export const ApiMobilizService: MobilizService = {
    fetchActivityDetailService: async (params: {
        accessToken: string,
        id_no: number | null | undefined,
        startTime:string,
        endTime:string,
    }): Promise<AxiosResponse> => {
        const config = {
            params:{
                startTime:params.startTime,
                endTime:params.endTime,
                networkId:params.id_no,
            },
            headers:{
                Authorization: `Bearer ${params.accessToken}`,
            }
        }
        try {
            const response = await axios.get("http://apitest.abcws.prod/tr/mobiliz/1/activity/detail", config)
            return response
        }
        catch (error) {
            throw error
        }
    },
    fetchTotalService: async (apiUrl: string, accessToken: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.get(apiUrl, {headers})
            console.log(response,"MOBILIZ")
            return response
        }
        catch (error) {
            throw error
        }
    }
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useEffect} from "react";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";

import Chip from "@mui/material/Chip";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    p: 4,
};

interface IDemandApprovalModal {
    open: boolean
    close: (status: boolean) => void
    approval:()=>void,
    data:any
}

function DemandCreateModal(props: IDemandApprovalModal) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        props.close(false)
        setOpen(false);
    }

    useEffect(() => {
        setOpen(props.open)
    }, [props.open]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card sx={style}>
                <CardHeader
                    title="Talep Onayı ve Stok Bilgisi"
                    subheader="Talep ettiğiniz ürünlerin stok miktarı kontrol edilmesi nedeniyle onaylamanız gerekmektedir. Bu onay aşaması, hem departmanımızın stok yönetimini düzgün bir şekilde yapabilmesi hem de sizin talep ettiğiniz ürüne hızlıca ulaşabilmeniz için gereklidir."
                    subheaderTypographyProps={{
                        style: {fontSize: "13px"}
                    }}
                />
                <CardContent>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Talep Edilen Ürün</TableCell>
                                    <TableCell>Talep Edilen Miktar</TableCell>
                                    <TableCell>Stok Durumu</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography>
                                            Ürün Adı
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography>
                                            Ürün Miktarı
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Chip label="Stok Sınırını Aştınız" color="primary" variant="outlined"/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={props.approval}
                    >
                        Talebi Onayliyorum
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={()=>props.close(false)}
                    >
                        Talep Miktarını Gözden Geçirin
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    );
}

export default DemandCreateModal

import React, {useEffect, useState, memo} from "react"
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";


const data = [
    { id: 1, name: 'Aktif Araçlar', status: "true" },
    { id: 2, name: 'Pasif Araçlar', status: "false" },
    { id: 3, name: 'Tümü', status: "" },
];


interface IIsActiveSelector {
    vehicle:(status:string)=>void
}

const IsActiveSelector = (props:IIsActiveSelector) => {
    const theme = useTheme();
    const handleSelectChange = (event: SelectChangeEvent) => {
        const selectedId = parseInt(event.target.value);
        const selectedData = data.find(item => item.id === selectedId);
        if (selectedData) {
            props.vehicle(selectedData.status);
        }
    }

    return (
        <Box sx={{
            m: 1
        }}>
            <FormControl sx={{
                display: "flex",
                width: "120px",
                justifyContent: "center"
            }} size="small">
                <InputLabel
                    id="demo-select-small-label"
                    sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        color: `${theme.palette.text.primary}`
                    }}
                >Araç Durumu</InputLabel>
                {
                    <Select
                        onChange={handleSelectChange}
                        sx={{
                            backgroundColor: "transparent",
                            minHeight: 0,
                            height: "35px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            borderRadius: "4px",
                            color: `${theme.palette.text.primary}`
                        }}
                    >
                        {
                            data.map((value, index) => (
                                <MenuItem
                                    key={index}
                                    value={value.id}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        color: `${theme.palette.text.primary}`
                                    }}
                                >
                                    <InputLabel
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            color: `${theme.palette.text.primary}`
                                        }}
                                    > {
                                        //@ts-ignore
                                        value.name
                                    }</InputLabel>
                                </MenuItem>
                            ))
                        }
                    </Select>
                }
            </FormControl>
        </Box>
    )
}

export default memo(IsActiveSelector);

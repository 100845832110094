import LanguageKeys from "./tr";

export class LanguageKeysEN extends LanguageKeys {
    public appBarTitle = "Dashboard"

    /**
     * App Drawer Titles
     * */
    public drawer_DashboardTitle = "Dashboard"
    public drawer_ConfigurationTitle = "Configurations"
    public drawer_AdvertsTitle = "Adverts"
    public drawer_MyAdvertsTitle = "My Adverts"
    public drawer_CreateAdvertTitle = "Create Adverts"
    public drawer_CategoryAdvertTitle = "Categories"
    public drawer_OffersTitle = "Offers"
    public drawer_WaitingVehiclesTitle = "Waiting Vehicles"
    public drawer_ExpeditionsTitle = "Expeditions"
    public drawer_DevicesTitle = "Devices"
    public drawer_ReportsTitle = "Reports"
    public drawer_TransactionsTitle = "Transactions"
    public drawer_SettingsTitle = "Settings"
    public drawer_DefinitionsTitle = "Definitions"
    public drawer_UsersTitle = "Users"
    public drawer_NotificationTitle = "Notification"
    public drawer_expeditionComparisonTitle = "Expedition Comparison"
    public drawer_taxesTitle = "Taxes"
    public drawer_dueOptionsTitle = "Due Options"
    public drawer_currenciesTitle = "Currencies"
    public drawer_holdersTitle = "Holders"
    public drawer_causesTitle = "Causes"
    public drawer_productsTitle = "Products"
    public appDrawer_DemandsTitle = "Demands"
    public appDrawer_Warehouse = "Warehouses"
    /**
     *
     */
    public navbar_accountButtonTitle = "Account"
    public navbar_logOutButtonTitle = "Logout"

    /**
     * DFDS
     * */
    public drawer_dfdsTitle = "Bookings"
    /**
     * Other
     * */
    public drawer_dashboardTitle = "Dashboard"
    public drawer_discountsTitle = "Discounts"
    public drawer_vehiclesTitle = "Vehicles"
    public drawer_companiesTitle = "Companies"

}
export default LanguageKeysEN;

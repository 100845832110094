import React, { useEffect, useState } from "react"
import {
    Grid,
    Button,
    Select,
    Box,
} from "@mui/material";

import { getStorageData } from "../../../utils/usePersistedState";
import ApiExpeditionsPositionTypesService from "../../../services/client/expeditions/expeditions.positionTypes.service";
import { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { ComponentView } from "../../../types/enums/components/component.enum";



interface IExpeditionTypeSelector {
    fallback: (type: number) => void
    componentView: ComponentView,
}


const ExpeditionTypeSelector = (props: IExpeditionTypeSelector) => {

    const [positionTypes, setPositionTypes] = useState([]);

    const [selectedButton, setSelectedButton] = useState(0);

    const handleButtonClick = (index: number) => {
        setSelectedButton(index);
        if (index === 5) {
            //@ts-ignore
            props.fallback(null);
        } else {
            props.fallback(index);
        }
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
        //@ts-ignore
        const selectedId = event.target.value.id;
        // Check if the selected ID is 5 (corresponds to "hepsi"), and set props.fallback accordingly
        if (selectedId === 5) {
            //@ts-ignore
            props.fallback(null);
        } else {
            props.fallback(selectedId);
        }
    }

    useEffect(() => {
        getStorageData("authState", true).then((auth) => {
            //@ts-ignore
            ApiExpeditionsPositionTypesService.fetchExpeditionsPositionTypes({ accessToken: auth.status.access_token })
                .then((res) => {
                    //@ts-ignore
                    setPositionTypes([{ id: 5, name: "Tümü" }, ...res.data.results])
                })
        })
    }, [])


    switch (props.componentView) {
        case ComponentView.TAB: {
            return (
                <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} md={12}>
                    {
                        positionTypes.map((types, index) => (
                            <Button
                                key={index}
                                //@ts-ignore
                                variant={selectedButton === types.id ? "outlined" : "outlined"}
                                //@ts-ignore
                                onClick={() => handleButtonClick(types.id)}
                                sx={{
                                    width: "auto",
                                    height: "28px",
                                    //@ts-ignore
                                    border: selectedButton === types.id ? "3px solid gray" : "1px solid gray",
                                    padding: "8px",
                                    // fontSize: "14px",
                                }}
                            >
                                {
                                    //@ts-ignore
                                    types.name
                                }
                            </Button>
                        ))

                    }
                </Grid>
            )
        }
        case ComponentView.SELECT: {
            return (
                <Box sx={{
                    m: 1
                }}>
                    <FormControl sx={{
                        display: "flex",
                        width: "120px",
                        justifyContent: "center"
                    }} size="small">
                        <InputLabel
                            id="demo-select-small-label"
                            sx={{
                                fontSize: "12px",
                                fontWeight: 600
                            }}
                        >Sefer Türleri</InputLabel>
                        {
                            <Select
                                onChange={handleSelectChange}
                                sx={{
                                    backgroundColor: "transparent",
                                    minHeight: 0,
                                    height: "35px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    borderRadius: "9px",
                                }}
                            >
                                {
                                    positionTypes.map((types, index) => (
                                        <MenuItem
                                            key={index}
                                            value={types}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 600
                                                }}
                                            > {
                                                    //@ts-ignore
                                                    types.name
                                                }</InputLabel>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        }
                    </FormControl>
                </Box>
            )
        }
    }
}

export default ExpeditionTypeSelector

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useEffect, useState} from "react";
import DemandStatusSelector from "../../selectors/demands/demand.status.selector";
import {SubmitHandler, useForm} from "react-hook-form";
import {getStorageData} from "../../../utils/usePersistedState";
import DemandsService from "../../../services/client/demands/demands.service";
import TextField from "@mui/material/TextField";
import {Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DemandsItemsMultipleService from "../../../services/client/demands/items/demands.items.service";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface IDemandApprovalModal {
    open: boolean
    close: (status: boolean) => void
    demand: object
}

type DemandInputs = {
    status: string,
    demand_items: {
        delivered_quantity: number
    }
}


export default function DemandItemApprovalModal(props: IDemandApprovalModal) {
    const [open, setOpen] = useState(false);

    const [demandsItems, setDemandsItems] = useState<object[]>([])

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        props.close(false)
        setOpen(false);
    }

    useEffect(() => {
        setOpen(props.open)
        console.log(props.demand)
    }, [props.open]);


    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: {
            errors
        }
    } = useForm<DemandInputs>()

    const onSubmitForm: SubmitHandler<DemandInputs> = (data, event) => {
        console.log(data)
        event?.preventDefault()

        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                DemandsService.editDemands({
                    //@ts-ignore
                    apiUrl: api.url,
                    //@ts-ignore
                    accessToken: auth.status.access_token,
                    data: data,
                    //@ts-ignore
                    id: props.demand.id
                })
            })
        })

        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                DemandsItemsMultipleService.updateMultiple({
                    //@ts-ignore
                    apiUrl: api.url,
                    //@ts-ignore
                    accessToken: auth.status.access_token,
                    data: data.demand_items,
                    //@ts-ignore
                    id: props.demand.id
                })
            })
        }).then((res:any) => {
            window.location.reload()
        })

    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} component="form">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{
                                    fontSize: "10px",
                                    width:"150px"
                                }}>Ürün İsmi</TableCell>
                                <TableCell sx={{
                                    fontSize: "10px",
                                    width:"200px"
                                }}>Talep Miktarı</TableCell>
                                <TableCell align="left" sx={{
                                    fontSize: "10px",
                                    width:"200px"
                                }}>Karşılanacak Miktar</TableCell>
                                <TableCell sx={{
                                    fontSize: "10px",
                                    width:"200px"
                                }}>Karşılanan Miktar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                //@ts-ignore
                                props.demand.demand_items?.map((res: any, index: number) => (
                                    <TableRow>
                                        <TextField
                                            sx={{
                                                display: "none"
                                            }}
                                            defaultValue={res.id}
                                            //@ts-ignore
                                            {...register((`demand_items.${index}.id`), {required: true})}
                                        />
                                        <TableCell
                                            align="left"
                                            sx={{

                                            }}>{
                                            //@ts-ignore
                                            res.product.name
                                        }</TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{

                                            }}>
                                            <TextField disabled defaultValue={
                                                //@ts-ignore
                                                res.requested_quantity
                                            }/>
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                placeholder={"Değer Giriniz"}
                                                //@ts-ignore
                                                {...register((`demand_items.${index}.delivered_quantity`), {required: true})}
                                            />
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{

                                            }}>
                                            <TextField disabled defaultValue={
                                                //@ts-ignore
                                                res.delivered_quantity
                                            }/>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack direction="column" spacing={1}>
                <DemandStatusSelector
                    label={"Talep Durumu"}
                    //@ts-ignore
                    register={register}
                    inputName={(`status`)}
                    //@ts-ignore
                    defaultValue={props.demand.status}
                />
                <Stack direction="row" spacing={1}>
                    <Button
                        variant="contained"
                        onClick={handleSubmit(onSubmitForm)}
                    >
                        Onayla
                    </Button>
                    <Button
                        variant="contained"
                        onClick={()=>handleClose()}
                    >
                        Iptal Et
                    </Button>
                </Stack>
                </Stack>
            </Box>

        </Modal>

    );
}

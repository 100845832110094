import {getStorageData, setStorageData} from "../usePersistedState";
import {baseUrls} from "../../types/enums/configs/api.enums";

interface ISetDynamicApi {
    name: string,
    url: string
}

export const setDefaultDynamicApi = () => {
    getStorageData("api", false).then((res) => {
        if (res == null) {
            setStorageData("api", {name: "PROD", url: baseUrls.prod}, false).then(() => {
                console.log("Default Dynamic Api Kayıt Edildi")
            })
        }
    })
}

export const setDynamicApi = (props: ISetDynamicApi) => {
    setStorageData("api", {name: props.name, url: props.url}, false).then(() => {
        console.log("Dynamic Api Kayıt Edildi")
    })
}

export const getDynamicApi = async () => {
    const response = await getStorageData("api", false).then((res) => {
        //@ts-ignore
        return res.url
    })
    return response
}

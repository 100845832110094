import React from "react"
import axios, {AxiosResponse} from "axios";
import {slug} from "../../../config";

interface IntegrationsTaxes {
    // Ürün birimi veri yapısı
}

interface IntegrationsTaxesService {
    apiUrl?: string
    fetchIntegrationsTaxes(accessToken: string): Promise<AxiosResponse>;
    createIntegrationsTaxes(accessToken: string, data: any): Promise<AxiosResponse>;
    updateIntegrationsTaxes(accessToken: string, data: any, taxID: string): Promise<AxiosResponse>;
    deleteIntegrationsTaxes(accessToken: string, taxID: string): Promise<AxiosResponse>;
}

const apiUrl = slug.client.integrations.taxes.url;

export const ApiIntegrationsTaxesService: IntegrationsTaxesService = {
    fetchIntegrationsTaxes: async (accessToken: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };

        try {
            const response = await axios.get(apiUrl, {headers});
            return response
        } catch (error) {
            throw error
        }
    },

    createIntegrationsTaxes: async (accessToken: string, data: any): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };

        try {
            const response = await axios.post(apiUrl, data, {headers})
            return response
        } catch (error) {
            throw error
        }
    },

    updateIntegrationsTaxes: async (accessToken: string, data: any, taxID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.put(apiUrl+taxID, data, {headers})
            return response
        } catch (error) {
            throw error
        }
    },

    deleteIntegrationsTaxes: async (accessToken: string, taxID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.put(apiUrl+taxID, {headers})
            return response
        } catch (error) {
            throw error
        }
    }
}

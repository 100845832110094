import React, {FC, useEffect, useMemo, useState} from "react"
import {getStorageData} from "../../../../utils/usePersistedState";
import {advertItemFetchService} from "../../../../services/client/adverts/adverts.service";
import {
    CssBaseline, Modal, Typography,
} from "@mui/material";

//MUI Templates - Data Grid
import { DataGrid, trTR } from '@mui/x-data-grid' ;

import {
    Box
} from '@mui/material';
import OffersStepperWizard from "../../../stepper/offers.stepper.wizard";

interface IAdvertItemOfferCreateForm {
    advertId:string
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const AdvertItemOfferCreateForm:FC<IAdvertItemOfferCreateForm> = (props, context) => {

    /**
     * Modal
     */
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [ getLoading, setLoading ] = useState<boolean>(true)
    const [ getAdvertItem, setAdvertItem ] = useState<object>([])
    const [ getSelectedItem, setSelectedItem] = useState<object>([])

    //@ts-ignore
    const handleOffersWizard = (adverdItem) => {
        setSelectedItem(adverdItem)
        handleOpen()
    }

    useEffect(()=> {
        getStorageData("authState",false).then((res:any)=> {
            //@ts-ignore
            advertItemFetchService(res.status.access_token, props.advertId).then((res:any)=> {
                setAdvertItem(res.data.results)
                setLoading(false)
            })
        })

    },[])

    const columns = useMemo(() => [
        {field: 'id', headerName: 'ID'},
        {
            field: 'product',
            headerName: 'Ürün',
            valueFormatter: (params: any) => params.value ? params.value?.name : "Bilgi bulunamadı!"
        },
        {
            field: 'count',
            headerName: 'Toplam',
            valueFormatter: (params: any) => params.value ? params.value?.name : "Bilgi bulunamadı!"
        },
        {
            field: 'unit',
            headerName: 'Birim',
            valueFormatter: (params: any) => params.value ? params.value?.name : "Bilgi bulunamadı!"
        },
        {
            field: 'unit_price',
            headerName: 'Birim Fiyat',
            valueFormatter: (params: any) => params.value ? params.value?.name : "Bilgi bulunamadı!"
        },
        {
            field: 'currency',
            headerName: 'Para Birimi',
            valueFormatter: (params: any) => params.value ? params.value?.currency_code : "Bilgi bulunamadı!"
        },
        {
            field: 'tax',
            headerName: 'Vergi Türü',
            valueFormatter: (params: any) => params.value ? params.value?.tax_code : "Bilgi bulunamadı!"
        },
        {
            field: 'total_price',
            headerName: 'Toplam Fiyat',
        },
        {
            field: 'desc',
            headerName: 'Açıklama',
        }
    ], [])

    return(
            getLoading ? <></> :
                <Box>
                    <CssBaseline/>
                    <DataGrid
                        sx={{
                            height: 400,
                            width: "100%"
                    }}
                        //@ts-ignore
                        columns={columns}
                        //@ts-ignore
                        rows={getAdvertItem}
                        onCellClick={(e:any) => handleOffersWizard(e)}
                    />
                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Box sx={style}>
                            <OffersStepperWizard advertId={props.advertId} advertItem={getSelectedItem}/>
                        </Box>
                    </Modal>
                </Box>
    )
}



export default AdvertItemOfferCreateForm

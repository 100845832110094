import React from "react"
import { useParams } from "react-router-dom";
import AdvertsPage from "./pages/adverts/adverts.page";
import UserPage from "./pages/configurations/users/user.page";
import NotificationsPage from "./pages/notifications/notification.page";

// @ts-ignore
import DevicesPage from "./pages/devices/devices.page"

import {Box, Grid} from "@mui/material";
import ProductsPage from "./pages/products/products.page";
import {permissionControl} from "./utils/permissionControl";
import PermissionRestricted from "./utils/permissionProvider/permissionRestricted";
import PermissionsTypes from "./types/models/components/permissions.default.model";
import PermissionProvider from "./utils/permissionProvider/permissionProvider";
import {useAppSelector} from "./store";
import Notfound from "./pages/notfound";
import ExpeditionsPage from "./pages/expeditions/expeditions.page";
import CssBaseline from "@mui/material/CssBaseline";
import DemandsPage from "./pages/demands/demands.page";
import WarehousePage from "./pages/warehouses/warehouse.page";

interface IAppView {
    permission: string
}

const AppView = () => {
    const userInfo = useAppSelector((state) => state.user);

    const {app} = useParams();

    return (
        <Box>
            {
                app == "warehouses" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_warehouse")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>Kullanıcı İzni Yok</>}>
                        {
                            <WarehousePage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&
                app == "demands" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_demand")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>Kullanıcı İzni Yok</>}>
                        {
                            <DemandsPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "adverts" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_advert")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>Kullanıcı İzni Yok</>}>
                        {
                            <AdvertsPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "products" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_product")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>Kullanıcı İzni Yok</>}>
                        {
                            <ProductsPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "expeditions" ? <ExpeditionsPage/> : <Notfound/> &&

                app == "devices" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_device")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>Kullanıcı İzni Yok</>}>
                        {
                            <DevicesPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "users" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_customuser")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>Kullanıcı İzni Yok</>}>
                        {
                            <UserPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/> &&

                app == "notifications" ? <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_pushnotification")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={<>Kullanıcı İzni Yok</>}>
                        {
                            <NotificationsPage/>
                        }
                    </PermissionRestricted>
                </PermissionProvider> : <Notfound/>
            }
        </Box>
    )
}

export default AppView

import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import BookingCompanyMultiSelector from "../../selectors/dfds/companies/booking.company.multiSelector";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import UpdateUserApproveModal from "../../modal/bookings/vehicles/update.user.approve.modal";
import AddUserApproveModal from "../../modal/bookings/vehicles/add.user.approve.modal";
import CompanyEditSvgComponent from "../../svg/company.edit.svg.component";
import VehicleViewSvgComponent from "../../svg/vehicle.view.svg.component";
import BookingVehicleInfoModal from "../../modal/bookings/vehicles/booking.vehicle.info.modal";
import {useTheme} from "@mui/material/styles";



interface IBookingMenu {
    rowValue:any,
    refreshing:()=>void
}

const BookingMenu = (props:IBookingMenu) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const [selectedCompany, setSelectedCompany] = useState({
        Id:"",
        Name:""
    })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        props.refreshing()
        setAnchorEl(null);
    };


    return (
        <React.Fragment>
            <Stack
                direction="row"
                spacing={3}
            >
                <BookingVehicleInfoModal
                    rowValue={props.rowValue}
                    refreshing={()=>handleClose()}
                />
                <Tooltip title="Firma Ekle / Güncelle">
                    <IconButton onClick={handleClick} aria-label="fingerprint" color="secondary" sx={{
                        backgroundColor: `${theme.palette.secondary.main}`,
                        width: "36px",
                        height: "36px",
                        justifyContent:"center",
                        alignItems:"center",
                        display:"flex",
                    }}>
                        <CompanyEditSvgComponent/>
                    </IconButton>
                </Tooltip>
            </Stack>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                sx={{
                    height:"500px"
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{
                    height: "400px",
                    width:"400px",
                    borderRadius: "10px",
                    borderColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding:1
                }}>
                    <Box>
                        <Typography sx={{
                            color: `${theme.palette.text.primary}`,
                            fontWeight: 600,
                            fontSize: "16px",
                            margin: 1,
                        }}>
                            {
                                props.rowValue.row?.plate
                            }
                        </Typography>
                        <Divider/>
                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            margin: 1,
                            color: `${theme.palette.text.primary}`
                        }}>
                            Firma Bilgisi
                        </Typography>
                        <BookingCompanyMultiSelector
                            defaultCompany={props.rowValue.row.company?.company_name}
                            company={(Id, name)=>setSelectedCompany({
                                Id:Id,
                                Name:name
                            })}
                        />
                    </Box>
                    <Box sx={{
                        width:"100%",
                        height:"50px",
                        display:"flex",
                        justifyContent:"flex-end"
                    }}>
                        <Stack
                            direction="row"
                            spacing={4}
                            justifyContent="space-between"
                            sx={{
                                width:"100%",
                            }}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    width:100,
                                    height:38
                                }}
                                onClick={()=>setAnchorEl(null)}
                            >İptal</Button>
                            {
                                props.rowValue.row.company?.company_name ?
                                    <UpdateUserApproveModal
                                        defaultCompany={props.rowValue.row?.company?.company_name}
                                        vehiclePlate={props.rowValue.row.plate}
                                        companyId={selectedCompany.Id}
                                        vehicleId={props.rowValue.row.id}
                                        updateCompany={selectedCompany.Name}
                                        refreshing={()=>handleClose()}
                                        defaultCompanyId={props.rowValue.row?.company?.company_id}
                                    />
                                    :
                                    <AddUserApproveModal
                                        companyId={selectedCompany.Id}
                                        vehicleId={props.rowValue.row.id}
                                        vehiclePlate={props.rowValue.row.plate}
                                        defaultCompany={props.rowValue.row?.company?.company_name}
                                        defaultCompanyId={props.rowValue.row?.company?.company_id}
                                        updateCompany={selectedCompany.Name}
                                        refreshing={()=>handleClose()}
                                    />
                            }
                        </Stack>
                    </Box>
                </Box>
            </Menu>
        </React.Fragment>
    );
}

export default BookingMenu

export class LanguageKeys {
    public appBarTitle = "Panel"
    /**
      * App Drawer Titles
      * */
    public drawer_DashboardTitle = "Panel"
    public drawer_ConfigurationTitle = "Yapılandırmalar"
    public drawer_AdvertsTitle = "İlanlar"
    public drawer_MyAdvertsTitle = "İlanlar"
    public drawer_CreateAdvertTitle = "İlan Oluştur"
    public drawer_CategoryAdvertTitle = "Kategoriler"
    public drawer_OffersTitle = "Teklifler"
    public drawer_WaitingVehiclesTitle = "Bekleyen Araçlar"
    public drawer_ExpeditionsTitle = "Operasyon Takip Ekranı"
    public drawer_DevicesTitle = "Cihazlar"
    public drawer_ReportsTitle = "Raporlar"
    public drawer_TransactionsTitle = "İşlemler"
    public drawer_SettingsTitle = "Ayarlar"
    public drawer_UsersTitle = "Kullanıcılar"
    public drawer_DefinitionsTitle = "Tanimlar"
    public drawer_NotificationTitle = "Bildirimler"
    public drawer_expeditionComparisonTitle = "Sefer Karşılaştırması"
    public appDrawer_DemandsTitle = "Talepler"
    public appDrawer_Warehouse = "Depolar"
    /**
     *
     */
    public navbar_accountButtonTitle = "Hesabım"
    public navbar_logOutButtonTitle = "Oturumu Kapat"

    public drawer_taxesTitle = "Vergiler"
    public drawer_dueOptionsTitle = "Vadeler"
    public drawer_currenciesTitle = "Para Birimleri"
    public drawer_holdersTitle = "Bekletenler"
    public drawer_causesTitle = "Bekleme Nedenleri"

    public drawer_productsTitle = "Ürünler"

    /**
     * DFDS
     * */
    public drawer_dfdsTitle = "Rezervasyonlar"
    /**
     * Other
     */
    public drawer_dashboardTitle = "Dashboard"
    public drawer_discountsTitle = "Faturalar"
    public drawer_vehiclesTitle = "Araçlar"
    public drawer_companiesTitle = "Firmalar"
    public drawer_bookingsTitle = "Biletler"
}

export type LanguageProps = keyof LanguageKeys;
export default LanguageKeys

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

//Storage
import {getStorageData} from "../../../utils/usePersistedState";

//Services
import {
    advertCategoryFetchService,
    advertCategoryDeleteService
} from "../../../services/client/adverts/adverts.service";

import {
    Container,
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    Breadcrumbs,
    Tooltip
} from "@mui/material";

//Icons
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';


const Category = () => {

    const [getCategories, setCategories] = useState([])

    useEffect(()=>{
        getStorageData("authState",false).then(auth=>(
            //@ts-ignore
            advertCategoryFetchService(auth.status.access_token).then((res:any) => {
                //@ts-ignore
                setCategories(res.data.results)
            })
        ))
    },[])


    const _deleteAdvertCategory = (advertCategoryId:string) => {
        getStorageData("authState",false).then(auth=>(
            //@ts-ignore
            advertCategoryDeleteService(auth.status.access_token,advertCategoryId).then((res:any)=>{
            })
        ))
    }

    return(
        <Grid md={12}>
            <Link to={"/adverts/category/create"}>
                <Button variant="contained"
                        startIcon={<AddShoppingCartOutlinedIcon/>}
                        sx={{
                            fontSize:"0.8em"
                        }}
                >Kategori Ekle</Button>
            </Link>
            <Container sx={{
                display:"flex",
                flexDirection:"row"
            }}>
                <Grid container md={12}>
                {
                    getCategories.map((res,index)=>(
                        <Grid md={3} sx={{margin:"1em"}}>
                            <Card sx={{
                                minWidth:"240px",
                                minHeight:"150px"
                            }}>
                                <CardContent>
                                    <Grid md={12}>
                                        <Grid md={6}>
                                            <Typography component="h1">
                                                {
                                                    //@ts-ignore
                                                    res.name
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid md={6}>
                                            <Typography component="h1">
                                                {
                                                    //@ts-ignore
                                                    res.id
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container md={12}>
                                        <Grid md={6}>

                                        </Grid>
                                        <Grid md={6} sx={{
                                            display:"flex",
                                            justifyContent:"flex-end"
                                        }}>
                                            <Link to="/advert/category/edit/" state={
                                                //@ts-ignore
                                                res.id
                                            }>
                                                <Tooltip title="Güncelle">
                                                    <IconButton>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                            <Tooltip title="Sil">
                                                <IconButton onClick={
                                                    //@ts-ignore
                                                    ()=>_deleteAdvertCategory(res.id)
                                                }>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
                </Grid>
            </Container>
        </Grid>
    )
}

export default Category;

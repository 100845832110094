//React Library
import React, {useState} from "react"
import {SelectChangeEvent} from "@mui/material/Select";
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {AdvertInputs} from "../../../types/types/components/forms/form.type";
import {getStorageData} from "../../../utils/usePersistedState";
import {advertCreateService} from "../../../services/client/adverts/adverts.service";

import {
    Box, Button,
    Card,
    CardContent,
    Container,
    FormControl, IconButton, InputAdornment,
    InputLabel,
    OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField
} from "@mui/material";

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import {AddBox} from "@mui/icons-material";
import TitleFormController from "../controllers/input.form.controller";

import {CurrencySelector} from "../controllers/currency.controller";

import {TaxSelector} from "../controllers/tax.controller";
import {UnitSelector} from "../controllers/unit.controller";
import ProductSelector from "../controllers/product.controller";
import {CategorySelector} from "../controllers/category.controller";

import {useNavigate} from 'react-router-dom';
import {AddProductModal} from "../../modal/modal";

//Advert Services
const AdvertCreateForm = () => {

    const navigate = useNavigate();

    const [categoriesId, setCategoriesId] = useState('');

    const [getCategories, setCategories] = useState([]);

    //Product Unit State
    const [getProductUnitHandleChange, setProductUnitHandleChange] = useState([]);

    const [getCurrencyIcon, setCurrencyIcon] = useState('');

    const [finishDate, setFinishDate] = useState<any>()

    const [startDate, setStartDate] = useState<any>()

    const [totalPrice, setTotalPrice] = useState<number>(0);

    const handleChange = (event: SelectChangeEvent) => {
        setCategoriesId(event.target.value as string);
    };

    const productUnitHandleChange = (event: SelectChangeEvent) => {
        //@ts-ignore
        setProductUnitHandleChange(event.target.value)
    }

    const vatCalculation = (event: SelectChangeEvent) => {
        var unit = getProductUnitHandleChange;
        var price = event.target.value;
        //@ts-ignore
        setTotalPrice((unit * price * 18 / 100) + unit * price)
    }

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: {
            errors
        }
    } = useForm<AdvertInputs>();

    const {
        fields,
        append,
        prepend
    } = useFieldArray({
        control,
        //@ts-ignore
        name: "items"
    });

    const onSubmitForm: SubmitHandler<AdvertInputs> = (data, event) => {
        //@ts-ignore
        event.preventDefault();
        data.start_time = startDate
        data.advert_items = data.advert_items
        data.end_time = finishDate
        data.tax = "1"

        getStorageData("authState",false).then(auth => (
            //@ts-ignore
            advertCreateService(data, auth.status.access_token).then(res => {
                    if (res.status == 201) {
                        navigate('/adverts');
                    }
                }
            )
        ))
    }

    return (
        <Container>
            <Card>
                <CardContent>
                    <Box component="form">
                        <Box sx={{minWidth: 120, marginBottom: "1em"}}>
                            <TitleFormController
                                label={"Başlık"}
                                registerName={"title"}
                                //@ts-ignore
                                register={register}
                            />
                        </Box>
                        <Box sx={{minWidth: 120, marginBottom: "1em"}}>
                            <CategorySelector
                                label={"Kategoriler"}
                                categoryId={(id) => console.log(id)}
                                //@ts-ignore
                                register={register}
                                inputName={(`category`)}
                            />
                        </Box>
                        <Box sx={{minWidth: 120, marginBottom: "1em"}}>
                            <CurrencySelector
                                label={"Para Birimi"}
                                currencyId={(id) => console.log(id)}
                                //@ts-ignore
                                register={register}
                                inputName={(`currency`)}
                            />
                        </Box>
                        <Box sx={{minWidth: 120, marginBottom: "1em"}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Toplam Fiyat</InputLabel>
                                <OutlinedInput
                                    required
                                    id="outlined-basic"
                                    label="Number"
                                    type="number"
                                    startAdornment={<InputAdornment position="start">{getCurrencyIcon}</InputAdornment>}
                                    //@ts-ignore
                                    {...register(`total_price`)}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{minWidth: 120, marginBottom: "1em"}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <InputLabel id="demo-simple-select-label">Başlangıç Tarihi</InputLabel>
                                <DatePicker onChange={(value) => setStartDate(value)}/>
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{minWidth: 120, marginBottom: "1em"}}>
                            <InputLabel id="demo-simple-select-label">Bitiş Tarihi</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    onChange={(value, context) => setFinishDate(value)}/>
                            </LocalizationProvider>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"><AddProductModal buttonTitle={"ÜRÜN"}/></TableCell>
                                        <TableCell align="left" sx={{
                                            fontSize:"10px"
                                        }}>ADET</TableCell>
                                        <TableCell align="left"><AddProductModal buttonTitle={"BİRİM"}/></TableCell>
                                        <TableCell align="left" sx={{
                                            fontSize:"10px"
                                        }}>BİRİM FİYAT</TableCell>
                                        <TableCell align="left"><AddProductModal buttonTitle={"PARA BİRİMİ"}/></TableCell>
                                        <TableCell align="left"><AddProductModal buttonTitle={"VERGİ TÜRÜ"}/></TableCell>
                                        <TableCell align="left" sx={{
                                            fontSize:"10px"
                                        }}>TOTAL</TableCell>
                                        <TableCell align="left" sx={{
                                            fontSize:"10px"
                                        }}>AÇIKLAMA</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fields.map((field, index) => {
                                        return (
                                            <TableRow
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                key={field.id}
                                            >
                                                <TableCell align="left" sx={{
                                                    width: "100vh"
                                                }}>
                                                    {
                                                        /**
                                                         * Product Selector
                                                         * **/
                                                    }
                                                    <ProductSelector
                                                        label={"Ürünler"}
                                                        index={index}
                                                        //@ts-ignore
                                                        register={register}
                                                        productId={(id) => console.log(id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" sx={{
                                                    width: "100vh"
                                                }}>
                                                    <FormControl sx={{width: "100%"}}>
                                                        <InputLabel id="demo-simple-select-label">Adet</InputLabel>
                                                        <OutlinedInput
                                                            id="standard-number"
                                                            label="Number"
                                                            type="number"
                                                            //@ts-ignore
                                                            {...register(`advert_items.${index}.count`)}
                                                            //@ts-ignore
                                                            onChange={productUnitHandleChange}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                {
                                                    /**
                                                     * Unit Selector
                                                     * **/
                                                }
                                                <TableCell align="left" sx={{
                                                    width: "100vh"
                                                }}>
                                                    <UnitSelector
                                                        label={"Birim"}
                                                        //@ts-ignore
                                                        register={register}
                                                        inputName={(`advert_items.${index}.unit`)}
                                                        unitId={(id) => console.log(id)}/>
                                                </TableCell>
                                                <TableCell align="left" sx={{
                                                    width: "100vh"
                                                }}>
                                                    <FormControl sx={{
                                                        width: "100%"
                                                    }}>
                                                        <InputLabel id="demo-simple-select-label">Birim
                                                            Fiyat</InputLabel>
                                                        <OutlinedInput
                                                            id="standard-number"
                                                            label="Number"
                                                            type="number"
                                                            startAdornment={<InputAdornment
                                                                position="start">{getCurrencyIcon}</InputAdornment>}
                                                            //@ts-ignore
                                                            {...register(`advert_items.${index}.unit_price`)}
                                                            //@ts-ignore
                                                            onChange={vatCalculation}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                {
                                                    /**
                                                     * Currency Selector
                                                     * **/
                                                }
                                                <TableCell sx={{
                                                    width: "100vh"
                                                }}>
                                                    <CurrencySelector
                                                        label="Para Birimi"
                                                        //@ts-ignore
                                                        register={register}
                                                        currencyId={(id: string) => console.log(id)}
                                                        inputName={(`advert_items.${index}.currency`)}
                                                    />
                                                </TableCell>
                                                {
                                                    /**
                                                     * Tax Selector
                                                     * **/
                                                }
                                                <TableCell sx={{
                                                    width: "100vh"
                                                }}>
                                                    <TaxSelector
                                                        label="Vergi Türü"
                                                        index={index}
                                                        //@ts-ignore
                                                        register={register}
                                                        taxId={(id: string) => console.log(id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" sx={{
                                                    width: "100vh"
                                                }}>
                                                    <FormControl fullWidth sx={{
                                                        width: "100%"
                                                    }}>
                                                        <TextField
                                                            disabled
                                                            id="outlined-disabled"
                                                            value={totalPrice}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell align="left" sx={{
                                                    width: "100vh"
                                                }}>
                                                    <TextField
                                                        sx={{
                                                            width: "100%"
                                                        }}
                                                        id="standard-multiline-static"
                                                        multiline
                                                        rows={2}
                                                        variant="standard"
                                                        //@ts-ignore
                                                        {...register(`advert_items.${index}.desc`)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                <IconButton color="primary" aria-label="add to shopping cart" onClick={() =>
                                    append({})
                                }>
                                    <AddBox/>
                                </IconButton>
                            </Table>
                        </TableContainer>
                        <Box sx={{
                            m: 1
                        }}>
                            <Button size="small"
                                variant="contained"
                                    onClick={handleSubmit(onSubmitForm)}>
                                İlan Ekle
                            </Button>
                            <Button>
                                İptal Et
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    )
}

export default AdvertCreateForm

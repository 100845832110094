import { createTheme } from "@mui/material/styles";
import { trTR as dataGridTrTR } from '@mui/x-data-grid';
import { trTR as coreTrTR } from '@mui/material/locale';
import { trTR } from '@mui/x-date-pickers/locales';

export const lightTheme = createTheme({
    typography: {
        fontFamily: 'Roboto',
    },

    palette: {
        mode: "light",
        primary: {
            main: "rgba(0,0,0,0.8)",
        },
        secondary: {
            main: "#FEB019",
        },
        info:{
            main:"#1070CF"
        },
        background:{
            paper:"rgba(245, 245, 245, 1)",
            default:"rgba(255,255,255,1)",
        },
        text:{
            secondary:"rgba(110, 107, 123, 1)",
            primary:"rgba(0,0,0,0.8)",
        },
        success:{
            main:"rgba(0,128,0,1)"
        },

        error:{
            main:"rgb(255,0,0)"
        },
    },
    //@ts-ignore
    trTR,
    dataGridTrTR,
    coreTrTR
});

export const darkTheme = createTheme({
    typography: {
        fontFamily: 'Roboto',
    },
    palette: {
        mode: "dark",
        primary: {
            main: "rgba(255,255,255,1)",
        },
        secondary: {
            main: "rgba(110, 107, 123, 1)",
        },
        info:{
            main:"#1e1e1e"
        },
        background:{
            default:"rgba(0,0,0,1)",
        },
        text:{
            secondary:"rgba(110, 107, 123, 1)",
            primary: "rgba(255,255,255,1)",
        },
        success:{
            main:"rgba(255,255,255,1)"
        },
        error:{
            main:"rgba(255,255,255,1)"
        },
        //@ts-ignore
        trTR,
        dataGridTrTR,
        coreTrTR
    }
});

import {FieldValues, UseFormRegister} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {getStorageData} from "../../../utils/usePersistedState";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {ApiIntegrationsDueOptionsService} from "../../../services/client/integrations/integrations.dueoptions.service";

/**
 * Interface Currency Select
 */
interface IDueoptionsSelect {
    label:string,
    dueoptionsId:(id:string) => void,
    register: UseFormRegister<FieldValues>,
    inputName:string,
}

export const DueoptionsSelector= ({label, dueoptionsId, register, inputName}: IDueoptionsSelect)=> {
    const [getDueoptions, setDueoptions] = useState([]);

    useEffect(()=>{
        //@ts-ignore
        getStorageData("authState").then(auth=>{
            //@ts-ignore
            ApiIntegrationsDueOptionsService.fetchIntegrationsDueOptionsService(auth.status.access_token).then(res=>{
                setDueoptions(res.data.results)
            })
        })
    },[])

    return(
        <FormControl sx={{
            width:"100%"
        }}>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                //@ts-ignore
                ref={register}
                //@ts-ignore
                {...register(inputName, {required:true})}
            >
                {
                    getDueoptions.map((res, index) => (
                        <MenuItem
                            key={index}
                            value={
                                //@ts-ignore
                                res.id
                            }
                        >
                            {
                                //@ts-ignore
                                res.option + " Gün"
                            }
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

import React from "react";
import {Link} from "react-router-dom";
import {useT} from "../../../../utils/locales/locale.index";

import {
    IAppLeftDrawerNavigation
} from "../../../../types/interfaces/components/navigations/drawers/app/app.left.drawer.navigation.interface";

//Components
import {
    List,
    Divider,
    IconButton,
    Toolbar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
    styled,
    ListSubheader,
} from "@mui/material";

import MuiDrawer from "@mui/material/Drawer";


//Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BackupTableIcon from '@mui/icons-material/BackupTable';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import DevicesOtherTwoToneIcon from '@mui/icons-material/DevicesOtherTwoTone';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import {useAppSelector} from "../../../../store";
import PermissionProvider from "../../../../utils/permissionProvider/permissionProvider";
import {permissionControl} from "../../../../utils/permissionControl";
import PermissionRestricted from "../../../../utils/permissionProvider/permissionRestricted";

import {PercentTwoTone} from "@mui/icons-material";
import {EmojiPeopleTwoTone} from "@mui/icons-material";
import {EventTwoTone} from "@mui/icons-material";
import NotListedLocationTwoToneIcon from '@mui/icons-material/NotListedLocationTwoTone';
import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import PermissionsTypes from "../../../../types/models/components/permissions.default.model";

import { FaShip } from "react-icons/fa";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { TbDiscount2 } from "react-icons/tb";
import { FaWarehouse } from "react-icons/fa6";
import { FaTrailer } from "react-icons/fa";
import {UserRoles} from "../../../../types/enums/reducers/user.enums";


// interface IDrawerNavigation {
//     open: boolean
//     toggleDrawer(): void
// }


const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: 240,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const AppLeftDrawerNavigation = (props:IAppLeftDrawerNavigation) => {

    const t = useT();

    /**
     * User Bilgisi Almak İçin
     * **/
    const userInfo = useAppSelector((state) => state.user);

    const [ilanMenu, setIlanMenu] = React.useState(false);

    const [accountMenu, setAccountMenu] = React.useState(false);

    const [dfdsMenu, setDfdsMenu] = React.useState(false);

    const handleDfdsMenu = () => {
        setDfdsMenu(!dfdsMenu)
    }


    const handleClick = () => {
        setIlanMenu(!ilanMenu);
    };

    const accountHandleClick = () => {
        setAccountMenu(!accountMenu)
    }

    return (
        <Drawer anchor="left" variant="permanent" open={props.isOpen} sx={{
            '& > div': {
                overflow: 'auto',
                '&::-webkit-scrollbar': { height: 5, WebkitAppearance: 'none', width: 5 },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: 8,
                    border: '1px solid',
                    borderColor: 'white',
                    backgroundColor: 'rgba(0 0 0 / 0.5)',
                },
            },
        }}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={props.onClose}>
                    <ChevronLeftIcon/>
                </IconButton>
            </Toolbar>
            <Divider/>
            <List component="nav" sx={{
                height:"60px",
                '& > div': {
                    overflow: 'auto hidden',
                    '&::-webkit-scrollbar': { height: 10, WebkitAppearance: 'none' },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: 8,
                        border: '2px solid',
                        borderColor: "red",
                        backgroundColor: 'rgba(0 0 0 / 0.5)',
                    },
                },
            }}>
                <List>
                    <Link style={{textDecoration: "none"}} to={"/"}>
                        <ListItemButton>
                            <ListItemIcon>
                                <GridViewOutlinedIcon sx={{fontSize: 20}}/>
                            </ListItemIcon>
                            <ListItemText primary={t("drawer_DashboardTitle")} style={{color: "grey"}}/>
                        </ListItemButton>
                    </Link>
                    <ListSubheader component="div" inset style={{fontWeight: "bold", color: "lightgray"}}>
                        {t("drawer_DefinitionsTitle")}
                    </ListSubheader>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_tax")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"integrations/taxes"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <PercentTwoTone sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t('drawer_taxesTitle')} style={{color: "gray"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_dueoption")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"integrations/dueoptions"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <EventTwoTone sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t('drawer_dueOptionsTitle')} style={{color: "gray"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_currency")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"integrations/currencies"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <EventTwoTone sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t('drawer_currenciesTitle')} style={{color: "gray"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    {/*<PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_holder")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"integrations/holders"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <EmojiPeopleTwoTone sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("drawer_holdersTitle")} style={{color: "gray"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>*/}
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_cause")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"integrations/causes"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <NotListedLocationTwoToneIcon sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("drawer_causesTitle")} style={{color: "gray"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <Divider/>
                    <ListSubheader component="div" inset style={{fontWeight: "bold", color: "lightgray"}}>
                        {t("drawer_TransactionsTitle")}
                    </ListSubheader>

                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_demand")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"/demands"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <WavingHandOutlinedIcon sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("appDrawer_DemandsTitle")} style={{color: "#555555"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_warehouse")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"/warehouses"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <WarehouseOutlinedIcon sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("appDrawer_Warehouse")} style={{color: "#555555"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_expedition")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"/expeditions"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LocalShippingIcon sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("drawer_ExpeditionsTitle")} style={{color: "gray"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_waitactivity")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"/reports/wait-activity/list"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <DepartureBoardIcon sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("drawer_WaitingVehiclesTitle")} style={{color: "gray"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>

                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_advert")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <ListItemButton onClick={handleClick}>
                                <ListItemIcon>
                                    <ShoppingCartCheckoutIcon sx={{fontSize: 20}}/>
                                </ListItemIcon>
                                <ListItemText primary={t("drawer_AdvertsTitle")}
                                              sx={{fontWeight: "bold", color: "gray"}}/>
                                {ilanMenu ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse in={ilanMenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <PermissionProvider fetchPermission={
                                        //@ts-ignore
                                        permissionControl(userInfo.data, "_advert")}>
                                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                                            <Link style={{textDecoration: "none"}} to={"/adverts"}>
                                                <ListItemButton sx={{pl: 4}}>
                                                    <ListItemIcon>
                                                        <ShoppingCartIcon sx={{fontSize: 20}}/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t("drawer_MyAdvertsTitle")}
                                                                  style={{color: "#555555"}}/>
                                                </ListItemButton>
                                            </Link>
                                        </PermissionRestricted>
                                    </PermissionProvider>
                                    <PermissionProvider fetchPermission={
                                        //@ts-ignore
                                        permissionControl(userInfo.data, "_offer")}>
                                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                                            <Link style={{textDecoration: "none"}} to={"/adverts/offers"}>
                                                <ListItemButton sx={{pl: 4}}>
                                                    <ListItemIcon>
                                                        <LocalOfferIcon sx={{fontSize: 20}}/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t("drawer_OffersTitle")}
                                                                  style={{color: "#555555"}}/>
                                                </ListItemButton>
                                            </Link>
                                        </PermissionRestricted>
                                    </PermissionProvider>
                                    <PermissionProvider fetchPermission={
                                        //@ts-ignore
                                        permissionControl(userInfo.data, "_advertcategory")}>
                                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                                            <Link to={"/adverts/categories/"} style={{textDecoration: 'none'}}>
                                                <ListItemButton sx={{pl: 4}}>
                                                    <ListItemIcon>
                                                        <BackupTableIcon sx={{fontSize: 20}}/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t("drawer_CategoryAdvertTitle")}
                                                                  style={{color: "#555555"}}/>
                                                </ListItemButton>
                                            </Link>
                                        </PermissionRestricted>
                                    </PermissionProvider>
                                </List>
                            </Collapse>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_product")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"products"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <EventTwoTone sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t('drawer_productsTitle')} style={{color: "gray"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <Divider/>
                    <ListSubheader component="div" inset style={{fontWeight: "bold", color: "lightgray"}}>
                        {t("drawer_SettingsTitle")}
                    </ListSubheader>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_customuser")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"/users"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <GroupTwoToneIcon sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("drawer_UsersTitle")} style={{color: "#555555"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_device")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"/devices/"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <DevicesOtherTwoToneIcon sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("drawer_DevicesTitle")} style={{color: "#555555"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_pushnotification")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.view]}>
                            <Link style={{textDecoration: "none"}} to={"/notifications/"}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <NotificationsActiveTwoToneIcon sx={{fontSize: 20}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("drawer_NotificationTitle")} style={{color: "#555555"}}/>
                                </ListItemButton>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>

                </List>
            </List>
        </Drawer>
    )
}

export default AppLeftDrawerNavigation;

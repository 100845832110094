import axios, { AxiosResponse } from "axios";
import { slug } from "../../../config";

interface ExpeditionService {

    createRating(apiUrl: string, accessToken: string, data: {}, expedition_id: string): Promise<AxiosResponse>;
    fetchRatingCriterias(extra: { accessToken: string, apiUrl: string }): Promise<AxiosResponse>;
}
const ApiRatingService: ExpeditionService = {

    fetchRatingCriterias: async (extra: { accessToken: string, apiUrl: string }): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${extra.accessToken}`,
            'Content-Type': 'application/json'
        };
        try {
            const response = await axios.get(extra.apiUrl + slug.client.ratings.url, { headers })
            return response;
        } catch (error) {
            throw error;
        }
    },

    createRating: async (apiUrl: string, accessToken: string, expedition_id, data): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.post(apiUrl + slug.client.expeditions.url + expedition_id + "/ratings", data, {
                headers
            })
            return response;
        } catch (error) {
            throw error;
        }
    },
}

export default ApiRatingService

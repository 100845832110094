import React from "react"
import {SubmitHandler, useForm} from "react-hook-form";
import {AdvertCategoryInputs} from "../../../../types/types/components/forms/form.type";
import {getStorageData} from "../../../../utils/usePersistedState";
import {advertCategoryCreateService} from "../../../../services/client/adverts/adverts.service";
import {Box, Button, Card, CardContent, Container, Grid, TextField} from "@mui/material";

export const CreateAdvertCategoryForm = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<AdvertCategoryInputs>();

    const onSubmitForm: SubmitHandler<AdvertCategoryInputs> = (data) => {
        getStorageData("authState",false).then(auth=>(
            //@ts-ignore
            advertCategoryCreateService(data,  auth.status.access_token).then(res=>{
                if(res.status == 201){
                    window.location.href="/advert/category"
                }
            })
        ))
    }
    return(
        <Container>
            <Card>
                <CardContent>
                    <Box component="form" onSubmit={handleSubmit(onSubmitForm)}>
                        <Box sx={{minWidth: 120, marginBottom:"1em"}}>
                            <TextField fullWidth id="outlined-basic"
                                       label="Başlık"
                                       variant="outlined"
                                       {...register(`name`)}
                            />
                        </Box>
                        <Box>
                            <TextField
                                fullWidth
                                id="standard-multiline-static"
                                multiline
                                rows={2}
                                variant="standard"
                                //@ts-ignore
                                {...register(`descriptions`)}
                            />
                        </Box>
                        <Grid
                            container
                            md={12}
                        >
                            <Grid md={8}>

                            </Grid>
                            <Grid md={2} >
                                <Button
                                    variant="contained"
                                    type="submit"
                                    onSubmit={handleSubmit(onSubmitForm)}
                                >
                                    İlan Ekle
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    )
}

export default CreateAdvertCategoryForm

import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {AdvertInputs} from "../../../types/types/components/forms/form.type";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {getStorageData} from "../../../utils/usePersistedState";
import {
    advertCategoryFetchService,
    advertDetailService,
    advertItemUpdateService,
    advertUpdateService
} from "../../../services/client/adverts/adverts.service";
import { fetchCurrencyList } from "../../../services/client/integrations/integrations.service";

import {ApiProductUnitService} from "../../../services/client/products/product.units.services";
import {ApiProductsService} from "../../../services/client/products/products.service";

// @ts-ignore
import {ApiIntegrationsTaxesService} from "../../../services/client/integrations/integrations.taxes.service";

import {
    Box, Button,
    Card,
    CardContent,
    Container,
    FormControl, IconButton, InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Tooltip
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AddProductModal} from "../../modal/modal";
import DeleteIcon from "@mui/icons-material/Delete";
import {CategorySelector} from "../controllers/category.controller";
import {TaxSelector} from "../controllers/tax.controller";
import {CurrencySelector} from "../controllers/currency.controller";


//@ts-ignore
export const AdvertEditForm = ({advertCategoryId}) => {

    const [getLoading, setLoading] = useState<boolean>(true);

    const [getAdvert, setAdvert] = useState([]);
    const [getAdvertItems, setAdvertItems] = useState([])

    const [getCategories, setCategories] = useState([]);
    const [categoriesId, setCategoriesId] = useState('');

    const [getCurrency, setCurrency] = useState([]);
    const [getCurrencyId, setCurrencyId] = useState('');
    const [getCurrencyIcon, setCurrencyIcon] = useState('');

    const [getStartDate, setStartDate] = useState<any>()
    const [getEndDate, setEndDate] = useState<any>()

    //Product State
    const [getProducts, setProducts] = useState([]);
    const [getProductId, setProductId] = useState('')

    //Product Unit State
    const [ getProductUnit, setProductUnit ] = useState([]);
    const [ getProductUnitId, setProductUnitId ] = useState([]);
    const [ getProductUnitHandleChange, setProductUnitHandleChange ] = useState([]);
    const [ getTaxHandleChange, setTaxHandleChange ] = useState([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);

    //Tax List
    const [getTax, setTax] = useState([]);
    const [ getTaxId, setTaxId ] = useState('');

    const navigate = useNavigate();
    const handleClick = () => navigate(-1);

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: {
            errors
        }
    } = useForm<AdvertInputs>();

    const handleChange = (event: SelectChangeEvent) => {
        setCategoriesId(event.target.value as string);
    };

    const currencyHandleChange = (event: SelectChangeEvent) => {
        setCurrencyId(event.target.value as string);
    }

    const productUnitHandleChange =  (event: SelectChangeEvent) => {
        //@ts-ignore
        setProductUnitHandleChange(event.target.value as string)
    }

    const taxHandleChange = (event: SelectChangeEvent) => {
        //@ts-ignore
        setTaxId(event.target.value as string)
    }

    const vatCalculation = (event:SelectChangeEvent) => {
        var unit = getProductUnitHandleChange;
        var price = event.target.value;
        //@ts-ignore
        setTotalPrice(( unit * price * 18 / 100 ) + unit * price)
    }
    const onSubmitForm: SubmitHandler<AdvertInputs> = (data, event) => {
        //@ts-ignore
        event.preventDefault();
        data.category = categoriesId
        data.currency = getCurrencyId
        data.start_time = getStartDate
        data.end_time = getEndDate
        data.tax = getTaxId
        //@ts-ignore
        getStorageData("authState").then(auth => {
            //@ts-ignore
            advertUpdateService(auth.status.access_token, data, advertCategoryId).then((e) =>handleClick());
            //@ts-ignore
            advertItemUpdateService(auth.status.access_token, data.advert_items, advertCategoryId).then((e)=> console.log(e))
            //@ts-ignore
            //advertUpdateService(auth.status.access_token, [...data], advertCategoryId).then((e) => {
            //})
        })
    }

    useEffect(()=>{

        getStorageData("authState",false).then(auth=> {
            //@ts-ignore
            advertDetailService(auth.status.access_token, advertCategoryId).then(res => {
                console.log(res.advert_items,"wewefdsf")
                setLoading(false)
                setAdvert(res)
                setAdvertItems(res.advert_items)
                //@ts-ignore
                setCategoriesId(res.category)
                setCurrencyId(res.currency)
                setStartDate(res.start_time)
                setEndDate(res.end_time)
                setTaxId(res.tax)
            });

            //@ts-ignore
            advertCategoryFetchService(auth.status.access_token).then(res => {
                setCategories(res.data.results)
            })

            //@ts-ignore
            fetchCurrencyList(auth.status.access_token).then(res => {
                setCurrency(res.data.results)
            })

            //@ts-ignore
            ApiProductsService.fetchProductsService(auth.status.access_token).then((res:any)=>{
                setProducts(res.data.results)
            })

            //@ts-ignore
            ApiProductUnitService.fetchProductUnit(auth.status.access_token).then((res:any)=>{
                setProductUnit(res.data.results)
            })

            //@ts-ignore
            ApiIntegrationsTaxesService.fetchIntegrationsTaxes(auth.status.access_token).then((res:any)=>{
                setTax(res.data.results)
            })
        })
    },[])

    return(
        getLoading ? <>Düzenleme Sayfası Yükleniyor</> :
            <Container>
                <Card>
                    <CardContent>
                        <Box component="form" onSubmit={handleSubmit(onSubmitForm)}>
                            <Box sx={{minWidth: 120, marginBottom:"1em"}}>
                                <TextField fullWidth id="outlined-basic"
                                           label="Başlık"
                                    //@ts-ignore
                                           defaultValue={getAdvert.title}
                                           variant="outlined"
                                           {...register(`title`)}
                                />
                            </Box>

                            <Box sx={{minWidth: 120, marginBottom:"1em"}}>
                                <CategorySelector
                                    label={"Kategori"}
                                    categoryId={(id)=>console.log(id)}
                                    //@ts-ignore
                                    register={register}
                                    value={categoriesId}
                                    onChange={handleChange}
                                    inputName={('category')}
                                />
                            </Box>

                            <Box sx={{minWidth: 120, marginBottom:"1em"}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Para Birimi</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        //@ts-ignore
                                        value={getCurrencyId}
                                        onChange={currencyHandleChange}
                                    >
                                        {
                                            getCurrency.map((res, index) => (
                                                <MenuItem key={index} value={
                                                    //@ts-ignore
                                                    res.id
                                                }>{
                                                    //@ts-ignore
                                                    res.currency_code
                                                }</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box sx={{minWidth: 120, marginBottom:"1em"}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Toplam Fiyat</InputLabel>
                                    <OutlinedInput
                                        id="outlined-basic"
                                        //@ts-ignore
                                        defaultValue={getAdvert.total_price}
                                        label="Number"
                                        type="number"
                                        startAdornment={<InputAdornment position="start">{getCurrencyIcon}</InputAdornment>}
                                        //@ts-ignore
                                        {...register(`total_price`)}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{minWidth: 120, marginBottom:"1em"}}>
                                <InputLabel id="demo-simple-select-label">Başlangıç Tarihi</InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        //@ts-ignore
                                        defaultValue={new Date(getAdvert.start_time)}
                                        onChange={(value) => setStartDate(value)}/>
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{minWidth: 120, marginBottom:"1em"}}>
                                <InputLabel id="demo-simple-select-label">Bitiş Tarihi</InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        //@ts-ignore
                                        defaultValue={new Date(getAdvert.end_time)}
                                        onChange={(value) => setEndDate(value)}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left"><AddProductModal buttonTitle={"ÜRÜN"}/></TableCell>
                                            <TableCell align="left">ADET</TableCell>
                                            <TableCell align="left"><AddProductModal buttonTitle={"BİRİM"}/></TableCell>
                                            <TableCell align="left">BİRİM FİYAT</TableCell>
                                            <TableCell align="left"><AddProductModal buttonTitle={"PARA BİRİMİ"}/></TableCell>
                                            <TableCell align="left"><AddProductModal buttonTitle={"VERGİ TÜRÜ"}/></TableCell>
                                            <TableCell align="left">TOTAL</TableCell>
                                            <TableCell align="left">AÇIKLAMA</TableCell>
                                            <TableCell align="left">İŞLEM</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            getAdvertItems?.map((field, index)=>{
                                                return(
                                                    <TableRow
                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                    >
                                                        <TableCell sx={{
                                                            display:"none",
                                                        }}>
                                                            <FormControl>
                                                                <TextField
                                                                    id="outlined-disabled"
                                                                    //@ts-ignore
                                                                    defaultValue={field.id}
                                                                    //@ts-ignore
                                                                    value={field.id}
                                                                    {
                                                                        //@ts-ignore
                                                                        ...register(`advert_items.${index}.id`)
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box style={{
                                                                display:"flex",
                                                                flexDirection:"row"
                                                            }}>
                                                                <FormControl sx={{
                                                                    width:"100px"
                                                                }}>
                                                                    <InputLabel id="demo-simple-select-label">Ürünler</InputLabel>
                                                                    <Select defaultValue={
                                                                        //@ts-ignore
                                                                        field.product.id
                                                                    }
                                                                            { //@ts-ignore
                                                                                ...register(`advert_items.${index}.product`)
                                                                            }
                                                                    >
                                                                        {
                                                                            getProducts.map((res, index) =>  (
                                                                                <MenuItem value={
                                                                                    //@ts-ignore
                                                                                    res.id
                                                                                }>
                                                                                    {
                                                                                        //@ts-ignore
                                                                                        res.name
                                                                                    }
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl sx={{
                                                                width:"100px"
                                                            }}>
                                                                <InputLabel id="demo-simple-select-label">Toplam Adet</InputLabel>
                                                                <OutlinedInput
                                                                    id="standard-number"
                                                                    label="Number"
                                                                    //@ts-ignore
                                                                    defaultValue={field.count}
                                                                    type="number"
                                                                    //@ts-ignore
                                                                    {...register(`advert_items.${index}.count`)}
                                                                    //@ts-ignore
                                                                    onChange={productUnitHandleChange}
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl sx={{
                                                                width:"100px"
                                                            }}>
                                                                <InputLabel id="demo-simple-select-label">Birim</InputLabel>
                                                                <Select defaultValue={
                                                                    //@ts-ignore
                                                                    field.unit.id
                                                                }
                                                                        { //@ts-ignore
                                                                            ...register(`advert_items.${index}.unit`)
                                                                        }
                                                                >
                                                                    {
                                                                        getProductUnit.map((res, index) =>  (
                                                                            <MenuItem value={
                                                                                //@ts-ignore
                                                                                res.id
                                                                            }>
                                                                                {
                                                                                    //@ts-ignore
                                                                                    res.name
                                                                                }
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl sx={{
                                                                width:"100px"
                                                            }}>
                                                                <InputLabel id="demo-simple-select-label">Birim Fiyat</InputLabel>
                                                                <OutlinedInput
                                                                    id="standard-number"
                                                                    defaultValue={
                                                                        //@ts-ignore
                                                                        field.unit_price
                                                                    }
                                                                    label="Number"
                                                                    type="number"
                                                                    startAdornment={<InputAdornment position="start">{getCurrencyIcon}</InputAdornment>}
                                                                    //@ts-ignore
                                                                    {...register(`advert_items.${index}.unit_price`)}
                                                                    //@ts-ignore
                                                                    onChange={vatCalculation}
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl sx={{
                                                                width:"100px"
                                                            }}>
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                >Para Birimi</InputLabel>
                                                                <Select
                                                                    defaultValue={
                                                                        //@ts-ignore
                                                                        field.currency.id
                                                                    }
                                                                    //@ts-ignore
                                                                    {...register(`advert_items.${index}.currency`)}
                                                                    //@ts-ignore
                                                                    onChange={currencyHandleChange}
                                                                >
                                                                    {
                                                                        getCurrency.map((item, index) => (
                                                                            <MenuItem key={index} value={
                                                                                //@ts-ignore
                                                                                item.id
                                                                            }>{
                                                                                //@ts-ignore
                                                                                item.currency_code
                                                                            }</MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box style={{
                                                                display:"flex",
                                                                flexDirection:"row"
                                                            }}>
                                                                <TaxSelector
                                                                    label={"Vergi Türü"}
                                                                    index={index}
                                                                    taxId={id => console.log(id)}
                                                                    //@ts-ignore
                                                                    register={register}
                                                                    inputName={'tax'}
                                                                    //@ts-ignore
                                                                    value={1}
                                                                    onChange={taxHandleChange}
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl sx={{
                                                                width:"100px"
                                                            }}>
                                                                <TextField
                                                                    disabled
                                                                    id="outlined-disabled"
                                                                    value={totalPrice}
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                id="standard-multiline-static"
                                                                multiline
                                                                rows={2}
                                                                variant="standard"
                                                                //@ts-ignore
                                                                {...register(`advert_items.${index}.desc`)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{
                                                                display:"flex",
                                                                flexDirection:"row",
                                                                justifyContent:"center",
                                                                alignItems:"center"
                                                            }}>
                                                                <Tooltip title="Delete">
                                                                    <IconButton onClick={
                                                                        //@ts-ignore
                                                                        ()=> {
                                                                            getAdvertItems.pop();
                                                                            setAdvertItems([...getAdvertItems]);
                                                                            console.log(getAdvertItems)
                                                                        }}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{
                                m:1
                            }}>
                                <Button variant="contained" type="submit" onSubmit={handleSubmit(onSubmitForm)}>
                                    İlan Güncelle
                                </Button>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
    )
}

export default AdvertEditForm


export const AUTH = {
    LOGIN : "/"
}

export const VCARD = {
    INDEX:"/vcard/:slug/"
}

export const APP = {
    INDEX:"/",
    VIEW:"/:app",
    MODEL_VIEW:"/:app/:model/",
    ACTION_VIEW:"/:app/:model/:action",
}
export const NOT_FOUND = "*"

import axios, { AxiosResponse } from "axios";

interface IOSRM {
    coordinates: Array<Array<number>>
}

interface IOSRMService {
    fetchRoute(params:IOSRM):Promise<AxiosResponse>
}

const ApiOSRMService: IOSRMService = {
    fetchRoute: async (params: IOSRM): Promise<AxiosResponse> => {
        const config = {
            params:{
                overview:false,
                alternatives:false,
                steps:true
            }
        }
        try{
            const response = axios.get(
                `https://router.project-osrm.org/route/v1/driving/${params.coordinates[0]}${params.coordinates.length > 2 ? ";"+(params.coordinates.slice(1,-1))+";" : ";"}${params.coordinates[params.coordinates.length-1]}`, config)
            return response
        }
        catch (err){
            throw err
        }
    }
}

export default ApiOSRMService
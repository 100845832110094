import axios, {AxiosResponse} from "axios";
import {slug} from "../../../../config";

import { IDemandsItemsService } from "../../../../types/interfaces/services/client.services.interfaces";


const DemandsItemsService : IDemandsItemsService = {
    updateMultiple: async (params: { accessToken: string; apiUrl: string; data: object, id:string }): Promise<AxiosResponse> => {

        const config = {
            headers:{
                Authorization: `Bearer ${params.accessToken}`,
            }
        }
        try {
            //@ts-ignore
            const response = await axios.patch(params.apiUrl + slug.client.demands.url + params.id + "/items/multiple", params.data, config)
            return response
        }
        catch (e){
            throw e
        }
    }
}

export default DemandsItemsService

import * as React from "react";
const CompanyEditSvgComponent = () => (
    <svg
        viewBox="233.0189 149.9628 15.5289 19.0151"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g transform="matrix(1, 0, 0, 1, 5.648237228393555, 0)">
            <g
                id="add-company-16px"
                transform="matrix(1, 0, 0, 1, 227.370636, 149.962891)"
            >
                <path
                    id="add-company-16px-2"
                    data-name="add-company-16px"
                    d="M 6.7 16 L 0 16 L 0 1.5 C 0.014 0.66 0.705 -0.011 1.545 0 L 8.755 0 C 9.595 -0.011 10.286 0.66 10.3 1.5 L 10.3 7.5 C 10.3 7.896 9.871 8.144 9.527 7.946 C 9.368 7.854 9.27 7.684 9.27 7.5 L 9.27 1.5 C 9.266 1.22 9.035 0.996 8.755 1 L 1.545 1 C 1.265 0.996 1.034 1.22 1.029 1.5 L 1.029 15 L 3.09 15 L 3.09 11.5 C 3.095 11.22 3.326 10.996 3.606 11 L 6.7 11 C 6.98 10.996 7.211 11.22 7.215 11.5 L 7.215 15 L 8.755 15 C 9.14 15 9.38 15.417 9.188 15.75 C 9.099 15.905 8.934 16 8.755 16 L 6.7 16 Z M 4.12 15 L 6.18 15 L 6.18 12 L 4.12 12 L 4.12 15 Z M 6.18 9 L 6.18 7 L 8.24 7 L 8.24 9 L 6.18 9 Z M 2.06 9 L 2.06 7 L 4.12 7 L 4.12 9 L 2.06 9 Z M 6.18 5 L 6.18 3 L 8.24 3 L 8.24 5 L 6.18 5 Z M 2.06 5 L 2.06 3 L 4.12 3 L 4.12 5 L 2.06 5 Z"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit={10}
                    strokeWidth={1}
                    style={{
                        fill: "rgb(255, 255, 255)",
                    }}
                />
            </g>
            <g
                transform="matrix(0.760015, -0.086628, 0.095285, 0.835968, 245.243027, 137.012787)"
                style={{
                    transformOrigin: "-7.375px 26.515625px",
                }}
            >
                <g transform="matrix(1, 0, 0, 1, -27.387873, 6.520922)">
                    <path
                        d="M25.576,15.934l-1.517-1.52c-0.418-0.421-1.094-0.424-1.507-0.009l-1.22,1.228l3.03,3.043    l1.221-1.229C25.998,17.032,25.995,16.354,25.576,15.934z"
                        style={{
                            fill: "rgb(255, 255, 255)",
                        }}
                    />
                    <path
                        d="M15.357,21.502c-0.067,0.055-0.124,0.123-0.15,0.213L14.15,25.33    c-0.047,0.157-0.003,0.327,0.112,0.443c0.086,0.085,0.2,0.131,0.317,0.131c0.042,0,0.085-0.006,0.126-0.019l3.602-1.062    c0.084-0.024,0.149-0.076,0.204-0.138l4.939-4.915l-3.163-3.175L15.357,21.502z M15.836,24.618l-0.422-0.425l0.599-2.047    l1.532,0.316l0.303,1.562L15.836,24.618z"
                        style={{
                            fill: "rgb(255, 255, 255)",
                        }}
                    />
                </g>
            </g>
        </g>
    </svg>
);
export default CompanyEditSvgComponent;

import React, {useEffect, useState} from "react"
import {FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip, Box} from "@mui/material";
import {FieldValues, UseFormRegister} from "react-hook-form";
import {fetchCurrencyList} from "../../../services/client/integrations/integrations.service";
import {getStorageData} from "../../../utils/usePersistedState";
import {SelectChangeEvent} from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";

/**
 * Interface Currency Select
 */
interface ICurrencySelect {
    label: string,
    currencyId: (id: string) => void,
    register: UseFormRegister<FieldValues>,
    inputName: string,
}

/**
 * Currency Selector
 * @param label
 * @param currencyId
 * @param register
 * @param inputName
 * @constructor
 */
export const CurrencySelector = ({label, currencyId, register, inputName}: ICurrencySelect) => {

    const [getCurrency, setCurrency] = useState([]);

    useEffect(() => {
        //@ts-ignore
        getStorageData("authState").then(auth => {
            //@ts-ignore
            fetchCurrencyList(auth.status.access_token).then(res => {
                setCurrency(res.data.results)
            })
        })
    }, [])

    const currencyHandleChange = (event: SelectChangeEvent) => {
        currencyId(event.target.value as string)
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row"
        }}>
            <FormControl sx={{
                width: "100%"
            }}>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    //@ts-ignore
                    ref={register}
                    //@ts-ignore
                    {...register(inputName, {required: true})}
                >
                    {
                        getCurrency.map((res, index) => (
                            <MenuItem
                                key={index}
                                value={
                                    //@ts-ignore
                                    res.id
                                }
                            >
                                {
                                    //@ts-ignore
                                    res.currency_code
                                }
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <Tooltip title="Para Birimi Ekle">
                <IconButton>
                    <Typography sx={{
                        fontSize: "10px"
                    }}></Typography>
                    <EditIcon sx={{
                        fontSize: "13px",
                        margin: "1em"
                    }}/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default {
    count: 0,
    next: "",
    previous: "",
    results: {
        id:0,
        abroad_custom_entry_date: "",
        customer: "",
        customer_representative: "",
        domestic_custom_entry_date: "",
        driver: "",
        duration_by_abroad_start_load_time: "",
        duration_by_end_tr_time: "",
        duration_by_end_vehicle_time: "",
        duration_by_load_end_time: "",
        duration_by_start_end_time: 0,
        duration_by_start_load_time: 0,
        duration_by_tr_abroad_time: 0,
        duration_by_vehicle_start_tr_time: 0,
        load_city: "",
        load_country: "",
        load_date: "",
        position_type: "",
        start_date: "",
        target_city: "",
        target_country: "",
        trailer: "",
    }
}

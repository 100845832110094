import axios, { AxiosResponse } from "axios";
import { slug } from "../../../config";

interface ContentService {
    deleteDocument(apiUrl: string, accessToken: string, contentId: string): Promise<AxiosResponse>;

}

export const ContentService: ContentService = {
    deleteDocument: async (apiUrl: string, accessToken: string, contentId: string): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            const response = await axios.delete(apiUrl + slug.client.contents.url + contentId, {
                headers
            })
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default ContentService

import axios, { AxiosResponse } from "axios";
import { slug } from "../../../../config"


interface IQuickDataService {
    fetchCompanyExpeditions(
        extra: {
            apiUrl: string,
            accessToken: string,
        }): Promise<AxiosResponse>;

    fetchCorporateExpeditions(
        extra: {
            apiUrl: string,
            accessToken: string
        }
    ): Promise<AxiosResponse>;

    fetchCompanyBalance(
        extra: {
            apiUrl: string,
            accessToken: string
        }
    ): Promise<AxiosResponse>;

    fetchCorporateDistance(
        extra: {
            apiUrl: string,
            accessToken: string,
            trImLoadDateGte?: string,
            trImLoadDateLte?: string,
        }
    ): Promise<AxiosResponse>
}

const ApiQuickDataExpeditionsService: IQuickDataService = {
    fetchCompanyExpeditions: async (extra: {
        apiUrl: string,
        accessToken: string,

    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${extra.accessToken}`
            }
        };
        try {
            const response = await axios.get(extra.apiUrl + slug.client.report.quickdata.companyExpeditions.url, config)
            return response;
        } catch (error) {
            throw error;
        }
    },

    fetchCorporateExpeditions: async (extra: { apiUrl: string; accessToken: string }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${extra.accessToken}`,
            }
        }
        try {
            const response = await axios.get(extra.apiUrl + slug.client.report.quickdata.corporateExpeditions.url, config)
            return response
        }
        catch (error) {
            throw error;
        }
    },
    fetchCompanyBalance: async (extra: { apiUrl: string; accessToken: string }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${extra.accessToken}`,
            }
        }
        try {
            const response = await axios.get(extra.apiUrl + slug.client.report.quickdata.companyBalance.url, config)
            return response
        }
        catch (error) {
            throw error;
        }
    },

    fetchCorporateDistance: async (extra: {
        apiUrl: string; accessToken: string, trImLoadDateGte?: string;
        trImLoadDateLte?: string;
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${extra.accessToken}`,
            },
            params: {
                ...(extra.trImLoadDateGte ? { tr_im_load_date__gte: extra.trImLoadDateGte } : {}),
                ...(extra.trImLoadDateLte ? { tr_im_load_date__lte: extra.trImLoadDateLte } : {}),
            }
        }
        try {
            const response = await axios.get(extra.apiUrl + slug.client.report.quickdata.distance.url, config)
            return response
        }
        catch (error) {
            throw error
        }
    }
}

export default ApiQuickDataExpeditionsService

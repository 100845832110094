import React from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {Card, CardContent, CardHeader, IconButton, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {getStorageData} from "../../utils/usePersistedState";
import {advertDeleteService} from "../../services/client/adverts/adverts.service";
import {useNavigate} from "react-router-dom";

const style = {
    alignItems:"center",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25%",
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

interface IAdDelNoticeModal {
    advertId:string
}

const AdDelNoticeModal = ({advertId}:IAdDelNoticeModal) => {
    const navigate = useNavigate();

    const [getModalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const _deleteAdvert = (advertId:string) => {
        getStorageData("authState",false).then(auth => (
            //@ts-ignore
            advertDeleteService(auth.status.access_token,advertId).then((res:any)=>{
                if (res.status == 200) {
                    navigate('/adverts');
                }
            }).catch((e:any)=>{
                console.log(e)
            })
        ))
    }

    return(
        <>
            <Tooltip title="Sil">
                <IconButton onClick={handleModalOpen}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Modal
                open={getModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Card>
                        <CardHeader sx={{
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:"center",
                            alignItems:"center",
                            textAlign:"center"
                        }}
                                    subheader={advertId+" ID'li Ürünü Silmek İstediğinize Emin Misiniz?"}
                        />
                        <CardContent sx={{
                            flexDirection:"column",
                            justifyContent:"center",
                            alignItems:"center",
                            textAlign:"center"
                        }}>
                            <Button
                                variant="outlined" sx={{
                                    margin:1
                            }}
                                onClick={()=>_deleteAdvert(advertId)}
                            >Sil</Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    margin:1
                                }}
                                onClick={handleModalClose}
                            >Vazgec</Button>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </>
    )
}

export default AdDelNoticeModal


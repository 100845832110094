import React, {
    useEffect,
    useMemo,
    useState
} from "react"

import DemandsService from "../../services/client/demands/demands.service";

import {
    getStorageData
} from "../../utils/usePersistedState";

import {
    Box,
    Chip,
    Stack,
    useTheme
} from "@mui/material";

import {
    DataGridPremium,
    useKeepGroupedColumnsHidden,
    useGridApiRef,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridRowModesModel,
} from "@mui/x-data-grid-premium";

import Button from "@mui/material/Button";

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';

import DemandApprovalModal from "../../components/modal/demands/demand.approval.modal";
import DemandCreateForm from "../../components/form/demands/demand.create.form";

import {EDemandAction} from "../../types/enums/pages/demands/demands.page.enum";
import {useAppSelector} from "../../store";
import PermissionProvider from "../../utils/permissionProvider/permissionProvider";
import {permissionControl} from "../../utils/permissionControl";
import PermissionRestricted from "../../utils/permissionProvider/permissionRestricted";
import PermissionsTypes from "../../types/models/components/permissions.default.model";
import Notfound from "../notfound";
import IntegrationsTaxesPage from "../integrations/taxes/integrations.taxes.page";
import {GridRowModel} from "@mui/x-data-grid";
import DemandItemApprovalModal from "../../components/modal/demands/demand.approval.modal";

const DemandsPage = () => {
    const theme = useTheme();

    /**
     * User Info
     */
    const userInfo = useAppSelector((state) => state.user);


    const [demands, setDemands] = useState([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const [demandApprovalStatus, setDemandApprovalStatus] = useState<boolean>(false)
    const [demandAction, setDemandAction] = useState<EDemandAction>(EDemandAction.Show)

    const [quantityCell, setQuantityCell] = useState<boolean>(false)


    const [demand, setDemand] = useState<object>([])

    const handleDemandsApproval = (params:{row:any}) => {
        setDemand(params.row)
        setDemandApprovalStatus(true)
    }

    useEffect(() => {
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                DemandsService.fetchDemands({apiUrl: api.url, accessToken: auth.status.access_token})
                    .then((res) => {
                        setDemands(res.data.results);
                        //@ts-ignore
                        console.log(demands)
                        setDataLoading(false);
                    });
            });
        });
    }, [demandAction]);

    //@ts-ignore
    const generateRowsFromDemand = (demand) => {
        //@ts-ignore
        const rows = [];

        if (demand.demand_items && demand.demand_items.length > 0) {
            //@ts-ignore
            demand.demand_items.forEach((item, index) => {
                rows.push({
                    id: `${demand.demand_no}_${index}`,
                    demander: demand.demander.first_name+" "+demand.demander.last_name,
                    status: demand.status,
                    created_date: demand.created_date,
                    demand_no: demand.demand_no,
                    product_name: item.product.name,
                    quantity: item.requested_quantity,
                    operations: demand,
                    isActive: demand.is_active,
                });
            });
        }
        //@ts-ignore
        return rows;
    };

    // const rows = useMemo(() => {
    //     //@ts-ignore
    //     let generatedRows = [];
    //
    //     demands.forEach((demand) => {
    //         const demandRows = generateRowsFromDemand(demand);
    //         //@ts-ignore
    //         generatedRows = [...generatedRows, ...demandRows];
    //     });
    //     //@ts-ignore
    //     return generatedRows;
    // }, [demands]);

    const columns = [
        {
            field: 'demand_no',
            headerName: 'Talep No',
            width: 150
        },
        {
            field: 'created_date',
            headerName: 'Olusturma Tarihi',
            width: 150,
        },
        // {field: 'product_name', headerName: 'Ürün İsmi', width: 150},
        // {
        //     field: 'quantity',
        //     headerName: 'Miktar',
        //     width: 150,
        //     valueFormatter: (params: any) => params.value ? params.value + " Adet " : "",
        // },
        // {
        //     field: 'demander',
        //     headerName: 'Talep Eden',
        //     width: 200,
        // },
        {
            field: 'status',
            headerName: 'Durum',
            width: 150,
            renderCell: (params: any) => {
                return (
                    Object.keys(params.row).length > 0 ?
                        params.value == "new" ? <Chip icon={<InfoRoundedIcon/>} label={"Yeni"} variant="outlined" color="info"/> :
                            params.value == "processing" ? <Chip icon={<InfoRoundedIcon/>} label={"İşlemde"} variant="outlined" color="warning"/> :
                                params.value == "standby" ? <Chip icon={<InfoRoundedIcon/>} label={"Beklemede"} variant="outlined" color="primary"/> :
                                    params.value == "ready" ? <Chip icon={<InfoRoundedIcon/>} label={"Hazır"} variant="outlined" color="success"/> :
                                        params.value == "partial-delivered" ? <Chip icon={<InfoRoundedIcon/>} label={"Kısmı Teslimat"} variant="outlined" color="error"/> :
                                            params.value == "delivered" ? <Chip icon={<InfoRoundedIcon/>} label={"Teslim Edildi"} variant="outlined" color="success"/> : "" : ""
                )
            }
        },
        {
            field: 'operations',
            headerName: 'İslemler',
            disableExport: true,
            width: 300,
            renderCell: (params: any) =>  {
                return(
                    params.row.status == "delivered" || params.row.status == "partial-delivered" ?
                        <></> : Object.keys(params.row).length > 0 ?
                            <Stack direction="row" spacing={2}>
                                <PermissionProvider fetchPermission={
                                    //@ts-ignore
                                    permissionControl(userInfo.data, "_demand")}>
                                    <PermissionRestricted to={[PermissionsTypes.permission.all]}>
                                        {
                                            <Button
                                                variant="outlined"
                                                startIcon={<ThumbUpAltRoundedIcon/>}
                                                size="small"
                                                onClick={() => handleDemandsApproval({row:params.row})}
                                            >
                                                Talep Onayla
                                            </Button>
                                        }
                                    </PermissionRestricted>
                                </PermissionProvider>
                                {
                                    params.row.isActive ?
                                        <Button size="small" variant="contained" endIcon={<ThumbDownRoundedIcon/>}
                                                onClick={() => handleCellClick(params)}>
                                            Talep İptal
                                        </Button> :
                                        <Button size="small" variant="contained" endIcon={<ThumbDownRoundedIcon/>}
                                                onClick={() => handleCellClick(params)}>
                                            Talep İptal
                                        </Button>
                                }
                            </Stack>
                            : <></>
                )
            }
        }
    ];

    const apiRef = useGridApiRef();

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            rowGrouping: {
                //model: ["demand_no"]
            },
            columns: {
                columnVisibilityModel: {
                    // quantity: quantityCell
                },
            },
        },
    });

    const handleCellClick = (params: any) => {
        console.log("Tıklanan Hücre Verileri:", params);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <GridToolbarExport/>
                <PermissionProvider fetchPermission={
                    //@ts-ignore
                    permissionControl(userInfo.data, "_demand")}>
                    <PermissionRestricted to={[PermissionsTypes.permission.add]}>
                        {
                            <Button
                                size="small"
                                variant="contained"
                                endIcon={<PanToolRoundedIcon/>}
                                onClick={() => setDemandAction(EDemandAction.Create)}
                            >
                                Talep Oluştur
                            </Button>
                        }
                    </PermissionRestricted>
                </PermissionProvider>
            </GridToolbarContainer>
        );
    }

    // const processRowUpdate = (newRow: GridRowModel) => {
    //     const updatedRow = {...newRow, isNew: false};
    //     //@ts-ignore
    //     setRows(getRows.map((row: any) => (row.id === newRow.id ? updatedRow : row)))
    //     return updatedRow;
    // }

    switch (demandAction) {
        case EDemandAction.Show: {
            return (
                <Box sx={{height: "calc(100vh - 145px)", width: '100%'}}>
                    <DataGridPremium
                        apiRef={apiRef}
                        initialState={initialState}
                        columns={columns}
                        rows={demands}
                        sx={{
                            backgroundColor: `${theme.palette.background.default}`,
                            '& .MuiDataGrid-virtualScroller': {
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {height: 5, WebkitAppearance: 'none', width: 5},
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: 8,
                                    border: '1px solid',
                                    borderColor: 'white',
                                    backgroundColor: 'rgba(0 0 0 / 0.5)',
                                },
                            },
                        }}
                        rowGroupingColumnMode="single"
                        loading={dataLoading}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                    />
                    <DemandItemApprovalModal
                        open={demandApprovalStatus}
                        close={status => setDemandApprovalStatus(status)}
                        demand={demand}
                    />
                </Box>
            )
        }
        case EDemandAction.Create: {
            return (
                <DemandCreateForm demand={action => setDemandAction(action)}/>
            )
        }
        default: {
            return (
                <></>
            )
        }
    }
};

export default DemandsPage;


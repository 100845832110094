//React Library
import React, {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import {
    Box,
    Card, CardActions,
    CardContent, CardHeader, FormControl,
    IconButton, InputLabel, OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@mui/material";
import TitleFormController from "../controllers/input.form.controller";
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {DemandFormType} from "../../../types/types/components/forms/demand.form.type";
import {AddBox} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {EDemandAction} from "../../../types/enums/pages/demands/demands.page.enum";
import {getStorageData} from "../../../utils/usePersistedState";
import DemandsService from "../../../services/client/demands/demands.service";
import TextField from "@mui/material/TextField";
import ProductSelector from "../controllers/product.controller";
import {permissionControl} from "../../../utils/permissionControl";
import PermissionRestricted from "../../../utils/permissionProvider/permissionRestricted";
import PermissionsTypes from "../../../types/models/components/permissions.default.model";
import Notfound from "../../../pages/notfound";
import IntegrationsTaxesPage from "../../../pages/integrations/taxes/integrations.taxes.page";
import PermissionProvider from "../../../utils/permissionProvider/permissionProvider";
import DemandsProductsFormController from "../controllers/demands/demands.products.form.controller";
import DemandsUnitsFormController from "../controllers/demands/demonds.units.form.controller";
import DemandCreateModal from "../../modal/demands/demand.create.modal";

interface IDemandCreateForm {
    demand: (action: EDemandAction) => void
}

//Demand Create Form
const DemandCreateForm = (props: IDemandCreateForm) => {

    const [demandCreationSummary, setDemandCreationSummary] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: {
            errors
        }
    } = useForm<DemandFormType>()

    const {
        fields,
        append,
        prepend
    } = useFieldArray({
        control,
        //@ts-ignore
        name: "items"
    })

    const onSubmitForm: SubmitHandler<DemandFormType> = (data, event) => {
        event?.preventDefault();
        //@ts-ignore
        data.demand_items = data.demand_items

        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                DemandsService.createDemands({apiUrl: api.url, accessToken: auth.status.access_token, data: data}).then(res => {
                    if (res.status == 201) {
                        props.demand(EDemandAction.Show)
                    }
                })
            })
        })
    }

    useEffect(() => {
        console.log(register)
        console.log(control)
        console.log(watch)
    }, [fields]);

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Card component="form">
                <CardHeader
                    title="Talep Oluştur"
                    subheader="Talep oluşturma formuyla, idari işler departmanına taleplerinizi iletebilirsiniz.
                    Talebiniz hakkında detayları eksiksiz bir şekilde doldurduğunuzdan emin olun."
                />
                <CardContent>
                    <Box sx={{
                        minWidth: 120,
                        marginBottom: "1em"
                    }}>
                        <TitleFormController
                            label={"Bu talebi, ofis malzemelerinin eksildiğini fark ettiğim için oluşturuyorum.\n"}
                            registerName={"descriptions"}
                            //@ts-ignore
                            register={register}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        Ürün
                                    </TableCell>
                                    <TableCell align="left">
                                        Birim
                                    </TableCell>
                                    <TableCell align="left">
                                        Miktar
                                    </TableCell>
                                    <TableCell align="left">
                                        Açıklama
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    fields.map((fields, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell align="left" sx={{
                                                    minWidth: 120,
                                                }}>

                                                    <DemandsProductsFormController
                                                        label={"Ürünler"}
                                                        index={index}
                                                        productId={(id) => console.log(id)}
                                                        //@ts-ignore
                                                        register={register}
                                                    />

                                                </TableCell>
                                                <TableCell align="left" sx={{
                                                    minWidth: 120,
                                                }}>
                                                    <DemandsUnitsFormController
                                                        label={"Birimler"}
                                                        index={index}
                                                        productId={(id) => console.log(id)}
                                                        //@ts-ignore
                                                        register={register}/>
                                                </TableCell>
                                                <TableCell align="left" sx={{
                                                    minWidth: 120,
                                                }}>
                                                    <FormControl sx={{width: "100%"}}>
                                                        <InputLabel id="demo-simple-select-label">Adet</InputLabel>
                                                        <Tooltip title="Lütfen unutmayın ki, depomuzda belirli bir stok
                                                        miktarı bulunmaktadır. Bu nedenle, depodaki miktarın üstünde bir
                                                        miktar giremezsiniz. Lütfen gerçekçi ve ihtiyacınıza uygun bir
                                                        miktar girerek talebinizi oluşturun.">
                                                            <OutlinedInput
                                                                notched={true}
                                                                id="standard-number"
                                                                label="Number"
                                                                type="number"
                                                                //@ts-ignore
                                                                {...register(`demand_items.${index}.requested_quantity`)}
                                                            />
                                                        </Tooltip>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell align="left" sx={{
                                                    minWidth: 120,
                                                }}>
                                                    <TextField
                                                        sx={{
                                                            width: "100%"
                                                        }}
                                                        id="standard-multiline-static"
                                                        multiline
                                                        rows={2}
                                                        variant="standard"
                                                        placeholder="Bu ürünü, toplantıda sunum yapabilmem için ihtiyaç duyuyorum."
                                                        //@ts-ignore
                                                        {...register(`advert_items.${index}.desc`)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            <IconButton
                                color="primary"
                                onClick={() => append({})}
                            >
                                <AddBox/>
                                <Typography>
                                    Yeni İhtiyaç Ekle
                                </Typography>
                            </IconButton>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={()=>setDemandCreationSummary(true)}
                    >
                        Talep Oluştur
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => props.demand(EDemandAction.Show)}
                    >
                        Talep Vazgec
                    </Button>
                </CardActions>
                <DemandCreateModal
                    //@ts-ignore
                    data={"test"}
                    open={demandCreationSummary}
                    close={(status)=>setDemandCreationSummary(status)}
                    approval={handleSubmit(onSubmitForm)}
                />
            </Card>
        </Container>
    )
}

export default DemandCreateForm

import React, { useEffect, useState } from "react"
import { Avatar, Box, Card, CardContent, CardHeader, Grid, Stack, Tooltip, Typography, Paper } from "@mui/material"
import { DataGridPremium, GridColDef, GridToolbar, trTR, useGridApiRef } from "@mui/x-data-grid-premium";
import { styled, useTheme } from '@mui/material/styles';

import DateRangePickerComponent from "../../datepicker/datePickers";
import { getStorageData } from "../../../utils/usePersistedState";
import ApiExpeditionsService from "../../../services/client/expeditions/expeditions.service";
import ExpeditionTypeSelector from "../../selectors/expedition/expeditionType.selector";
import ExpeditionStatusSelector from "../../selectors/expedition/expeditionStatus.selector";
import { ComponentView } from "../../../types/enums/components/component.enum";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ExpeditionTimesModel } from "../../../types/models/services/expeditions/expeditions.model";
import expeditionTimesInitialState from "../../../types/models/services/expeditions/expeditions.default.model"
import { dateFormatting } from "../../../utils/dateProvider/dateFormatting";
import dayjs, { Dayjs } from 'dayjs';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

interface IPositionStatus {
    id: number,
    name: string,
    status: string
}

const columns: GridColDef[] = [
    {
        field: 'position_type',
        headerName: 'Sefer Türü',
        width: 100,
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'driver',
        width: 150,
        headerName: 'Sürücü',
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'trailer',
        width: 150,
        headerName: 'Römork Plaka',
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'load_country',
        width: 150,
        headerName: 'Yükleme Ülkesi',
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'load_city',
        width: 150,
        headerName: 'Yükleme Sehri',
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'target_country',
        width: 150,
        headerName: 'Bitis Ülkesi',
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'target_city',
        width: 150,
        headerName: 'Bitis Sehri',
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'start_date',
        width: 150,
        headerName: 'Başlangıç Tarihi',
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? dateFormatting(params.formattedValue) : ""}
            </Box>
        ),
    },
    {
        field: 'load_date',
        headerName: 'Yükleme Tarih',
        width: 150,
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? dateFormatting(params.formattedValue) : ""}
            </Box>
        )
    },
    {
        field: 'duration_by_load_start_time',
        headerName: 'Yüke Verilme Süresi',
        width: 150,
        type: 'number',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
            <>
                <Tooltip title={"Araç ihracat yükünü boşaltıktan sonra, dönüş yükünü yükleyene kadar geçen süre"}>
                    <Avatar sx={{
                        backgroundColor: "transparent",
                        width: "20px",
                        height: "20px"
                    }}>
                        <InfoOutlinedIcon fontSize={"small"} sx={{
                            color: "black"
                        }} />
                    </Avatar>
                </Tooltip>
                <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                    Yüke Verilme Süresi
                </Typography>
            </>
        ),
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"  // Center the cell value
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'duration_by_entry_exit_load_time',
        width: 150,
        type: 'number',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
            <>
                <Tooltip title={"Araç yüklendikten sonra çıkış limanına kadar geçen süre. İhracat için: “Tahmini giriş çıkış tarihi (TR)” alanı,Transit ve İthalat için: “Tahmini giriş çıkış tarihi (YD)“ alanı - Yükleme tarihi"}>
                    <Avatar sx={{
                        backgroundColor: "transparent",
                        width: "20px",
                        height: "20px"
                    }}>
                        <InfoOutlinedIcon fontSize={"small"} sx={{
                            color: "black"
                        }} />
                    </Avatar>
                </Tooltip>
                <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                    Çıkış Limanına Varış Süresi
                </Typography>

            </>
        ),
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'duration_by_entry_exit_time',
        headerName: 'Çıkış->Varış Liman',
        width: 150,
        type: 'number',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
            <>
                <Tooltip title={"Çıkış Limanından, Varış Limanına kadar geçen süre (liman beklemeleri dahil).İhracat için: Tahmini giriş çıkış tarihi(YD) - Tahmini giriş çıkış tarihi(TR) İthalat ve Transit için:  Tahmini giriş çıkış tarihi(TR) - Tahmini giriş çıkış tarihi(YD)"}>
                    <Avatar sx={{
                        backgroundColor: "transparent",
                        width: "20px",
                        height: "20px"
                    }}>
                        <InfoOutlinedIcon fontSize={"small"} sx={{
                            color: "black"
                        }} />
                    </Avatar>
                </Tooltip>
                <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                    Çıkış-Varış Liman
                </Typography>

            </>
        ),
        renderCell: (params: any) => (
            params.formattedValue ? params.formattedValue : ""
        ),
    },
    {
        field: 'duration_by_vehicle_entry_exit_time',
        type: 'number',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        headerName: 'TR Giriş-Kayseri',
        renderHeader: () => (
            <>
                <Tooltip
                    title={"Varış limanından kayseri çıkışa kadar olan süre (varış limanı bekleme süresi dahil).İhracat için: Araç çıkış tarihi - Tahmini giriş çıkış tarihi(TR),İthalat ve Transit için: Araç çıkış tarihi - Tahmini giriş çıkış tarihi (YD)"}>
                    <Avatar sx={{
                        backgroundColor: "transparent",
                        width: "20px",
                        height: "20px"
                    }}>
                        <InfoOutlinedIcon fontSize={"small"} sx={{
                            color: "black"
                        }} />
                    </Avatar>
                </Tooltip>
                <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                    TR Giriş-Kayseri
                </Typography>

            </>
        ),
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'duration_by_end_entry_exit_time',
        type: 'number',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
            <>
                <Tooltip title={"Araç Türkiye’ye geldikten sonra teslimata kadar geçen süre.İhracat için: Bitiş tarihi - Tahmini giriş çıkış tarihi (TR),İthalat ve Transit için: Bitiş tarihi - Tahmini giriş çıkış tarihi (YD)"}>
                    <Avatar sx={{
                        backgroundColor: "transparent",
                        width: "20px",
                        height: "20px"
                    }}>
                        <InfoOutlinedIcon fontSize={"small"} sx={{
                            color: "black"
                        }} />
                    </Avatar>
                </Tooltip>
                <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                    TR Giriş-Bitiş Tarihi
                </Typography>
            </>
        ),

        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'duration_by_end_vehicle_time',
        type: 'number',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
            <>
                <Tooltip title={"İhracat gümrüğü bitişinden itibaren boşaltmaya kadar geçen süre."}>
                    <Avatar sx={{
                        backgroundColor: "transparent",
                        width: "20px",
                        height: "20px"
                    }}>
                        <InfoOutlinedIcon fontSize={"small"} sx={{
                            color: "black"
                        }} />
                    </Avatar>
                </Tooltip>
                <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                    A. Çıkış/Teslimat Süresi
                </Typography>

            </>
        ),
        renderCell: (params: any) => (
            params.formattedValue ? params.formattedValue : ""
        ),
    },
    {
        field: 'duration_by_load_end_time',
        type: 'number',
        width: 150,
        headerName: 'Transit Süresi',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
            <>
                <Tooltip title={"Bitiş tarihi - Yükleme tarihi"}>
                    <Avatar sx={{
                        backgroundColor: "transparent",
                        width: "20px",
                        height: "20px"
                    }}>
                        <InfoOutlinedIcon fontSize={"small"} sx={{
                            color: "black"
                        }} />
                    </Avatar>
                </Tooltip>
                <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                    Transit Süre
                </Typography>
            </>
        ),

        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },

    {
        field: 'customer',
        width: 200,
        headerName: 'Müsteri',
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
    {
        field: 'customer_representative',
        width: 200,
        headerName: 'Müsteri Temsilcisi',
        renderCell: (params: any) => (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
            }}>
                {params.formattedValue ? params.formattedValue : ""}
            </Box>
        ),
    },
];



const ExpeditionsDurationsGrid = () => {
    const theme = useTheme();
    const [firstTime, setFirstTime] = useState("")
    const [lastTime, setLastTime] = useState("")
    const [positionType, setPositionType] = useState<null | number>(null)
    const [positionStatus, setPositionStatus] = useState<IPositionStatus | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [expeditionsDurations, setExpeditionsDurations] = useState<ExpeditionTimesModel>(expeditionTimesInitialState)

    const handleClickDateRangePicker = (firstTime: string, lastTime: string) => {
        setFirstTime(firstTime)
        setLastTime(lastTime)
    }

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });

    const [defaultValueDateRange, setDefaultValueDateRange] = useState([dayjs().subtract(7, 'day'), dayjs()])

    useEffect(() => {
        setLoading(true)
        setExpeditionsDurations(expeditionTimesInitialState)
        getStorageData("authState", true).then(auth => {
            getStorageData("api", false).then(api => {
                //@ts-ignore
                ApiExpeditionsService.fetchExpeditionsDurations({
                    //@ts-ignore
                    apiUrl: api.url,
                    //@ts-ignore
                    accessToken: auth.status.access_token,
                    //@ts-ignore
                    endDateGte: firstTime.slice(0, 10),
                    endDateLte: lastTime.slice(0, 10),
                    //@ts-ignore
                    positionType: positionType,
                    isCompleted: positionStatus?.status,
                    offset: (paginationModel.page * paginationModel.pageSize),
                    limit: paginationModel.pageSize
                })
                    .then((res: any) => {
                        const resultsWithUniqueIds = res.data.results.map((item: any, index: number) => ({
                            ...item,
                            id: index.toString(),
                        }));
                        //@ts-ignore
                        setExpeditionsDurations({
                            count: res.data.count,
                            results: resultsWithUniqueIds,
                        });
                        setLoading(false)
                        console.log(resultsWithUniqueIds)
                    })
            })
        })

    }, [lastTime, positionType, positionStatus, paginationModel]);

    const apiRef = useGridApiRef();

    return (
        <Grid md={12}>
            <Card sx={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                boxShadow: "none"
            }}>
                <CardHeader sx={{
                    padding: "16px 16px 0 16px"
                }} title={
                    <Grid container md={12}>
                        <Grid md={2} sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "1rem"
                        }}>
                            <Typography sx={{
                                fontSize: "18px",
                                fontWeight: 600
                            }}>
                                Transit Süre Raporu
                            </Typography>
                        </Grid>
                        <Grid md={10} sx={{
                            display: "flex",
                            flexDirection: "row-reverse"
                        }}>
                            <Stack direction="row" spacing={1} sx={{
                                '& .MuiFormLabel-root': {
                                    fontSize: "12px",
                                    fontWeight: 600
                                },
                            }}>
                                <Box>
                                    <ExpeditionTypeSelector
                                        fallback={type => setPositionType(type)}
                                        componentView={ComponentView.SELECT}
                                    />
                                </Box>
                                <Box>
                                    <ExpeditionStatusSelector
                                        //@ts-ignore
                                        fallback={status => setPositionStatus((prevPositionStatus) => ({
                                            ...prevPositionStatus,
                                            status:
                                                //@ts-ignore
                                                status.status,  // Replace with the actual new status value you want to set
                                        }))}

                                        componentView={ComponentView.SELECT}
                                    />
                                </Box>
                                <Box sx={{ width: "300px" }}>
                                    <DateRangePickerComponent
                                        event={(firstTime, lastTime) => handleClickDateRangePicker(firstTime, lastTime)} defaultValueDateRange={defaultValueDateRange} />
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                } />
                <CardContent>
                    <Box sx={{
                        height: 600
                    }}>
                        <DataGridPremium
                            sx={{
                                borderRadius: "10px 10px 0px 0px",
                                backgroundColor: `${theme.palette.background.default}`,
                                '& .MuiDataGrid-virtualScroller': {
                                    overflow: 'auto',
                                    '&::-webkit-scrollbar': { height: 10, WebkitAppearance: 'none', width: 10 },
                                    '&::-webkit-scrollbar-thumb': {
                                        borderRadius: 8,
                                        border: '1px solid',
                                        borderColor: 'white',
                                        backgroundColor: 'rgba(0 0 0 / 0.5)',
                                    },
                                },
                            }}
                            apiRef={apiRef}
                            //@ts-ignore
                            rows={expeditionsDurations.results}
                            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            slots={{
                                toolbar: GridToolbar,
                                noRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Bilgi bulunamadı!
                                    </Stack>
                                ),

                            }}
                            initialState={{
                                // pagination: {
                                //     paginationModel: {
                                //         pageSize: 50,
                                //     },
                                // },
                                aggregation: {
                                    model: {
                                        duration_by_load_start_time: 'avg',
                                        duration_by_entry_exit_load_time: 'avg',
                                        duration_by_entry_exit_time: 'avg',
                                        duration_by_vehicle_entry_exit_time: 'avg',
                                        duration_by_end_entry_exit_time: 'avg',
                                        duration_by_end_vehicle_time: 'avg',
                                        duration_by_load_end_time: 'avg',
                                    },
                                },
                            }}
                            pagination
                            pageSizeOptions={[50, 100, 150, 200]}
                            paginationMode="server"
                            rowCount={expeditionsDurations.count}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            loading={loading}
                        />


                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default ExpeditionsDurationsGrid


import L from "leaflet";

interface IOpenStreetMapLayerTool {
    removeLayers(
        params:{
            map:object
        }):Promise<string>;
}

const OpenStreetMapLayerTool: IOpenStreetMapLayerTool = {
    removeLayers: async (params:{map:object}): Promise<string> => {
        try {
            //@ts-ignore
            Object.keys(params.map._layers).map((res:any)=>{
                //@ts-ignore
                if(!params.map._layers[res]._url){
                    //@ts-ignore
                    //L.control.layers().removeLayer(params.map._layers[res])
                    //@ts-ignore
                    params.map.removeLayer(params.map._layers[res])
                }
            })
            return ""
        }
        catch (e){
            throw e
        }
    }
}

export default OpenStreetMapLayerTool

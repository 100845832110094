import React, {useEffect, useState} from "react"
import {Box, Checkbox, IconButton, Tooltip, Typography, Stack, Button, checkboxClasses, Avatar} from "@mui/material";
import {alpha, styled, useTheme} from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Divider from "@mui/material/Divider";
import CloseIcon from '@mui/icons-material/Close';


const FilterStyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light'
                ? 'rgb(55, 65, 81)'
                : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));


interface ExpeditionsListFilterMenus {
    expedition: (status:boolean) => void
}

const ExpeditionsListFilterMenus = (props:ExpeditionsListFilterMenus) => {

    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [expeditionStatus, setExpeditionStatus] = useState<boolean>(false)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        props.expedition(expeditionStatus)
    }, [expeditionStatus]);


    return (
        <>
            <IconButton
                color="primary"
                sx={{ p: '10px' }}
                onClick={handleClick}
            >
                <FilterAltOutlinedIcon sx={{
                    color:`${theme.palette.grey["600"]}`,
                }} />
            </IconButton>
            <FilterStyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    ".MuiPaper-root":{
                        background: `${theme.palette.background.default}`,
                        borderRadius:"10px"
                    }
                }}
            >
                <Box sx={{
                    minWidth: "150px",
                    height:"auto",
                    margin: "1rem",
                    background: `${theme.palette.background.default}`,
                }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <TuneOutlinedIcon sx={{
                            color:"#A6A6A6"
                        }}/>
                        <Typography sx={{
                            color:"#A6A6A6"
                        }}>
                            Filtre
                        </Typography>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={2}
                            width="100%"
                        >
                            <Avatar sx={{
                                height:"20px",
                                width:"20px",
                                position:"relative",
                                bottom:"10px"
                            }}>
                                <CloseIcon sx={{
                                    fontSize:"12px"
                                }} />
                            </Avatar>
                        </Stack>

                    </Stack>
                    <Divider/>
                    <FormGroup sx={{
                        minWidth:"150px"
                    }}>
                        <FormControlLabel control={
                            <Tooltip title={expeditionStatus ? "Tamamlanan Seferler" : "Tamamlanmamış Seferler"}>
                                <Checkbox
                                    defaultChecked
                                    checked={expeditionStatus}
                                    onChange={()=>setExpeditionStatus(!expeditionStatus)}
                                    sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: `${theme.palette.info.main}`,
                                        },
                                    }}
                                />
                            </Tooltip>
                        } label="Tamamlanmış Seferler" />
                    </FormGroup>
                    <Divider sx={{
                        margin:"15px"
                    }}/>
                    <Stack direction="row" spacing={2}  justifyContent="space-between" alignItems="center">
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor:`${theme.palette.text.secondary}`,
                                borderRadius:"20px",
                                fontSize:"13px",
                                textTransform:"capitalize"
                            }}
                        >Temizle</Button>
                        <Button
                            variant="contained"
                            sx={{
                                background: `${theme.palette.info.main}`,
                                borderRadius:"20px",
                                textTransform:"capitalize"
                            }}
                        >
                            Filtrele
                        </Button>
                    </Stack>
                </Box>
            </FilterStyledMenu>
        </>
    )
}

export default ExpeditionsListFilterMenus

import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {useEffect} from "react";
import {getStorageData} from "../../utils/usePersistedState";

interface IToggleButton {
    toggleButton: {
        label: string,
        process: (label: string) => void; // Specify the type as a function
    }[],
    defaultSelectedButton: string,
}

const DynamicApiToggleButton = (props: IToggleButton) => {

    const [alignment, setAlignment] = React.useState(props.defaultSelectedButton);

    useEffect(() => {
        getStorageData("api", false).then((api) => {
            //@ts-ignore
            setAlignment(api.name)
        }).catch((e:any) => {
        })
    }, [])

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
        const selectedButton = props.toggleButton.find(btn => btn.label === newAlignment);
        if (selectedButton) {
            selectedButton.process(newAlignment); // Invoke the process function
        }
        window.location.reload()
    };

    return (
        <ToggleButtonGroup
            color="secondary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
        >
            {
                props.toggleButton.map((res, index) => (
                    <ToggleButton
                        value={res.label}
                        key={index}
                    >{res.label}
                    </ToggleButton>
                ))
            }

        </ToggleButtonGroup>
    );
}

export default DynamicApiToggleButton

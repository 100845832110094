import axios, { AxiosResponse } from "axios";
import { slug } from "../../../config";

interface Device {

    os: "string",
    device_unique_id: "string",
    device_type: "string",
    device_brand: "string",
    device_model: "string",
    device_name: "string",
    os_version: "string",
    fcm_token: "string",

}

interface NotificationService {
    fetchNotifications(extra: { accessToken: string, apiUrl: string, is_read?: boolean }): Promise<AxiosResponse>;
    fetchDevices(extra: { accessToken: string, apiUrl: string }): Promise<AxiosResponse>;
    PostTokenAndDeviceInfo(extra: { accessToken: string, apiUrl: string, data: Device }): Promise<AxiosResponse>;
    PostNotificationsRead(extra: { accessToken: string, apiUrl: string, data: Device }): Promise<AxiosResponse>;

}
const ApiNotificationsService: NotificationService = {

    fetchNotifications: async (extra: { accessToken: string, apiUrl: string, is_read?: boolean }): Promise<AxiosResponse> => {
        const config = {
            params: {
                ...({ is_read: extra.is_read }),
            },
            headers: {
                Authorization: `Bearer ${extra.accessToken}`,
                'Content-Type': 'application/json'
            }
        };
        try {
            const response = await axios.get(extra.apiUrl + slug.client.notifications.url, config)
            return response;
        } catch (error) {
            throw error;
        }
    },
    fetchDevices: async (extra: { accessToken: string, apiUrl: string }): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${extra.accessToken}`,
            'Content-Type': 'application/json'
        };
        try {
            const response = await axios.get(extra.apiUrl + slug.client.notifications.devices, { headers })
            return response;
        } catch (error) {
            throw error;
        }
    },
    PostTokenAndDeviceInfo: async (extra: { accessToken: string, apiUrl: string, data: Device }): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${extra.accessToken}`,
            'Content-Type': 'application/json'
        };
        try {
            const response = await axios.post(extra.apiUrl + slug.client.notifications.devices, extra.data, { headers })
            return response;
        } catch (error) {
            throw error;
        }
    },
    PostNotificationsRead: async (extra: { accessToken: string, apiUrl: string, data: Device }): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${extra.accessToken}`,
            'Content-Type': 'application/json'
        };
        try {
            const response = await axios.post(extra.apiUrl + slug.client.notifications.read, extra.data, { headers })
            return response;
        } catch (error) {
            throw error;
        }
    },
}

export default ApiNotificationsService

import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import DfdsDiscountList from "./dfds.discount.list";
import DfdsDiscountDetail from "./dfds.discount.detail";

const DFDSDiscountsPage = () => {
    const [discount, setDiscount] = useState({})
    return (
        <Grid container>
            <Grid item md={5} sx={{
                borderRight: '2px solid #E9E9E9',
            }}>
                <DfdsDiscountList discount={value => setDiscount(value)}/>
            </Grid>
            <Grid item md={7}>
                <DfdsDiscountDetail
                    discount={discount}
                />
            </Grid>
        </Grid>
    );
};

export default DFDSDiscountsPage;

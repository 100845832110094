import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid";
import {Box, Typography, Stack, List, Button} from "@mui/material";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import {IoMdTrendingUp} from "react-icons/io";
import {IoTrendingDown} from "react-icons/io5";

import ApiDFDSService from "../../../services/client/dfds/dfds.service";
import {getStorageData} from "../../../utils/usePersistedState";
import {
    DataGridPremium,
    GridColDef,
    GridAggregationFunction,
    GRID_AGGREGATION_FUNCTIONS
} from "@mui/x-data-grid-premium";

import clsx from 'clsx';
import {GridCellParams} from '@mui/x-data-grid-pro';
import {styled} from '@mui/system';
import DiscountBillingPeriodSelector
    from "../../../components/selectors/dfds/discounts/discount.billing.period.selector";
import SearchBar from "../../../components/form/controllers/search.input";
import BookingRatioByRouteCard from "../../../components/card/dfds/booking.card";

import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { HiOutlineTicket } from "react-icons/hi";

import {Skeleton} from "@mui/material";
import {trTR} from "@mui/x-data-grid";


const ProgressBarRoot = styled('div')(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 26,
    borderRadius: 2,
}));

const ProgressBarValue = styled('div')({
    position: 'absolute',
    lineHeight: '24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
});

const ProgressBarBar = styled('div')((props) => ({
    height: '100%',
    width: "100%",
    '&.low': {
        width: "100%",
        maxWidth: "100% !important",
        //backgroundColor: '#f44336',
        //@ts-ignore
        background: `linear-gradient(to left, transparent 50%, red 50% ${Math.abs(props.value / 2) + 50}%, transparent ${Math.abs(props.value / 2) + 50}%)`
    },
    '&.high': {
        width: "100%",
        maxWidth: "100% !important",
        //backgroundColor: '#088208a3',
        //@ts-ignore
        background: `linear-gradient(to right, transparent 50%, green 50% ${Math.abs(props.value / 2) + 50}%, transparent ${Math.abs(props.value / 2) + 50}%)`
    },
}));

interface ProgressBarProps {
    value: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({value}) => {
    const valueInPercent = value;
    return (
        <ProgressBarRoot>
            <ProgressBarValue>{`${valueInPercent.toLocaleString().slice(0, 5)} %`}</ProgressBarValue>
            <ProgressBarBar
                className={clsx({
                    low: valueInPercent < 0,
                    high: valueInPercent > 0,
                })}
                style={{maxWidth: `${valueInPercent}%`}}
                //@ts-ignore
                value={value}
            />
        </ProgressBarRoot>
    );
};

function renderProgress(params: GridCellParams) {
    return <ProgressBar value={Number(params)!}/>;
}


const DFDSDashboardPage = () => {
    const [companyCount, setCompanyCount] = useState([])
    const [companyCountLoading, setCompanyCountLoading] = useState<boolean>(false)

    const [bookingsSold, setBookingsSold] = useState([])
    const [bookingsSoldLoading, setBookingsSoldLoading] = useState<boolean>(true)

    const [bookingsTotal, setBookingsTotal] = useState([])
    const [bookingsTotalLoading, setBookingsTotalLoading] = useState<boolean>(true)

    const [bookingsUsed, setBookingsUsed] = useState([])
    const [bookingsUsedLoading, setBookingsUsedLoading] = useState<boolean>(true)

    const [bookingsNotMatched, setBookingsNotMatched] = useState([])
    const [bookingsNotMatchedLoading, setBookingsNotMatchedLoading] = useState<boolean>(true)

    const [bookingRatioByRoute, setBookingRatioByRoute] = useState([])
    const [bookingRatioByRouteLoading, setBookingRatioByRouteLoading] = useState<boolean>(true)

    const [bookingRatioByCompany, setBookingRatioByCompany] = useState([])
    const [bookingRatioByCompanyLoading, setBookingRatioByCompanyLoading] = useState<boolean>(true)

    const [bookingsCanceled, setBookingsCanceled] = useState([])
    const [bookingsCanceledLoading, setBookingsCanceledLoading] = useState<boolean>(true)

    const [ bookingsTraing, setBookingsTraing ] = useState([])
    const [ bookingsTraingLoading, setBookingsTraingLoading ] = useState<boolean>(true)

    const [ bookingRatioByCompanyCount, setBookingRatioByCompanyCount] = useState<number>(0)

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });


    const [ beforePeriod, setBeforePeriod ] = useState("")
    const [ lastPeriod, setLastPeriod ] = useState("")

    const [ search, setSearch ] = useState("")
    const [ approve, setApprove ] = useState(true)

    const columns: GridColDef[] = [{
            field: "company_name",
            headerName: "Firma İsmi",
            minWidth: 110,
            flex: 1,
            headerAlign:'left',
            align:'left',
            renderHeader: () => (
                <>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                       Firma İsmi
                    </Typography>
                </>
            )
        },
        {
            field: "route",
            headerName: "Rota",
            minWidth: 110,
            flex: 1,
            type:"string",
            headerAlign:'left',
            align:'left',
        },
        {
            field: "before_period_bookings_total",
            minWidth: 110,
            flex: 1,
            type:"number",
            headerAlign:'center',
            align:'center',
            renderHeader: () => (
                <>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        İlk Periyod Bilet Sayısı
                    </Typography>
                </>
            )
        },
        {
            field: "last_period_bookings_total",
            minWidth: 110,
            flex: 1,
            type:"number",
            headerAlign:'center',
            align:'center',
            renderHeader: () => (
                <>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        Son Periyod Bilet Sayısı
                    </Typography>
                </>
            )
        },
        {
            field: "increase_decrease_rate",
            headerName: "Artış Oranı",
            minWidth: 110,
            flex: 1,
            type:"number",
            headerAlign:'center',
            align:'center',
            renderHeader: () => (
                <>
                    <Typography sx={{ fontSize: "13px", fontWeight: "medium" }}>
                        Artış Oranı
                    </Typography>
                </>
            ),
            renderCell: (params) => {
                return (
                    renderProgress(params.value)
                )
            }
        }
    ];

    useEffect(() => {
        const fetchBookingRatioByCompany = (params:{
            accessToken:string,
            search:string,
            offset: number,
            limit: number,
        }) => {
            setBookingRatioByCompanyLoading(true);
            setBookingRatioByCompany([])
            ApiDFDSService.fetchBookingRatioByCompany({
                //@ts-ignore
                accessToken: params.accessToken,
                offset: params.offset,
                limit: params.limit,
                before_period: beforePeriod,
                last_period: lastPeriod,
                search: params.search,
            }).then((res: any) => {
                //@ts-ignore
                setBookingRatioByCompany(res.results)
                setBookingRatioByCompanyLoading(false)
                setBookingRatioByCompanyCount(res.count)
            })
        }

        getStorageData("authState", true).then((auth) => {
            fetchBookingRatioByCompany({
                //@ts-ignore
                accessToken:auth.status.access_token,
                search:search,
                offset: (paginationModel.page * paginationModel.pageSize),
                limit: paginationModel.pageSize
            });
        })

    }, [paginationModel, search]);

    useEffect(() => {
        setBookingRatioByCompany([])
        setPaginationModel({
            page:0,
            pageSize: 50
        })
    }, [approve, search])

    useEffect(() => {
        setBookingRatioByRouteLoading(true)
        setBookingsTotalLoading(true)
        setBookingsUsedLoading(true)
        setBookingsSoldLoading(true)
        setBookingsNotMatchedLoading(true)
        setBookingsCanceledLoading(true)
        setBookingsTraingLoading(true)
        setCompanyCountLoading(true)


        getStorageData("authState", true).then(auth => {
            ApiDFDSService.fetchActiveCompanyCount({
                //@ts-ignore
                accessToken: auth.status.access_token,
                before_period: beforePeriod,
                last_period: lastPeriod
                //@ts-ignore
            }).then((res: any) => {
                setCompanyCount(res.results[0])
                setCompanyCountLoading(false)
            })

            ApiDFDSService.fetchBookingsSold({
                //@ts-ignore
                accessToken: auth.status.access_token,
                before_period: beforePeriod,
                last_period: lastPeriod
                //@ts-ignore
            }).then((res: any) => {
                setBookingsSold(res.results[0])
                setBookingsSoldLoading(false)
            })

            ApiDFDSService.fetchBookingsTotal({
                //@ts-ignore
                accessToken: auth.status.access_token,
                before_period: beforePeriod,
                last_period: lastPeriod
                //@ts-ignore
            }).then((res: any) => {
                setBookingsTotal(res.results[0])
                setBookingsTotalLoading(false)
            })

            ApiDFDSService.fetchBookingRatioByRoute({
                //@ts-ignore"
                accessToken: auth.status.access_token,
                before_period: beforePeriod,
                last_period: lastPeriod
                //@ts-ignore
            }).then((res: any) => {
                setBookingRatioByRouteLoading(false)
                //@ts-ignore
                setBookingRatioByRoute(res.results)
            })

            ApiDFDSService.fetchBookingsUsed({
                //@ts-ignore
                accessToken:auth.status.access_token,
                before_period: beforePeriod,
                last_period: lastPeriod
            }).then((res)=>{
                //@ts-ignore
                setBookingsUsed(res.results[0])
                setBookingsUsedLoading(false)
            })

            ApiDFDSService.fetchBookingsNotMatched({
                //@ts-ignore
                accessToken:auth.status.access_token,
                before_period: beforePeriod,
                last_period: lastPeriod
            }).then((res)=>{
                //@ts-ignore
                setBookingsNotMatched(res.results[0])
                setBookingsNotMatchedLoading(false)
            })

            ApiDFDSService.fetchBookingsCanceled({
                //@ts-ignore
                accessToken:auth.status.access_token,
                before_period: beforePeriod,
                last_period: lastPeriod
            }).then((res)=>{
                //@ts-ignore
                setBookingsCanceled(res.results[0])
                setBookingsCanceledLoading(false)
            })

            ApiDFDSService.fetchBookingsTrain({
                //@ts-ignore
                accessToken:auth.status.access_token,
                before_period: beforePeriod,
                last_period: lastPeriod
            }).then((res)=>{
                //@ts-ignore
                setBookingsTraing(res.results[0])
                setBookingsTraingLoading(false)
            })
        })

    }, [approve]);

    const companyNameAggregation: GridAggregationFunction<string, string | null> =
        {
            apply: (params) => {
                if (params.values.length === 0) {
                    return null;
                }

                const sortedValue = params.values.sort((a = '', b = '') => a.localeCompare(b));
                return sortedValue[0];
            },
            label: 'company name',
            valueFormatter: (params) => `TOPLAM`,

            columnTypes: ['string'],
        };

    const increaseDecreaseRateAggregation: GridAggregationFunction<string, string | null> =
        {
            apply: (params) => {
                if (params.values.length === 0) {
                    return null;
                }

                const sortedValue = params.values.sort((a = '', b = '') => a.localeCompare(b));
                return sortedValue[0];
            },
            label: 'increase Decrease Rate',
            valueFormatter: (params) => "",
            columnTypes: ['string'],
        };

    return (
        <Grid md={12} sx={{
            margin: 2
        }}>
            <Grid md={2}>
                <Stack direction="row" spacing={2} sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2
                }}>
                    <Typography sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        marginBottom: 2,
                        marginTop: 2
                    }}>
                        Bilet Raporları
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        <DiscountBillingPeriodSelector defaultPeriod={2} fallback={(period) => setBeforePeriod(period)} disable={false}
                                                       inputLabel={"İlk Dönem"}/>
                        <DiscountBillingPeriodSelector defaultPeriod={1} fallback={(period) => setLastPeriod(period)} disable={false}
                                                       inputLabel={"Son Dönem"}/>
                        <Button variant="outlined" onClick={()=>setApprove(!approve)}>Uygula</Button>
                    </Stack>
                </Stack>
            </Grid>
            <Grid md={12}>
                <Grid container md={12}>
                    <Grid md={2} xs={12}>
                        <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={10}
                            sx={{
                                border: '1px solid',
                                borderColor: 'rgba(217, 217, 217, 1)',
                                borderRadius: "10px",
                                paddingTop: 3,
                                paddingBottom: 3,
                                paddingLeft:2,
                                height: "100%"
                            }}
                        >
                            <Box sx={{
                                width: "220px",
                                marginTop:"0 !important"
                            }}>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <ConfirmationNumberOutlinedIcon sx={{
                                            backgroundColor: "#0476D9",
                                            borderRadius: "4px",
                                            color: "white",
                                        }}/>
                                        <Typography sx={{
                                            fontSize: "16px",
                                            color: "#9C9C9C"
                                        }}>
                                            Toplam Bilet
                                        </Typography>
                                    </Stack>
                                    {
                                        bookingsTotalLoading ? <Skeleton
                                                variant="rectangular"
                                                width={200}
                                                height={30}
                                            /> :
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography sx={{
                                                    fontSize: "20px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px",
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        bookingsTotal.last_period_bookings_total
                                                    } Adet
                                                </Typography>
                                                {
                                                    //@ts-ignore
                                                    bookingsTotal?.increase_decrease_rate < 0 ?
                                                        <IoTrendingDown style={{
                                                            fontSize: "24px",
                                                            color: "red"
                                                        }}/> : <IoMdTrendingUp style={{
                                                            fontSize: "24px",
                                                            color: "green"
                                                        }}/>
                                                }
                                                <Typography sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px",
                                                    //@ts-ignore
                                                    color:   bookingsTotal?.increase_decrease_rate < 0 ?  "red" :"green"
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        bookingsTotal?.increase_decrease_rate?.toString().slice(0, 5)
                                                    }  %
                                                </Typography>
                                            </Stack>
                                    }
                                </Stack>
                            </Box>
                            <Box sx={{
                                width: "220px",
                                marginTop:"0 !important"
                            }}>
                                <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <HiOutlineTicket style={{
                                                backgroundColor: "#ffa601",
                                                borderRadius: "4px",
                                                color: "white",
                                                fontSize: "24px",
                                            }}/>
                                            <Typography sx={{
                                                fontSize: "16px",
                                                color: "#9C9C9C"
                                            }}>
                                                Kullanılan Bilet
                                            </Typography>
                                        </Stack>
                                    {
                                        bookingsUsedLoading ?  <Skeleton
                                                variant="rectangular"
                                                width={200}
                                                height={30}
                                            /> :
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography sx={{
                                                fontSize: "20px",
                                                fontWeight: 600,
                                                lineHeight: "28px",
                                            }}>
                                                {
                                                    //@ts-ignore
                                                    bookingsUsed.last_period_bookings_total
                                                } Adet
                                            </Typography>
                                            {
                                                //@ts-ignore
                                                bookingsUsed?.increase_decrease_rate < 0 ?
                                                    <IoTrendingDown style={{
                                                        fontSize: "24px",
                                                        color: "red"
                                                    }}/> : <IoMdTrendingUp style={{
                                                        fontSize: "24px",
                                                        color: "green"
                                                    }}/>
                                            }
                                            <Typography sx={{
                                                fontSize: "14px",
                                                fontWeight: 600,
                                                lineHeight: "28px",
                                                //@ts-ignore
                                                color:   bookingsUsed?.increase_decrease_rate < 0 ?  "red" :"green"
                                            }}>
                                                {
                                                    //@ts-ignore
                                                    bookingsUsed?.increase_decrease_rate?.toString().slice(0, 5)
                                                }  %
                                            </Typography>
                                        </Stack>
                                    }
                                    </Stack>
                            </Box>
                            <Box sx={{
                                width: "220px",
                                marginTop:"0 !important"
                            }}>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <ConfirmationNumberOutlinedIcon sx={{
                                            backgroundColor: "#04D9B2",
                                            borderRadius: "4px",
                                            color: "white",
                                        }}/>
                                        <Typography sx={{
                                            fontSize: "16px",
                                            color: "#9C9C9C"
                                        }}>
                                            Satılan Bilet
                                        </Typography>
                                    </Stack>
                                    {
                                        bookingsSoldLoading ? <Skeleton
                                                variant="rectangular"
                                                width={200}
                                                height={30}
                                            /> :
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography sx={{
                                                    fontSize: "20px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px"
                                                }}>

                                                    {   //@ts-ignore
                                                        bookingsSold?.last_period_bookings_total
                                                    } Adet
                                                </Typography>
                                                {
                                                    //@ts-ignore
                                                    bookingsSold?.increase_decrease_rate < 0 ?
                                                        <IoTrendingDown style={{
                                                            fontSize: "24px",
                                                            color: "red"
                                                        }}/> : <IoMdTrendingUp style={{
                                                            fontSize: "24px",
                                                            color: "green"
                                                        }}/>
                                                }
                                                <Typography sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px",
                                                    //@ts-ignore
                                                    color: bookingsSold?.increase_decrease_rate < 0 ? "red" : "green"
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        bookingsSold?.increase_decrease_rate?.toString().slice(0, 5)
                                                    } %
                                                </Typography>
                                            </Stack>
                                    }
                                </Stack>
                            </Box>
                            <Box sx={{
                                width: "220px",
                                marginTop:"0 !important"
                            }}>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <HiOutlineTicket style={{
                                            backgroundColor: "#9d4600",
                                            borderRadius: "4px",
                                            color: "white",
                                            fontSize: "24px",
                                        }}/>
                                        <Typography sx={{
                                            fontSize: "16px",
                                            color: "#9C9C9C"
                                        }}>
                                            Tren Bilet
                                        </Typography>
                                    </Stack>
                                    {
                                        bookingsTraingLoading ?  <Skeleton
                                                variant="rectangular"
                                                width={200}
                                                height={30}
                                            /> :
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography sx={{
                                                    fontSize: "20px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px",
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        bookingsTraing.last_period_bookings_total
                                                    } Adet
                                                </Typography>
                                                {
                                                    //@ts-ignore
                                                    bookingsTraing?.increase_decrease_rate < 0 ?
                                                        <IoTrendingDown style={{
                                                            fontSize: "24px",
                                                            color: "red"
                                                        }}/> : <IoMdTrendingUp style={{
                                                            fontSize: "24px",
                                                            color: "green"
                                                        }}/>
                                                }
                                                <Typography sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px",
                                                    //@ts-ignore
                                                    color:   bookingsTraing?.increase_decrease_rate < 0 ?  "red" :"green"
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        bookingsTraing?.increase_decrease_rate?.toString().slice(0, 5)
                                                    }  %
                                                </Typography>
                                            </Stack>
                                    }
                                </Stack>
                            </Box>
                            <Box sx={{
                                width: "220px",
                                marginTop:"0 !important"
                            }}>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <ConfirmationNumberOutlinedIcon sx={{
                                            backgroundColor: "#190064",
                                            borderRadius: "4px",
                                            color: "white",
                                        }}/>
                                        <Typography sx={{
                                            fontSize: "16px",
                                            color: "#9C9C9C"
                                        }}>
                                            Eşleşmeyen Bilet
                                        </Typography>
                                    </Stack>
                                    {
                                        bookingsNotMatchedLoading ? <Skeleton
                                            variant="rectangular"
                                            width={200}
                                            height={30}
                                        /> :
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography sx={{
                                                    fontSize: "20px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px"
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        bookingsNotMatched?.last_period_bookings_total
                                                    } Adet
                                                </Typography>
                                                {
                                                    //@ts-ignore
                                                    bookingsNotMatched?.increase_decrease_rate < 0 ?
                                                        <IoTrendingDown style={{
                                                            fontSize: "24px",
                                                            color: "red"
                                                        }}/> : <IoMdTrendingUp style={{
                                                            fontSize: "24px",
                                                            color: "green"
                                                        }}/>
                                                }
                                                <Typography sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px",
                                                    //@ts-ignore
                                                    color: bookingsNotMatched?.increase_decrease_rate < 0 ? "red" : "green"
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        bookingsNotMatched?.increase_decrease_rate?.toString().slice(0, 5)
                                                    } %
                                                </Typography>
                                            </Stack>
                                    }
                                </Stack>
                            </Box>
                            <Box sx={{
                                width: "220px",
                                marginTop:"0 !important"
                            }}>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <ConfirmationNumberOutlinedIcon sx={{
                                            backgroundColor: "#ff0045",
                                            borderRadius: "4px",
                                            color: "white",
                                        }}/>
                                        <Typography sx={{
                                            fontSize: "16px",
                                            color: "#9C9C9C"
                                        }}>
                                            İptal Bilet
                                        </Typography>
                                    </Stack>
                                    {
                                        bookingsCanceledLoading ?
                                            <Skeleton
                                                variant="rectangular"
                                                width={200}
                                                height={30}
                                            /> :
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography sx={{
                                                    fontSize: "20px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px"
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        bookingsCanceled?.last_period_bookings_total
                                                    } Adet
                                                </Typography>
                                                {
                                                    //@ts-ignore
                                                    bookingsCanceled?.increase_decrease_rate < 0 ?
                                                        <IoTrendingDown style={{
                                                            fontSize: "24px",
                                                            color: "red"
                                                        }}/> : <IoMdTrendingUp style={{
                                                            fontSize: "24px",
                                                            color: "green"
                                                        }}/>
                                                }
                                                <Typography sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px",
                                                    //@ts-ignore
                                                    color: bookingsCanceled?.increase_decrease_rate < 0 ? "red" : "green"
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        bookingsCanceled?.increase_decrease_rate?.toString().slice(0, 5)
                                                    } %
                                                </Typography>
                                            </Stack>
                                    }
                                </Stack>
                            </Box>
                            <Box sx={{
                                width: "220px",
                                marginTop:"0 !important"
                            }}>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <HiOutlineBuildingOffice2 style={{
                                            fontSize: "24px",
                                            backgroundColor: "#775DD0",
                                            borderRadius: "4px",
                                            color: "white",
                                        }}/>
                                        <Typography sx={{
                                            fontSize: "16px",
                                            color: "#9C9C9C"
                                        }}>
                                            Firma Sayısı
                                        </Typography>
                                    </Stack>
                                    {
                                        companyCountLoading ? <Skeleton
                                                variant="rectangular"
                                                width={200}
                                                height={30}
                                            /> :
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography sx={{
                                                    fontSize: "20px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px"
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        companyCount.last_period_company_count
                                                    } Adet
                                                </Typography>
                                                {
                                                    //@ts-ignore
                                                    companyCount?.between_periods_company_difference_total < 0 ?
                                                        <IoTrendingDown style={{
                                                            fontSize: "24px",
                                                            color: "red"
                                                        }}/> : <IoMdTrendingUp style={{
                                                            fontSize: "24px",
                                                            color: "green"
                                                        }}/>
                                                }
                                                <Typography sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 600,
                                                    lineHeight: "28px",
                                                    //@ts-ignore
                                                    color: companyCount?.increase_decrease_rate < 0 ? "red" : "green"
                                                }}>
                                                    {
                                                        //@ts-ignore
                                                        companyCount.increase_decrease_rate
                                                    } %
                                                </Typography>
                                            </Stack>
                                    }
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid md={10} sx={{
                        paddingLeft: 1
                    }}>
                        <Stack
                            direction="column"
                            spacing={1}
                        >
                            <Stack
                                direction="row"
                                spacing={3}
                            >
                                {
                                        <List sx={{
                                            maxWidth: '100vw',
                                            display: "flex",
                                            position: 'relative',
                                            overflow: 'auto',
                                            padding: 0,
                                            '&::-webkit-scrollbar': {height: 5, WebkitAppearance: 'none', width: 5},
                                            '&::-webkit-scrollbar-thumb': {
                                                borderRadius: 8,
                                                border: '1px solid',
                                                borderColor: 'white',
                                                backgroundColor: 'rgba(0 0 0 / 0.5)',
                                            }
                                        }}>
                                            {
                                                bookingRatioByRouteLoading ?
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        spacing={5}
                                                        sx={{
                                                            marginRight: "2rem"
                                                        }}
                                                    >
                                                    <Skeleton
                                                        variant="rectangular"
                                                        width={320}
                                                        height={120}
                                                        sx={{

                                                        }}
                                                    />
                                                        <Skeleton
                                                            variant="rectangular"
                                                            width={320}
                                                            height={120}
                                                            sx={{

                                                            }}
                                                        />
                                                        <Skeleton
                                                            variant="rectangular"
                                                            width={320}
                                                            height={120}
                                                            sx={{

                                                            }}
                                                        />
                                                    </Stack> :
                                                bookingRatioByRoute.map((res, index) => (
                                                    <BookingRatioByRouteCard
                                                        index={index}
                                                        last_period_bookings_total={
                                                        //@ts-ignore
                                                        res.last_period_bookings_total
                                                    }
                                                        increase_decrease_rate={
                                                        //@ts-ignore
                                                        res.increase_decrease_rate
                                                        }
                                                        main_route={{
                                                            //@ts-ignore
                                                            route:res.main_route.route,
                                                            //@ts-ignore
                                                            last_period_bookings_total:res.main_route.last_period_bookings_total,
                                                            //@ts-ignore
                                                            increase_decrease_rate:res.main_route.increase_decrease_rate
                                                    }}
                                                        linked_route={{
                                                            //@ts-ignore
                                                            route:res.linked_route.route,
                                                            //@ts-ignore
                                                            last_period_bookings_total:res.linked_route.last_period_bookings_total,
                                                            //@ts-ignore
                                                            increase_decrease_rate:res.linked_route.increase_decrease_rate
                                                        }}
                                                    />
                                                ))
                                            }
                                        </List>
                                }
                            </Stack>
                            <Box sx={{
                                border: '1px solid',
                                borderColor: 'rgba(217, 217, 217, 1)',
                                borderRadius: "10px",
                                padding: 1,
                            }}>
                                <Grid container spacing={2} sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 1
                                }}>
                                    <Grid item xs={4}>
                                        <Typography sx={{
                                            fontSize: "16px",
                                            lineHeight: "18px",
                                            fontWeight: 500,
                                            margin: 1
                                        }}>
                                            Aylara Göre Bilet Satışları
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SearchBar tooltipTitle={"Firma Ara"} placeHolder={"Ara"} onChangeText={(text) => setSearch(text)}/>
                                    </Grid>
                                </Grid>
                                <DataGridPremium
                                    columns={columns}
                                    rows={bookingRatioByCompany}
                                    sx={{
                                        height: "calc(100vh - 400px)",
                                        width: "100%",
                                        '& .MuiDataGrid-virtualScroller': {
                                            overflow: 'auto',
                                            '&::-webkit-scrollbar': {height: 5, WebkitAppearance: 'none', width: 5},
                                            '&::-webkit-scrollbar-thumb': {
                                                borderRadius: 8,
                                                border: '1px solid',
                                                borderColor: 'white',
                                                backgroundColor: 'rgba(0 0 0 / 0.5)',
                                            },
                                        },
                                    }}
                                    localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                                    getRowId={(row: any) => row.company_id + row.route}
                                    pagination
                                    paginationMode="server"
                                    pageSizeOptions={[25, 50, 75, 100]}
                                    rowCount={bookingRatioByCompanyCount}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                    loading={bookingRatioByCompanyLoading}
                                    aggregationFunctions={{
                                        ...GRID_AGGREGATION_FUNCTIONS,
                                        companyName: companyNameAggregation,
                                        increaseDecreaseRate: increaseDecreaseRateAggregation
                                    }}
                                    initialState={{
                                        aggregation: {
                                            model: {
                                                company_name: 'companyName',
                                                before_period_bookings_total:'sum',
                                                last_period_bookings_total:'sum',
                                                increase_decrease_rate:'sum'
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DFDSDashboardPage

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchOAuthLogin} from "../services/auth/auth.service";
import {IOAuthLoginToken, IOAuthLoginTokenState} from "../types/interfaces/reducers/auth/oAuthLogin.interface";
import {setStorageData} from "../utils/usePersistedState";

const initialState: IOAuthLoginTokenState = {
    // @ts-ignore
    data: null,
    loading:false,
    error:""
}

const fetchOAuthTokenLoginSlice = createSlice({
    name:"auth",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(fetchOAuthLogin.pending, (state, action)=>{
            state.loading = true;
            state.error = "pending";
        })
        builder.addCase(fetchOAuthLogin.fulfilled, (state,action:PayloadAction<IOAuthLoginToken>)=>{
            state.data = action.payload;
            state.loading = false;
        })
        builder.addCase(fetchOAuthLogin.rejected, (state, action) => {
            state.loading = true;
            state.error = "error"
        })
    }
})

export default fetchOAuthTokenLoginSlice.reducer;

import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {SelectChangeEvent} from "@mui/material/Select";
import {useEffect} from "react";
import {getStorageData} from "../../../../utils/usePersistedState";
import ApiDFDSService from "../../../../services/client/dfds/dfds.service";

import useDebounce from "../../../../utils/debounce";
import {useTheme} from "@mui/material/styles";

interface IBookingCompanyMultiSelector {
    defaultCompany:string,
    company:(Id: string, name:string) => void;
}
const BookingCompanyMultiSelector = (props:IBookingCompanyMultiSelector) => {
    const theme = useTheme();

    const [value, setValue] = React.useState<string | null>('');

    const [inputValue, setInputValue] = useState('');

    const [options, setOptions] = useState([])

    const [companies, setCompanies] = useState([])

    const debounceValue = useDebounce(inputValue, 2000)

    const [loading, setLoading] = useState<boolean>(true)

    const [loadingText, setLoadingText] = useState('');

    useEffect(() => {

        setLoading(true)
        setLoadingText("Aradığınız firmayı buraya yazın")
        setOptions([])

        if (inputValue.length >= 3){
            setLoadingText("Aranıyor...")
        }

        if(inputValue.length >= 3 && inputValue != "" && debounceValue != ""){
            getStorageData("authState", true).then((auth) => {
                ApiDFDSService.fetchCompanies({
                    //@ts-ignore
                    accessToken:auth.status.access_token,
                    search:inputValue
                }).then((res)=>{
                    //@ts-ignore
                    const companies = res.results.map((company: any, index: number) => ({
                        id: company.id,
                        name: company.name,
                    }));
                    setCompanies(companies)
                    //@ts-ignore
                    const companyNames = res.results.map((company: any) => company.name);
                    setOptions(companyNames);
                    setLoading(false)
                })
            })
        }
        }, [debounceValue, inputValue]);

    return(
        <Autocomplete
            value={value}
            onChange={(event: any, newValue: string | null) => {
                setValue(newValue);
                //@ts-ignore
                const selectedCompany = companies.find(company => company.name === newValue);
                if (selectedCompany) {
                    //@ts-ignore
                    const selectedCompanyId = selectedCompany.id;
                    //@ts-ignore
                    const selectedCompanyName = selectedCompany.name;
                    props.company(selectedCompanyId, selectedCompanyName)
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            sx={{
                width: "100%",
                fontSize:"14px",
                color: `${theme.palette.text.primary}`
            }}
            renderInput={(params) => <TextField sx={{
                fontSize:"4px",
                color: `${theme.palette.text.primary}`
            }} {...params} label={props.defaultCompany} />}
            noOptionsText={"Firma ismi giriniz"}
            loadingText={loadingText}
            loading={loading}
        />
    )
}

export default BookingCompanyMultiSelector

import React, {useEffect, useState} from "react"
import {getStorageData} from "../../../utils/usePersistedState";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {InputLabel, FormControl, MenuItem} from "@mui/material";
import {FieldValues, UseFormRegister} from "react-hook-form";

import {ApiIntegrationsTaxesService} from "../../../services/client/integrations/integrations.taxes.service";

interface ITaxSelector {
    label: string,
    index: number,
    taxId: (id: string) => void,
    register: UseFormRegister<FieldValues>,
    inputName: string,
    onChange?: any,
    value?: string
}

export const TaxSelector = ({label, index, taxId, register, inputName, value, onChange}: ITaxSelector) => {

    const [getTax, setTax] = useState([])

    useEffect(() => {
        //@ts-ignore
        getStorageData("authState").then(auth => {
            //@ts-ignore
            ApiIntegrationsTaxesService.fetchIntegrationsTaxes(auth.status.access_token).then((res:any) => {
                setTax(res.data.results)
            })
        })
    }, [])


    return (
        <FormControl sx={{
            width: "100%"
        }}>
            <InputLabel
                id="demo-simple-select-label"
            >
                {
                    label
                }
            </InputLabel>
            <Select
                //@ts-ignore
                ref={register}
                defaultValue={value}
                //@ts-ignore
                onChange={onChange}
                //@ts-ignore
                {...register((`advert_items.${index}.${inputName}`), {required: true})}
            >
                {
                    getTax.map((res, index) => (
                        <MenuItem
                            key={index}
                            value={
                                //@ts-ignore
                                res.id
                            }
                        >
                            {
                                //@ts-ignore
                                res.tax_code
                            }
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

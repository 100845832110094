import {createSlice, PayloadAction} from '@reduxjs/toolkit';
//import { autoHideDuration, horizontal, severity, vertical } from "./types/enums/components/snackbar/snackbar.enums";
import {severity, autoHideDuration, vertical, horizontal} from "../types/enums/components/snackbar/snackbar.enums";

interface SnackbarState {
    open: boolean;
    severity:severity
    autoHideDuration:autoHideDuration
    message:string,
    anchorOrigin:{
        vertical:vertical,
        horizontal:horizontal
    }
    close: (status: boolean) => void
}

const initialState: SnackbarState = {
    open: false,
    severity:severity.success,
    autoHideDuration:autoHideDuration.ms3000,
    message:"",
    anchorOrigin:{
        vertical:vertical.bottom,
        horizontal:horizontal.right,
    },
    close: (status: boolean) => console.log(status)
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbar: (state, action:PayloadAction<SnackbarState>) => {
            state.open = true;
            state.message = action.payload.message
            state.severity = action.payload.severity
            state.close = action.payload.close
            state.autoHideDuration = action.payload.autoHideDuration
            state.anchorOrigin = action.payload.anchorOrigin
        },
        closeSnackbar: (state) => {
            state.open = false;
        },
    },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;

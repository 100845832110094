import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import Crop75Icon from '@mui/icons-material/Crop75';
import { Notification } from './notification';
import { getStorageData } from '../../utils/usePersistedState';
import ApiNotificationsService from '../../services/client/notifications/notifications.service';

interface BasicMenuProps {
  totalNotifications: Notification[]; // Make sure Notification type is imported and defined
  setTotalNotifications: React.Dispatch<React.SetStateAction<Notification[]>>
  fetchNotifications: (is_read?: boolean) => void
}

export default function BasicMenu(props: BasicMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAllRead = async () => {
    await getStorageData("authState", true).then((auth) => {
      getStorageData("api", false).then((api) => {

        const newData: string[] = []
        props.totalNotifications.map((singleNotification) => { newData.push(singleNotification.id) })
        const data = {
          notifications: newData
        };
        ApiNotificationsService.PostNotificationsRead({
          //@ts-ignore
          apiUrl: api.url, accessToken: auth.status.access_token, data
        })
          .then((res: any) => {
            console.log(res)
          })
          .catch((error: any) => console.error(error));
      });
    })

    await props.fetchNotifications()
    handleClose()
  }

  const handleRead = () => {
    props.fetchNotifications(true)
    handleClose()
  }

  const handleUnread = () => {
    props.fetchNotifications(false)
    handleClose()
  }
  const handleAll = () => {
    props.fetchNotifications()
    handleClose()
  }

  return (
    <div>

      <IconButton
        size="small"
        sx={{ ml: 2 }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}

      >
        <Crop75Icon />
        <ArrowDropDownIcon fontSize="small" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleAllRead}>Tümünü okundu olarak işaretle</MenuItem>
        <MenuItem onClick={handleAll}>Tümü</MenuItem>
        <MenuItem onClick={handleRead}>Okunanlar</MenuItem>
        <MenuItem onClick={handleUnread}>Okunmayanlar</MenuItem>
      </Menu>
    </div>
  );
}

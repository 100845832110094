import React, {useEffect, useState} from "react";
import {advertFetchService} from "../../services/client/adverts/adverts.service";
import AdvertCard from "../../components/card/advert.card";
import {getStorageData} from "../../utils/usePersistedState";
import {Link} from "react-router-dom";

//Components
import {
    Box,
    Button, Card, CardContent,
    Grid, Typography
} from '@mui/material';
import {permissionControl} from "../../utils/permissionControl";
import PermissionRestricted from "../../utils/permissionProvider/permissionRestricted";
import PermissionsTypes from "../../types/models/components/permissions.default.model";
import PermissionProvider from "../../utils/permissionProvider/permissionProvider";
import {useAppSelector} from "../../store";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import AlertTitle from "@mui/material/AlertTitle";

interface IAdverts {
    componentName: string
}

const AdvertsPage = () => {
    const userInfo = useAppSelector((state) => state.user);

    const [getAdverts, setAdverts] = useState([])

    useEffect(() => {
        getStorageData("authState",false).then(auth => (
            //@ts-ignore
            advertFetchService(auth.status.access_token).then(res => {
                setAdverts(res)
            })
        ))
    }, [])

    return (
        <Grid md={12}>
            <Grid container md={12}>
                <Grid md={10}>

                </Grid>
                <Grid md={2}>

                    <PermissionProvider
                        //@ts-ignore
                        fetchPermission={permissionControl(userInfo.data, "_advert")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.add]}>
                            <Link to="/adverts/advert/create">
                                <Button variant="outlined">İlan Ekle</Button>
                            </Link>
                        </PermissionRestricted>
                    </PermissionProvider>

                </Grid>
            </Grid>

            <Grid container>
                {
                Object.keys(getAdverts).length == 0 ?
                <Box sx={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    justifyContent:"center",
                }}>
                    <Card>
                        <CardContent>
                            <Typography variant="body1" gutterBottom>
                                Henüz ilan yayınlanmadı
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
                :
                    getAdverts.map((res:any)=>(
                        <AdvertCard data={res}/>
                    ))
                }
            </Grid>

        </Grid>
    )
}


export default AdvertsPage

import axios, { AxiosResponse } from "axios";

import { slug } from "../../../config";

interface ExpeditionsPositionTypes {
    // Position Types
}

interface ExpeditionsPositionTypesService {
    apiUrl?:string;
    fetchExpeditionsPositionTypes(extra:{accessToken:string}):Promise<AxiosResponse>;
}

const apiUrl = slug.client.expeditions.positionTypes.url


const ApiExpeditionsPositionTypesService: ExpeditionsPositionTypesService ={
    fetchExpeditionsPositionTypes: async (extra: { accessToken: string }): Promise<AxiosResponse> => {
        const config = {
            headers:{
                Authorization: `Bearer ${extra.accessToken}`,
            }
        }
        try {
            const response = await axios.get(apiUrl, config)
            return response
        } catch (error) {
            throw error;
        }
    }
}


export default ApiExpeditionsPositionTypesService

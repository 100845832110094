import { Button } from '@mui/material';
import React from 'react';

interface DocumentViewerProps {
    documentUrl: string;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ documentUrl }) => {
    return (
        <iframe
            src={documentUrl}
            width="900"
            height="800"
            title=" Viewer"
        />
    );
};

export default DocumentViewer;

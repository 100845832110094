import axios, {AxiosResponse} from "axios";
import {slug} from '../../../config'
interface IntegrationsCurrenciesService {
    apiUrl?:string
    fetchIntegrationsCurrenciesService(accessToken: string, CurrenciesID?:string): Promise<AxiosResponse>;
    createIntegrationsCurrenciesService(accessToken: string, data: any): Promise<AxiosResponse>;
    updateIntegrationsCurrenciesService(accessToken: string, data: any, CurrenciesD: string): Promise<AxiosResponse>;
    deleteIntegrationsCurrenciesService(accessToken: string, CurrenciesID: string): Promise<AxiosResponse>;
}

const apiUrl = slug.client.integrations.currencies.url

export const ApiIntegrationsCurrenciesService: IntegrationsCurrenciesService = {

    fetchIntegrationsCurrenciesService: async (accessToken: string, holderID?: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.get(apiUrl, {headers})
            return response
        } catch (error) {
            throw error
        }
    },

    createIntegrationsCurrenciesService: async (accessToken: string, data: any): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.post(apiUrl, {headers})
            return response
        } catch (error) {
            throw error
        }
    },

    updateIntegrationsCurrenciesService: async (accessToken: string, data: any, holderID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.put(apiUrl, data, {headers} )
            return response
        } catch (error){
            throw error
        }
    },

    deleteIntegrationsCurrenciesService: async (accessToken: string, CurrenciesID: string): Promise<AxiosResponse>  => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.post(apiUrl + CurrenciesID, {headers})
            return response
        } catch (error) {
            throw error
        }
    }
}

import React from "react"
import axios from "axios";
import {slug} from "../../../config";

/**
 * Offer Fetch Service
 * @param accessToken
 * @param advertCategoryId
 */
export const offerFetchService = async (accessToken:string) => {
    const response =
        await axios.get(slug.client.adverts.url+slug.client.adverts.offers.url, {
            headers:{
                "Content-Type": "application/json",
                'Authorization': 'Bearer '+accessToken.valueOf()
            }
        })
    return response
}

/**
 * Offer Create Service
 * @param accessToken
 * @param advertId
 * @param advertItemId
 */
export const offerCreateService = async (accessToken:string,data:object) => {
    const response =
        await axios.post(slug.client.adverts.url+slug.client.adverts.offers.url, data,{
            headers:{
                "Content-Type": 'application/json',
                "Authorization": 'Bearer '+accessToken.valueOf()
            }
        })
    return response
}

export const offerUpdateService = async (accessToken:string, offersId:string)=> {
    const response = await axios.put(
        slug.client.adverts.url + slug.client.adverts.offers.url + "/" + offersId, {
        headers:{
            "Content-Type": 'application/json',
            "Authorization":  'Bearer '+accessToken.valueOf()
        }
    })
    return response
}


export const offerDeleteService = async (accessToken:string, offersId:string) => {
    const response = await axios.delete(
        slug.client.adverts.url + slug.client.adverts.offers.url + "/" + offersId, {
            headers:{
                "Content-Type":'application/json',
                "Authorization": 'Bearer '+accessToken.valueOf()
            }
        })

    return response
}


import React from "react";
import {useParams, useLocation, Link} from "react-router-dom";

import DevicesCreateForm from "./components/form/devices/devices.create.form";
import NotificationCreateForm from "./components/form/notifications/notification.create.form";

//Adverts Form
import AdvertCreateForm from "./components/form/adverts/advert.create.form";
import AdvertEditForm from "./components/form/adverts/advert.edit.form";
import AdvertCategoryCreateForm from "./components/form/adverts/categories/advert.category.create.form";
import AdvertCategoryEditForm from "./components/form/adverts/categories/advert.category.edit.form";


import WaitingActivityPage from "./pages/reports/waitingActivity/waitingActivity.page";
import ExpeditionComparePage from "./pages/reports/expeditionCompare/expeditionCompare.page";
import UserAccountPage from "./pages/user/user.account.page";
import AdvertItemOfferCreateForm from "./components/form/adverts/offers/advert.offert.create.form";
import { Grid } from "@mui/material";

const AppActionView = () => {
    const { app, model, action } = useParams();
    let location = useLocation();
    return(
        <Grid md={12}>
            <Grid container md={12} sx={{
                marginBottom:"3em"
            }}>
                <Grid md={10} sx={{
                    display:"flex",
                    justifyContent:"flex-start",
                    alignItems:"center"
                }}>

                </Grid>
                <Grid md={2} sx={{
                    display:"flex",
                    justifyContent:"flex-end"
                }}>

                </Grid>
            </Grid>
            {
                app == "reports" && model == "wait-activity" && action == "list" ? <WaitingActivityPage/> : <></> &&

                app == "reports" && model == "expedition-compare" && action == "list" ? <ExpeditionComparePage/> : <></> &&

                app == "adverts" && model == "advert" && action == "create" ? <AdvertCreateForm/> : <></> &&

                app == "adverts" && model == "advert" && action == "edit" ? <AdvertEditForm advertCategoryId={location.state}/> : <></> &&

                app == "adverts" && model == "category" && action == "create" ? <AdvertCategoryCreateForm/>  : <></> &&

                app == "advert" && model == "category" && action == "edit" ? <AdvertCategoryEditForm advertCategoryId={location.state}/> : <></> &&

                app == "devices" && model == "device" && action == "create" ? <DevicesCreateForm/> : <></> &&

                app == "notifications" && model == "push-notification" && action == "create" ? <NotificationCreateForm/> : <></> &&

                app == "account" && model == "user" && action == "info" ? <UserAccountPage/> : <></> &&

                app == "advert" && model == "offer" && action == "create" ? <AdvertItemOfferCreateForm advertId={location.state}/> : <></>
            }
        </Grid>
    )
}

export default AppActionView

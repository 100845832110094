import axios from "axios";
import {slug} from "../../../../config";

export const fetchExpeditionCompare = async (token:string, corporateSiberId:string, branchSiberId:string) => {
    const response = await axios.post(slug.client.report.expedition.compare.list,{company_id:corporateSiberId,
        branch_id:branchSiberId}, {
        headers: {

            'Authorization': 'Bearer '+token.valueOf()
        }
    })
    return response.data
}

export const createWaitReason = (token:string) => {
    const response = axios.post("http://abclojistik.metaws.local:8000/tr/v1.1/reports/expedition-compare/list", {},  {
        headers: {
            'Authorization': 'Bearer '+token.valueOf()
        }
    });
    return response;
}

import axios, { AxiosResponse } from "axios";
import {slug} from "../../../config";
import {IMapsService} from "../../../types/interfaces/services/client/maps/maps.service.interface";
import DateProvider from "../../../utils/dateProvider/dateProvider";

/**
 * Haritalar servisi API'sinin gerçekleştirmesini temsil eder.
 */
const ApiMapsService: IMapsService = {

    /**
     * Belirtilen parametreleri kullanarak konumları getirir.
     *
     * @param extra - İsteğe ilişkin ek parametreler.
     * @param extra.url - İstek için URL.
     * @param extra.accessToken - Kimlik doğrulama için erişim belirteci.
     * @param extra.id - İstek için ID.
     * @returns Axios yanıtını çözen bir promise döner.
     */
    fetchPositions: async (extra: { apiUrl: string; slug:string; accessToken: string; id: string, startTime:string, endTime:string, expedition_no:string }): Promise<AxiosResponse> => {
        const config = {
            params:{
                ...(slug.client.mobiliz.activity.last.url == extra.slug ? {networkId:extra.id} : {vehicle_id: extra.id}),
                ...(slug.client.mobiliz.activity.last.url == extra.slug ? {startTime: extra.startTime.length > 0 ? extra.startTime : {}} :
                    {first_event_time__gte: extra.startTime.length > 0 ? extra.startTime : Object.keys(extra.expedition_no).length > 0 ? {} : DateProvider({daysAgo:1, hours:23,min:59,sec:59,ms:59})}),
                ...(slug.client.mobiliz.activity.last.url == extra.slug ? {endTime: extra.endTime.length > 0 ? extra.endTime : {}} :
                    {last_event_time__lte: extra.endTime.length > 0  ? extra.endTime : Object.keys(extra.expedition_no).length > 0 ? {} : DateProvider({daysAgo:1, hours:23,min:59,sec:59,ms:59})}),
                //...(api.client.mobiliz.activityDetail.url == extra.slug ? {} : {calculate: true}),
                //...(api.client.mobiliz.activityDetail.url == extra.slug ? {} : {only_parents:true}),
                ...(slug.client.mobiliz.activity.last.url == extra.slug ? {} : {expedition_no:extra.expedition_no}),
                //...(api.client.mobiliz.activityDetail.url == extra.slug ? {} : {without_record:false}),
                //...(api.client.mobiliz.activityDetail.url == extra.slug ? {} : {parent__isnull:true}),
            },
            headers:{
                Authorization: `Bearer ${extra.accessToken}`,
            }
        }
        try {
            const response = await axios.get(extra.apiUrl+extra.slug, config)
            return response
        } catch (error) {
            throw error
        }
    }
}

export default ApiMapsService

import React, {useEffect} from "react"
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import {Link} from "react-router-dom";
import {setStorageData, usePersistedState} from "../../../utils/usePersistedState";
import oAuthInitialState from "../../../types/models/storage/auth/oAuth.default.model"
import {Typography} from "@mui/material";

interface IAccountMenu {
    userInfo?: object
}

const AccountMenu = (props: IAccountMenu) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setStorageData("authState", oAuthInitialState, true).then((res:any)=>{
            console.log("ÇIKIŞ YAPILDI", res)
        })
        window.location.href = "/"
    }


    return (
        <React.Fragment>
            <Tooltip title="Account">
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    color="inherit"
                    onClick={handleClick}
                >
                    <Avatar sx={{
                        height: "30px",
                        width: "30px",
                        fontSize: "12px"
                    }}>
                        {
                            //@ts-ignore
                            props.userInfo.first_name.substring(0, 1) +
                            //@ts-ignore
                            props.userInfo.last_name.substring(0, 1)
                        }
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem onClick={handleClose}>

                    <Avatar/>
                    <Link style={{
                        textDecoration: "none",
                        color: 'text.primary'
                    }} to={"/account/user/info"}>
                        <Typography sx={{
                            color:"gray",
                            fontSize:"16px"
                        }}>
                            {
                                //@ts-ignore
                                props.userInfo.first_name + " " +
                                //@ts-ignore
                                props.userInfo.last_name
                            }
                        </Typography>
                        <Typography sx={{
                            color:"gray",
                            fontSize:"12px"
                        }}>
                            {
                                //@ts-ignore
                                props.userInfo.email
                            }
                        </Typography>
                    </Link>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small"/>
                    </ListItemIcon>
                    Ayarlar
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Oturumu Kapat
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default AccountMenu

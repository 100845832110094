import React, {useEffect} from "react"
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import TextField from "@mui/material/TextField";
import {useAppSelector} from "../../store";
import Container from "@mui/material/Container";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const UserAccountPage = () => {
    const userInfo = useAppSelector((state) => state.user);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Container maxWidth="xl" sx={{
            mt: 4,
            mb: 4,
        }}>
            <Box>
                <Grid md={12}>
                    <Grid
                        item
                        xs={false} sm={false} md={12}
                        sx={{
                            backgroundImage: "linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)",
                            height: "237px",
                        }}
                        display={{xs: "none", sm: "block"}}
                    >
                    </Grid>
                    <Grid sx={{
                        height: "300 px",
                    }}
                    >
                        <Card>
                            <CardContent>
                                <Grid container>
                                    <Grid
                                        md={2}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Avatar
                                            style={{
                                                width: 150,
                                                height: 150,
                                                position: "relative",
                                            }} src="/static/images/avatar/1.jpg"/>
                                    </Grid>
                                    <Grid
                                        md={8}
                                    >
                                        <Typography style={{
                                            fontWeight: "bold",
                                            fontSize: 20,
                                        }}>
                                            {
                                                //@ts-ignore
                                                userInfo.data.first_name + " " +userInfo.data.last_name
                                            }
                                        </Typography>

                                    </Grid>

                                    <Grid
                                        md={2}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}>
                                    </Grid>
                                </Grid>
                                <Box sx={{borderBottom: 1, borderColor: 'diveder'}}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab
                                            style={{fontWeight: "bold"}}
                                            label="PROFİL" {...a11yProps(0)}
                                            icon={<AccountCircleOutlinedIcon/>} iconPosition="start"
                                        />

                                        <Tab style={{fontWeight: "bold"}}
                                             label="FİRMA BİLGİLERİ"
                                             {...a11yProps(2)}
                                             icon={<BusinessOutlinedIcon/>} iconPosition="start"
                                        />


                                    </Tabs>
                                </Box>

                                <TabPanel index={0} value={value}>
                                    <Grid container>
                                        <Grid item md={6} sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>

                                            <TextField
                                                disabled
                                                id="outlined-disabled"
                                                label="Ad-Soyad"
                                                defaultValue={
                                                    //@ts-ignore
                                                    userInfo.data?.first_name + " " +userInfo.data?.last_name
                                                }
                                                sx={{
                                                    width: 350,
                                                    marginBottom: 5,
                                                    color: "black",
                                                    input: {color: 'black'}
                                                }}/>
                                            <TextField
                                                disabled
                                                id="outlined-disabled"
                                                label="E-posta"
                                                defaultValue={
                                                    //@ts-ignore
                                                    userInfo.data?.email
                                                }
                                                sx={{
                                                    width: 350,
                                                    marginBottom: 5,
                                                    color: "black",
                                                    input: {color: 'black'}
                                                }}/>
                                            <TextField
                                                disabled
                                                id="outlined-disabled"
                                                label="Doğum Tarihi"
                                                defaultValue={
                                                    //@ts-ignore
                                                    userInfo.data?.profile?.birth_date
                                                }
                                                sx={{
                                                    width: 350,
                                                    marginBottom: 5,
                                                    color: "black",
                                                    input: {color: 'black'}
                                                }}/>
                                        </Grid>
                                        <Grid item md={6} sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>
                                            <TextField
                                                disabled
                                                id="outlined-disabled"
                                                label="Pozisyon"
                                                defaultValue={
                                                    //@ts-ignore
                                                    userInfo.data?.work_profile?.title
                                                }
                                                sx={{
                                                    width: 350,
                                                    marginBottom: 5,
                                                    color: "black",
                                                    input: {color: 'black'}
                                                }}/>
                                            <TextField
                                                disabled
                                                id="outlined-disabled"
                                                label="İş Telefonu"
                                                defaultValue={
                                                    //@ts-ignore
                                                    userInfo.data?.profile?.gsm
                                                }
                                                sx={{
                                                    width: 350,
                                                    marginBottom: 5,
                                                    color: "black",
                                                    input: {color: 'black'}
                                                }}/>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel index={1} value={value}>
                                    <Grid container>
                                        <Grid item md={6} sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>

                                            <TextField
                                                disabled
                                                id="outlined-disabled"
                                                label="Firma"
                                                defaultValue={
                                                    //@ts-ignore
                                                    userInfo.data?.work_profile?.corporate != null ?
                                                        //@ts-ignore
                                                        userInfo.data?.work_profile?.corporate?.name : userInfo.data?.work_profile?.company?.name
                                                }
                                                sx={{
                                                    width: 350,
                                                    marginBottom: 5,
                                                    color: "black",
                                                    input: {color: 'black'}
                                                }}/>
                                            <TextField
                                                disabled
                                                id="outlined-disabled"
                                                label="Departman"
                                                defaultValue={
                                                    //@ts-ignore
                                                    userInfo.data?.work_profile?.department != null ?
                                                        //@ts-ignore
                                                        userInfo.data?.work_profile?.department : ""
                                                }
                                                sx={{
                                                    width: 350,
                                                    marginBottom: 5,
                                                    color: "black",
                                                    input: {color: 'black'}
                                                }}/>
                                            <TextField
                                                disabled
                                                id="outlined-disabled"
                                                label="Adres"
                                                defaultValue={""}
                                                sx={{
                                                    width: 500,
                                                    marginBottom: 5,
                                                    color: "black",
                                                    input: {color: 'black'}
                                                }}/>
                                        </Grid>
                                        <Grid item md={6} sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>

                                            <TextField
                                                disabled
                                                id="outlined-disabled"
                                                label="Telefon"
                                                defaultValue={""}
                                                sx={{
                                                    width: 350,
                                                    marginBottom: 5,
                                                    color: "black",
                                                    input: {color: 'black'}
                                                }}/>

                                        </Grid>
                                    </Grid>
                                </TabPanel>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}


export default UserAccountPage

import React, { useEffect, useState } from "react"
import { Card, CardContent, Grid, Typography } from "@mui/material";
import PieChartComponent from "../../../../charts/pie/pie.chart";
import { useTheme } from "@mui/material/styles";
import { getStorageData } from "../../../../../utils/usePersistedState";
import QuickDataService from "../../../../../services/client/reports/quickData/quickData.service";

type IBalance = {
    currency?: string,
    debt?: string,
    receive?: string,
    balance?: string,
    overdue_balance?: string
}

const CustomerBalanceCard = () => {

    const theme = useTheme();

    const [balance, setBalance] = useState<IBalance>()

    useEffect(() => {
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                QuickDataService.fetchCompanyBalance({ apiUrl: api.url, accessToken: auth.status.access_token }).then((balance) => {
                    setBalance({
                        balance: balance.data[0].balance,
                        overdue_balance: balance.data[0].overdue_balance,
                        currency: balance.data[0].currency
                    })
                })
            })
        })
    }, []);
    return (
        <Card sx={{
            margin: "1rem",
            maxHeight: "245px",
            boxShadow: "none",
        }}>
            <CardContent>
                <Grid container md={12}>
                    <Grid md={4}>
                        <Grid md={12}>
                            <Typography sx={{
                                fontSize: "25px",
                                fontWeight: 600,
                                color: `${theme.palette.text.secondary}`,
                            }}>
                                Ödemeler
                            </Typography>
                            <Typography sx={{
                                fontSize: "14px",
                                color: `${theme.palette.info.main}`,
                            }}>
                                Mevcut Bakiye : {balance ? Number(balance?.balance).toFixed(1) + " " + balance?.currency : "-"}
                            </Typography>
                            <Typography sx={{
                                fontSize: "14px",
                                fontWeight: 600
                            }}>
                                Vadesi Geçmiş: {balance ? balance?.overdue_balance + " " + balance?.currency : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid md={8}>
                        <PieChartComponent
                            data={[
                                { id: 1, value: balance?.overdue_balance, label: 'Vadesi Geçmiş' },
                                { id: 0, value: balance?.balance, label: ' Mevcut Bakiye' }
                            ]}
                            series={{
                                innerRadius: 60,
                                outerRadius: 100,
                                cx: 100,
                                cy: 100,
                                cornerRadius: 0,
                                startAngle: 0,
                                endAngle: 360,
                                paddingAngle: 0,
                            }}
                            legend={{
                                hidden: false,
                                position: {
                                    vertical: "middle",
                                    horizontal: "middle"
                                },
                                direction: "column",
                                offset: {
                                    x: 40,
                                    y: -40
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default CustomerBalanceCard

import {getStorageData} from "../../../../utils/usePersistedState";
import ApiBookingVehiclesService from "../../../../services/client/bookings/booking.vehicles.service";
import {Stack, Box, Button, Typography, Modal, Chip} from '@mui/material';
import {useAppDispatch} from "../../../../store";

import {openSnackbar} from "../../../../reducers/snackbar.slice";
import {
    autoHideDuration,
    horizontal,
    severity,
    vertical
} from "../../../../types/enums/components/snackbar/snackbar.enums";
import * as React from "react";
import {useTheme} from "@mui/material/styles";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface IAddUserApproveModal {
    companyId:string,
    vehicleId:string,
    vehiclePlate:string,
    defaultCompany:string,
    defaultCompanyId:string
    updateCompany:string
    refreshing:()=>void
}

const AddUserApproveModal = (props:IAddUserApproveModal) => {
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dispatch = useAppDispatch();

    const handleUpdate = () => {

        handleClose()
        getStorageData("authState", true).then((auth) => {
            ApiBookingVehiclesService.addCompany({
                //@ts-ignore
                accessToken: auth.status.access_token,
                companyId: props.companyId,
                vehicleId: props.vehicleId
            }).then((res)=>{
                props.refreshing()
                if (res.status === 200) {
                    dispatch(openSnackbar(
                        {
                            message:"Firma bilgisi güncellendi.",
                            severity:severity.success,
                            anchorOrigin:{
                                vertical:vertical.bottom,
                                horizontal:horizontal.right
                            },
                            autoHideDuration:autoHideDuration.ms3000,
                            open:true,
                            close:status => console.log(status)
                        }
                    ))
                }
            }).catch((e)=>{
                dispatch(openSnackbar(
                    {
                        message:e.message,
                        severity:severity.error,
                        anchorOrigin:{
                            vertical:vertical.bottom,
                            horizontal:horizontal.right
                        },
                        autoHideDuration:autoHideDuration.ms3000,
                        open:true,
                        close:status => console.log(status)
                    }
                ))
            })
        })
    }

    return (
        <div>
            <Button
                disabled={props.updateCompany.length === 0}
                variant="contained"
                sx={{
                    backgroundColor: "rgba(0, 143, 251, 1)",
                    width: 85,
                    color:`${theme.palette.text.primary}`
                }}
                onClick={handleOpen}
            >Ekle</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            color:`${theme.palette.text.primary}`
                        }}
                    >
                        Onaylıyor musunuz?
                    </Typography>
                    {
                        props.companyId === props.defaultCompanyId ?
                            <Typography id="modal-modal-description" sx={{mt: 2, mb: 2}}>
                                Lütfen farklı bir firma seçiniz. Şu anda aynı firmayı seçtiniz.
                            </Typography> :
                            <Typography id="modal-modal-description" sx={{
                                mt: 2, mb: 2
                            }}>
                                {props.vehiclePlate} plakalı araç firma bilgisi <Chip label={props.updateCompany} color="success" variant="outlined" />  olarak güncelenecektir.
                            </Typography>
                    }

                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                width: 85
                            }}
                            onClick={handleClose}
                        >IPTAL</Button>
                        <Button
                            disabled={props.updateCompany.length === 0}
                            variant="contained"
                            sx={{
                                backgroundColor: "rgba(0, 143, 251, 1)",
                                width: 85,
                                color:`${theme.palette.text.primary}`
                            }}
                            onClick={handleUpdate}
                        >ONAYLA</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    )
}

export default AddUserApproveModal


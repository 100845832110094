import React from "react";
import {LoginForm} from "../../components/form/auth/login.form";

import {ILogin} from "../../types/interfaces/pages/auth/auth.page.interface";

const Login = ({userRoleControl, userPermissionControl}:ILogin) => {
    return (
        <LoginForm
            userRoleControl={userRoleControl}
            userPermissionControl={userPermissionControl}
        />
    )
}

export default Login

import React, {useEffect, useState} from "react"
import {FieldValues, UseFormRegister} from "react-hook-form";
import {getStorageData} from "../../../../../utils/usePersistedState";
import ApiDemandsProductsService from "../../../../../services/client/demands/products/demands.products";
import FormControl from "@mui/material/FormControl";
import {InputLabel, Tooltip} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


interface IWarehouseStocksProductsFormController {
    label:string,
    index:number,
    productId:(id:string) => void,
    register: UseFormRegister<FieldValues>,
}

const WarehouseStocksProductsFormController = (props:IWarehouseStocksProductsFormController) => {

    const [getDemandsProducts, setDemandsProducts] = useState([])

    useEffect(() => {
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                ApiDemandsProductsService.fetchDemandsProducts({apiUrl:api.url,  accessToken: auth.status.access_token }).then((res:any)=> {
                        setDemandsProducts(res.data.results)
                    }
                )
            })
        })
    }, []);

    return(
        <FormControl sx={{width:"100%"}}>
            <InputLabel
                id="demo-simple-select-label"
            >
                {
                    props.label
                }
            </InputLabel>
            <Select
                //@ts-ignore
                ref={props.register}
                //@ts-ignore
                {...props.register((`product`), {required:true})}
            >
                {
                    getDemandsProducts.map((product, index)=>(
                        <MenuItem
                            key={index}
                            value={
                                //@ts-ignore
                                product.id
                            }
                        >
                            {
                                //@ts-ignore
                                product.name
                            }
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default WarehouseStocksProductsFormController

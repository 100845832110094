import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { getStorageData } from "../../utils/usePersistedState";
import { ApiWaitingActivityService } from "../../services/client/reports/waitingActivity/waitingActivity.service";
import { Box, InputAdornment, Stack, Button } from "@mui/material";
import DateRangePickerComponent from "../datepicker/datePickers";
import { useTheme } from "@mui/material/styles";
import { Card, CardHeader, Grid, Tooltip, Typography } from "@mui/material"
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import { trTR } from "@mui/x-data-grid";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

interface IWaitingActivityQuick {
    filter: {
        first_event_time: string
        last_evenet_time: string
    }
}

export default function WaitingActivityList() {
    const theme = useTheme();

    const [loading, setLoading] = useState<boolean>(true)
    const [waitingActivity, setWaitingActivity] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [firstTime, setFirstTime] = useState("")
    const [lastTime, setLastTime] = useState("")
    const [statusId, setStatusId] = useState<number>()
    const [doSearch, setDoSearch] = useState<boolean>(false)
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [searchInput, setSearchInput] = useState('');


    const handleClickDateRangePicker = (firstTime: string, lastTime: string) => {
        setFirstTime(firstTime)
        setLastTime(lastTime)
    }
    useEffect(() => {
        if (searchInput == "") {
            setDoSearch(false)
            setLoading(true)
            getStorageData("authState", true).then(auth => {
                getStorageData("api", false).then(api => {
                    ApiWaitingActivityService.fetchWaitingActivityQuick({
                        //@ts-ignore
                        apiUrl: api.url,
                        //@ts-ignore
                        accessToken: auth.status.access_token,
                        firstEventTime: firstTime,
                        lastEventTime: lastTime,
                        //@ts-ignore
                        statusId: statusId,
                        search: searchInput,
                        offset: (paginationModel.page * paginationModel.pageSize),
                        limit: paginationModel.pageSize
                    }).then((res:any) => {
                        //setWaitingActivity(res.data.filter((waiting: any) => waiting.total_passing_time >= 2))
                        const dataWithIds = res.data.results.map((item: any, index: number) => ({
                            ...item,
                            id: index + 1, // Generate a unique id for each row (you can use a different logic if needed)
                        }));
                        setWaitingActivity(dataWithIds)
                        setLoading(false)
                        setTotalCount(res.data.count)
                        setLoading(false)
                        console.log(dataWithIds)
                    })
                })
            })
        } else {
            getStorageData("authState", true).then(auth => {
                getStorageData("api", false).then(api => {
                    //@ts-ignore
                    ApiWaitingActivityService.fetchWaitingActivityQuick({
                        //@ts-ignore
                        apiUrl: api.url,
                        //@ts-ignore
                        accessToken: auth.status.access_token,
                        //@ts-ignore
                        search: searchInput,
                    }).then((res:any) => {
                        //setWaitingActivity(res.data.filter((waiting: any) => waiting.total_passing_time >= 2))
                        const dataWithIds = res.data.results.map((item: any, index: number) => ({
                            ...item,
                            id: index + 1, // Generate a unique id for each row (you can use a different logic if needed)
                        }));
                        setWaitingActivity(dataWithIds)
                        setLoading(false)
                        setTotalCount(res.data.count)
                        setLoading(false)
                    })
                })
            })
        }
    }, [paginationModel, lastTime, searchInput])

    const handlePlateRedirect = (params: any) => (
        <div>
            <Button
                onClick={() => console.log(params.value)}
                style={{ background: 'none', border: 'none', cursor: 'pointer', display: "flex", alignItems: 'center', }}
            > {params.value}
            </Button>
        </div>
    );

    function EditToolbar() {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <GridToolbar />
                <Tooltip title="Burası açıklama bölümü olacak">
                    <HelpCenterIcon style={{ fontSize: 30, margin: "10px" }} />
                </Tooltip>
            </div>
        );
    }

    const columns = useMemo(() => [
        {
            field: 'expedition_no',
            headerName: 'Sefer No',
            width: 150,
        },
        {
            field: 'plate',
            headerName: 'Plaka',
            width: 150,
            renderCell: handlePlateRedirect,
        },
        {
            field: 'total_passing_time',
            headerName: 'Beklediği Gün',
            width: 180,
        },
        {
            field: 'status_name',
            headerName: 'Durumu',
            width: 230,
        },

    ], [])
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            console.log(event.target.value)
        }
    }

    return (
        <Grid md={12} sx={{
            backgroundColor: "transparent",
            borderColor: "transparent",
            boxShadow: "none"
        }}>
            <Card sx={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                boxShadow: "none"
            }}>
                <CardHeader sx={{
                    padding: "16px 16px 0 16px"
                }} title={
                    <Grid container md={12}>
                        <Grid md={2} sx={{
                            display: "flex",
                            alignItems: "center",
                        }}>

                            <Typography sx={{
                                fontSize: "18px",
                                fontWeight: 600
                            }}>
                                Araç Beklemelerii
                            </Typography>
                        </Grid>
                        <Grid md={10} sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent:"flex-end",
                            alignItems:"center"
                        }}>
                            <Stack direction="row" spacing={1} sx={{
                                '& .MuiFormLabel-root': {
                                    fontSize: "12px",
                                    fontWeight: 600
                                },
                            }}>

                                <Box sx={{ display: 'flex', alignItems: 'center', p: '10px' }}>
                                    <TextField
                                        id="outlined-controlled"
                                        size="small"
                                        value={searchInput}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setSearchInput(event.target.value);
                                        }}
                                        placeholder='Arama'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon sx={{ color: 'grey' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <DateRangePickerComponent
                                        event={(firstTime, lastTime) => handleClickDateRangePicker(firstTime, lastTime)}
                                    // defaultValueDateRange={defaultValueDateRange}
                                    />
                                </Box>

                            </Stack>
                        </Grid>
                    </Grid>
                } />
                <Card >

                    <Box sx={{ height: "calc(100vh - 145px)", width: '100%' }}>
                        <DataGridPremium

                            sx={{
                                borderRadius: "10px 10px 0px 0px",
                                backgroundColor: `${theme.palette.background.default}`,
                                '& .MuiDataGrid-virtualScroller': {
                                    overflow: 'auto',
                                    '&::-webkit-scrollbar': { height: 10, WebkitAppearance: 'none', width: 10 },
                                    '&::-webkit-scrollbar-thumb': {
                                        borderRadius: 8,
                                        border: '1px solid',
                                        borderColor: 'white',
                                        backgroundColor: 'rgba(0 0 0 / 0.5)',
                                    },
                                },

                            }}
                            rows={waitingActivity}
                            //@ts-ignore
                            columns={columns}
                            slots={{
                                toolbar: EditToolbar,
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'total_passing_time', sort: 'desc' }]
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        load_no: false
                                    },
                                },

                            }}
                            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                            pagination
                            pageSizeOptions={[50, 100, 150, 200]}
                            paginationMode="server"
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            loading={loading}
                            rowCount={totalCount}
                        />
                    </Box>
                </Card >
            </Card >
        </Grid >
    );
}

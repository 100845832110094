import axios, {AxiosResponse} from "axios";

interface IDFDS {
    //tes
}

type CreateDiscountsParams = {
    accessToken: string;
    payload: object;
};

interface IDFDSService {
    fetchDiscounts( params:{
        apiUrl:string,
        accessToken:string,
        period:string,
        search:string,
        offset:number,
    }):Promise<AxiosResponse>;
    createDiscounts( params:CreateDiscountsParams):Promise<AxiosResponse>;
    fetchPeriods( params:{ apiUrl:string, accessToken:string }):Promise<AxiosResponse>

    fetchCompanies(params:{
        accessToken:string,
        search?:string,
        offset?:number,
        limit?:number,
    }):Promise<AxiosResponse>;

    fetchVehicles(params:{
        accessToken:string,
        offset:number,
        limit:number,
        search:string,
        isActive?:string,
        companyIsNull?:string
    }):Promise<AxiosResponse>;

    fetchBookings(params:{
        accessToken:string,
        search:string,
        offset?: number,
        limit?: number,
    }):Promise<AxiosResponse>;

    fetchActiveCompanyCount(params:{
        accessToken:string,
        before_period:string,
        last_period:string
    }):Promise<AxiosResponse>;

    fetchBookingRatioByCompany(params:{
        accessToken:string,
        offset: number;
        limit: number;
        before_period:string;
        last_period:string;
        search:string;
    }):Promise<AxiosResponse>;

    fetchBookingRatioByRoute(params:{
        accessToken:string;
        before_period:string;
        last_period:string;
    }):Promise<AxiosResponse>

    fetchBookingsSold(params:{
        accessToken:string;
        before_period:string;
        last_period:string;
    }):Promise<AxiosResponse>

    fetchBookingsTotal(params:{
        accessToken:string;
        before_period:string;
        last_period:string;
    }):Promise<AxiosResponse>

    fetchBookingsUsed(params:{
        accessToken:string;
        before_period:string;
        last_period:string;
    }):Promise<AxiosResponse>

    fetchBookingsNotMatched(params:{
        accessToken:string;
        before_period:string;
        last_period:string;
    }):Promise<AxiosResponse>

    fetchBookingsCanceled(params:{
        accessToken:string;
        before_period:string;
        last_period:string;
    }):Promise<AxiosResponse>

    fetchBookingsTrain(params:{
        accessToken:string;
        before_period:string;
        last_period:string;
    }):Promise<AxiosResponse>
}

const ApiDFDSService: IDFDSService = {
    fetchDiscounts: async (params: {
        apiUrl:string,
        accessToken:string,
        period:string,
        search:string,
    }): Promise<AxiosResponse> => {
        const config = {
            params:{
                ...(params.period ? { period: params.period } : {}),
                ...(params.search ? { search: params.search } : {}),
            },
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            }
        }

        try {
            const response = await axios.get("https://booking.abclojistik.com/en/discounts/", config).then((res:any)=>{
                return res.data
            })
            return response
        }
        catch (e){
            throw e
        }
    },

    createDiscounts: async (params: CreateDiscountsParams): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            }
        }
        try {
            return await axios.post("https://booking.abclojistik.com/en/discounts/", params.payload, config)
        }
        catch (e) {
            throw e
        }
    },

    fetchPeriods: async (params: { accessToken: string }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/tr/bookings/periods", config).then((res) => {
                return res.data
            })
        }
        catch (e) {
            throw e
        }
    },

    fetchCompanies : async ( params: {
        accessToken: string,
        search?:string,
        offset?:number,
        limit?:number
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.search ? { search: params.search } : {}),
                ...(params.offset ? { offset: params.offset } : {}),
                limit: params.limit ? params.limit : {},
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/companies", config).then((res) => {
                return res.data
            })
        }
        catch (e) {
            throw e
        }
    },

    fetchVehicles: async (params: {
        accessToken:string,
        offset:number,
        limit:number,
        search:string,
        isActive:string,
        companyIsNull:string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.offset ? { offset: params.offset } : {}),
                limit: params.limit ? params.limit : {},
                ...(params.search ? { search: params.search } : {}),
                ...(params.isActive ? { is_active: params.isActive } : {}),
                ...(params.companyIsNull ? { vehicle_company__isnull: params.companyIsNull } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/vehicles", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e) {
            throw e
        }
    },

    fetchBookings: async (params: {
        accessToken: string,
        search:string,
        offset?: number,
        limit?: number,
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                before_period:"2023-11",
                last_period:"2023-12",
                ...(params.search ? { search: params.search } : {}),
                ...(params.offset ? { offset: params.offset } : {}),
                limit: params.limit ? params.limit : {},
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/bookings", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e){
            throw e
        }
    },

    fetchActiveCompanyCount: async (params: {
        accessToken: string,
        before_period:string,
        last_period:string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.before_period ? { before_period: params.before_period } : {}),
                ...(params.last_period ? { last_period: params.last_period } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/dashboards/active-company-count", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e){
            throw e
        }
    },

    fetchBookingsSold: async (params: {
        accessToken: string,
        before_period:string,
        last_period:string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.before_period ? { before_period: params.before_period } : {}),
                ...(params.last_period ? { last_period: params.last_period } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/dashboards/bookings-sold", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e){
            throw e
        }
    },

    fetchBookingsTotal: async (params: {
        accessToken: string,
        before_period:string,
        last_period:string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.before_period ? { before_period: params.before_period } : {}),
                ...(params.last_period ? { last_period: params.last_period } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/dashboards/bookings-total", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e){
            throw e
        }
    },

    fetchBookingRatioByRoute: async (params: {
        accessToken: string,
        before_period:string,
        last_period:string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.before_period ? { before_period: params.before_period } : {}),
                ...(params.last_period ? { last_period: params.last_period } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/dashboards/booking-ratio-by-route", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e){
            throw e
        }
    },

    fetchBookingRatioByCompany: async (params: {
        accessToken: string,
        offset: number,
        limit: number,
        search:string,
        before_period:string;
        last_period:string;
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.before_period ? { before_period: params.before_period } : {}),
                ...(params.last_period ? { last_period: params.last_period } : {}),
                ...(params.offset ? { offset: params.offset } : {}),
                limit: 100,
                ...(params.search ? { search: params.search } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/dashboards/booking-ratio-by-company", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e) {
            throw e
        }
    },

    fetchBookingsUsed: async (params: {
        accessToken: string;
        before_period: string;
        last_period: string
    }): Promise<AxiosResponse>  => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.before_period ? { before_period: params.before_period } : {}),
                ...(params.last_period ? { last_period: params.last_period } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/dashboards/bookings-used", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e) {
            throw e
        }
    },

    fetchBookingsNotMatched: async (params: {
        accessToken: string;
        before_period: string;
        last_period: string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.before_period ? { before_period: params.before_period } : {}),
                ...(params.last_period ? { last_period: params.last_period } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/dashboards/bookings-not-matched", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e) {
            throw e
        }
    },

    fetchBookingsCanceled: async (params: {
        accessToken: string;
        before_period: string;
        last_period: string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.before_period ? { before_period: params.before_period } : {}),
                ...(params.last_period ? { last_period: params.last_period } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/dashboards/bookings-canceled", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e){
            throw e
        }
    },

    fetchBookingsTrain: async (params: {
        accessToken: string;
        before_period: string;
        last_period: string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                'Authorization': `Bearer ${params.accessToken}`
            },
            params:{
                ...(params.before_period ? { before_period: params.before_period } : {}),
                ...(params.last_period ? { last_period: params.last_period } : {}),
            }
        }
        try {
            return await axios.get("https://booking.abclojistik.com/en/dashboards/bookings-train", config).then((res:any)=>{
                return res.data
            })
        }
        catch (e){
            throw e
        }
    }
}

export default ApiDFDSService

import React from "react"
import Container from "@mui/material/Container";
import {Grid, IconButton, Tooltip} from "@mui/material";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {EWarehousesAction} from "../../../types/enums/pages/warehouses/warehouses.page.enum";

interface IWarehousesBarNavigation {
    warehouses:(action:EWarehousesAction)=>void
}


const WarehousesBarNavigation = (props:IWarehousesBarNavigation) => {
    return(
        <Container maxWidth="xl">
            <Grid md={6}>

            </Grid>
            <Grid md={6} display="flex" flexDirection="row-reverse">
                <Tooltip title={"Depo Oluştur"}>
                    <IconButton onClick={()=>props.warehouses(EWarehousesAction.Show)}>
                        <AddHomeOutlinedIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Ürün Oluştur"}>
                    <IconButton>
                        <AddShoppingCartIcon/>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Container>
    )
}

export default WarehousesBarNavigation

import axios, {AxiosResponse} from "axios";
import {slug} from "../../../config";

interface ILoadsService {
    fetchLoadsService(extra: {
        url:string,
        accessToken: string,
        search?:string,
        offset?: number,
        limit?: string,
        loadID?:string,
        ordering?:string,
        load_type?:number
    }): Promise<AxiosResponse>
}

const apiSlug= slug.client.loads.url

const ApiLoadsService: ILoadsService = {
    fetchLoadsService: async (extra: {
        url:string,
        accessToken: string,
        search?:string,
        offset?: number,
        limit?: string,
        loadID?:string,
        ordering?:string,
        load_type?:number
    }): Promise<AxiosResponse> => {
        const config = {
            params:{
                ...(extra.offset ? {offset:extra.offset } : {}),
                //...(extra.limit ? {limit:extra.limit} : {}),
                limit:50,
                ...(extra.loadID ? {} : extra.load_type ? {load_type:extra.load_type} : {load_type:0}),
                ...(extra.search ? {search:extra.search} : {}),
                ...(extra.ordering ? {ordering:extra.ordering} : {}),
                ...(extra.loadID ? {} : {is_completed:false} ),

            },
            headers: {
                'Authorization': `Bearer ${extra.accessToken}`
            }
        }

        try {
            if (extra.loadID != null){
                const response= await axios.get(extra.url+apiSlug+extra.loadID, config);
                return response
            }
            else {
                const response= await axios.get(extra.url+apiSlug, config);
                return response
            }
        }
        catch (error) {
            throw error
        }
    }

}

export default ApiLoadsService


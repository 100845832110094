import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import { IExpeditionsState, IExpeditions } from "../types/interfaces/reducers/expeditions/expeditions.interface";
import {ApiExpeditionsService} from "../services/client/expeditions/expeditions.service.new";

const initialState: IExpeditionsState = {
    //@ts-ignore
    data: null,
    loading:true,
    error:""
}

const expeditionsSlice = createSlice({
    name:"expeditions",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        //@ts-ignore
        builder.addCase(ApiExpeditionsService.fetchExpeditions.pending, (state) => {
            state.loading = true; // Update loading state
        });
        //@ts-ignore
        builder.addCase(ApiExpeditionsService.fetchExpeditions.fulfilled, (state, action:PayloadAction<IExpeditions>) => {
            state.loading = false; // Update loading state when fulfilled
            state.data = action.payload
        });
        //@ts-ignore
        builder.addCase(ApiExpeditionsService.fetchExpeditions.rejected, (state, action) => {
            state.loading = false; // Update loading state when rejected
            state.error = action.error
            // Handle rejection if needed
        });
    }
})

export default expeditionsSlice.reducer

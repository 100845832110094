import React from "react"
import axios, {AxiosResponse} from "axios";

import {slug} from "../../../config"

interface IntegrationsDueOptionsService {
    apiUrl?:string
    fetchIntegrationsDueOptionsService(accessToken: string): Promise<AxiosResponse>;
    createIntegrationsDueOptionsService(accessToken: string, data: any): Promise<AxiosResponse>;
    updateIntegrationsDueOptionsService(accessToken: string, data: any, DueOptionID: string): Promise<AxiosResponse>;
    deleteIntegrationsDueOptionsService(accessToken: string, DueOptionID: string): Promise<AxiosResponse>;
}

const apiUrl = slug.client.integrations.dueOptions.url


export const ApiIntegrationsDueOptionsService: IntegrationsDueOptionsService = {

    fetchIntegrationsDueOptionsService: async (accessToken: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };
        try {
            const response = await axios.get(apiUrl, {headers})
            return response
        } catch (error) {
            throw error
        }
    },

    createIntegrationsDueOptionsService: async (accessToken: string, data: any): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };
        try {
            const response = await axios.post(apiUrl, data, {headers})
            return response
        } catch (error) {
            throw error
        }
    },
    updateIntegrationsDueOptionsService: async (accessToken: string, data: any, DueOptionID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };
        try {
            const response = await axios.put(apiUrl+DueOptionID, data, {headers})
            return response
        } catch (error) {
            throw error
        }
    },
    deleteIntegrationsDueOptionsService: async (accessToken: string, DueOptionID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };
        try {
            const response = await axios.delete(apiUrl+DueOptionID, {headers})
            return response
        } catch (error) {
            throw error
        }
    }
}



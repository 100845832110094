import React from "react"
import {Stack, Typography, Divider, Skeleton} from "@mui/material";

import {IoMdTrendingUp} from "react-icons/io";
import {IoTrendingDown} from "react-icons/io5";

interface IBookingRatioByRouteCard {
    index: number
    last_period_bookings_total:number,
    increase_decrease_rate:number,
    main_route: {
        route: string,
        last_period_bookings_total: number,
        increase_decrease_rate: number
    }
    linked_route: {
        route: string,
        last_period_bookings_total: number,
        increase_decrease_rate: number
    }
}

export const BookingRatioByRouteCard = (props: IBookingRatioByRouteCard) => {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={5}
            key={props.index}
            sx={{
                marginRight: "2rem"
            }}
        >
            <Stack
                direction="column"
                sx={{
                    border: '1px solid',
                    borderColor: 'rgba(217, 217, 217, 1)',
                    borderRadius: "10px",
                    padding: 1,
                    width: "320px",
                    height: "auto"
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Typography sx={{
                            fontSize: "12px",
                            fontWeight: 700,
                            lineHeight: "12px",
                            color: "#7A7A7A",
                        }}>
                            {props.main_route.route}
                        </Typography>
                        <Typography sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "14px",
                        }}>
                            {props.main_route.last_period_bookings_total ? props.main_route.last_period_bookings_total : 0} Adet
                        </Typography>
                        <Stack direction="row"
                               justifyContent="center"
                               alignItems="center"
                               spacing={1}>
                            {
                                //@ts-ignore
                                props.main_route.increase_decrease_rate < 0 ?
                                    <IoTrendingDown style={{
                                        fontSize: "18px",
                                        color: "red"
                                    }}/> : <IoMdTrendingUp style={{
                                        fontSize: "18px",
                                        color: "green"
                                    }}/>
                            }
                            <Typography sx={{
                                fontSize: "12px",
                                fontWeight: 600,
                                lineHeight: "28px",
                                //@ts-ignore
                                color: props.main_route.increase_decrease_rate < 0 ? "red" : "green"
                            }}>
                                {
                                    //@ts-ignore
                                    props.main_route.increase_decrease_rate ? props.main_route.increase_decrease_rate?.toString().slice(0, 5) :
                                        //@ts-ignore
                                        0
                                } %
                            </Typography>
                        </Stack>
                    </Stack>
                    <Divider orientation="vertical" variant="middle" flexItem/>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Typography sx={{
                            fontSize: "12px",
                            fontWeight: 700,
                            lineHeight: "14px",
                            color: "#7A7A7A",
                        }}>
                            {props.linked_route.route}
                        </Typography>
                        <Typography sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "14px",
                        }}>
                            {props.linked_route.last_period_bookings_total ? props.linked_route.last_period_bookings_total : 0} Adet
                        </Typography>
                        <Stack direction="row"
                               justifyContent="center"
                               alignItems="center"
                               spacing={1}>
                            {
                                //@ts-ignore
                                props.linked_route.increase_decrease_rate < 0 ?
                                    <IoTrendingDown style={{
                                        fontSize: "18px",
                                        color: "red"
                                    }}/> : <IoMdTrendingUp style={{
                                        fontSize: "18px",
                                        color: "green"
                                    }}/>
                            }
                            <Typography sx={{
                                fontSize: "12px",
                                fontWeight: 600,
                                lineHeight: "28px",
                                //@ts-ignore
                                color: props.linked_route.increase_decrease_rate < 0 ? "red" : "green"
                            }}>
                                {
                                    //@ts-ignore
                                    props.linked_route.increase_decrease_rate ? props.linked_route.increase_decrease_rate?.toString().slice(0, 5) :
                                        //@ts-ignore
                                        0
                                } %
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider orientation="horizontal" variant="middle" flexItem/>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-around"
                >
                    <Typography sx={{
                        fontSize: "12px",
                        fontWeight: 700,
                        lineHeight: "14px",
                        color: "#7A7A7A",
                    }}>
                        TOPLAM
                    </Typography>
                    <Typography sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "14px",
                    }}>
                        {props.last_period_bookings_total ? props.last_period_bookings_total : 0} Adet
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                    >
                        {
                            //@ts-ignore
                            props.increase_decrease_rate < 0 ?
                                <IoTrendingDown style={{
                                    fontSize: "18px",
                                    color: "red"
                                }}/> : <IoMdTrendingUp style={{
                                    fontSize: "18px",
                                    color: "green"
                                }}/>
                        }
                        <Typography sx={{
                            fontSize: "12px",
                            fontWeight: 600,
                            lineHeight: "28px",
                            //@ts-ignore
                            color: props.increase_decrease_rate < 0 ? "red" : "green"
                        }}>
                            {
                                //@ts-ignore
                                props.increase_decrease_rate ? props.increase_decrease_rate?.toString().slice(0, 5) :
                                    //@ts-ignore
                                    0
                            } %
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default BookingRatioByRouteCard

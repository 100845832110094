import React from "react";
import {useAppSelector} from "../../store";
import {UserRoles} from "../../types/enums/reducers/user.enums";
import DashboardCustomer from "./dashboard.customer";
import DashboardAdmin from "./dashboard.admin";


const DashboardPage = () => {
    const userInfo = useAppSelector((state) => state.user);
    //@ts-ignore
    switch (userInfo.data.role) {
        case UserRoles.EMPLOYEE: {
            return (
                <DashboardAdmin/>
            )
        }
        case UserRoles.CUSTOMER: {
            return (
                <DashboardCustomer/>
            )
        }
        case UserRoles.ADMIN: {
            return (
               <DashboardAdmin/>
            )
        }
        case UserRoles.MANAGER:{
            return (
                <DashboardAdmin/>
            )
        }
        case UserRoles.DEVELOPER: {
            return (
                <DashboardAdmin/>
            )
        }
        default: {
            return (
               <></>
            )
        }
    }
}

export default DashboardPage

import React, {useEffect, useState} from "react";
import {MenuItem} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
interface ToggleOption {
    label: string;
    positiveValue: string;
    negativeValue: string;
    isActive: boolean;
}


interface IExpeditionSortingSelector {
    fallback:(status:string) => void,
    sortingOptions: ToggleOption[];
}


const ExpeditionSortingSelector = (props:IExpeditionSortingSelector) => {

    const [toggleOptions, setToggleOptions] = useState<ToggleOption[]>(props.sortingOptions);
    const [value, setValue] = useState<string>("");

    const toggleOption = (index: number) => {
        setToggleOptions((prevOptions) => {
            const updatedOptions = prevOptions.map((option, i) => {
                if (i === index) {
                    return {
                        ...option,
                        isActive: !option.isActive
                    };
                }
                return option;
            });
            setValue(
                updatedOptions[index].isActive
                    ? updatedOptions[index].negativeValue
                    : updatedOptions[index].positiveValue
            );
            return updatedOptions;
        });
    };

    useEffect(()=>{
        if (value != ""){
            props.fallback(value)
        }
    }, [value])


    return (
      <div>
        {toggleOptions.map((option, index) => (
            <MenuItem key={index} onClick={() => toggleOption(index)}>
                {option.isActive ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}
                {option.isActive ? option.label : option.label}
            </MenuItem>
        ))}
      </div>
    );

};

export default ExpeditionSortingSelector;

import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGridPremium, GridToolbar} from '@mui/x-data-grid-premium';
import {useEffect, useMemo, useState} from "react";
import {dateFormatting} from "../../../utils/dateProvider/dateFormatting";
import ApiExpeditionsService from "../../../services/client/expeditions/expeditions.service";
import {getStorageData} from "../../../utils/usePersistedState";
import {Chip, Tooltip, useTheme} from '@mui/material';
import {GridRowId, GridToolbarContainer, trTR} from "@mui/x-data-grid";
import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import SearchBar from "../../form/controllers/search.input";


interface SubRowData {
    id: number;
    load_no: string;
    truck: string;
    trailer: string,
    start_date: string,
}



export default function ExpeditionsGrid() {
    const theme = useTheme();

    const [expeditions, setExpeditions] = useState([]);

    const [selectedRow, setSelectedRow] = useState<GridRowId | null>(null);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const [expeditionsOffset, setExpeditionsOffset] = useState(10);
    const [offset, setOffset] = useState(50);

    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const handleRowClick = (params: any) => {

        if (params.id === selectedRow) {
            setSelectedRow(null); // Kapatmak için seçili satıra tekrar tıklanırsa seçim iptal edilir
        } else {
            setSelectedRow(params.id);
        }
    };

    const renderRows = () => {

        const renderedRows: SubRowData[] = [];

        expeditions.forEach((value, index, array) => {
            renderedRows.push(value);
            // Seçili satırın altına bir alt satır eklenir
            //@ts-ignore
            if (selectedRow === value.id) {
                //@ts-ignore
                value.loads.map((res: any) => {
                    //@ts-ignore
                    const newId = res.id // Geçici bir formülle yeni bir benzersiz ID oluşturulabilir
                    const newSubRow: SubRowData = {id: newId, load_no: res.load_no, truck: res.truck, trailer: res.trailer, start_date: res.load_date};
                    renderedRows.push(newSubRow);
                })
                //@ts-ignore
            }
        });
        return renderedRows;
    };

    useEffect(() => {

        const {page, pageSize} = paginationModel;

        if (page == 0 && expeditionsOffset == 10) {
            setDataLoading(true)
            getStorageData("authState", true).then((auth) => {
                getStorageData("api", false).then((api) => {
                    //@ts-ignore
                    ApiExpeditionsService.fetchExpeditions({
                        //@ts-ignore
                        apiUrl: api.url,
                        //@ts-ignore
                        accessToken: auth.status.access_token
                    }).then((res:any) => {
                        //@ts-ignore
                        setExpeditions(res.data.results)
                        setDataLoading(false)
                    })
                })
            })
        }
        if ((page + 1) * pageSize === offset) {
            console.log((page + 1) * pageSize, "AMAAANN")
            console.log(offset)
            setDataLoading(true);
            setOffset(offset + 50);
            getStorageData("authState", true).then((auth) => {
                getStorageData("api", false).then((api) => {
                    //@ts-ignore
                    ApiExpeditionsService.fetchExpeditions({
                        //@ts-ignore
                        apiUrl: api.url,
                        //@ts-ignore
                        accessToken: auth.status.access_token,
                        offset: offset + 50
                    }).then((res:any) => {
                        //@ts-ignore
                        setExpeditions(prevExpeditions => [...prevExpeditions, ...res.data.results]);
                        setDataLoading(false)
                    })
                })
            })
        }
    }, [paginationModel]);


    const columns = useMemo(() => [
        {
            field: 'position_no',
            headerName: 'Sefer No',
            width: 150,
        },
        {
            field: 'truck',
            headerName: 'Çekici Plaka',
            width: 150,
            valueFormatter: (params: any) => params.value?.plate ? params.value.plate : "Bilgi bulunamadı!"
        },
        {
            field: 'trailer',
            headerName: 'Römork Plaka',
            width: 150,
            valueFormatter: (params: any) => params.value?.plate ? params.value.plate : "Bilgi bulunamadı!"
        },
        {
            field: 'driver',
            headerName: 'Sürücü',
            width: 150,
            renderCell: (params: any) => (
                params.value ? params.value.first_name + " " + params.value.last_name : "Bilgi bulunamadı!"
            )
        },
        {
            field: 'start_date',
            headerName: 'Çıkış Tarihi',
            width: 150,
            renderCell: (params: any) => (
                dateFormatting(params.formattedValue)
            )
        },
        {
            field: 'load_date',
            headerName: 'Yükleme Tarihi',
            width: 150,
            renderCell: (params: any) => (

                params.formattedValue ? dateFormatting(params.formattedValue) : "Bilgi bulunamadı!"
            )
        },
        {
            field: 'estimated_unloading_date',
            headerName: 'Tahmini Boşaltma Tarihi',
            width: 150,
            renderCell: (params: any) => (
                params.formattedValue ? dateFormatting(params.formattedValue) : "Bilgi bulunamadı!"
            )
        },
        {
            field: 'position_status',
            headerName: 'Durumu',
            width: 200,
            renderCell: (params: any) => (
                params.formattedValue ?
                    <Tooltip title={params.formattedValue}>
                        <Chip label={params.formattedValue} color="primary" variant="outlined"/>
                    </Tooltip>
                    :
                    "Bilgi bulunamadı!"
            )
        }
    ], [])

    function EditToolbar() {
        return (
            <GridToolbarContainer>
                <SearchBar
                    tooltipTitle={"Sefer Ara"}
                    placeHolder={"Ara"}
                    onChangeText={(text)=>console.log(text)}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{height: "calc(100vh - 145px)", width: '100%'}}>
            <DataGridPremium
                slotProps={{
                    toolbar: EditToolbar
                }}
                sx={{
                    borderRadius:"10px 10px 0px 0px",
                    backgroundColor: `${theme.palette.background.default}`,
                    '& .MuiDataGrid-virtualScroller': {
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {height: 5, WebkitAppearance: 'none', width: 5},
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: 8,
                            border: '1px solid',
                            borderColor: 'white',
                            backgroundColor: 'rgba(0 0 0 / 0.5)',
                        },
                    },
                }}
                rows={renderRows()}
                //@ts-ignore
                columns={columns}
                //onRowClick={handleRowClick}
                slots={{
                    toolbar: GridToolbar,
                }}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'position_status', sort: 'desc'}]
                    },
                    columns: {
                        columnVisibilityModel: {
                            load_no: false
                        },
                    },

                }}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                pagination
                pageSizeOptions={[10, 20]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                loading={dataLoading}
            />
        </Box>
    );
}

import {useEffect} from "react";
import {useMap} from "react-leaflet";
import L from "leaflet";
import {ApiMobilizService} from "../../../../services/client/mobiliz/mobiliz.service";
import {getStorageData} from "../../../../utils/usePersistedState";
import ApiOSRMService from "../../../../services/client/osrm/osrm.service";
import {LatLngExpression} from "../../../../types/types/components/geomap/osm/tool/geomap.osm.tool.types"
import {mersinAndTriestePortRouteCoordinates} from "../../../../data/routes/port.routes";

interface IOpenStreetMapPolyline {
    id_no: number | null | undefined
    startTime: string | null | undefined
    endTime: string | null | undefined
}

const OpenStreetMapPolyline = (props:IOpenStreetMapPolyline) => {

    const map = useMap()

    const mersinAndTriestePortRoute:number[][] = mersinAndTriestePortRouteCoordinates

    const markerHtmlStyles = `
        background-color: "transparent";
    `;

    const startIcon = L.divIcon({
        className:markerHtmlStyles,
        iconSize: [32, 32],
        html:`
            <div style="display: flex; justify-content: center; align-items: center;">
                <svg style="position:absolute" fill="rgba(227, 6, 19, 1)" height="24" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CircleIcon"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path></svg>
                <svg style="position: absolute" fill="rgba(227, 6, 19, 0.53)" height="46" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CircleIcon">
                <style>
                    .blinking {
                        animation: blink-animation 1s infinite;
                        /* Animasyon süresi ve tekrar sayısı */
                    }
                    @keyframes blink-animation {
                        0% { opacity: 0; } /* Başlangıçta görünmez */
                        50% { opacity: 1; } /* Orta noktada tamamen görünür */
                        100% { opacity: 0; } /* Sonunda tekrar görünmez */
                    }
                </style>
                <path class="blinking" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path>
                </svg>
            </div>
        `
    });

    const endIcon = L.divIcon({
        className:markerHtmlStyles,
        iconSize: [32, 32],
        html:`
            <div style="display: flex; justify-content: center; align-items: center;">
                <svg style="position:absolute" fill="rgba(8, 122, 193, 1)" height="24" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CircleIcon"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path></svg>
                <svg style="position:absolute" fill="rgba(12, 154, 242, 0.55)" height="46" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CircleIcon"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path></svg>
            </div>
        `
    });

    useEffect(() => {
        /**
         * Aracın mobiliz üzerinden rota almak için API üzerinden sorgu yapar ve sonuçları haritaya çizer.
         * @param {Object} params - Rota sorgusu için seçenekler.
         * @param {number[][]} params.coordinates - Rota koordinatları, her biri bir dizi içinde iki sayıdan oluşur: [longitude, latitude].
         * @returns {Promise<void>} - Rota sorgusu sonuçlarını işlemek için bir `Promise`.
         */
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                ApiMobilizService.fetchActivityDetailService({
                    //@ts-ignore
                    accessToken:auth.status.access_token,
                    id_no:props.id_no,
                    startTime:props.startTime,
                    endTime:props.endTime,
                }).then((activityDetail)=> {
                    console.log('%c{Open Street Map Polyline}-Activity Detail', 'color: black; background-color: white',
                        activityDetail
                    );
                    const points: LatLngExpression[] = activityDetail.data.result.map((item:any) => [
                        item.latitude,
                        item.longitude,
                    ]);

                    var latTotals = (points[0][0]+points[points.length-1][0]) / 2
                    var lngTotals = (points[0][1]+points[points.length-1][1]) / 2

                    const eartRadius = 6371; //Dünya yarıcapı (km)

                    //Dereceleri radyan cinsine dönüştürme
                    const toRadians = (degrees: number) => (degrees * Math.PI) / 180;

                    const deltaLat = toRadians(points[0][0] - points[points.length-1][0]);
                    const deltaLon = toRadians(points[0][1] - points[points.length-1][1]);

                    const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
                        Math.cos(toRadians(points[0][0])) * Math.cos(toRadians(points[points.length-1][0])) *
                        Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);

                    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

                    const distance = eartRadius * c;

                    L.polyline(points, {
                        color:"#F49856",
                        opacity:1,
                        weight:5
                    }).addTo(map.setView([latTotals, lngTotals], 7)).bindTooltip(distance.toString().slice(0,7) + " KM").openTooltip()

                    L.marker(points[0], {
                        icon:startIcon,
                    }).addTo(map)

                    L.marker(points[points.length-1], {
                        icon:endIcon,
                    }).addTo(map)

                    /**
                     * Kayseri - Mersin arası rota almak için API üzerinden sorgu yapar ve sonuçları haritaya çizer.
                     * @param {Object} params - Rota sorgusu için seçenekler.
                     * @param {number[][]} params.coordinates - Rota koordinatları, her biri bir dizi içinde iki sayıdan oluşur: [longitude, latitude].
                     * @returns {Promise<void>} - Rota sorgusu sonuçlarını işlemek için bir `Promise`.
                     */
                    ApiOSRMService.fetchRoute({
                        coordinates: [[35.363810062408454,38.75554326295013],[34.6461382,36.8055051]],
                    }).then((route)=> {
                        const intersectionCoordinates: LatLngExpression[] = route.data.routes[0].legs[0].steps.flatMap(
                            (step: any) => {
                                return step.intersections.map((intersection: any) => {
                                    //console.log(intersection, "intersection");
                                    const [lng, lat] = intersection.location;
                                    return [lat, lng];
                                });
                            }
                        );
                        /*const maneuverCoordinates: LatLngExpression[] = route.data.routes[0].legs[0].steps.map((route:any)=>{
                            const [lng, lat] = route.maneuver.location
                            return [lat,lng];
                        })*/
                        L.polyline(intersectionCoordinates, { color: '#0C9AF2', weight:5 }).addTo(map)

                        const seaCoordinates: LatLngExpression[]  = mersinAndTriestePortRoute.map((item)=>{
                            const [lng, lat] = item;
                            return [lat, lng]
                        });

                        L.polyline(seaCoordinates, { color: '#0C9AF2', weight:5 }).addTo(map)

                        /**
                         * Trieste-Odine-Salzburg arası rota almak için API üzerinden sorgu yapar ve sonuçları haritaya çizer.
                         * @param {Object} params - Rota sorgusu için seçenekler.
                         * @param {number[][]} params.coordinates - Rota koordinatları, her biri bir dizi içinde iki sayıdan oluşur: [longitude, latitude].
                         * @returns {Promise<void>} - Rota sorgusu sonuçlarını işlemek için bir `Promise`.
                         */
                        ApiOSRMService.fetchRoute({
                            coordinates: [
                                [13.77537488937378,45.63529418226708],[13.20462226867676,46.121797178062856],[12.954511642456056,47.76863764860897]
                            ],
                        }).then((route)=>{
                            const intersectionCoordinates: LatLngExpression[] = route.data.routes[0].legs.reduce((result:any, leg:any)=>{
                                return result.concat(
                                    leg.steps.flatMap((step:any) => step.intersections.map((intersection:any)=>{
                                        const [lng, lat] = intersection.location;
                                        return [lat, lng]
                                    }))
                                )
                            }, [])
                            L.polyline(intersectionCoordinates, { color: '#0C9AF2', weight:5 }).addTo(map)
                        })
                    })
                }).catch((e:any)=>{
                    console.log('%c{Open Street Map Polyline}-Activity Detail', 'color: red; background-color: white',
                        e
                    );
                })
            })
        })
    },[props.id_no, map]);
    return null
}

export default OpenStreetMapPolyline

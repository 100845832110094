import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TwitterIcon from '@mui/icons-material/Twitter';
import {Card, CardContent} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import {fetchUserVCardInfo} from "../../services/user/user.service";
import Link from "@mui/material/Link";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BasicTabs = (user: object) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab
                        style={{fontWeight: "bold"}}
                        label="KİŞİSEL BİLGİLER" {...a11yProps(0)}
                        icon={<AccountCircleOutlinedIcon/>} iconPosition="start"
                    />
                    <Tab
                        style={{fontWeight: "bold"}}
                        label="Sosyal Medya"
                        {...a11yProps(1)}
                        icon={<GroupsOutlinedIcon/>} iconPosition="start"
                    />
                    <Tab style={{fontWeight: "bold"}}
                         label="ŞİRKET BİLGİLERİ"
                         {...a11yProps(2)}
                         icon={<BusinessOutlinedIcon/>} iconPosition="start"
                    />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Grid container>
                    <CssBaseline/>
                    <Grid item md={6} sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <Typography sx={{
                            fontWeight: "bold",
                            fontSize: "medium",
                            marginBottom: 5
                        }}>
                            Biyografi
                        </Typography>
                        <TextField
                            id="outlined-disabled"
                            label="Biyografi"
                            value={
                                //@ts-ignore
                                user.user?.profile?.bio
                            }
                            sx={{width: 350, marginBottom: 5, color:"black", input: { color: 'black' } }}
                        />
                        <Typography sx={{
                            fontWeight: "bold",
                            fontSize: "medium",
                            marginBottom: 5
                        }}>
                            Kişisel Bilgiler
                        </Typography>
                        <TextField
                            id="outlined-disabled"
                            label="Cep Telefonu"
                            defaultValue={
                                //@ts-ignore
                                user.user?.profile?.gsm
                            }
                            sx={{width: 350, marginBottom: 5, input: { color: 'black' }}}
                        />
                        <TextField
                            id="outlined-disabled"
                            label="Doğum Tarihi"
                            defaultValue={
                                //@ts-ignore
                                user.user?.profile?.birth_date
                            }
                            sx={{width: 350, marginBottom: 5, input: { color: 'black' }}}
                        />
                    </Grid>
                    <Grid item md={6} sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <Typography sx={{
                            fontWeight: "bold",
                            fontSize: "medium",
                            marginBottom: 5
                        }}>
                            Linkler
                        </Typography>
                        <TextField disabled sx={{width: 350, marginBottom: 5}}/>
                        <TextField disabled sx={{width: 350, marginBottom: 5}}/>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Typography style={{
                    fontWeight: "bold",
                }}>
                    Sosyal Medya
                </Typography>

                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "bold",
                }}>
                    {
                        //@ts-ignore
                        user.user?.social?.map((res) => (
                            res.platform == "instagram" ?
                                <Link href={"https://www.instagram.com/" + res.username}>
                                    <Box
                                        sx={{
                                            marginRight: 5,
                                            width: 50,
                                            height: 50,
                                            marginTop: 2,
                                            borderRadius: 2,
                                            backgroundImage: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                                            '&:hover': {
                                                backgroundColor: '#0148FF',
                                                opacity: [0.9, 0.8, 0.7],
                                            },
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "white",
                                        }}
                                    >
                                        <InstagramIcon>{res.username}</InstagramIcon>
                                    </Box>
                                </Link>
                                : <></> &&
                                res.platform == "linkedin" ?
                                    <Link href={"https://www.linkedin.com/" + res.username}>
                                        <Box
                                            sx={{
                                                marginRight: 5,
                                                width: 50,
                                                height: 50,
                                                marginTop: 2,
                                                borderRadius: 2,
                                                backgroundImage: "linear-gradient(45deg, #0077b5, #0077b5, #0077b5, #0077b5, #0077b5, #0077b5)",
                                                '&:hover': {
                                                    backgroundColor: '#0148FF',
                                                    opacity: [0.9, 0.8, 0.7],
                                                },
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                color: "white",
                                            }}
                                        >
                                            <LinkedInIcon>{res.username}</LinkedInIcon>
                                        </Box>
                                    </Link> : <></> &&
                                    res.platform == "facebook" ?
                                        <Link href={"https://www.facebook.com/" + res.username}>
                                            <Box
                                                sx={{
                                                    marginRight: 5,
                                                    width: 50,
                                                    height: 50,
                                                    marginTop: 2,
                                                    borderRadius: 2,
                                                    backgroundColor: '#0148FF',
                                                    '&:hover': {
                                                        backgroundColor: '#0148FF',
                                                        opacity: [0.9, 0.8, 0.7],
                                                    },
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: "white",
                                                }}
                                            >
                                                <FacebookIcon>{res.username}</FacebookIcon>
                                            </Box>
                                        </Link> : <></> &&
                                        res.platform == "twitter" ?
                                            <Link href={"https://www.twitter.com/" + res.username}>
                                                <Box
                                                    sx={{
                                                        marginRight: 5,
                                                        width: 50,
                                                        height: 50,
                                                        marginTop: 2,
                                                        borderRadius: 2,
                                                        backgroundImage: "linear-gradient(45deg, #66757f, #00ACEE, #36D8FF, #00ACEE, #00ACEE);",
                                                        '&:hover': {
                                                            backgroundColor: '#0148FF',
                                                            opacity: [0.9, 0.8, 0.7],
                                                        },
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        color: "white",
                                                    }}
                                                >
                                                    <TwitterIcon>{res.username}</TwitterIcon>
                                                </Box>
                                            </Link> : <></>
                        ))
                    }
                </Box>
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Typography sx={{fontWeight: "bold", width: 350, marginBottom: 5}}>
                    Şirket Adı
                </Typography>

                <Box style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "medium",
                    borderColor: "black",

                }}>
                    <TextField
                        id="outlined-disabled"
                        label="Şirket"
                        defaultValue={
                            //@ts-ignore
                            user.user?.employee?.corporate
                        }
                        sx={{width: 350, marginBottom: 5, input: { color: 'black' }}}
                    />

                    <TextField
                        id="outlined-disabled"
                        label="Departman"
                        defaultValue={
                            //@ts-ignore
                            user.user?.employee?.department
                        }
                        sx={{width: 350, marginBottom: 5, input: { color: 'black' }}}
                    />

                </Box>


            </TabPanel>
        </Box>
);
}


const UserVcardPage = () => {

    const {slug} = useParams();
    const [getUser, setUser] = useState<object>({})
    const [getLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        //@ts-ignore
        fetchUserVCardInfo(slug).then(res => {
            setLoading(false)
            setUser(res)
        })
    }, [getLoading])


    return (
        getLoading ? <></> :
            <Container>
                <Grid
                    item
                    xs={false} sm={false} md={12}
                    sx={{
                        backgroundImage: "linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)",
                        height: "237px",
                    }}
                    display={{xs: "none", sm: "block"}}
                >
                </Grid>

                <Grid sx={{
                    height: "300px",
                }}
                >
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid
                                    xs={12}
                                    md={3}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    //display={"flex"}
                                    //justifyContent={{xs:"center"}}
                                >
                                    <Avatar
                                        style={{
                                            width: 150,
                                            height: 150,
                                            //top: -100
                                        }} src="/static/images/avatar/1.jpg"/>
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                    md={6}
                                    sx={{
                                        //backgroundColor:"black"
                                    }}
                                >
                                    <Typography style={{
                                        fontWeight: "bold",
                                        fontSize: "30",
                                    }}>
                                        {
                                            //@ts-ignore
                                            getUser?.first_name + " " + getUser?.last_name
                                        }
                                    </Typography>
                                    <Typography>
                                        {
                                            //@ts-ignore
                                            getUser?.employee?.title
                                        }
                                    </Typography>

                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    sx={{
                                        //backgroundColor:"yellow",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                    <Button style={{
                                        width: 150,
                                        marginRight: 50
                                    }} variant="outlined">Bağlan</Button>
                                    <Button style={{width: 150,}} variant="outlined">Kaydet</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <BasicTabs
                        //@ts-ignore
                        user={getUser}
                    />
                </Grid>
            </Container>

    )
}

export default UserVcardPage

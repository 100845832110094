import React, {useEffect, useMemo, useState} from "react"
import {getStorageData} from "../../utils/usePersistedState";
import {offerFetchService} from "../../services/client/adverts/offers.service";
import Box from '@mui/material/Box';

//MUI Templates - Data Grid
import { DataGrid, trTR } from '@mui/x-data-grid' ;

const Offers = () => {

    const [ getLoading, setLoading ] = useState<boolean>(true)
    const [getOffers, setOffers] = useState<any>([])

    useEffect(()=>{
        getStorageData("authState",false).then(auth => {
            //@ts-ignore
            offerFetchService(auth.status.access_token).then((res:any)=>{
                setOffers(res.data.results)
                setLoading(false)
            })
        })
    },[getLoading])

    const columns = useMemo(() => [
        {field: 'id', headerName: 'ID'},
        {field: 'advert', headerName: 'Ürün'},
        {field: 'advert_item', headerName: 'Item'},
        {
            field: 'offer',
            headerName: 'Birim Fiyat',
            valueFormatter: (params: any) => params.value.unit_price?.toString()
        },
        {
            field: 'offer',
            headerName: 'Toplam',
            valueFormatter: (params: any) => params.value.count?.toString()
        },
    ], [])

    return (
        getLoading ?  <></> :
            <>
                {/**<Box sx={{
                display:"flex",
                flexDirection:"column"
            }}>
                {
                    getOffers.map((res:any)=>(
                        <Box>
                            {res.advert} id'li ilanın  {res.advert_item} id'li itemına  tanesi {res.unit_price} TL'den {res.count} adet teklif verildi.
                        </Box>
                    ))
                }
            </Box>**/}
            <Box>
                {
                    <DataGrid
                        sx={{
                            height: 400,
                            width: "100%"
                        }}
                        columns={columns}
                        rows={getOffers}
                    />
                }
            </Box>
            </>
    )
}




export default Offers

import React, {useEffect, useState} from "react"
import {getStorageData} from "../../utils/usePersistedState";
import {ApiProductsService} from "../../services/client/products/products.service";
import { Box, Button } from "@mui/material";
import {
    DataGrid, GridActionsCellItem,
    GridColDef, GridRowId, GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp,
    GridToolbarContainer
} from "@mui/x-data-grid";


import { randomId } from '@mui/x-data-grid-generator';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";


interface EditToolbarProps {
    setProducts: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

function EditToolbar(props: EditToolbarProps) {
    const { setProducts, setRowModesModel } = props;

    const handleClick = () => {
        const id = randomId();
        setProducts((oldRows) => [...oldRows, { id, unit: '', isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus:'name'},
        }));
    }

    return(
        <GridToolbarContainer>
            <Button onClick={handleClick}>
                Kayıt Ekle
            </Button>
        </GridToolbarContainer>
    )
}

const ProductsPage = () => {
    const [ getLoading, setLoading ] = useState<boolean>(true)
    const [ getProducts, setProducts] = useState<any>([])
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        setProducts(getProducts.map((row:any) => (row.id === newRow.id ? updatedRow : row)));
        getStorageData("authState",false).then(auth=>(
            //@ts-ignore
            ApiProductsService.createProductsService(auth.status.access_token, newRow).then(res=>console.log(res))
        ))
        return updatedRow;
    }

    useEffect(()=>{
        getStorageData("authState",true).then(auth=>(
            //@ts-ignore
            ApiProductsService.fetchProductsService(auth.status.access_token).then((res:any)=>{
                setProducts(res.data.results)
                setLoading(false)
            })
        ))
    },[])

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.View },
        }));
    };

    const columns: GridColDef[] = [
        {
            field: `name`,
            headerName: `Ürün İsmi`,
            width: 200,
            editable: true
        },

        {
            field: `unit_price`,
            headerName: `Birim Fiyat`,
            width: 200,
            editable: true
        },
        {
            field: `unit`,
            headerName: `Birim`,
            width: 200,
            editable: true
        },
        {
            field: `category`,
            headerName: `Kategori`,
            width: 200,
            editable: true
        },
        {
            field: `product_code`,
            headerName: `Ürün Kodu`,
            width: 200,
            editable: true
        },
        {
            field: `descriptions`,
            headerName: `Açıklama`,
            width: 200,
            editable: true
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width:200,
            cellClassName: 'actions',
            //@ts-ignore
            getActions: ({id, row, columns}) => {
                const isInEditMode = rowModesModel[id]?.mode == GridRowModes.Edit;

                if(isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            color="inherit"
                        />
                    ]
                }
                return [

                ]
            }
        }
    ]

    return(
        getLoading ?
            <> Veriler Yükleniyor </> :
            <Box>
                <DataGrid
                    sx={{
                        height: 400,
                        margin: "10px"
                    }}
                    rows={getProducts}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: EditToolbar
                    }}
                    slotProps={{
                        toolbar: { setProducts, setRowModesModel }
                    }}
                />
            </Box>
    )
}

export default ProductsPage

import React, { useEffect, useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { trTR } from '@mui/x-date-pickers/locales';
import { useTheme } from "@mui/material/styles";
import { Box } from '@mui/material';
import 'dayjs/locale/tr';

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    // {
    //     label: 'Gecen Yıl',
    //     getValue: () => {
    //         const today = dayjs();
    //         const beginningOfPreviousYear = today.endOf('year').add(1, 'day');
    //         return [beginningOfPreviousYear, beginningOfPreviousYear.endOf('year')];
    //     },
    // },
    {
        label: 'Bu Hafta',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'Geçen Hafta',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: 'Son 7 Gün',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
        },
    },
    {
        label: 'Bu Ay',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'Gelecek Ay',
        getValue: () => {
            const today = dayjs();
            const startOfNextMonth = today.endOf('month').add(1, 'day');
            return [startOfNextMonth, startOfNextMonth.endOf('month')];
        },
    },
    { label: 'Sıfırla', getValue: () => [null, null] },
];

interface IDateRangePicker {
    event: (firstTime: string, lastTime: string) => void,
    onClose?: () => void,
    defaultValueDateRange?: dayjs.Dayjs[]
}

const DateRangePickerComponent = (props: IDateRangePicker) => {
    const theme = useTheme();

    const [firstEventTime, setFirstEventTime] = useState("")
    const [lastEventTime, setLastEventTime] = useState("")

    //const [eventTime, setEventTime] = useState<[string, string]>(["", ""])

    useEffect(() => {
        const todayStart = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const todayEnd = dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        // bu kontrolün yapılma nedeni sıfırlaya basıldıktan sonra firstEventTime ve lastEventTime null a çekiliyor fakat date olarak bugünün başlangıç saatiyle bitiş saati alınıyor. bu şekilde istek yapılmasını engellemek için.
        if (firstEventTime === todayStart && lastEventTime === todayEnd) {
            props.event("", "")
        } else {
            props.event(firstEventTime, lastEventTime)
        }

    }, [lastEventTime]);

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="tr"
            localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <DemoContainer components={['DateRangePicker']} sx={{

                '& .MuiInputBase-formControl': {
                    borderRadius: "9px",
                },


                '& .MuiInputBase-input, .MuiOutlinedInput-input,l': {
                    padding: '6px 14px',
                    borderRadius: "9px",
                    color: `${theme.palette.text.secondary} !important`,
                },
            }}>
                <DateRangePicker
                    //@ts-ignore
                    label={"Tarih Aralığı"}
                    //@ts-ignore
                    defaultValue={props.defaultValueDateRange}
                    slotProps={{
                        shortcuts: {
                            items: shortcutsItems
                        },
                        textField: {
                            size: "small",
                            sx: {}
                        },
                    }}
                    //slots={{
                        //field: SingleInputDateRangeField
                    //}}
                    calendars={2}
                    format="LL"
                    //@ts-ignore
                    onChange={(dateRange: any, index: number) => {
                        if (dateRange) {
                            setFirstEventTime(dayjs(dateRange[0]?.$d).hour(0o0).minute(0o0).second(0o0).format('YYYY-MM-DDTHH:mm:ss'))
                            setLastEventTime(dayjs(dateRange[1]?.$d).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'))
                        } else {
                            setFirstEventTime('');
                            setLastEventTime('');
                        }
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}

export default DateRangePickerComponent;

import React, { useState, useEffect } from 'react'
import { getStorageData } from '../../utils/usePersistedState';
import { SnackbarWrapper } from '../snackbar/snackbar';
import { autoHideDuration, horizontal, severity, vertical } from "../../types/enums/components/snackbar/snackbar.enums";
import DocumentViewer from './documentViewer';
import { Dialog, Card, CardContent, Typography, Box } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import { DocumentService } from '../../services/client/documents/documents.service';
import { useAppSelector } from '../../store';
import { UserRoles } from '../../types/enums/reducers/user.enums';
import PermissionProvider from '../../utils/permissionProvider/permissionProvider';
import PermissionRestricted from '../../utils/permissionProvider/permissionRestricted';
import PermissionsTypes from "../../types/models/components/permissions.default.model"
import { permissionControl } from '../../utils/permissionControl';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTheme } from '@mui/material/styles';
import { AiOutlineFilePdf } from "@react-icons/all-files/ai/AiOutlineFilePdf"
import DeleteIcon from '@mui/icons-material/Delete';
import { ContentService } from '../../services/client/contents/contents.service';

interface DocumentType {
    id: string;
    text: string;
    file: string; // Add this property to represent the document URL
    file_name: string;
    type: string;
    uploaded_by_is_user: boolean;
}

interface INewDialog {
    loadId: number
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const DocumentDisplay = (props: INewDialog) => {

    const [selectedDocumentUrl, setSelectedDocumentUrl] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [documents, setDocuments] = useState([])
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severityType, setSeverityType] = useState(severity.warning)
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const openAnchor = Boolean(anchorEl);
    const userInfo = useAppSelector((state) => state.user);
    const theme = useTheme();

    useEffect(() => {
        //@ts-ignore
        if (props.loadId) {
            getStorageData("authState", true).then((auth) => {
                getStorageData("api", false).then(api => {
                    //@ts-ignore
                    DocumentService.getDocuments(api.url, auth.status.access_token, props.loadId)
                        .then((res: any) => {
                            setDocuments(res.data.results)
                        })
                        .catch((error: any) => {
                            console.error(error)
                            setSeverityType(severity.error)
                            setSnackbarMessage("Döküman yüklenirken hata oluştu.")
                            setAlertOpen(true)
                        });
                })
            });
        }
    }, [props.loadId, props.loading])
    const handleCloseDocumentViewer = () => {
        setSelectedDocumentUrl(null);
    };

    const handleClickAnchor = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };

    const handleDelete = (id: string) => {
        props.setLoading(true)
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                ContentService.deleteDocument(
                    //@ts-ignore
                    api.url, auth.status.access_token, id
                ).then((res: any) => {
                    setAlertOpen(true);
                    setSnackbarMessage("Döküman başarıyla silindi.");
                    setSeverityType(severity.success)
                    props.setLoading(false)
                })
                    .catch((error: any) => {
                        console.error("Error deleting the document:", error);
                        setSeverityType(severity.error)
                        setSnackbarMessage("Döküman silinirken hata oluştu.")
                        setAlertOpen(true)
                        props.setLoading(false)
                    });
            })
        })
    }



    return (

        <PermissionProvider fetchPermission={
            //@ts-ignore
            permissionControl(userInfo.data, "_document")}>
            <PermissionRestricted to={[PermissionsTypes.permission.view]} fallback={
                <SnackbarWrapper
                    open={alertOpen}
                    autoHideDuration={autoHideDuration.ms6000}
                    severity={severity.error}
                    message={"Döküman görüntüleme yetkiniz bulunmamaktadır."}
                    anchorOrigin={{ vertical: vertical.bottom, horizontal: horizontal.right }}
                    close={(status) => setAlertOpen(status)}
                />
            }>

                <SnackbarWrapper
                    open={alertOpen}
                    autoHideDuration={autoHideDuration.ms6000}
                    severity={severityType}
                    message={snackbarMessage}
                    anchorOrigin={{ vertical: vertical.bottom, horizontal: horizontal.center }}
                    close={(status) => setAlertOpen(status)}

                />
                {documents.length > 0 ? <Card sx={{ marginTop: "5px" }}>
                    <CardContent >

                        <>

                            {selectedDocumentUrl && (
                                <Dialog open={Boolean(selectedDocumentUrl)} onClose={handleCloseDocumentViewer}
                                    maxWidth={"lg"}
                                >
                                    <DocumentViewer documentUrl={selectedDocumentUrl} />
                                </Dialog>
                            )}
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>

                                <DescriptionIcon sx={{
                                    color: `${
                                        //@ts-ignore
                                        theme.palette.info.main
                                        }`,
                                }} />
                                <Typography sx={{
                                    color: `${
                                        //@ts-ignore
                                        theme.palette.info.main
                                        }`,
                                    marginLeft: "5px"
                                }} >
                                    Belgelerim
                                </Typography>
                            </Box>
                            {documents ? documents.map((document: DocumentType) => (
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Box sx={{
                                        margin: "10px 0 0 0",
                                        padding: "2px 0 ",
                                        // border: "1px solid grey",
                                        border: "1px solid  #EBEBEB",

                                        borderRadius: "4px ",
                                        paddingLeft: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor: "lightgray", // Change to the desired background color
                                        },
                                    }} onClick={() =>
                                        setSelectedDocumentUrl(document.file)
                                    }>
                                        <AiOutlineFilePdf size={35} color='red' />
                                        <Typography sx={{ marginLeft: "5px" }}>{document.file_name}</Typography>
                                    </Box>
                                    {
                                        //@ts-ignore
                                        userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.MANAGER || userInfo.data.role == UserRoles.DEVELOPER || document.uploaded_by_is_user === true ?
                                            <DeleteIcon sx={{
                                                color: 'red', // Text color
                                                cursor: 'pointer', // Optional: Add a pointer cursor for better usability
                                            }}
                                                onClick={() => handleDelete(document.id)}
                                            /> : <></>
                                    }

                                </Box>
                            )) : <></>}
                        </>


                    </CardContent>
                </Card> : <></>}



            </PermissionRestricted>
        </PermissionProvider>
    )
}

export default DocumentDisplay

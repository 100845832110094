import React, {useEffect, useState} from "react"
import {FieldValues, UseFormRegister} from "react-hook-form";
import {getStorageData} from "../../../utils/usePersistedState";
import {advertCategoryFetchService} from "../../../services/client/adverts/adverts.service";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormControl, IconButton, InputLabel, MenuItem, Tooltip, Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";

interface ICategorySelector {
    label: string,
    categoryId: (id: string) => void,
    register: UseFormRegister<FieldValues>,
    inputName: string,
    onChange?:any,
    value?:string
}

export const CategorySelector = ({label, categoryId, register, inputName, value, onChange}: ICategorySelector) => {

    const [getCategory, setCategory] = useState([])

    useEffect(() => {
        getStorageData("authState",false).then(auth => {
            //@ts-ignore
            advertCategoryFetchService(auth.status.access_token).then(res => {
                setCategory(res.data.results)
            })
        })
    }, [])

    const categoryHandleChange = (event: SelectChangeEvent) => {
        categoryId(event.target.value as string)
    }

    return (
        <Box sx={{
            display:"flex",
            flexDirection:"row"
        }}>
            <FormControl sx={{
                width: "100%",
            }}>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    required
                    value={value}
                    //@ts-ignore
                    ref={register}
                    //@ts-ignore
                    {...register(inputName, {required: true})}
                    onChange={onChange}
                >width:"100%"
                    {
                        getCategory.map((res, index) => (
                            <MenuItem
                                key={index}
                                value={
                                    //@ts-ignore
                                    res.id
                                }
                            >
                                {
                                    //@ts-ignore
                                    res.name
                                }
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <Tooltip title="Kategori Ekle">
                <IconButton>
                    <Typography sx={{
                        fontSize: "10px"
                    }}></Typography>
                    <EditIcon sx={{
                        fontSize: "13px",
                        margin: "1em"
                    }}/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

import React from "react";

import axios, { AxiosResponse } from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

//Interfaces
import { IWaitingActivity } from "../../../../types/interfaces/reducers/reports/waitingActivity/waitingActivity.interface";
import { IWaitingActivityDetails } from "../../../../types/interfaces/reducers/reports/waitingActivity/waitingActivity.details.interface";
//Environment
import { slug } from "../../../../config";

/**
 * Bekleyen Etkinlikleri getiren asenkron bir işlemdir.
 *
 * @param {Object} extra - Ekstra bilgiler nesnesi.
 * @param {string} extra.accessToken - Erişim belirteci.
 * @param {number} extra.offset - Kayıtların başlangıç noktası.
 * @param {number} extra.limit - Alınacak maksimum kayıt sayısı.
 * @param {string=} extra.vehicleType - Araç tipi.
 * @param {string=} extra.plate - Araç plakası.
 * @param {number=} extra.km_metric - Kilometre metriği.
 * @param {string=} extra.first_event_time - İlk etkinlik zamanı.
 * @param {string=} extra.last_event_time - Son etkinlik zamanı.
 * @param {number=} extra.status_id - Durum ID'si.
 * @returns {Promise<IWaitingActivity>} - Bekleyen etkinliklerle tamamlanan bir Promise.
 */
export const fetchWaitingActivity = createAsyncThunk("fetchWaitingActivity",
    async (extra: {
        accessToken: string;
        offset: number;
        limit: number;
        vehicleType?: string;
        plate?: string;
        km_metric?: number;
        first_event_time?: string;
        last_event_time?: string;
        status_id?: number;
    }
    ) => {
        const response = await axios.get<IWaitingActivity>(slug.client.report.wait.activity.url,
            {
                params: {
                    //first_event_time:firstEventTimes,
                    // last_event_time:lastEventTimes
                    ...(extra.offset ? { offset: extra.offset } : {}),
                    ...(extra.limit ? { limit: extra.limit } : {}),
                    only_parents: true,
                    ...(extra.vehicleType ? { vehicle_type: extra.vehicleType } : {}),
                    ...(extra.plate ? { plate: extra.plate } : {}),
                    ...(extra.km_metric ? { km_metric: extra.km_metric } : {}),
                    ...(extra.first_event_time ? { first_event_time: extra.first_event_time } : {}),
                    ...(extra.last_event_time ? { last_event_time: extra.first_event_time } : {}),
                    ...(extra.status_id ? { status_id: extra.status_id } : {}),
                    only_expeditions: true,
                },
                headers: {
                    "Content-type": "application/json",
                    // @ts-ignore
                    'Authorization': 'Bearer ' + extra.accessToken.valueOf()
                }
            })
        return response.data;
    });

/**
 * Bekleyen Etkinlik Ebeveynlerini getiren asenkron bir işlemdir.
 * @param {Object} extra - Ekstra bilgiler nesnesi.
 * @param {string} extra.access_token - Erişim belirteci.
 * @param {number} extra.vehicle_id - Araç ID'si.
 * @returns {Promise<IWaitingActivity[]>} - Bekleyen etkinlik ebeveynleriyle tamamlanan bir Promise.
 */
export const fetchWaitingActivityParents = createAsyncThunk("fetchWaitingActivityParents",
    async (extra: { access_token: string; vehicle_id: number }) => {
        const response = await axios.get<IWaitingActivity>(slug.client.report.wait.activity.url, {
            params: {
                vehicle_id: extra.vehicle_id,
                only_parents: true,
                calculate: true,
                only_expeditions: true,
            },
            headers: {
                "Content-type": "application/json",
                // @ts-ignore
                'Authorization': 'Bearer ' + extra.access_token.valueOf()
            }
        })

        return response.data.results;
    })

/**
 * Bekleyen Etkinlik Detaylarını getiren asenkron bir işlemdir.
 *
 * @param {Object} extra - Ekstra bilgiler nesnesi.
 * @param {string} extra.access_token - Erişim belirteci.
 * @param {string} extra.id - Bekleyen etkinlik ID'si.
 * @returns {Promise<IWaitingActivityDetails>} - Bekleyen etkinlik detayları verisiyle tamamlanan bir Promise.
 */
export const fetchWaitingActivityDetails = createAsyncThunk(
    "waitingActivityDetails", async (
        extra: { apiUrl: string, access_token: string; id: string }) => {
    const response = await axios.get<IWaitingActivityDetails>(extra.apiUrl + slug.client.report.wait.activity.url + "/" + extra.id, {
        headers: {
            "Content-type": "application/json",
            // @ts-ignore
            'Authorization': 'Bearer ' + extra.access_token.valueOf()
        }
    })
    return response.data;
})

interface WaitingActivityService {
    fetchWaitingActivityQuick(params: {
        apiUrl: string,
        accessToken: string,
        firstEventTime: string,
        lastEventTime: string,
        statusId: string,
        offset: number,
        search: string,
        limit?: number
    }): Promise<AxiosResponse>;
}

export const ApiWaitingActivityService: WaitingActivityService = {
    fetchWaitingActivityQuick: async (params: {
        apiUrl: string;
        accessToken: string,
        firstEventTime: string,
        lastEventTime: string,
        statusId: string,
        offset: number,
        search: string,
        limit?: number
    }): Promise<AxiosResponse> => {
        const config = {
            params: {
                first_event_time__gte: params.firstEventTime ? params.firstEventTime : {},
                last_event_time__lte: params.lastEventTime ? params.lastEventTime : {},
                expedition_no__isnull: false,
                status_id: params.statusId,
                offset: params.offset,
                search: params.search ? params.search : {},
                limit: params.limit ? params.limit : {}
            },
            headers: {
                Authorization: `Bearer ${params.accessToken}`,
            }
        };
        try {
            const response = await axios.post(params.apiUrl + slug.client.report.wait.activity.quick,
                {
                    fields: ["status_name", "plate", "total_passing_time", "expedition_no"]
                    // values:["status_name","vehicle","expedition_no"]
                }, config)
            return response;
        }
        catch (e) {
            console.log(e)
            throw e;
        }
    }
}

import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from "@mui/material";
import Input from '@mui/material/Input';
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {PhotoCamera} from "@mui/icons-material";

import { SubmitHandler, useForm } from "react-hook-form";

import {AdvertOfferInput} from "../../types/types/components/forms/form.type";
import {UnitSelector} from "../form/controllers/unit.controller";
import {CurrencySelector} from "../form/controllers/currency.controller";
import {getStorageData} from "../../utils/usePersistedState";
import {offerCreateService} from "../../services/client/adverts/offers.service";

import { DateSelector } from "../form/controllers/date.controller";
import {DueoptionsSelector} from "../form/controllers/dueoptions.controller";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BasicTabs = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function UploadButtons() {
        return (
            <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" component="label">
                    GÖZAT
                    <input hidden accept="image/*" multiple type="file" />
                </Button>
                <IconButton color="primary" aria-label="upload picture" component="label">
                    <input hidden accept="image/*" type="file" />
                    <PhotoCamera />
                </IconButton>
            </Stack>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Standart Yükleme" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <UploadButtons/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UploadButtons/>
            </TabPanel>
        </Box>
    );
}


const steps = ['Teklif Ver', 'Dosyalarınızı Yükleyin', 'Teklif Özeti'];


interface IOffersStepperWizard {
    advertId:string,
    advertItem:object
}


const OffersStepperWizard = ({advertId, advertItem}:IOffersStepperWizard) => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const [getEndDate, setEndDate] = useState<any>()

    const [ getTotalCount, setTotalCount ] = useState<any>(0)
    const [ getUnitPrice, setUnitPrice ] = useState<any>(0)

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {

        let newSkipped = skipped;
        console.log(skipped)
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: {
            errors
        }
    } = useForm<AdvertOfferInput>();

    const onSubmitForm: SubmitHandler<AdvertOfferInput> = (data, event) => {
        //@ts-ignore
        event.preventDefault();
        //@ts-ignore
        data.offer.end_time = getEndDate
        //@ts-ignore
        data.advert = advertId
        //@ts-ignore
        data.advert_item = advertItem.row.id

        getStorageData("authState",false).then((auth)=>{
            //@ts-ignore
            console.log(auth.status.access_token)
            //@ts-ignore
            offerCreateService(auth.status.access_token, data).then(res=>{
                console.log(data)
            })
        })
    }

    useEffect(()=>{
    })

    // @ts-ignore
    return(
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {

                    const stepProps: { completed?: boolean } = {};

                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};

                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }

                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        Teklifiniz gönderilmiştir!
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>ÇIKIŞ</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box component="form">
                        {
                            activeStep == 0 ?
                                <Card>
                                    <CardContent>
                                        <Grid container md={12}>
                                            <Grid md={6} sx={{
                                                display:"flex",
                                                flexDirection:"column",
                                                padding:2,
                                            }}>
                                                <Box sx={{margin:1, width:"100%"}}>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        id="outlined-disabled"
                                                        label="Ürün İsmi"
                                                        //@ts-ignore
                                                        defaultValue={advertItem.row.product.name}
                                                    />
                                                </Box>
                                                <Box sx={{margin:1, width:"100%"}}>
                                                    <UnitSelector
                                                        label={"Birim"}
                                                        unitId={(id)=>console.log(id)}
                                                        //@ts-ignore
                                                        register={register}
                                                        inputName={`offer.unit`}
                                                    />
                                                </Box>
                                                <Box sx={{margin:1, width:"100%"}}>
                                                    <CurrencySelector
                                                        label={"Para Birimi"}
                                                        currencyId={(id)=>console.log(id)}
                                                        //@ts-ignore
                                                        register={register}
                                                        inputName={`offer.currency`}
                                                    />
                                                </Box>
                                                <Box sx={{margin:1, width:"100%"}}>
                                                    <DueoptionsSelector
                                                        label={"Vade"}
                                                        dueoptionsId={(id)=>console.log(id)}
                                                        //@ts-ignore
                                                        register={register}
                                                        inputName={`offer.due_option`}/>
                                                </Box>
                                            </Grid>
                                            <Grid md={6} sx={{
                                                display:"flex",
                                                flexDirection:"column",
                                                padding:2,
                                            }}>
                                                <Box sx={{margin:1, width:"100%"}}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Toplam Adet</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-basic"
                                                            label="Number"
                                                            type="number"
                                                            //@ts-ignore
                                                            {...register(`offer.count`, {
                                                                onChange: (e) => {setTotalCount(e.target.value)},
                                                            })}
                                                            required={true}
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{margin:1, width:"100%"}}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Birim Fiyat</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-basic"
                                                            label="Number"
                                                            type="number"
                                                            //@ts-ignore
                                                            {...register(`offer.unit_price`, {
                                                                onChange: (e) => {(setUnitPrice(e.target.value))}
                                                            })}
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{margin:1, width:"100%"}}>
                                                    <DateSelector label={"Bitiş Tarihi"} date={(date) => setEndDate(date)}/>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid md={12}>
                                            <FormControl fullWidth sx={{ m:1, }} variant="standard">
                                                <InputLabel htmlFor="standard-adornment-amount">Açıklama</InputLabel>
                                                <Input id="standard-adornment-amount"/>
                                            </FormControl>
                                        </Grid>
                                    </CardContent>
                                </Card> : <></> &&
                                activeStep == 1 ?
                                    <Card>
                                        <CardContent>
                                            <BasicTabs/>
                                        </CardContent>
                                    </Card> : <></> &&

                                activeStep == 2 ? <Card sx={{
                                    weihgt:50,
                                    height:400}}>
                                    <CardContent>
                                        <Grid container md={12}>
                                            <Grid md={6} sx={{
                                                display:"flex",
                                                flexDirection:"column",
                                                padding:2,
                                            }}>
                                                <Typography sx={{margin:2}}>
                                                    {
                                                        //@ts-ignore
                                                        advertItem.row.product.name
                                                    }
                                                </Typography>
                                                <Typography sx={{
                                                    marginLeft:2,
                                                    color:"grey",
                                                }}>
                                                    { getTotalCount } adet
                                                </Typography>
                                                <Typography sx={{fontWeight:"bold",margin:2}}>
                                                    TOTAL
                                                </Typography>
                                                <Typography sx={{fontWeight:"bold",margin:2}}>
                                                    %18 KDV TOPLAMI
                                                </Typography>
                                            </Grid>
                                            <Grid md={6} sx={{
                                                display:"flex",
                                                flexDirection:"column",
                                                alignItems:"end",
                                            }}>
                                                <Typography sx={{
                                                    margin:3,
                                                }}>
                                                    {getUnitPrice}
                                                </Typography >
                                                <Typography sx={{margin:3}}>
                                                    {
                                                        //@ts-ignore
                                                        getTotalCount * getUnitPrice
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent></Card> : <></>
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    GERİ
                                </Button>
                            )}
                            {
                                getTotalCount.length > 0 && getUnitPrice.length > 0 ?
                                    <Button  onClick={handleNext}>
                                        {
                                            activeStep === steps.length - 1 ?
                                                <Button
                                                    variant="contained"
                                                    onClick={handleSubmit(onSubmitForm)}
                                                > Teklif Gönder </Button> :
                                                'İLERİ'
                                        }
                                    </Button> :
                                    <Button disabled onClick={handleNext}>
                                        {
                                            'İLERİ'
                                        }
                                    </Button>
                            }
                        </Box>
                    </Box>

                </React.Fragment>
            )}
        </Box>
    );
}

export default OffersStepperWizard

import React from "react"
import axios, {AxiosResponse} from "axios";
import {slug} from "../../../config";

interface ProductsService {
    apiUrl?:string
    fetchProductsService(accessToken: string, causeID?:string): Promise<AxiosResponse>;
    createProductsService(accessToken: string, data: any): Promise<AxiosResponse>;
    updateProductsService(accessToken: string, data: any, productID: string): Promise<AxiosResponse>;
    deleteProductsService(accessToken: string, productID: string): Promise<AxiosResponse>;
}

const apiUrl = slug.client.product.url

export const ApiProductsService: ProductsService = {

    fetchProductsService: async (accessToken: string, causeID?: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.get(apiUrl, {headers});
            return response
        }
        catch (error){
            throw error
        }
    },

    createProductsService: async (accessToken: string, data: any): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const repsonse = await axios.post(apiUrl, data, {headers})
            return repsonse
        }
        catch (error){
            throw error
        }
    },

    updateProductsService: async (accessToken: string, data: any, productID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.put(apiUrl+productID, data, {headers})
            return response
        }
        catch (error) {
            throw error
        }
    },
    deleteProductsService: async (accessToken: string, productID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.delete(apiUrl+productID, {headers})
            return response
        }
        catch (error) {
            throw error
        }
    }

}

import React, {useEffect, useState} from "react";
import {FieldValues, UseFormRegister} from "react-hook-form";
import {getStorageData} from "../../../utils/usePersistedState";
import {ApiProductUnitService} from "../../../services/client/products/product.units.services";
import {FormControl, InputLabel, MenuItem} from "@mui/material";
import Select from "@mui/material/Select";

interface IUnitSelector {
    label:string,
    unitId:(id:string) => void,
    register: UseFormRegister<FieldValues>,
    inputName:string,
}

export const UnitSelector = ({label, unitId, register, inputName} : IUnitSelector) => {
    const [getUnit, setUnit] = useState([])

    useEffect(()=>{
        getStorageData("authState",false).then(auth=> {
            //@ts-ignore
            ApiProductUnitService.fetchProductUnit(auth.status.access_token).then((res:any)=>{
                setUnit(res.data.results)
            })
            //@ts-ignore
            fetchProductsUnits(auth.status.access_token).then((res:any)=>{
                setUnit(res.data.results)
            })
        })
    }, [])
    return(
        <FormControl sx={{ width:"100%"}}>
            <InputLabel
                id="demo-simple-select-label"
            >
                {
                    label
                }
            </InputLabel>
            <Select
                //@ts-ignore
                ref={register}
                //@ts-ignore
                {...register(inputName, {required:true})}
            >
                {
                    getUnit.map((res, index)=>(
                        <MenuItem
                            key={index}
                            value={
                                //@ts-ignore
                                res.id
                            }
                        >
                            {
                                //@ts-ignore
                                res.name
                            }
                        </MenuItem>
                    ))
                }

            </Select>
        </FormControl>
    )
}

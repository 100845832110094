import React from "react";

interface DateProviderProps {
    daysAgo?: number;
    hours?:number
    min?:number,
    sec?:number,
    ms?:number,
}

const DateProvider = (props: DateProviderProps) => {
    const currentDate = new Date();

    if (props.daysAgo) {
        currentDate.setUTCDate(currentDate.getDate() - props.daysAgo);
        //@ts-ignore
        currentDate.setUTCHours(props.hours, props.min, props.sec, props.ms)
        return currentDate.toISOString().slice(0,19)
    } else{
        //@ts-ignore
        currentDate.setUTCHours(props.hours, props.min, props.sec, props.ms)
        return currentDate.toISOString().slice(0,19)
    }
};

export default DateProvider;

import * as React from 'react';
import { PieChart, pieArcClasses } from '@mui/x-charts/PieChart';


interface IHighlightChartComponent {
    data:any,
    series: {
        innerRadius: number,
        additionalRadius:number,
        outerRadius: number,
        paddingAngle: number,
        cornerRadius: number,
        startAngle: number,
        endAngle: number,
        cx: number,
        cy: number,
    }
    legend:{
        hidden:boolean,
        position:{
            vertical:string
            horizontal:string
        }
        direction:string,
        offset:{
            x?:number,
            y?:number
        }
    }
}

export default function HighlightChartComponent(props:IHighlightChartComponent) {
    return (
        <PieChart
            series={[
                {
                    data:props.data,
                    highlightScope: {
                        faded: 'global',
                        highlighted: 'item'
                    },
                    faded: {
                        innerRadius: props.series.innerRadius,
                        additionalRadius: props.series.additionalRadius
                    },
                    cx:props.series.cx,
                    cy:props.series.cy
                },
            ]}
            legend={{
                hidden: props.legend.hidden,
                position: {
                    //@ts-ignore
                    vertical:props.legend.position.vertical,
                    //@ts-ignore
                    horizontal:props.legend.position.horizontal
                },
                //@ts-ignore
                direction:props.legend.direction,
                offset:{
                    x:props.legend.offset.x,
                    y:props.legend.offset.y
                }
            }}
            sx={{
                [`& .${pieArcClasses.faded}`]: {
                    fill: 'gray',
                },
            }}
            height={200}
        />
    );
}

import React, {useEffect, useRef, useState} from "react"
import {getStorageData} from "../../../utils/usePersistedState";
import ApiDFDSService from "../../../services/client/dfds/dfds.service";

import {
    Box, Stack, List, ListItem, ListItemButton, ListItemText, Checkbox,
    IconButton, Chip, Button
} from '@mui/material';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DfdsDiscountFiltersMenu from "./dfds.discount.filters.menu";
import DiscountBillingPeriodSelector from "../../../components/selectors/dfds/discounts/discount.billing.period.selector";
import SearchBar from "../../../components/form/controllers/search.input";
import Modal from "@mui/material/Modal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import {LiaFileInvoiceSolid} from "react-icons/lia";

interface IDiscountList {
    discount: (value: object) => void
}

const DFDSDiscountList = (props:IDiscountList) => {

    const [checked, setChecked] = useState([]);
    const [selectedDiscount, setSelectedDiscount] = useState([])

    const [selectAll, setSelectAll] = useState(false);

    const [discounts, setDiscounts] = useState([])
    const [discountsCount, setDiscountsCount] = useState(0)

    const [period, setPeriod] = useState("")
    const [action, setAction] = useState("")

    const [search, setSearch] = useState("")

    const [loading, setLoading] =  useState(true)

    const discountsListRef = useRef<HTMLUListElement>(null);

    const [discountsOffset, setDiscountsOffset] = useState(0);


    /**
     * Modal
     */
    const [ modalTitle, setModalTitle ] = useState("")
    const [ modalDesc, setModalDesc ] = useState("")

    const [modal, setModal] = useState({
        open: false,
        actionable:false,
        title: "",
        desc: "",
    });
    const handleModalClose = () => setModal({
        open: false,
        actionable:false,
        title: "",
        desc: "",
    });


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleDiscountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDiscountMenuClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (value: never) => () => {
        //@ts-ignore
        const currentIndex = checked.findIndex((item) => item.company_id === value.company_id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
            selectedDiscount.push(value)
        } else {
            selectedDiscount.splice(currentIndex, 1);
            newChecked.splice(currentIndex, 1);
        }

        console.log(newChecked)
        setChecked(newChecked);
        setSelectAll(newChecked.length === discounts.length);
    };

    const handleSelectAll = () => {
        const allValues = discounts.map((value, index) => value);

        let newChecked: never[] = [];

        if (selectAll) {
            newChecked.push(...allValues);
            selectedDiscount.push(...allValues)
        } else {
            setSelectedDiscount([])
            setChecked([]);
        }

        console.log(newChecked)
        setChecked(newChecked);
        setSelectAll(!selectAll)
    };

    const handleCreateDiscount = () => {
        setLoading(true)
        let payload = {}
        let companies: string[] = [];

        selectedDiscount.forEach((res:any) => {
            //@ts-ignore
            companies.push(res.company_id)

            payload = {
                "period": period,
                "action": action,
                "companies": companies
            }
        })

        getStorageData("authState", true).then(auth => {
            ApiDFDSService.createDiscounts({
                //@ts-ignore
                accessToken: auth.status.access_token,
                payload:payload
            }).then((res:any)=>{
                setLoading(false)
            }).catch((e:any)=>{
                setLoading(false)
            })
        })
    }

    const handleActionControl = (action:string) => {
        //@ts-ignore
        const hasManuelSendValue = selectedDiscount.some(obj => obj.is_manuel_send === false);

        setAction(action)

        if (hasManuelSendValue){
            console.log("heyyyy")
            if (action === "mark_paid"){
                console.log("Bir nesnenin 'value' özelliği false ve action mark_paid");
                setModal({
                    open: true,
                    actionable: false,
                    title: "İndirim ödendi olarak işaretlenemez!",
                    desc: "Seçtiğiniz indirimler arasında gönderilmeyenler var."
                })
            }
            if (action === "send_manuel"){
                setModal({
                    open: true,
                    title: "Emin misiniz?",
                    desc: "İndirimleri Gönderildi Olarak İşaretlemek istediğinize eminmisiniz?",
                    actionable: true
                })

            }
        }
        else{
            if (action === "mark_paid"){
                setModal({
                    open: true,
                    actionable: true,
                    title: "Emin misiniz?",
                    desc: "İndirimleri Ödendi Olarak İşaretlemek istediğinize eminmisiniz?"
                })
                console.log("Hiçbir nesnenin 'value' özelliği false değil ve action send_manuel");
            }
        }
    }

    const handleDiscountListNextPage = (): void => {
        setDiscountsOffset(discountsOffset + 10)
        console.log()
    }
    const handleDiscountsListScroll = () => {
        if (discountsListRef.current){
            const isScrollAtBottom =  discountsListRef.current.scrollHeight
                - discountsListRef.current.scrollTop
                - discountsListRef.current.clientHeight;
            if (isScrollAtBottom === 0) {
                console.log(isScrollAtBottom)
                handleDiscountListNextPage()
            }
        }
    }

    useEffect(() => {
        setLoading(true);
        setDiscounts([]);
        props.discount({});

        getStorageData("authState", true).then(auth => {
            return getStorageData("api", false).then(api => {
                console.log(search, "hey");
                return ApiDFDSService.fetchDiscounts({
                    //@ts-ignore
                    ApiUrl: api.url,
                    //@ts-ignore
                    accessToken: auth.status.access_token,
                    period: period,
                    search: search,
                });
            });
        }).then((res:any) => {
            console.log(discountsCount, "hey");
            //@ts-ignore
            setLoading(false);
            //@ts-ignore
            setDiscounts(res.results);
            //@ts-ignore
            setDiscountsCount(res.count);
            //@ts-ignore
            if (res.results.length > 0 && discountsListRef.current) {
                discountsListRef.current.addEventListener('scroll', handleDiscountsListScroll, { passive: true });
            }
        }).catch(error => {
            console.error("Error fetching discounts:", error);
            setLoading(false);
        });

        return()=> {
            if (discountsListRef.current){
                discountsListRef.current.removeEventListener('scroll', handleDiscountsListScroll);
            }
        };
    }, [period, search]);

    return (
        <Box sx={{
            height: "calc(100vh - 120px)",
        }}>
            <div>
                <Modal
                    open={modal.open}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={0.5}
                        >
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {modal.title}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{mt: 2}}>
                                {modal.desc}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0.5}
                        >
                            {
                                modal.actionable ?
                                    <>
                                        <Button variant="contained" onClick={handleCreateDiscount}>Onayla</Button>
                                        <Button variant="outlined" onClick={handleModalClose}>İptal</Button>
                                    </> :
                                    <></>
                            }
                        </Stack>
                    </Box>
                </Modal>
            </div>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                marginLeft="15px"
                marginBottom="15px"
                spacing={2}
            >
                <Typography sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                }}>
                    Faturalar <Chip sx={{ argin:1 }} label={discountsCount ? discountsCount + " Firma" : 0 + " Firma"} color="primary" variant="outlined"/>
                </Typography>
                {
                    selectedDiscount.length > 0 ?
                        <Box>
                            <Button
                                variant="contained"
                                startIcon={<AddCircleOutlineRoundedIcon/>}
                                sx={{
                                    backgroundColor: "rgba(0, 153, 255, 1)"
                                }}
                                onClick={handleDiscountMenuOpen}
                            >
                                İşlem Yap
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleDiscountMenuClose}
                            >
                                {
                                    //@ts-ignore
                                    selectedDiscount.some(obj => obj.is_manuel_send === false) ?
                                        <MenuItem onClick={()=>console.log(selectedDiscount)}>
                                            <Button onClick={() => handleActionControl("send_manuel")}>İndirimi Gönder</Button>
                                        </MenuItem> : <></>
                                }
                                {
                                    //@ts-ignore
                                    selectedDiscount.some(obj => obj.is_paid === false) ?
                                        <MenuItem onClick={handleDiscountMenuClose}>
                                            <Button onClick={() => handleActionControl("mark_paid")}>İndirimi Öde</Button>
                                        </MenuItem> : <></>
                                }

                            </Menu>
                        </Box> :
                        <></>
                }
            </Stack>

            <Box sx={{
                borderBottom: '1px solid #E9E9E9',
                marginLeft:"5px"
            }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            //border: '1px solid #ced4da',
                            //borderRadius: '4px',
                        }}
                    >
                        <Checkbox
                            edge="start"
                            //@ts-ignore
                            checked={discounts.length === checked.length}
                            tabIndex={-1}
                            disableRipple
                            onClick={handleSelectAll}
                            sx={{
                                marginLeft:"0px"
                            }}
                        />
                        <DfdsDiscountFiltersMenu/>
                    </Box>
                    <SearchBar
                        placeHolder={"Ara"}
                        onChangeText={(text) => setSearch(text)}
                        disable={loading}
                        tooltipTitle={"Firma Ara"}
                    />
                    <DiscountBillingPeriodSelector
                        fallback={(period) => setPeriod(period)}
                        disable={loading}
                        inputLabel={"Dönem"}
                        defaultPeriod={1}
                    />

                </Stack>
            </Box>
            {
                discountsCount != 0 ?
                    <List
                        sx={{
                            width: '100%',
                            position: 'relative',
                            maxHeight: "calc(100vh - 220px)",
                            overflow: 'auto',
                            '&::-webkit-scrollbar': { height: 5, WebkitAppearance: 'none', width: 5 },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: 8,
                                border: '1px solid',
                                borderColor: 'white',
                                backgroundColor: 'rgba(0 0 0 / 0.5)',
                            },
                        }}
                        ref={discountsListRef}
                    >
                        {
                            loading ?
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height:"100%"
                                }}>
                                    <CircularProgress/>
                                </Box> :
                                //@ts-ignore
                            discounts.slice().sort((a, b) => b.discount_total - a.discount_total).map((value, index) => {
                            //@ts-ignore
                            const labelId = `checkbox-list-label-${value.company_id}`;
                            return (
                                <ListItem
                                    key={index}
                                    disablePadding
                                    sx={{
                                        borderBottom: '1px solid #E9E9E9',
                                    }}
                                >
                                    <ListItemButton role={undefined} onClick={()=>props.discount(value)} dense>
                                        <Grid container>
                                            <Grid md={8}>
                                                <Stack>
                                                    <Stack
                                                        direction="row"
                                                        spacing={0}
                                                        alignItems="center"
                                                    >
                                                        {

                                                        }
                                                        <Checkbox
                                                            onClick={handleToggle(value)}
                                                            edge="start"
                                                            //@ts-ignore
                                                            checked={checked.some((item) => item.company_id === value.company_id)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{'aria-labelledby': labelId}}
                                                        />
                                                        <Typography sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 600
                                                        }}>
                                                            {
                                                                //@ts-ignore
                                                                value.company_name}
                                                        </Typography>
                                                    </Stack>
                                                    <Typography sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 600,
                                                        marginTop: "15px",
                                                        color: "#A0A0A0"
                                                    }}>
                                                        {
                                                            //@ts-ignore
                                                            value.email ? value.email : "E-mail Adresi bulunamadı."
                                                        }
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid md={4}>
                                                <Stack
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-end"
                                                    spacing={0}
                                                >
                                                    <Typography sx={{
                                                        color: "#A0A0A0",
                                                        fontSize: "12px",
                                                        fontWeight: 500
                                                    }}>
                                                        İndirim Toplamı
                                                    </Typography>
                                                    <ListItemText
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            '& span': {
                                                                color: "#000000",
                                                                fontSize: "16px !important",
                                                                fontWeight: 600,
                                                            }
                                                        }}
                                                        id={labelId}
                                                        //@ts-ignore
                                                        primary={"€ " + `${value.discount_total}`}>
                                                    </ListItemText>
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                        marginTop="10px"
                                                    >
                                                        {
                                                            //@ts-ignore
                                                            value.is_paid ? <Chip
                                                                label="Ödendi"
                                                                variant="filled"
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor: "rgba(10, 220, 18, 0.24)",
                                                                    borderColor: "#0BB512",
                                                                    color: "#0BB512"
                                                                }}
                                                            /> : <Chip
                                                                label="Ödenmedi"
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{
                                                                    borderColor: "rgba(242, 7, 7, 1)",
                                                                    color: "rgba(242, 7, 7, 1)"
                                                                }}
                                                            />
                                                        }
                                                        {
                                                            //@ts-ignore
                                                            value.is_send || value.is_manuel_send ? <Chip
                                                                label="Gönderildi"
                                                                variant="filled"
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor: "rgba(10, 220, 18, 0.24)",
                                                                    borderColor: "#0BB512",
                                                                    color: "#0BB512"
                                                                }}
                                                            /> : <Chip
                                                                label="Gönderilmedi"
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{
                                                                    borderColor: "rgba(242, 7, 7, 1)",
                                                                    color: "rgba(242, 7, 7, 1)",
                                                                }}
                                                            />
                                                        }
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List> : <Box sx={{
                        display: 'flex',
                        justifyContent: 'center', // Yatayda ortala
                        alignItems: 'center', // Dikeyde ortala
                        height:"100%"
                    }}>
                        <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                        >
                            <LiaFileInvoiceSolid style={{
                                fontSize:"150px",
                                color:"rgba(1,1,1,0.1)"
                            }}/>
                            <Typography>
                                Herhangi bir fatura bulunamadı.
                            </Typography>
                        </Stack>
                    </Box>
            }
        </Box>
    )
}

export default DFDSDiscountList

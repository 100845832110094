import React, {useEffect, MouseEvent, useState} from 'react';
import {useMap} from "react-leaflet"
import 'leaflet.markercluster/dist/MarkerCluster.css'; // MarkerCluster CSS dosyasını ekleyin
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'; // MarkerCluster CSS dosyasını ekleyin
import L from 'leaflet'; // Leaflet'i içe aktarın
import 'leaflet.markercluster';
import {getStorageData} from "../../../../utils/usePersistedState";
import {ApiMobilizService} from "../../../../services/client/mobiliz/mobiliz.service";
import {UserRoles} from "../../../../types/enums/reducers/user.enums";
import {useAppSelector} from "../../../../store";
import ApiVehiclesService from "../../../../services/client/vehicles/vehicles.service";

interface IOpenStreetMapMarkerClusterTool {
    id_no: number | null | undefined
}

const OpenStreetMapMarkerClusterTool = (props:IOpenStreetMapMarkerClusterTool) => {

    const userInfo = useAppSelector((state) => state.user);

    const [currentZoom, setCurrentZoom] = useState<number>()

    const [cluster, setCluster] = useState([])

    const map = useMap()

    useEffect(() => {
        map.on('zoom',function(e) {
            //@ts-ignore
            setCurrentZoom(e.target._zoom)
        })

        let markers = new L.MarkerClusterGroup()

        //@ts-ignore
        if (currentZoom > 15){
            map.eachLayer(function (layer){
                //@ts-ignore
                if(!layer._url){
                    layer.remove()
                }
            })
        }
        else {
            //@ts-ignore
            if (userInfo.data.role == UserRoles.EMPLOYEE || userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.DEVELOPER ){
                if(!props.id_no){
                    const truckIcon = L.icon({
                        iconUrl: 'https://cdn0.iconfinder.com/data/icons/isometric-city-basic-transport/48/truck-front-01-512.png',
                        iconSize: [32, 32],
                    });

                    getStorageData("authState", true).then((auth) => {
                        getStorageData("api", false).then((api) => {
                            ApiVehiclesService.fetchVehiclesService({
                                //@ts-ignore
                                url: api.url,
                                //@ts-ignore
                                accessToken: auth.status.access_token
                            }).then((vehicle)=>{
                                const points = vehicle.data.results.map((item: any) => item.last_location.features[0].geometry?.coordinates);

                                for (let i = 0; i < points.length; i++) {
                                    if (!points[i]) {
                                        continue; // points[i] undefined ise döngüyü atla
                                    }
                                    const [lat, lng] = points[i];
                                    let marker = L.marker(new L.LatLng(lng, lat), { icon: truckIcon }).bindPopup("title");
                                    markers.addLayer(marker);
                                }
                            }).catch((e:any)=>{
                                console.log(e)
                            })
                        })
                    })

                    /*getStorageData("authState", true).then((auth) => {
                        getStorageData("api", false).then((api) => {
                            //@ts-ignore
                            ApiMobilizService.fetchTotalService("http://apitest.abcws.prod/tr/mobiliz/1/activity/last", auth.status.access_token).then((res)=>{
                                setCluster(res.data)
                                //@ts-ignore
                                const points = res.data.result.map((item, index) => [item.latitude, item.longitude, item.companyName]);
                                for (let i = 0; i < points.length; i++){
                                    const [lat, lng, title] = points[i];
                                    //@ts-ignore
                                    let marker = L.marker(new L.LatLng(lat, lng), {icon: truckIcon}).bindPopup(title);
                                    markers.addLayer(marker)
                                }
                            })
                        })
                    })*/
                    map.addLayer(markers)
                }
            }
        }
        //@ts-ignore
    }, []);

    return null
}

export default OpenStreetMapMarkerClusterTool

import React, { useEffect, useState } from "react";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef, GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel
} from "@mui/x-data-grid";
import { getStorageData } from "../../utils/usePersistedState";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppSelector } from "../../store";
import { permissionControl } from "../../utils/permissionControl";
import PermissionRestricted from "../../utils/permissionProvider/permissionRestricted";
import PermissionsTypes from "../../types/models/components/permissions.default.model";
import PermissionProvider from "../../utils/permissionProvider/permissionProvider";

const NotificationsPage = () => {
    const userInfo = useAppSelector((state) => state.user);


    const [getLoading, setLoading] = useState<boolean>(true)
    const [getNotifications, setNotifications] = useState<any[]>([])

    const [rows, setRows] = React.useState(getNotifications);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true }
        })
    }


    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.View },
        }));
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row: any) => (row.id === newRow.id) ? updatedRow : row))
        return updatedRow
    }

    useEffect(() => {
        getStorageData("authState", false).then((auth) => {
            //@ts-ignore
            ApiNotificationsPushService.fetchNotificationsPushService(auth.status.access_token).then((res) => {
                setNotifications(res.data.results)
                setLoading(false)
            })
        })
    }, [])


    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Bildirim İsmi',
            width: 200,
            editable: true,
        },
        {
            field: 'title',
            headerName: 'Bildirim Başlığı',
            width: 200,
            editable: true,
        },
        {
            field: 'body',
            headerName: 'Bildirim İçeriği',
            width: 200,
            editable: true,
        },
        {
            field: 'image',
            headerName: 'Bildirim Resmi',
            width: 200,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',

            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode == GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            onClick={handleCancelClick(id)}
                            label="Cancel"
                            className="textPrimary"
                            color="inherit"
                        />
                    ]
                }
                return [
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_pushnotification")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.add]} fallback={<>Kullanıcı İzni Yok</>}>
                            {
                                <GridActionsCellItem
                                    icon={<EditIcon />}
                                    label="Edit"
                                    className="textPrimary"
                                    onClick={handleEditClick(id)}
                                    color="inherit"
                                />
                            }
                        </PermissionRestricted>
                    </PermissionProvider>,
                    <PermissionProvider fetchPermission={
                        //@ts-ignore
                        permissionControl(userInfo.data, "_pushnotification")}>
                        <PermissionRestricted to={[PermissionsTypes.permission.delete]} fallback={<>Kullanıcı İzni Yok</>}>
                            {
                                <GridActionsCellItem
                                    icon={<DeleteIcon />}
                                    label="Delete"
                                    color="inherit"
                                />
                            }
                        </PermissionRestricted>
                    </PermissionProvider>
                ]
            }
        }
    ]

    return (
        getLoading ?
            <>Veriler Yükleniyor</> :
            <DataGrid
                sx={{
                    height: 400,
                    margin: "10px"
                }}
                columns={columns}
                rows={getNotifications}
                editMode="row"
                rowModesModel={rowModesModel}
                processRowUpdate={processRowUpdate}
            />
    )
}

export default NotificationsPage

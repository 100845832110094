import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {IconButton, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import BookingCompanySelector from "../../../selectors/dfds/companies/booking.company.selector";
import BookingCompanyMultiSelector from "../../../selectors/dfds/companies/booking.company.multiSelector";
import VehicleViewSvgComponent from "../../../svg/vehicle.view.svg.component";
import Tooltip from "@mui/material/Tooltip";
import {useTheme} from "@mui/material/styles";
import VehicleBookingActiveSwitch from "../../../switch/bookings/vehicles/vehicle.booking.active.switch";
import {randomId} from "@mui/x-data-grid-generator";
import {GridRowModes} from "@mui/x-data-grid";
import {getStorageData} from "../../../../utils/usePersistedState";
import BookingVehiclesService from "../../../../services/client/bookings/booking.vehicles.service";
import {useAppDispatch} from "../../../../store";
import {openSnackbar} from "../../../../reducers/snackbar.slice";
import {
    autoHideDuration,
    horizontal,
    severity,
    vertical
} from "../../../../types/enums/components/snackbar/snackbar.enums";


interface IBookingVehicleInfoModal {
    rowValue: any
    refreshing:()=>void
}

const BookingVehicleInfoModal = (props: IBookingVehicleInfoModal) => {
    const theme = useTheme();

    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [isActive, setIsActive] = useState(props.rowValue?.row?.is_active)

    const dispatch = useAppDispatch();


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
    }, [props.rowValue]);


    const handleSave = () => {
        handleClose()
        props.refreshing()
        getStorageData("authState", true).then(auth => {
            BookingVehiclesService.updateVehicle({
                vehicleId:props.rowValue.row.id,
                //@ts-ignore
                accessToken: auth.status.access_token,
                isActive:isActive
            }).then((res)=>{
                if (res.status === 200) {
                    dispatch(openSnackbar(
                        {
                            message:"Firma bilgisi güncellendi.",
                            severity:severity.success,
                            anchorOrigin:{
                                vertical:vertical.bottom,
                                horizontal:horizontal.right
                            },
                            autoHideDuration:autoHideDuration.ms3000,
                            open:true,
                            close:status => console.log(status)
                        }
                    ))
                }
            }).catch((e)=>{
                dispatch(openSnackbar(
                    {
                        message:e.message,
                        severity:severity.error,
                        anchorOrigin:{
                            vertical:vertical.bottom,
                            horizontal:horizontal.right
                        },
                        autoHideDuration:autoHideDuration.ms3000,
                        open:true,
                        close:status => console.log(status)
                    }
                ))
            })
        })
    }


    return (
        <div>
            <Tooltip title="Araç Bilgisi">
                <IconButton onClick={handleOpen} aria-label="fingerprint" color="secondary" sx={{
                    backgroundColor: `${theme.palette.secondary.main}`,
                    width: "36px",
                    height: "36px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}>
                    <VehicleViewSvgComponent/>
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 740,
                    height: "400px",
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    borderRadius: "10px",
                    borderColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <Box>
                        <Typography sx={{
                            color: `${theme.palette.text.primary}`,
                            fontWeight: 600,
                            fontSize: "16px",
                            margin: 1,
                        }}>
                            {
                                props.rowValue.row?.plate
                            }
                        </Typography>
                        <Divider/>
                        <Box sx={{
                            margin: 1
                        }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                            >
                                <Typography sx={{
                                    color: `${theme.palette.text.primary}`,
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    margin: 1,
                                }}>
                                    Firma
                                </Typography>
                                <Typography sx={{
                                    color: `${theme.palette.text.primary}`,
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    margin: 1,
                                }}>
                                    {
                                        props.rowValue.row?.company?.company_name ? props.rowValue.row?.company?.company_name : "Firma bilgisi bulunamadı."
                                    }
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                margin={0}
                            >
                                <VehicleBookingActiveSwitch
                                    defaultChecked={props.rowValue?.row?.is_active}
                                    active={(status)=>setIsActive(status)}
                                />
                            </Stack>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <Stack
                            direction="row"
                            spacing={4}
                            justifyContent="space-between"
                            sx={{
                                width: "100%",
                                margin:1
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                sx={{
                                    height:"35px"
                                }}
                            >
                                IPTAL
                            </Button>
                            <Button variant="contained"
                                    sx={{
                                        backgroundColor: "rgba(0, 143, 251, 1)",
                                        color: `white`,
                                        height:"35px"
                                    }}
                                    onClick={handleSave}
                            >Kaydet</Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default BookingVehicleInfoModal

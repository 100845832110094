import axios, { AxiosResponse } from "axios";
import {slug} from "../../../config";


import {IDemandsService} from "../../../types/interfaces/services/client.services.interfaces";

/**
 * IDemandsService arayüzü, DemandsService nesnesi tarafından sağlanan hizmetleri belirtir.
 * @typedef {Object} IDemandsService
 * @property {function} fetchDemands - Talepleri getirmek için bir fonksiyon.
 * @property {function} createDemands - Talep oluşturmak için bir fonksiyon.
 * @property {function} editDemands - Talep düzenlemek için bir fonksiyon.
 */

/**
 * @type {IDemandsService}
 */

const DemandsService: IDemandsService = {
    fetchDemands: async (params:{apiUrl:string, accessToken:string}): Promise<AxiosResponse> => {
         const headers = {
             Authorization: `Bearer ${params.accessToken}`,
         }
         try {
             const response = await axios.get(params.apiUrl+slug.client.demands.url, {headers})
             return response
         }
         catch (e){
             throw e
         }
    },

    createDemands: async (params: { apiUrl: string; accessToken: string, data:object}): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${params.accessToken}`,
        }
        try {
            const response = await axios.post(params.apiUrl+slug.client.demands.url, params.data, {headers})
            return response
        }
        catch (e){
            throw e
        }
    },

    editDemands: async (params: { apiUrl: string; accessToken: string; id:string, data: object }): Promise<AxiosResponse> => {
        const headers = {
            Authorization: `Bearer ${params.accessToken}`,
        }
        try {
            const response = await axios.patch(params.apiUrl + slug.client.demands.url+params.id, params.data, { headers });
            return response
        }
        catch (e){
            throw e
        }
    }
}

export default DemandsService


import React from "react"
import {Box, TextField} from "@mui/material";

const NotificationCreateForm = () => {
    return(
        <Box component="form">
            <Box sx={{minWidth: 120, marginBottom:"1em"}}>
                <TextField fullWidth id="outlined-basic"
                           label="Notification Title"
                           variant="outlined"
                />
            </Box>
        </Box>
    )
}

export default NotificationCreateForm

import React from "react";

import {FormControl, TextField} from "@mui/material";
import {UseFormRegister, FieldValues } from 'react-hook-form'

interface InputFieldProps {
    label:string,
    register: UseFormRegister<FieldValues>,
    registerName:string,
}

const InputFormField = ({label, register, registerName}: InputFieldProps) => {
    return(
        <FormControl fullWidth>
            <TextField
                fullWidth
                id="outlined-basic"
                label={label}
                variant="outlined"
                {...register((registerName), {required:true})}/>
        </FormControl>
    )
}

export default InputFormField

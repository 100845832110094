import React, {useEffect, useState} from "react"
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel
} from "@mui/x-data-grid";
import {getStorageData} from "../../../utils/usePersistedState";
import {ApiIntegrationsDueOptionsService} from "../../../services/client/integrations/integrations.dueoptions.service";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";


const IntegrationsDueoptionsPage = () => {
    const [getLoading, setLoading] = useState<boolean>(true)

    const [getDueOptions, setDueOptions] = useState<any>([])

    const [rows, setRows] = React.useState(getDueOptions);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}
        })
    }

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.View },
        }));
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row:any) => (row.id === newRow.id ? updatedRow : row)));
        console.log(updatedRow);
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'option',
            headerName: 'Vade',
            width: 200,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            //@ts-ignore
            getActions: ({id}) => {

                const isInEditMode = rowModesModel[id]?.mode == GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            onClick={handleCancelClick(id)}
                            label="Cancel"
                            className="textPrimary"
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        color="inherit"
                    />,
                ];
            }
        }
    ]


    useEffect(()=>{
        getStorageData("authState",false).then((auth)=> {
            //@ts-ignore
            ApiIntegrationsDueOptionsService.fetchIntegrationsDueOptionsService(auth.status.access_token).then((res:any)=>{
                setDueOptions(res.data.results)
                setLoading(false)
            })
        })
    },[])

    return(
        getLoading ? <>Veriler Yükleniyor</> :
            <Box>
                <DataGrid
                    sx={{
                        height: 400,
                        margin: "10px"
                    }}
                    rows={getDueOptions}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    processRowUpdate={processRowUpdate}
                    slotProps={{
                        toolbar: { setRows, setRowModesModel },
                    }}
                />
            </Box>
    )
}

export default IntegrationsDueoptionsPage

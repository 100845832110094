export enum severity {
    error="error",
    warning="warning",
    info="info",
    success="success",
}

export enum vertical {
    bottom="bottom",
    top="top"
}

export enum horizontal {
    left="left",
    right="right",
    center="center"
}

export enum autoHideDuration {
    ms3000=3000,
    ms6000=6000,
    ms9000=9000,
}

import React, {useEffect, useMemo, useState} from "react";

import {getStorageData} from "../../../utils/usePersistedState";
import {Button, CardContent} from "@mui/material";

import {ApiIntegrationsTaxesService} from "../../../services/client/integrations/integrations.taxes.service";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Close';

import {
    DataGrid,
    GridRowModel,
    GridRowId,
    GridToolbarContainer,
    GridActionsCellItem,
    GridColDef,
    GridRowsProp,
    GridRowModesModel,
    GridRowModes
} from "@mui/x-data-grid";

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
    const handleClick = () => {
        const id = 2;
        setRows((oldRows) => [...oldRows, { id, tax_name: '', tax_code: '', isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'tax_name' },
        }));
    };

    return(
        <GridToolbarContainer>
            <Button onClick={handleClick}>
                Kayıt Ekle
            </Button>
        </GridToolbarContainer>
    )
}

const IntegrationsTaxesPage = () =>{

    const [getLoading, setLoading] = useState<boolean>(true)

    const [getTax, setTax] = useState<any>([])

    const [rows, setRows] = React.useState(getTax);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}
        })
    }

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.View },
        }));
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row:any) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    useEffect(()=>{
        getStorageData("authState",false).then((auth)=>{
            //@ts-ignore
            ApiIntegrationsTaxesService.fetchIntegrationsTaxes(auth.status.access_token).then((item)=>{
                setTax(item.data.results)
                setLoading(false)
            })
        })
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'tax_name',
            headerName: 'Vergi İsmi',
            width: 200,
            editable: true,
        },
        {
            field: 'tax_code',
            headerName: 'Vergi Kodu',
            width: 200,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            //@ts-ignore
            getActions: ({id}) => {

                const isInEditMode = rowModesModel[id]?.mode == GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            onClick={handleCancelClick(id)}
                            label="Cancel"
                            className="textPrimary"
                            color="inherit"
                        />
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        color="inherit"
                    />,
                ];
            }
        }
    ]

    return(
      getLoading ? <>Veriler Yükleniyor</> :
          <Box>
              <DataGrid
                  sx={{
                      height: 400,
                      margin: "10px"
                  }}
                  rows={getTax}
                  columns={columns}
                  editMode="row"
                  rowModesModel={rowModesModel}
                  processRowUpdate={processRowUpdate}
                  slots={{
                      toolbar: EditToolbar,
                  }}
                  slotProps={{
                      toolbar: { setRows, setRowModesModel },
                  }}

              />
          </Box>
    )
}

export default IntegrationsTaxesPage

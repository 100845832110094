//React
import React, { useEffect } from "react"
import { Link } from "react-router-dom";
//Components
import {
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    styled,
    Box,
    Switch, Avatar, Badge, useTheme, DialogContent, DialogTitle, DialogActions, Dialog, Tab, Grid
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar/AppBar";

//Icons
import MenuIcon from "@mui/icons-material/Menu";
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';

//Languages
import { useSetLanguage, useLanguage, useT } from "../../../utils/locales/locale.index";
import oAuthInitialState from "../../../types/models/storage/auth/oAuth.default.model"

import { setStorageData } from "../../../utils/usePersistedState";
import { useAppSelector } from "../../../store";

import { UserRoles } from "../../../types/enums/reducers/user.enums";

import { setDefaultDynamicApi, setDynamicApi } from "../../../utils/dynamicApiProvider/dynamicApi.provider";
import { baseUrls } from "../../../types/enums/configs/api.enums";
import DynamicApiToggleButton from "../../buttons/component.toggle.button";
import AccountMenu from "../../menu/account/account.menu";
import NotificationFirebase from "../../notifications/notification";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: 240,
        width: `calc(100% - ${240}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));


interface IAppBarNavigation {
    open: boolean

    toggleDrawer(): void

    toggleTheme(): void
}


const AppBarNavigation = (props: IAppBarNavigation) => {

    const theme = useTheme();

    const t = useT();
    const setLang = useSetLanguage();
    const lang = useLanguage();


    const [accountMenu, setAccountMenu] = React.useState<null | HTMLElement>(null);
    const [languageMenu, setLanguageMenu] = React.useState<null | HTMLElement>(null);
    const [companyName, setCompanyName] = React.useState(null);


    const handleAccountClose = () => {
        setAccountMenu(null);
    };

    const handleLanguageMenuClose = () => {
        setLanguageMenu(null);
    }

    const handleLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
        setLanguageMenu(event.currentTarget);
    };

    const Logout = () => {
        setStorageData("authState", oAuthInitialState, true).then((res:any) => {
            console.log("ÇIKIŞ YAPILDI", res)
        })
        window.location.href = "/"
    }

    const userInfo = useAppSelector((state) => state.user);

    const checkLocalHost = () => {
        return window.location.host
    }

    useEffect(() => {

        setDefaultDynamicApi()
        //@ts-ignore
        userInfo.data.work_profile?.corporate ?
            setCompanyName(
                //@ts-ignore
                userInfo.data.work_profile.corporate?.name
            ) : setCompanyName(
                //@ts-ignore
                userInfo.data?.work_profile?.company?.name
            )
    }, [])

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    return (
        <AppBar position="absolute" open={props.open} sx={{
            boxShadow: "none",
            textShadow: "none"
        }}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                    backgroundColor: `${theme.palette.background.paper}`,
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(props.open && { display: 'none' }),
                    }}
                >
                    <MenuIcon sx={{
                        color: `${theme.palette.primary.main}`,
                    }} />
                </IconButton>

                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Typography
                        noWrap
                        component="h1"
                        variant="h6"
                        sx={{
                            color: `${theme.palette.primary.main}`,
                            fontSize: "13px"
                        }}
                    >
                        {
                            companyName != null ? companyName : t("drawer_DashboardTitle")
                        }
                    </Typography>
                </Box>
                <div>
                    {

                        //@ts-ignore
                        userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.DEVELOPER || checkLocalHost() == "localhost:3010" ?
                            <DynamicApiToggleButton
                                toggleButton={[
                                    { label: "PROD", process: ((label) => setDynamicApi({ name: label, url: baseUrls.prod })) },
                                    { label: "TEST", process: ((label) => setDynamicApi({ name: label, url: baseUrls.test })) },
                                    { label: "DEV", process: ((label) => setDynamicApi({ name: label, url: baseUrls.dev })) },
                                ]}
                                defaultSelectedButton={"DEV"}
                            />
                            : <></>
                    }
                    <NotificationFirebase />
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleLanguageMenu}
                        color="inherit"
                    >
                        <LanguageTwoToneIcon sx={{
                            width: "25px",
                            height: "25px",
                            color: "gray"
                        }} />
                    </IconButton>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={props.toggleTheme}
                        color="inherit"
                    >
                        <MaterialUISwitch />
                    </IconButton>
                    <AccountMenu userInfo={userInfo.data} />
                    <Menu
                        id="menu-appbar"
                        anchorEl={accountMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(accountMenu)}
                        onClose={handleAccountClose}
                    >
                        <Link style={{ textDecoration: "none", color: 'text.primary' }} to={"/account/user/info"}>
                            <MenuItem>{t("navbar_accountButtonTitle")}</MenuItem>
                        </Link>
                        <Link style={{ textDecoration: "none", color: 'text.primary' }} to={"/account/user/info"}>
                            <MenuItem>{t("navbar_accountButtonTitle")}</MenuItem>
                        </Link>
                        <MenuItem onClick={Logout}>{t("navbar_logOutButtonTitle")}</MenuItem>
                    </Menu>
                    <Menu
                        id="menu-appbar"
                        anchorEl={languageMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(languageMenu)}
                        onClose={handleLanguageMenuClose}
                    >
                        <MenuItem onClick={() => setLang(lang === "en" ? "tr" : "tr")}>Türkçe</MenuItem>
                        <MenuItem onClick={() => setLang(lang === "tr" ? "en" : "en")}>İngilizce</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default AppBarNavigation

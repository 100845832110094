export const apiConfig = {
    url: "https://api.abclojistik.com",
    // url: "http://apitest.abcws.prod",
    // url: "http://abclojistik.metaws.local:8000",
    version:"v1.1",
    language:"tr"
}

export const slug = {
    auth:{
        login:`https://accounts.abclojistik.com/o/token/`,
        register:`https://accounts.abclojistik.com/accounts/register/`,
        client_id:`1abZdil5Sx0qhROxXfVuuifyL45vMEPIGegQQVHo`,
        client_secret:`10tMRY13Ve116B8YRVhfney0xhQsipzFMisOX7BhAjZCiNnRyUkYkymOSzKtcjI7kqO7nKspkTHSTqjlxfWG0lkyhE4bvOXbfZ47eN5WY1KU91cHK0OMRGRwwYSaRUct`,
        grant_type:`password`
    },

    client:{
        user:{
            info:`/${ apiConfig.language }/users/user-info`,
            role:{
                list:`${apiConfig.url}/${ apiConfig.language }/${ apiConfig.version }/accounts/user/role/list`,
            },
            vcard:`${apiConfig.url}/${ apiConfig.language }/${ apiConfig.version }/accounts/user/vcard/`
        },

        adverts:{
            url:`${apiConfig.url}/${apiConfig.language}/adverts/`,
            items:{
                url:`/items/`,
            },
            categories:{
                url:`${apiConfig.url}/${apiConfig.language}/adverts/categories`,
            },
            offers:{
                url:`offers`,
            }
        },
        company:{
            list:``,
            detail:``,
            branch: {
                detail:``
            },
            employee : {
                detail:``
            }
        },

        dfds:{
            discounts:{
                url:`/${apiConfig.language}/discounts`
            },
        },
        expeditions:{
            url:`/${apiConfig.language}/expeditions/`,
            positionTypes:{
                url:`${apiConfig.url}/${apiConfig.language}/expeditions/position-types`,
            },
            rating:{
                url:`/${apiConfig.language}/expeditions/rating`
            },
            quick:{
                url:`/${apiConfig.language}/reports/wait-activities/quick`
            },
            duration:{
                url:`/${apiConfig.language}/reports/quick-data/expeditions/siber/expedition-times`
            },
            positionStatus:{
                url:`${apiConfig.url}/${apiConfig.language}/expeditions/position-statuses`},
            documents:{
                documentTypesUrl:`/${apiConfig.language}/expeditions/document-types`,
                url : `/${apiConfig.language}/expeditions/`
            }
        },
        ratings : {
            url : `/${apiConfig.language}/ratings/criterias`
        },
        notifications : {
            url : `/${apiConfig.language}/notifications/`,
            read : `/${apiConfig.language}/notifications/read`,
            devices : `/${apiConfig.language}/devices/`,

        },
        integrations:{
            url:`${apiConfig.url}/${apiConfig.language}/integrations/`,

            cause:{
                url:`${apiConfig.url}/${apiConfig.language}/integrations/causes`,
            },
            holder:{
                url:`${apiConfig.url}/${apiConfig.language}/integrations/holders`,
            },
            currencies:{
                url:`${apiConfig.url}/${apiConfig.language}/integrations/currencies`,
            },
            taxes:{
                url:`${apiConfig.url}/${apiConfig.language}/integrations/taxes`
            },
            dueOptions:{
                url:`${apiConfig.url}/${apiConfig.language}/integrations/dueoptions`
            }
        },
        load:{
            detail:``,
            list:``
        },

        product:{
            url:`${apiConfig.url}/${ apiConfig.language }/products/`,
            unit:{
                url:`${apiConfig.url}/${ apiConfig.language }/products/units`
            }
        },
        report:{
            expedition: {
              compare:{
                  list:`${apiConfig.url}/${ apiConfig.language }/reports/expedition-compare/list`,
              }
            },
            wait:{
                activity:{
                    url:`${apiConfig.url}/${ apiConfig.language }/reports/wait-activities`,
                    //quick: `/${ apiConfig.language }/reports/wait-activities/quick`,
                    quick:`/${apiConfig.language}/reports/wait-activities/wait-total`
                },
            },
            quickdata : {
                companyExpeditions:{
                    url:`/${ apiConfig.language }/reports/quick-data/company/expeditions`
                },
                corporateExpeditions:{
                    url:`/${ apiConfig.language }/reports/quick-data/corporate/expeditions`
                },
                companyBalance:{
                    url:`/${ apiConfig.language }/reports/quick-data/balance`
                },
                distance:{
                    url:`/${ apiConfig.language }/reports/quick-data/corporate/distance`
                }
            }
        },

        devices:{
            url:`${apiConfig.url}/${apiConfig.language}/devices/`,
        },

        notification:{
            push:{
                url:`${apiConfig.url}/${apiConfig.language}/notifications/push-notifications`
            }
        },

        mobiliz:{
            url:"",
            activityDetail:{
                url: `/${apiConfig.language}/mobiliz/1/activity/detail`
            },
            activity:{
                last:{
                    url: `/${apiConfig.language}/mobiliz/1/activity/last`
                }
            }
        },
        siber:{
            loads:{
                url:`${apiConfig.url}/${apiConfig.language}/siber/loads`
            },
            expeditions: {
                refresh: {
                    url:`/${apiConfig.language}/expeditions/refresh-data`
                }
            }
        },
        loads:{
            url: `/${apiConfig.language}/loads/`,
            documents:{
                documentTypesUrl:`/${apiConfig.language}/loads/document-types`,
                url : `/${apiConfig.language}/loads/`
            }
        },
        vehicles:{
            url: `/${apiConfig.language}/vehicles/`
        },
        locations:{
            url: `/${apiConfig.language}/locations/`
        },

        /**
        * Talepler
        */
        demands:{
            url: `/${apiConfig.language}/demands/`,
            products: {
                url:`/${apiConfig.language}/demands/products`
            },
        },
        /**
         * Depolar
         */
        warehouses:{
            url: `/${apiConfig.language}/warehouses/`,
        },
        contents : {
            url:`/${apiConfig.language}/contents/documents/`
        }
    },
}

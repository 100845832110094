import React, {FC} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Card, CardContent, IconButton, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import {IAddProductModal} from "../../types/interfaces/components/modal/modal.interface";

const style = {
    alignItems:"center",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

export const AddProductModal:FC<IAddProductModal> = (props, context) => {
    const [getModalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    return(
        <>
            <Tooltip title="Ürün Güncelle">
                <IconButton onClick={handleModalOpen}>
                    <Typography sx={{
                        fontSize:"10px"
                    }}>{props.buttonTitle}</Typography>
                    <EditIcon sx={{
                        fontSize:"13px",
                        margin:"1em"
                    }} />
                </IconButton>
            </Tooltip>
            <Modal
                open={getModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Card>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </>
    )
}


export const AlertModal = () => {
    return(
        <>
        </>
    )
}


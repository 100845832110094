import {Box, Card, CardActions, CardContent, Grid, Tooltip, Typography} from "@mui/material"
import React from "react"
import {useTheme} from '@mui/material/styles';
import {EWarehouseCardAction} from "../../../../types/enums/components/card/warehouse/warehouse.card.enum";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";


interface IWarehouseProductStockCard {
    name:string
}

const WarehouseProductStockCard = () => {

    const theme = useTheme();

    return(
        <Card
            sx={{
                marginTop: "1rem !important",
                maxWidth: 350,
                maxHeight: 450,
                textAlign: "center",
            }}
        >
            <CardContent>
                <Grid md={12}>
                    <Grid md={8}>
                        <Box
                            sx={{
                                '&:hover': {
                                    backgroundColor: "transparent",
                                    cursor: "pointer"
                                },
                            }}
                            onClick={() => console.log()}>
                            <img
                                src={process.env.PUBLIC_URL + "images/Image_not_available.png"}
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: "200px",
                                    height: "100%",
                                    margin: 50,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid md={4}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            textAlign: "left"
                        }}>
                            <Typography sx={{
                                fontSize: "1rem",
                                fontWeight: 500,
                                color: `${theme.palette.text.primary}`,
                            }}>
                                Ürün İsmi
                            </Typography>
                            <Tooltip
                                title={"Bu depo kategorisi, ofis malzemeleri, kırtasiye ürünleri ve benzeri ihtiyaçların depolandığı alandır. Depoyu seçerek stokları kontrol edebilirsiniz."}>
                                <Typography sx={{
                                    color: `${theme.palette.text.disabled}`,
                                    fontSize: "0.875rem"
                                }}>
                                    Bu depo kategorisi, ofis malzemeleri, kırt...
                                </Typography>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{
                padding: "0 !important",
                margin: "0 !important"
            }}>
                <Grid container md={12}>
                    <Grid md={4}>
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                '&:hover': {
                                    backgroundColor: `${theme.palette.text.disabled}`,
                                    cursor: "pointer"
                                },
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={() => console.log()}
                        >
                            <DeleteOutlineOutlinedIcon fontSize={"small"}/>
                            <Typography>
                                Sil
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={8} sx={{
                        backgroundColor: `${theme.palette.info.main}`,
                    }}>
                        <Box
                            sx={{
                                width: "100%",
                                height: "50px",
                                color: `${theme.palette.text.secondary}`,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: 500
                            }}
                            onClick={() => console.log()}
                        >
                            Düzenle
                        </Box>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}

export default WarehouseProductStockCard

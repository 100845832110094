import React from "react"
import axios, {AxiosResponse} from "axios";
import {slug} from "../../../config";

interface IntegrationsCausesService {
    apiUrl?:string
    fetchIntegrationsCausesService(accessToken: string, causeID?:string): Promise<AxiosResponse>;
    createIntegrationsCausesService(accessToken: string, data: any): Promise<AxiosResponse>;
    updateIntegrationsCausesService(accessToken: string, data: any, causeID: string): Promise<AxiosResponse>;
    deleteIntegrationsCausesService(accessToken: string, causeID: string): Promise<AxiosResponse>;
}

const apiUrl = slug.client.integrations.cause.url


export const ApiIntegrationsCausesService:  IntegrationsCausesService = {
    fetchIntegrationsCausesService: async (accessToken: string, causeID?:string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };
        try {
            const response = await axios.get(apiUrl, {headers});
            return response
        } catch (error) {
            throw error
        }
    },
    createIntegrationsCausesService: async (accessToken: string, data: any): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.post(apiUrl, {headers});
            return response
        } catch (error) {
            throw error
        }
    },
    updateIntegrationsCausesService: async (accessToken: string, data: any, causeID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = axios.put(apiUrl, data, {headers})
            return response
        } catch (error) {
            throw error
        }
    },
    deleteIntegrationsCausesService: async (accessToken: string, causeID: string): Promise<AxiosResponse> => {
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }
        try {
            const response = await axios.delete(apiUrl+causeID, {headers})
            return response
        } catch (error) {
            throw error
        }
    }
}

import React, {useEffect, useState} from "react"
import {Box, Button, Grid} from "@mui/material";
import {Link} from "react-router-dom";

import QueuePlayNextTwoToneIcon from '@mui/icons-material/QueuePlayNextTwoTone';
import {getStorageData} from "../../utils/usePersistedState";

import ApiDeviceService from "../../services/client/devices/devices.service";
import {DataGrid, GridColDef, GridRowModes} from "@mui/x-data-grid";


const DevicesPage = () => {

    const [getLoading, setLoading] = useState<boolean>(true)
    const [getDevices, setDevices] = useState<any>([])

    useEffect(() => {
        getStorageData("authState",false).then(auth => {
            //@ts-ignore
            ApiDeviceService.fetchDevices(auth.status.access_token).then((res:any)=>{
                setLoading(false)
                setDevices(res.data.results)
            })
        })
    }, [])


    const columns: GridColDef[] = [
        {
            field: `device_unique_id`,
            headerName: `Cihaz Uniq Id`,
            width: 200,
            editable: true
        },
        {
            field: `device_name`,
            headerName: `Cihaz İsmi`,
            width: 200,
            editable: true
        },
        {
            field: `device_model`,
            headerName: `Cihaz Modeli`,
            width: 200,
            editable: true
        },
        {
            field: `device_type`,
            headerName: `Cihaz Tipi`,
            width: 200,
            editable: true
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width:200,
            cellClassName: 'actions',

        }
    ]


    return (
        getLoading ?
            <> Cihazlar Getiriliyor </> :
            <Box>
                <DataGrid
                    sx={{
                        height: 400,
                        margin: "10px"
                    }}
                    columns={columns}
                    rows={getDevices}
                />
            </Box>
    )
}

export default DevicesPage

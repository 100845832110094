import React, { useEffect, useState } from "react";

import { Box } from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {getStorageData} from "../../../../utils/usePersistedState";
import ApiDFDSService from "../../../../services/client/dfds/dfds.service";


interface IDiscountBillingPeriodSelector {
    fallback: (period: string) => void
    disable:boolean,
    inputLabel:string,
    defaultPeriod:number
}

const DiscountBillingPeriodSelector = (props:IDiscountBillingPeriodSelector) => {
    const [periods, setPeriods] = useState([])
    const [defaultPeriodIndex, setDefaultPeriodIndex] = useState("");
    const [loading, setLoading] = useState(true)

    const handleSelectChange = (event: SelectChangeEvent) => {
        //@ts-ignore
        const period = event.target.value;
        setDefaultPeriodIndex(period);
        props.fallback(period);
    };

    useEffect(() => {
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                ApiDFDSService.fetchPeriods({
                    //@ts-ignore
                    apiUrl: api.url,
                    //@ts-ignore
                    accessToken: auth.status.access_token,
                }).then((res:any)=>{
                    //@ts-ignore
                    const newPeriods = res.results.map((period: any, index: number) => ({
                        id: index,
                        period: period.period,
                        label_period: period.label_period,
                    }));
                    setPeriods(newPeriods)
                    console.log(newPeriods.length >= 2 ? newPeriods[props.defaultPeriod].label_period : "")
                    setDefaultPeriodIndex(newPeriods.length >= 2 ? newPeriods[props.defaultPeriod].period : "");
                    props.fallback(newPeriods[props.defaultPeriod].period)
                    setLoading(false)
                })
            })
        })

    }, [loading]);

    return(
        <Box sx={{
            m: 0,
        }}>
            <FormControl sx={{
                display: "flex",
                width: "180px",
                justifyContent: "center",
            }}>
                <InputLabel id="demo-simple-select-disabled-label">{ props.inputLabel }</InputLabel>
                <Box sx={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}>
                    <CalendarMonthIcon />
                </Box>
                <Select
                    labelId="demo-simple-select-disabled-label"
                    id="demo-simple-select-disabled"
                    value={defaultPeriodIndex}
                    label="Age"
                    onChange={handleSelectChange}
                    sx={{
                        backgroundColor: "transparent",
                        minHeight: 0,
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        borderRadius: "4px",
                    }}
                >
                    {
                        periods?.map((value, index) => (
                            <MenuItem
                                key={index}
                                value={
                                    //@ts-ignore
                                    value.period
                                }
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                <InputLabel
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 600
                                    }}
                                >  {
                                    //@ts-ignore
                                    value.label_period
                                }</InputLabel>
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </Box>
    )
}

export default DiscountBillingPeriodSelector

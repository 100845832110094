import { Avatar, Badge, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, MenuList, Typography } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';

import React, { useEffect, useState } from 'react'
// import { requestForToken } from '../../utils/firebase/requestForToken'
import { onMessageListener } from '../../utils/firebase/firebase'
import { messaging } from '../../firebase';
import { SnackbarWrapper } from '../snackbar/snackbar'
import {
    autoHideDuration,
    horizontal,
    severity,
    vertical
} from '../../types/enums/components/snackbar/snackbar.enums'
import { getStorageData } from '../../utils/usePersistedState';
import ApiNotificationsService from '../../services/client/notifications/notifications.service';
import { Box, display } from '@mui/system';
import { dateFormatting } from '../../utils/dateProvider/dateFormatting';
import BasicMenu from './filterNotification';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from "@mui/material/styles";
// @ts-ignore
import { PiConfettiBold } from 'react-icons/pi';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { ExpeditionStatus } from '../../types/enums/components/notification/expedition.status';
import List from '@mui/material/List';


export interface Notification {
    title: string;
    body: string;
    id: string;
    is_read: boolean;
    created_date: Date;
    name: string;
}


const Notifications = () => {
    const [notificationBadge, setNotificationBadge] = useState(0);
    const [totalNotifications, setTotalNotifications] = useState<Notification[]>([]);
    const [newMessageAlert, setNewMessageAlert] = useState(false)
    const [alertShow, setAlertShow] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();
    const open = Boolean(anchorEl);
    const today = new Date();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchNotifications = (is_read?: boolean) => {
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                ApiNotificationsService.fetchNotifications({ apiUrl: api.url, accessToken: auth.status.access_token, is_read: is_read })
                    .then((res: any) => {
                        setTotalNotifications(res.data.results)
                    })
                    .catch((error: any) => console.error(error));
            });
        })
    };

    useEffect(() => {
        fetchNotifications()
    }, []);

    useEffect(() => {
        const unreadNotificationsCount = totalNotifications?.filter(notification => !notification.is_read).length;
        setNotificationBadge(unreadNotificationsCount)
    }, [totalNotifications])


    // setTimeout(() => {
    //     fetchNotifications()
    // }, 3000);

    // const messageListener = async () => {
    //     try {
    //         const payload = await onMessageListener();
    //         const newNotification: Notification = {
    //             //@ts-ignore
    //             title: payload?.notification?.title || '',
    //             //@ts-ignore
    //             body: payload?.notification?.body || '',
    //         };
    //         setAlertShow(true);


    //     } catch (err) {
    //         console.log('failed: ', err);
    //     }
    // };

    // messageListener();

    // Current date
    // Current date

    const handleNotificationClick = async (id: string) => {
        console.log(`Notification clicked with id: ${id}`);

        await getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                const data = {
                    notifications: [id]
                };
                ApiNotificationsService.PostNotificationsRead({
                    //@ts-ignore
                    apiUrl: api.url, accessToken: auth.status.access_token, data
                })
                    .then((res: any) => {
                    })
                    .catch((error: any) => console.error(error));
            });
        })
        await fetchNotifications()
    };


    return (
        <>
            <SnackbarWrapper
                open={alertShow}
                autoHideDuration={autoHideDuration.ms3000}
                close={(status) => setAlertShow(status)}
                severity={severity.warning}
                message={"yeni mesajınız var"}
                anchorOrigin={{ vertical: vertical.bottom, horizontal: horizontal.right }}
            />
            <IconButton onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <Badge badgeContent={notificationBadge} color="secondary">
                    <NotificationsIcon color="action" />
                </Badge>
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                        maxWidth: '600px',
                        minWidth: "500px",
                        backgroundColor: theme.palette.background.default,
                    },
                }}
            >
                <BasicMenu totalNotifications={totalNotifications} setTotalNotifications={setTotalNotifications} fetchNotifications={fetchNotifications} />
                <Typography sx={{ marginLeft: "20px", fontWeight: 'bold' }}>Bildirimler</Typography>
                <Divider />

                <List
                    sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: "calc(100vh - 145px)",
                        '& ul': { padding: 0 },
                        '&::-webkit-scrollbar': {
                            display: "none",
                            msOverflowStyle: "none"
                        }
                    }}
                    subheader={<li />}
                >
                    {

                        <li>
                            <ul>
                                {totalNotifications.map((singleNotification, index) => {
                                    const notificationDate = new Date(singleNotification.created_date);
                                    const timeDifference = notificationDate.getTime() - today.getTime();
                                    const daysAgo = Math.floor(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));

                                    const notificationDay = notificationDate.getDate();
                                    const todayDay = today.getDate();
                                    return (
                                        <MenuItem onClick={() => handleNotificationClick(singleNotification.id)} key={index} sx={{ backgroundColor: theme.palette.mode == "dark" ? singleNotification.is_read ? "" : "#000000" : singleNotification.is_read ? "" : "#F1F9FF" }}
                                        >
                                            <ListItemIcon sx={{ marginRight: "10px", display: 'flex', alignItems: 'center' }}>
                                                {<CircleIcon sx={{ fontSize: "6px", marginRight: "5px", color: "#0096FF", opacity: singleNotification.is_read ? 0 : "", }} />}
                                                { //@ts-ignore
                                                    singleNotification.via_template.type._key === ExpeditionStatus.EXPEDITION_STATUS_CHANGE ?
                                                        <Avatar sx={{ backgroundColor: "#5CBAF7", marginLeft: "5px" }}>
                                                            <ChangeCircleIcon fontSize="large" sx={{ color: "#5CBAF7", backgroundColor: "#99D4FA" }} />
                                                        </Avatar>
                                                        :
                                                        <Avatar sx={{ backgroundColor: "#90EE90", marginLeft: "5px" }}>
                                                            <PiConfettiBold fontSize="large" style={{ color: 'green', }} />
                                                        </Avatar>
                                                }

                                            </ListItemIcon>
                                            <Typography >
                                                <Box sx={{
                                                    overflow: 'auto',
                                                    maxHeight: '100px',
                                                    '&::-webkit-scrollbar': {
                                                        display: "none",
                                                        msOverflowStyle: "none"
                                                    }
                                                }} >
                                                    <Typography sx={{ fontSize: "15px", whiteSpace: 'pre-wrap', }}> {singleNotification.body}</Typography>
                                                </Box>
                                                <Typography variant="caption" color="textSecondary" >
                                                    {singleNotification.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{
                                                        daysAgo > 7
                                                            ? dateFormatting(singleNotification.created_date)
                                                            : daysAgo === 0 && notificationDay !== todayDay
                                                                ? "1 gün önce"
                                                                : daysAgo < 1
                                                                    ? "Bugün"
                                                                    : daysAgo === 1
                                                                        ? "1 gün önce"
                                                                        : `${daysAgo} gün önce`
                                                    }
                                                </Typography>
                                            </Typography>
                                        </MenuItem>
                                    )

                                })}
                            </ul>
                        </li>
                    }
                </List>
            </Menu>
        </>
    )
}

export default Notifications

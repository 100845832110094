import React, {useEffect, useState} from "react";

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux"

//Routes
import * as ROUTES from "./contants/routes"

/**
 * Pages
 */
import Login from "./pages/auth/login";
import DashboardContent from "./pages/dashboard/dashboard";
import UserVCardPage from "./pages/user/user.vcard.page";
import Notfound from "./pages/notfound";
import AppWrapper from "./App.Wrapper";

//Redux Configs
import store, {useAppDispatch} from "./store";

//App Views
import AppView from "./App.view";
import AppModelView from "./App.model.view";
import AppActionView from "./App.action.view";
import {getStorageData, removeValue} from "./utils/usePersistedState";
import {fetchUserInfo} from "./services/user/user.service";
import {LoadingAnimation} from "./components/animation/animation";
import {ApiExpeditionsService} from "./services/client/expeditions/expeditions.service.new";
import FooterComponent from "./components/footer/footer.component";


interface IAuthRouting {
    userRoleControl?: boolean
    userPermissionControl?: boolean
}

export const AuthRouting = ({userRoleControl, userPermissionControl}: IAuthRouting) => (
    <Router>
        <Provider store={store}>
            <Routes>
                <Route path={ROUTES.AUTH.LOGIN} element={<Login userRoleControl={userRoleControl}
                                                                userPermissionControl={userPermissionControl}/>}/>
                <Route path={ROUTES.NOT_FOUND} element={<Notfound/>}/>
                <Route path={ROUTES.VCARD.INDEX} element={<UserVCardPage/>}/>
            </Routes>
        </Provider>
    </Router>
)

export const DashboardRouting = () => {

    const [getLoading, setLoading] = useState<boolean>(true)
    const [getUserInfoCheck, setUserInfoCheck] = useState<boolean>(false)

    const [getRoleStatus, setRoleStatus] = useState<boolean>()
    const [getUserPermissionStatus, setUserPermissionStatus] = useState<boolean>()

    const dispatch = useAppDispatch();

    useEffect(() => {
        //@ts-ignore
        getStorageData("authState", true).then(auth => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                dispatch(fetchUserInfo({apiUrl: api.url, accessToken: auth.status.access_token}))
                    .then(e => {
                        if (e.payload.role == null) {
                            setLoading(true)
                            setRoleStatus(true)
                            setUserInfoCheck(true)
                        } else if (e.payload.user_permissions.length == 0) {
                            if (e.payload.groups.length == 0) {
                                setLoading(true)
                                setUserPermissionStatus(true)
                                setUserInfoCheck(true)
                            } else {
                                setLoading(false)
                            }
                        } else {
                            setLoading(false)
                            setUserInfoCheck(false)
                        }
                    })
                    .catch(e => {
                        setUserInfoCheck(true)
                        removeValue("authState")
                    })
                //@ts-ignore
                // dispatch(ApiExpeditionsService.fetchExpeditions({apiUrl:api.url, access_token:auth.status.access_token})).then((e)=>{
                // })
            })
        })
    }, [getLoading])


    if (getLoading) {
        if (getUserInfoCheck) {
            return (
                <AuthRouting userPermissionControl={getUserPermissionStatus} userRoleControl={getRoleStatus}/>
            )
        } else {
            return (
                <LoadingAnimation
                    background={'black'}
                    bakcgroundOpacity={1}
                    truckBackground='white'
                />
            )
        }

    } else {
        return (
            <Router>
                <Provider store={store}>
                    <AppWrapper>
                        <Routes>
                            <Route path={ROUTES.APP.INDEX} element={<DashboardContent/>}/>
                            <Route path={ROUTES.APP.VIEW} element={<AppView/>}/>
                            <Route path={ROUTES.APP.MODEL_VIEW} element={<AppModelView/>}/>
                            <Route path={ROUTES.APP.ACTION_VIEW} element={<AppActionView/>}/>
                            {/*Error Routes*/}
                            <Route path={ROUTES.NOT_FOUND} element={<Notfound/>}/>
                        </Routes>
                        {/*<FooterComponent/>*/}
                    </AppWrapper>
                </Provider>
            </Router>
        )
    }

}

import L from "leaflet";
import {useMap} from "react-leaflet";
import OpenStreetMapLayerTool from "../tool/openStreet.map.layerTool";
import {useEffect, useState} from "react";

const truckIcon = L.icon({
    iconUrl: 'https://cdn0.iconfinder.com/data/icons/isometric-city-basic-transport/48/truck-front-01-512.png',
    iconSize: [32, 32],
});

interface IOpenStreetMapGeoMapJson {
    data: any,
    markerVehicle:(id: string) => void
}

const OpenStreetMapGeoJson = (props: IOpenStreetMapGeoMapJson) => {

    const [latLong, setLatLong] = useState([])
    const map = useMap();

    var popup = L.popup({
        maxWidth:400
    });

    useEffect(() => {
        OpenStreetMapLayerTool.removeLayers({map:map}).then((e)=>{
            props.data.map((res:any)=>{
                if(res.parent == null){
                    if (res.last_location.features[0].geometry != null){
                        console.log(res.km_metric,"DATA")
                        //@ts-ignore
                        L.circle([res.last_location.features[0].geometry.coordinates[1], res.last_location.features[0].geometry.coordinates[0]], {
                            color: 'rgba(250,0,0,1)',
                            fillColor: 'transparent',
                            radius: (res.km_metric * 1000)
                        }).addTo(map).on('mouseover', function (e){

                        }).bindPopup(popup).openPopup().setPopupContent("Yarıçap = "+(res.km_metric)+" KM"+" Alan = "+(res.km_metric*6) + " KM")

                        //@ts-ignore
                        // L.circle([res.last_location.features[0].geometry.coordinates], {
                        //     color: 'rgba(0,0,0,1)',
                        //     fillColor: 'rgba(0,0,0,0.4)',
                        //     radius: 40000
                        // }).addTo(map).on('mouseup', function (e){
                        //     console.log(res.id, res.last_location.features[0].geometry.coordinates,"CIRCLE")
                        // })
                    }
                }

                L.geoJson(res.first_location, {
                    pointToLayer: function (geoJsonPoint, latlng) {
                        return (
                            L.marker(latlng, {
                                icon: truckIcon
                            }).addTo(map.setView(latlng, 40)).on('mousedown', function (e){
                                console.log(res.id, res.first_location.features[0].geometry.coordinates,"FIRST LOCATION")
                                //props.markerVehicle(res.id)
                            })
                            // L.circle(latlng, {
                            //     color: 'rgba(0,0,0,1)',
                            //     fillColor: 'rgba(0,0,0,0.4)',
                            //     radius: 40000
                            // }).addTo(map)
                        )
                    },
                }).addTo(map)
            })
            props.data.map((res:any)=>{
                //console.log(res,"last_location")
                L.geoJson(res.last_location, {
                    pointToLayer: function (geoJsonPoint, latlng) {
                        return L.marker(latlng, {
                            icon: truckIcon
                        }).addTo(map.setView(latlng, 40)).on('mousedown', function (e){
                            console.log(res.id, res.first_location.features[0].geometry.coordinates, res.parent, "LAST LOCATION")
                            //props.markerVehicle(res.id)
                        })
                    },
                }).addTo(map)
            })
        })
    }, [props.markerVehicle]);





    //@ts-ignore


    return null
}

export default OpenStreetMapGeoJson

import axios, {AxiosResponse} from "axios"
import {slug} from "../../../config";

interface IWarehousesService {
    fetchWarehouses(params: { accessToken: string, apiUrl: string }): Promise<AxiosResponse>
    createWarehouses(params: { accessToken: string, apiUrl: string }): Promise<AxiosResponse>
    updateWarehouses(params: { accessToken: string, apiUrl: string, data: object }): Promise<AxiosResponse>
    deleteWarehouses(params: { accessToken: string, apiUrl: string, id: string }): Promise<AxiosResponse>
}

const ApiWarehousesService: IWarehousesService = {
    fetchWarehouses: async (params: {
        accessToken: string;
        apiUrl: string
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${params.accessToken}`
            }
        }
        try {
            const response = await axios.get(params.apiUrl+slug.client.warehouses.url, config)
            return response
        } catch (error) {
            throw error
        }
    },
    createWarehouses: async (params: {
        accessToken: string,
        apiUrl: string,
        data:object
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${params.accessToken}`
            }
        }
        try {
            const response = await axios.post(params.apiUrl, params.data, config)
            return response
        }
        catch (error){
            throw error
        }
    },
    updateWarehouses: async (params: {
        accessToken: string;
        apiUrl: string;
        data:object
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${params.accessToken}`
            }
        }
        try {
            const response = await axios.put(
                params.apiUrl+slug.client.warehouses.url,
                params.data,
                config
            )
            return response
        }
        catch (error){
            throw error
        }
    },
    deleteWarehouses: async (params: {
        accessToken: string;
        apiUrl: string;
        id: string
    }): Promise<AxiosResponse> => {
        const config = {
            headers:{
                Authorization: `Bearer ${params.accessToken}`
            }
        }
        try {
            const response = await axios.delete(params.apiUrl+slug.client.warehouses.url+params.id, config)
            return response
        }
        catch (error){
            throw error
        }
    }
}

export default ApiWarehousesService

import React, {useEffect, useRef, useState} from 'react'
import {Box, Grid, Typography} from '@mui/material';
import {getStorageData} from "../../utils/usePersistedState";
import ApiMapsService from "../../services/client/maps/maps.service";
import {LoadingAnimation} from "../animation/animation";
import {slug} from "../../config";
import OpenStreetMapLayerTool from "./openStreet/tool/openStreet.map.layerTool";
import LocationsSnakeAnim from "./animation/locations.snake.anim";
/**
 * React Leaflet bileşenlerini içe aktarır.
 * @module react-leaflet-import
 */
import {MapContainer, Marker, Popup, TileLayer, ZoomControl} from 'react-leaflet'
/**
 * Leaflet kütüphanesinin CSS stil sayfasını içe aktarır.
 * @module leaflet-css-import
 */
import 'leaflet/dist/leaflet.css';

/**
 * Leaflet Draw CSS stil sayfasını içe aktarır.
 * @module leaflet-draw-import
 */
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-draw'

import L from "leaflet"

//Interfaces
import {IOpenStreetMap} from "../../types/interfaces/components/geoMaps/openStreet.map.interface";
import ExpeditionVehicleSteppers from "../timelines/timelines";
import {dateFormatting} from "../../utils/dateProvider/dateFormatting";

//Snackbar
import {SnackbarWrapper} from "../snackbar/snackbar";

import {autoHideDuration, horizontal, severity, vertical} from "../../types/enums/components/snackbar/snackbar.enums";

// @ts-ignore
import OpenStreetMapGeoJson from "./openStreet/geoJson/openStreet.map.geoJson";
import {ApiSiberService} from "../../services/client/siber/siber.services";
import ApiLocationsService from "../../services/client/locations/locations.service";
import OpenStreetMapTool from "./openStreet/tool/openStreet.map.tool";
import OpenStreetMapPolyline from "./openStreet/tool/openStreet.map.polyline";
import OpenStreetMapDrawTool from "./openStreet/tool/openStreet.map.drawTool";
import OpenStreetMapToolRoutingMachine from "./openStreet/tool/openStreet.map.tool.routingMachine";
import OpenStreetMapMarkerClusterTool from "./openStreet/tool/openStreet.map.markerCluster.tool";
import {ApiMobilizService} from "../../services/client/mobiliz/mobiliz.service";
import DateProvider from "../../utils/dateProvider/dateProvider";

const OpenStreetMap = (props: IOpenStreetMap) => {
    console.log(props,"%c{Open Street Map Polyline")
    console.log('%c{Open Street Map Polyline}-Activity Detail', 'color: red; background-color: white')

    const mapRef = useRef(null);

    const [realActivitiesLoading, setRealActivitiesLoading] = useState<boolean>(false)
    const [waitingActivitiesLoading, setWaitingActivitiesLoading] = useState<boolean>(false)

    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");

    const truckIcon = L.icon({
        iconUrl: 'https://cdn0.iconfinder.com/data/icons/isometric-city-basic-transport/48/truck-front-01-512.png',
        iconSize: [32, 32],
    });

    const limeOptions = {color: 'lime'}

    const [latitude, setLatitude] = useState<number>(0)

    const [longitude, setLongitude] = useState<number>(0)

    const [geoDatas, setGeoDatas] = useState([])

    const [vehicles, setVehicles] = useState([])
    const [map, setMap] = useState<string>("")

    const [yuklemesehir, setYuklemesehir] = useState<[number, number]>([0, 0])
    const [bosaltmasehir, setBosaltmasehir] = useState<[number, number]>([0, 0])


    useEffect(() => {
        //TODO: İşaretleyici Oluşturma Aracı Oluşturulacak.
        setAlertOpen(false)
        props.activityUrl == slug.client.mobiliz.activity.last.url ?
            getStorageData("authState", true).then((auth) => {
                getStorageData("api", false).then((api) => {
                    setRealActivitiesLoading(true)
                    if (
                        props.data.expedition.results[0].trailer.id_no ||
                        props.data.expedition.results[0].truck.id_no ||
                        props.data.expedition.results[0].loads[0]?.trailer.id_no ||
                        props.data.vehicle.results[0].id
                    ) {
                        /**
                         * API'den konum verilerini sağlayan bir servisi kullanarak konum verilerini alır.
                         *
                         * @param {Object} options - Konum verilerini almak için kullanılan seçenekler.
                         * @param {string} options.apiUrl - Verilerin alınacağı API'nin URL'si.
                         * @param {string} options.accessToken - Kimlik doğrulama için erişim belirteci.
                         * @param {string} options.search - Aranacak konumların virgülle ayrılmış listesi.
                         * @returns {Promise<Object>} API'den gelen yanıt verilerini çözen bir Promise.
                         * @throws {Error} Verilerin alınmasında hata oluşursa veya yanıt geçerli değilse.
                         *
                         */
                        /*ApiSiberService.fetchSiberLoadsService({
                            //@ts-ignore
                            accessToken: auth.status.access_token,
                            id_no: props.data.expedition.results[0].loads[0]?.results[0].trailer.id_no
                        }).then((res) => {
                            ApiLocationsService.fetchLocations({
                                //@ts-ignore
                                apiUrl: api.url,
                                //@ts-ignore
                                accessToken: auth.status.access_token,
                                //@ts-ignore
                                search: res.data.results[0].field_yuklemesehir,

                            }).then((locations) => {
                                setYuklemesehir([locations.data.lat, locations.data.lon])
                            })

                            ApiLocationsService.fetchLocations({
                                //@ts-ignore
                                apiUrl: api.url,
                                //@ts-ignore
                                accessToken: auth.status.access_token,
                                //@ts-ignore
                                search: res.data.results[0].field_bosaltmasehir,
                            }).then((locations) => {
                                setBosaltmasehir([locations.data.lat, locations.data.lon])
                            })
                        })*/

                        console.log('%c{Open Street Map} - Fetch Position', 'color: black; background-color: white',
                            "Trailer Id No:",props.data.expedition.results[0].trailer.id_no,
                            "Truck Id No:",props.data.expedition.results[0].truck.id_no
                        );

                        /**
                         * Api Maps Service / Fetch Positions
                         * **/
                        ApiMapsService.fetchPositions({
                            //@ts-ignore
                            apiUrl: api.url,
                            slug: props.activityUrl,
                            //@ts-ignore
                            accessToken: auth.status.access_token,
                            id: props.data.expedition.results[0].trailer.id_no ?
                                props.data.expedition.results[0].trailer.id_no :
                                props.data.expedition.results[0].loads[0]?.trailer.id_no ?
                                    props.data.expedition.results[0].loads[0]?.trailer.id_no:
                                    props.data.expedition.results[0].truck.id_no || props.data.vehicle.results[0].id,
                            startTime: props.event.firstTime,
                            endTime: props.event.lastTime,
                        }).then(res => {
                            if (res.data.result.length > 0 && !props.data.expedition.results[0].is_completed) {
                                setLatitude(res.data.result[0].latitude ? res.data.result[0].latitude : 0)
                                setLongitude(res.data.result[0].longitude ? res.data.result[0].longitude : 0)
                                //@ts-ignore
                                mapRef.current?.setView([res.data.result[0].latitude, res.data.result[0].longitude], 15)
                                setRealActivitiesLoading(false)
                                setVehicles(res.data.result)
                            } else {
                                setLongitude(0)
                                setLatitude(0)
                                //@ts-ignore
                                mapRef.current?.setView([38, 38], 4)
                                setRealActivitiesLoading(false)
                            }
                        }).catch((e:any) => {
                            console.log('%c{Open Street Map} - Fetch Position', 'color: red; background-color: white',e);
                            setRealActivitiesLoading(true)
                        })
                    } else {
                        setRealActivitiesLoading(false)
                        setLatitude(0)
                        setLongitude(0)
                    }
                })
            }) :
            getStorageData("authState", true).then((auth) => {
                getStorageData("api", false).then((api) => {
                    setWaitingActivitiesLoading(true)
                    if (
                        props.data.expedition.results[0].loads[0]?.trailer.id ||
                        props.data.expedition.results[0].truck.id ||
                        props.data.vehicle.results[0].id
                    ) {
                        ApiMapsService.fetchPositions({
                            //@ts-ignore
                            apiUrl: api.url,
                            slug: props.activityUrl,
                            //@ts-ignore
                            accessToken: auth.status.access_token,
                            id: props.data.vehicle.results[0].id || props.data.expedition.results[0].loads[0]?.id,
                            startTime: props.event.firstTime,
                            endTime: props.event.lastTime,
                            expedition_no:
                                props.data.expedition.results[0].position_no ? props.data.expedition.results[0].position_no :
                                    props.data.expedition.results[0].loads[0]?.load_no ? props.data.expedition.results[0].loads[0]?.load_no : {}
                        }).then((res:any) => {
                            //@ts-ignore
                            setGeoDatas([res.data.results[0].first_location, res.data.results[0].last_location])
                            setVehicles(res.data.results)
                            setWaitingActivitiesLoading(false)
                        }).catch((e:any) => {
                            setLatitude(0)
                            setLongitude(0)
                            setWaitingActivitiesLoading(false)
                        })
                    } else {
                        setWaitingActivitiesLoading(false)
                        setLatitude(0)
                        setLongitude(0)
                    }
                })
            })
        getStorageData("theme", false).then(res => {
            //@ts-ignore
            if (res.isDarkMode) {
                setMap('https://tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey=2c4e5f4cf1214424ba074479d9071403')
            } else {
                setMap('https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=2c4e5f4cf1214424ba074479d9071403')
            }
        })
        /*if (
            props.data.expedition.results[0].loads[0]?.results[0].trailer.id_no == undefined &&
            props.data.expedition.results[0].trailer.id_no == undefined &&
            props.data.expedition.results[0].truck.id_no == undefined &&
            props.data.vehicle.results[0].id) {
            setAlertOpen(true)
            setAlertMessage("Yük'e Ait Römork Bilgisi Bulunamadı.")
        } else {
            if (props.data.expedition.results[0].is_completed && props.activityUrl == slug.client.mobiliz.activity.last.url) {
                setAlertOpen(true)
                setAlertMessage("Araç Boşaltıldı, Canlı Konum Bulunamadı.")
            }
        }*/
    }, [
        props.styles.height,
        map,
        props.activityUrl,
        props.data.expedition.results[0].trailer.id_no,
        props.data.expedition.results[0].truck.id_no,
        props.data.expedition.results[0].is_completed,
        props.data.vehicle.results[0].id,
        props.event.lastTime
    ])

    const handleAlert = () => {
        return alertOpen
    }

    const tileLayerOptions = {
        url: map,
        className: 'map-tiles',
        tileSize: 256,
        zoomOffset: 0,
        minZoom: 0,
        maxZoom: 18
    };
    // @ts-ignore

    return (
        <MapContainer
            minZoom={3} // Minimum zoom seviyesi
            maxZoom={15} // Maksimum zoom seviyesi
            center={[39.6478625, 27.8118136]}
            zoom={5}
            ref={mapRef}
            scrollWheelZoom={true}
            zoomControl={false}
            boundsOptions={{padding: [50, 50]}}
            style={{
                width: '100%',
                height: `calc(100vh - ${props.styles.height})`
            }}>
            <TileLayer{...tileLayerOptions} />
            <OpenStreetMapTool
                leftDrawerDisplay={() => props.leftDrawerDisplay()}
                clusterDisplay={()=>props.clusterDisplay()}
                expedition_no={props.data.expedition.results[0].position_no ?props.data.expedition.results[0].position_no : "--------"}
                trailer={{
                    plate:props.data.expedition.results[0].trailer.plate ? props.data.expedition.results[0]?.trailer.plate:"--------"
                }}
                truck={{
                    plate:props.data.expedition.results[0].truck.plate ? props.data.expedition.results[0].truck.plate : "--------"
                }}
            />
            <OpenStreetMapDrawTool/>
            <OpenStreetMapPolyline
                id_no={
                    props.data.expedition.results[0]?.trailer.id_no ?
                        props.data.expedition.results[0]?.trailer.id_no :
                        props.data.expedition.results[0]?.truck.id_no
                }
                //@ts-ignore
                startTime={props.data.expedition.results[0]?.start_date?.slice(0, 19)}
                endTime={
                    props.data.expedition.results[0]?.end_date ?
                    props.data.expedition.results[0]?.end_date?.slice(0, 19) :
                        DateProvider({daysAgo: 0, hours: 23, min: 59, sec: 59, ms: 59})}
            />
            <OpenStreetMapMarkerClusterTool
                id_no={
                    props.data.expedition.results[0]?.trailer.id_no ?
                    props.data.expedition.results[0]?.trailer.id_no :
                    props.data.expedition.results[0]?.truck.id_no
            }
            />
            {/*<OpenStreetMapToolRoutingMachine/>*/}
            {
                realActivitiesLoading ? <></> :
                    <>
                        <SnackbarWrapper
                            open={alertOpen}
                            autoHideDuration={autoHideDuration.ms3000}
                            close={status => console.log(status)}
                            severity={severity.warning}
                            message={alertMessage}
                            anchorOrigin={{vertical: vertical.bottom, horizontal: horizontal.center}}
                        />
                        {
                            latitude != 0 && longitude != 0 ? <Marker
                                position={[latitude, longitude]} title={"Araç Bilgisi"} icon={truckIcon}
                                eventHandlers={{
                                    click: (e) => {
                                        //@ts-ignore
                                        console.log(e)
                                    }
                                }}
                            >
                                <Popup maxWidth={1000} maxHeight={1000}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <div style={{
                                            margin: 8
                                        }}>
                                            <Typography>
                                                {
                                                    //@ts-ignore
                                                    vehicles[0]?.plate
                                                }
                                            </Typography>
                                            <ExpeditionVehicleSteppers first_location={
                                                //@ts-ignore
                                                dateFormatting(vehicles[0]?.time)
                                            } last_location={"."}/>
                                        </div>
                                    </div>
                                </Popup>
                            </Marker> : <></>
                        }
                    </>
            }
            {
                waitingActivitiesLoading ? <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                            position: "absolute",
                            zIndex: "499",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadingAnimation
                            background={'black'}
                            bakcgroundOpacity={0.7}
                            truckBackground={'white'}
                        />
                    </Box> :
                    Object.keys(geoDatas).length > 0 ?
                        <OpenStreetMapGeoJson data={vehicles} markerVehicle={(id) => console.log(id)}/>
                        : <></>
            }
            <ZoomControl position={"bottomright"}/>
        </MapContainer>
    )
}

export default OpenStreetMap

import React from "react"
import axios from "axios";

import { slug } from "../../../config";


export const createWaitReason = (apiUrl:string, token:string, data:object, activity:number) => {
    const response = axios.post(apiUrl+slug.client.report.wait.activity.url + "/" + activity + "/wait-reasons", data, {
        headers: {
            'Authorization': 'Bearer '+token.valueOf(),
            'Content-Type' : 'application/json'
        }
    });
    return response;
}

export const fetchWaitReason = async (apiUrl:string,token:string, activity:number) => {
    const response = await axios.get(apiUrl + "/" + activity + "/wait-reasons", {
        params:{
            activity_id:activity
        },
        headers:{
            'Authorization': 'Bearer '+token.valueOf()
        }
    })
}

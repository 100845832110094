import React, {useEffect, useState} from "react"
import {LoadingAnimation} from "../animation/animation";
import PrivateRouting from "../../Private.Routing";
import {getStorageData, usePersistedState} from "../../utils/usePersistedState";
import oAuthInitialState from "../../types/models/storage/auth/oAuth.default.model";
import AppControl from "../../utils/versionControl/versionControl";
import packageJson from "../../../package.json"

export const AuthLoading = () => {
    useEffect(() => {

        const timer = setTimeout(() => {
            window.location.href = "/"
        }, 1500);
        return () => clearTimeout(timer);
    }, []);
    return (
        <LoadingAnimation
            background={'black'}
            bakcgroundOpacity={1}
            truckBackground='white'
        />
    )
}

interface IOnBoardingLoading {
    versionControl: boolean
}

/**
 * OnBoardingLoading bileşeni, kullanıcının oturum durumuna göre yüklenme ekranını
 * veya gizli yönlendirme bileşenini render eder.
 *
 * @function OnBoardingLoading
 * @returns {JSX.Element} - Yüklenme ekranını veya gizli yönlendirme bileşenini oluşturan JSX öğesi.
 */
export const OnBoardingLoading = () => {
    const [isLoading, setLoading] = useState<boolean>(true)

    const [isLoggedIn, setLoggedIn] = useState<boolean>(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            getStorageData("authState", true).then((auth) => {
                //@ts-ignore
                setLoggedIn(auth.isLoggedIn)
            })
            setLoading(false)
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    return (
        isLoading ?
            <LoadingAnimation
                background={'black'}
                bakcgroundOpacity={1}
                truckBackground='white'
            />
            :
            <PrivateRouting isLoggedIn={isLoggedIn}/>
    )
}

export const VersionControlLoading = () => {

    const [ loading, isLoading ] = useState<boolean>(true)

    const [versionControl, setVersionControl] = useState<boolean>(true)
    const [versionTitle, setVersionTitle] = useState<string>()

    useEffect(() => {

        AppControl.versionControl({message:(res:any)=>setVersionTitle(res)}).then((res:any) => {
            if (res) {
                setVersionControl(res)
            } else {
                setVersionControl(res)
            }
        })
        const timer = setTimeout(() => {
            isLoading(versionControl)
        }, 1000);
        return () => clearTimeout(timer);

    }, [versionControl])

    return (
        loading ?

            <LoadingAnimation
                background={'black'}
                bakcgroundOpacity={1}
                truckBackground='white'
                title={versionTitle ? packageJson.version + versionTitle : "" }
            />
            :
            <OnBoardingLoading/>
    )
}

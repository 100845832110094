import React, {useEffect, useState} from "react"

import {ApiIntegrationsHoldersService} from "../../../services/client/integrations/integrations.holders.service";
import {getStorageData} from "../../../utils/usePersistedState";
import {DataGrid, GridColDef, GridRowId, GridRowModel, GridRowModes, GridRowModesModel} from "@mui/x-data-grid";
import Box from "@mui/material/Box";


const IntegrationsHoldersPage = () => {
    const [getLoading, setLoading] = useState<boolean>(true)

    const [getHolders, setHolders] = useState<any>([])

    const [getRows, setRows] = React.useState(getHolders);
    const [getRowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...getRowModesModel, [id]: {mode:GridRowModes.Edit} });
    }

    const handleCancelClick = (id:GridRowId) => () => {
        setRowModesModel({
            ...getRowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}
        })
    }

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel((oldModel)=>({
            ...oldModel,
            [id]: {mode: GridRowModes.View}
        }))
    }

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false};
        setRows(getRows.map((row:any) => ( row.id === newRow.id ? updatedRow : row )))
        return updatedRow;
    }

    useEffect(()=>{
        getStorageData('authState',false).then((auth)=>{
            //@ts-ignore
            ApiIntegrationsHoldersService.fetchIntegrationsHoldersService(auth.status.access_token).then((res:any)=>{
                setHolders(res.data.results)
                setLoading(false)
            })
        })
    })

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width:200,
            editable: true
        },
        {
            field: 'slug',
            headerName: 'Slug',
            width:200,
            editable: true
        }
    ]

    useEffect(()=>{
        getStorageData("authState",false).then(auth => (
            //@ts-ignore
            ApiIntegrationsHoldersService.fetchIntegrationsHoldersService(auth.status.access_token).then((res:any)=>{
                setHolders(res.data.results)
                setLoading(false)
            })
        ))
    },[])
    return(
        getLoading ? <>Veriler Yükleniyor</> :
            <Box>
                <DataGrid
                    sx={{
                        height:400,
                        margin: "10px"
                    }}
                    columns={columns}
                    rows={getHolders}
                    editMode="row"
                    rowModesModel={getRowModesModel}
                    processRowUpdate={processRowUpdate}
                ></DataGrid>
            </Box>
    )
}

export default IntegrationsHoldersPage

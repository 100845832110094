import React, {useEffect, useState} from "react"
import {SubmitHandler, useForm} from "react-hook-form";
import {AdvertCategoryInputs} from "../../../../types/types/components/forms/form.type";
import {useNavigate} from "react-router-dom";
import {getStorageData} from "../../../../utils/usePersistedState";
import {
    advertCategoryDetailService,
    advertCategoryEditService
} from "../../../../services/client/adverts/adverts.service";
import {Box, Button, Card, CardContent, Container, Grid, TextField} from "@mui/material";

//@ts-ignore
export const AdvertCategoryEditForm = ({advertCategoryId}) => {

    const [getLoading, setLoading] = useState(true)

    const { register, handleSubmit, watch, formState: { errors } } = useForm<AdvertCategoryInputs>();

    const [getAdvertCategory, setAdvertCategory] = useState([]);

    const navigate = useNavigate();
    const handleClick = () => navigate(-1);

    useEffect(()=>{
        getStorageData("authState",false).then(auth=>(
            //@ts-ignore
            advertCategoryDetailService(auth.status.access_token, advertCategoryId).then((res:any)=>{
                setAdvertCategory(res)
                //@ts-ignore
                console.log(getAdvertCategory.name )
                setLoading(false)
            })
        ))
    },[])

    const onSubmitForm: SubmitHandler<AdvertCategoryInputs> = (data, event) => {
        getStorageData("authState",false).then(auth=>(
            //@ts-ignore
            advertCategoryEditService(data, auth.status.access_token,advertCategoryId).then((res:any)=>handleClick())
        ))
    }

    return(
        getLoading ? <>Loading...</> :
            <Container>
                <Card>
                    <CardContent>
                        <Box component="form" onSubmit={handleSubmit(onSubmitForm)}>
                            <Grid md={12}>
                                <Grid md={6} sx={{
                                    margin:2
                                }}>
                                    <TextField
                                        label="Başlık"
                                        fullWidth
                                        id="standard-multiline-static"
                                        multiline
                                        rows={2}
                                        variant="standard"
                                        //@ts-ignore
                                        {...register(`descriptions`)}
                                    />
                                </Grid>
                                <Grid md={6} sx={{
                                    margin:2
                                }}>
                                    <TextField fullWidth id="outlined-basic"
                                               label="Başlık"
                                        //@ts-ignore
                                               defaultValue={getAdvertCategory.name}
                                               variant="outlined"
                                               {...register(`name`)}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                variant="contained"
                                type="submit"
                                onSubmit={handleSubmit(onSubmitForm)}
                            >
                                İlan Güncelle
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
    )
}


export default AdvertCategoryEditForm

import React from "react"
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import {Tooltip} from "@mui/material";
interface ISearchBarProps {
    placeHolder:string
    onChangeText?:(text:string) => void
    disable?:boolean
    tooltipTitle:string
}


const SearchBar:React.FC<ISearchBarProps> = (props, context) => {
    const theme = useTheme();

    //@ts-ignore
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            //@ts-ignore
            props.onChangeText(event.target.value)
        }
    }

    return(
        <Tooltip title={props.tooltipTitle}>
            <TextField
                disabled={props.disable}
                size="small"
                id="outlined-basic"
                label={props.placeHolder}
                variant="outlined"
                fullWidth
                onChange={(e)=> handleKeyDown(e.target.value)}

                //@ts-ignore
                onKeyDown={handleKeyDown}
                inputProps={{
                    style: {
                        height: "17px",
                        borderRadius:"200px",
                        color: `${theme.palette.text.primary}`
                    },
                }}
                InputLabelProps={{
                    style:{
                        fontSize: "12px",
                        fontWeight: 600,
                        color: `${theme.palette.text.primary}`
                    }
                }}
            />
        </Tooltip>

    )
}

export default SearchBar

import React from "react"

import {UserPermission} from "./types/permissionProvider.type";

import PermissionContext from "./permissionContext";

import {PermissionProviderType, PermissionCache} from "./types/permissionProvider.type";

const PermissionProvider = ({fetchPermission, children}:PermissionProviderType) => {
    const cache: PermissionCache = {};

    const isAllowedTo = async (permission: UserPermission): Promise<boolean> => {
        //@ts-ignore
        if (Object.keys(cache).includes(permission)) {
            //@ts-ignore
            return cache[permission];
        }
        const isAllowed = await fetchPermission(permission);
        //@ts-ignore
        cache[permission] = isAllowed;
        return isAllowed;
    };

    return (
        <PermissionContext.Provider value={{ isAllowedTo }}>
            {children}
        </PermissionContext.Provider>
    );
}


export default PermissionProvider

import React, {useEffect, useState} from "react";
import Switch, {SwitchProps} from '@mui/material/Switch';
import {styled} from '@mui/material/styles';
import {slug} from "../../../config";
import {Box, Typography} from "@mui/material";
import {useAppSelector} from "../../../store";
import {UserRoles} from "../../../types/enums/reducers/user.enums";


const ExpeditionActivityOptions = [
    {
        label: "Sefer Numarası",
        waitingActivity: slug.client.report.wait.activity.url,
        liveActivity: slug.client.mobiliz.activity.last.url,
        isActive: false,
    }
]

interface SwitchOption {
    label: string;
    waitingActivity: string;
    liveActivity: string;
    isActive: boolean;
}

interface IExpeditionActivitySelector {
    fallback: (status: string) => void
}

const ExpeditionActivitySwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 90,
    height: 60,

    '& .MuiSwitch-switchBase': {
        margin: 14,
        padding: 0,
        left: 0,
        transform: 'translateX(1px)',
        '&.Mui-checked': {
            right: 0,
            color: '#fff',
            transform: 'translateX(15px)',
            '&:hover': {
                backgroundColor:"transparent",
            },
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="2 2 20 20"><path fill="rgba(72, 72, 72, 1)" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                borderRadius: 10,
                backgroundColor: `${theme.palette.background.paper}`,
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="2 2 20 20"><path fill="rgba(72, 72, 72, 0.25)" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path></svg>')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "left",
                backgroundPositionX:"5px"
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: `${theme.palette.background.default}`,
        width: 32,
        height: 32,
        borderRadius: 6,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="2 2 20 20"><path fill="rgba(72, 72, 72, 1)" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: `${theme.palette.background.paper}`,
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="2 2 20 20"><path fill="rgba(72, 72, 72, 0.25)" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path><path fill="rgba(72, 72, 72, 0.25)" d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>')`,
        opacity: 1,
        borderRadius: 10,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        backgroundPositionX: "37px"
    },
}));


const ExpeditionActivitySelector = (props: IExpeditionActivitySelector) => {

    const [switchOptions, setSwitchOptions] = useState<SwitchOption[]>(ExpeditionActivityOptions)

    const [value, setValue] = useState<string>(slug.client.mobiliz.activity.last.url);

    const userInfo = useAppSelector((state) => state.user);

    const switchOption = (index: number) => {
        setSwitchOptions((prevOptions) => {
            const updatedOptions = prevOptions.map((option, i) => {
                if (i === index) {
                    return {
                        ...option,
                        isActive: !option.isActive
                    };
                }
                return option;
            });
            setValue(updatedOptions[index].isActive ? updatedOptions[index].waitingActivity : updatedOptions[index].liveActivity)
            return updatedOptions
        })
    }

    useEffect(() => {
        props.fallback(value)
    }, [value])

    return (
        //@ts-ignore
        userInfo.data.role == UserRoles.EMPLOYEE || userInfo.data.role == UserRoles.ADMIN || userInfo.data.role == UserRoles.DEVELOPER ?
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            }}>
                {switchOptions.map((option, index) => (
                    <ExpeditionActivitySwitch key={index} onChange={() => switchOption(index)} sx={{m: 1}}/>
                ))}
            </Box>
            :
            <></>
    )
}

export default ExpeditionActivitySelector

import React from "react"
import Box from "@mui/material/Box";
import logo from "../../assets/logo/abc_logo.png";
import CircularProgress from "@mui/material/CircularProgress";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

interface ILoadingAnimation {
    background:string,
    bakcgroundOpacity:number,
    truckBackground:string,
    title?:string
}

export const LoadingAnimation = (props:ILoadingAnimation) => {
    return (
        <Box sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            position: "relative",
            width: '100%',
            height: '100vh',
            backgroundColor: props.background,
            opacity: props.bakcgroundOpacity
        }}>
            <Grid md={12}>
                <Grid md={12}>
                    <Box sx={{
                        position: "relative",
                        width: "150px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: props.truckBackground,
                        border: "solid",
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        borderColor: props.truckBackground
                    }}>
                        <Box
                            sx={{
                                backgroundImage: `url(${logo})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: "100px",
                                height: "22px",
                            }}
                        />
                        <Box
                            sx={{
                                borderRadius: 100,
                                width: "16px",
                                height: "16px",
                                border: "solid",
                                borderWidth: "2px",
                                borderColor: props.truckBackground,
                                backgroundColor: props.truckBackground,
                                position: "absolute",
                                bottom: "-10px",
                                left: "20px"
                            }}
                        >
                            <CircularProgress
                                size={17}
                                thickness={8}
                                sx={{
                                    color: props.background,
                                    position: "absolute",
                                    right: -2,
                                    bottom: -2
                                }}
                            />
                        </Box>
                        <Box sx={{
                            borderRadius: 100,
                            width: "16px",
                            height: "16px",
                            border: "solid",
                            borderWidth: "2px",
                            borderColor: props.truckBackground,
                            backgroundColor: props.truckBackground,
                            position: "absolute",
                            bottom: "-10px",
                            right: "20px"
                        }}>
                            <CircularProgress
                                size={17}
                                thickness={8}
                                sx={{
                                    color: props.background,
                                    position: "absolute",
                                    right: -2,
                                    bottom: -2
                                }}
                            />
                        </Box>

                        <Box sx={{
                            position: "absolute",
                            right: "-34px",
                            bottom: 0,
                            top: "12px",
                            width: "30px",
                            height: "25px",
                            border: "solid",
                            borderWidth: "1px",
                            borderColor: props.truckBackground,
                            backgroundColor: props.truckBackground,
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "4px",
                        }}
                        >
                            <Box sx={{
                                position: "absolute",
                                border: "solid",
                                borderWidth: "1px",
                                backgroundColor: props.truckBackground,
                                width: "20px",
                                height: "15px",
                                top: "-16px",
                                left: "-2px",
                                borderTopRightRadius: 100
                            }}/>
                            <Box sx={{
                                borderRadius: 100,
                                width: "16px",
                                height: "16px",
                                border: "solid",
                                borderWidth: "2px",
                                borderColor: props.truckBackground,
                                backgroundColor: props.truckBackground,
                                position: "absolute",
                                bottom: "-8px",
                                left: "5px"
                            }}>
                                <CircularProgress
                                    size={17}
                                    thickness={8}
                                    sx={{
                                        color: props.background,
                                        position: "absolute",
                                        right: -2,
                                        bottom: -2
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid md={12} sx={{
                    textAlign:"center"
                }}>
                    <Typography sx={{
                        color:"white",
                        marginTop:"1rem"
                    }}>
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

import React from 'react';

import axios, {AxiosResponse} from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import {slug} from  "../../config"

import { IOAuthLoginConfig, IOAuthRegisterConfig } from "../../types/interfaces/services/auth/auth.config.interface";
import {setStorageData} from "../../utils/usePersistedState";


/**
 * This Function Fetch OAuth Login
 * @param {string} - Client ID
 * @param {string} - Client Secret
 * @param {string} - Client Grant Type
 * @returns {object} - Response
 */
export const fetchOAuthLogin = createAsyncThunk("fetchOAuthLogin", async (config:IOAuthLoginConfig) => {
    config.client_id = slug.auth.client_id
    config.client_secret = slug.auth.client_secret
    config.grant_type = slug.auth.grant_type

    const response = await axios.post(slug.auth.login, {...config}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
    return response.data

})

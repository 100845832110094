import React, {FC, useEffect} from "react";

import { AuthRouting, DashboardRouting } from "./Routing";
import {RootState, useAppSelector} from "./store";

interface IPrivateRouting {
    isLoggedIn:boolean
}

/**
 * PrivateRouting bileşeni, kullanıcının oturum durumuna bağlı olarak dashboard'u render eder.
 *
 * @component PrivateRouting
 * @param {Object} props - PrivateRouting bileşenine geçirilen özellikler.
 * @param {boolean} props.isLoggedIn - Kullanıcının oturum durumu.
 * @returns {JSX.Element} - Yetkilendirme veya gösterge tablosu yönlendirmesini oluşturan JSX öğesi.
 */
const PrivateRouting:FC<IPrivateRouting> = (props) => {

    if (props.isLoggedIn){
        return (
            <DashboardRouting/>
        )
    }else{
       return (
           <AuthRouting/>
       )
    }
};

export default PrivateRouting;

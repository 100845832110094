import React, { useEffect, useState } from "react"
import { Box, Avatar, Card, CardContent, Divider, Grid, Stack, Typography, Skeleton } from "@mui/material";
import { DonutCharts } from "../../components/charts/donut/donut";
import Chip from "@mui/material/Chip";
import SimpleCharts from "../../components/charts/singleCharts/singleChart";
import LineChartComponent from "../../components/charts/line/sparkLine.chart";
import PieChartComponent from "../../components/charts/pie/pie.chart";
import ExpeditionsGrid from "../../components/grid/expeditions/expeditions.grid";
import WaitingActivityList from "../../components/list/waitingActivity.list";
import StackingChartComponent from "../../components/charts/stacking/stacking.chart";
import HighlightChartComponent from "../../components/charts/highlight/highlight.chart";
import { useAppSelector } from "../../store";
import QuickDataService from "../../services/client/reports/quickData/quickData.service";
import { getStorageData } from "../../utils/usePersistedState";
import { useTheme } from "@mui/material/styles";
import ExpeditionsRatesAnalysisCard from "../../components/card/pages/dashboard/admin/ExpeditionsRatesAnalysis.card";
import ExpeditionsDistanceAnalysisCard
    from "../../components/card/pages/dashboard/admin/ExpeditionsDistanceAnalysis.card";
import VotedExpeditionList from "../../components/list/votedExpedition.list";
import ExpeditionDistancesGrid from "../../components/grid/expeditions/expedition.distances.grid";
import ExpeditionsDurationsGrid from "../../components/grid/expeditions/expeditions.durations.grid";
import RouteIcon from '@mui/icons-material/Route';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ApexChart from "../../components/charts/singleCharts/singleChart";

const HoldersData = [
    { id: 0, value: 18, label: 'Gönderici' },
    { id: 1, value: 107, label: 'Alıcı' },
    { id: 2, value: 80, label: 'Taşıyıcı' },
];


const CausesData = [
    { id: 0, value: 18, label: 'Araç Arızası' },
    { id: 1, value: 107, label: 'Yol İzin Belgesi' },
    { id: 3, value: 80, label: 'Yol Yasağı' },
    { id: 4, value: 80, label: 'Bayram Tatili' },
    { id: 5, value: 80, label: 'Dorse Bekliyor' },
    { id: 6, value: 80, label: 'Gümrük Beklemesi' },
]
interface ICorporateExpeditions {
    totalExpeditions: object,
    totalCompletedExpedition: object,
    totalUncompletedExpedition: object
    totalOverdueExpedition: object,
    totalExpeditionsByStatus: object,
    expeditionsByRemainingDay: object,
    others: object
}

const DashboardAdmin = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean>(true)

    const [corporateExpedition, setCorporateExpedition] = useState<ICorporateExpeditions>({
        totalExpeditions: {},
        totalCompletedExpedition: {},
        totalUncompletedExpedition: {},
        totalOverdueExpedition: {},
        totalExpeditionsByStatus: {},
        expeditionsByRemainingDay: {},
        others: {}
    })

    useEffect(() => {

        setLoading(true)
        getStorageData("authState", true).then(auth => {
            getStorageData("api", false).then((api) => {
                //@ts-ignore
                QuickDataService.fetchCorporateExpeditions({
                    //@ts-ignore
                    apiUrl: api.url,
                    //@ts-ignore
                    accessToken: auth.status.access_token
                }).then((e) => {
                    setCorporateExpedition({
                        totalExpeditions: e.data.total_expeditions,
                        totalUncompletedExpedition: e.data.others.series[0],
                        totalCompletedExpedition: e.data.others.series[1],
                        totalOverdueExpedition: e.data.others.series[2],
                        totalExpeditionsByStatus: e.data.total_expeditions_by_status,
                        expeditionsByRemainingDay: e.data.expeditions_by_remaining_day,
                        others: e.data.others,
                    })
                    setLoading(false)
                }).catch((e) => setLoading(false))
            })
        })
    }, []);
    console.log(corporateExpedition, "corporateexpedition")
    return (
        <Grid md={12}>
            <Grid container md={12}>
                <Grid md={12} xs={12}>
                    {loading ? <><Skeleton variant="rectangular" sx={{
                        margin: "0.4rem",
                        height: "245px",
                    }} /></> : <Card sx={{
                        margin: "1rem",
                        maxHeight: "445px",
                        boxShadow: "none",
                        // background: `${
                        //     //@ts-ignore
                        //     theme.palette.info.main
                        //     }`,
                    }}>
                        <CardContent>
                            <Grid md={12}>
                                {/* <PieChartComponent
                                    data={
                                        //@ts-ignore
                                        corporateExpedition.totalExpeditions.series
                                    }
                                    series={{
                                        innerRadius: 60,
                                        outerRadius: 100,
                                        cx: 100,
                                        cy: 100,
                                        cornerRadius: 0,
                                        startAngle: 0,
                                        endAngle: 360,
                                        paddingAngle: 0,
                                    }}
                                    legend={{
                                        hidden: false,
                                        position: {
                                            vertical: "middle",
                                            horizontal: "middle"
                                        },
                                        direction: "column",
                                        offset: {
                                            x: 40,
                                            y: -40
                                        }
                                    }}
                                /> */}
                                {/* <SimpleCharts data={
                                    //@ts-ignore
                                    corporateExpedition.totalExpeditions.series
                                } totalExpeditions={
                                    //@ts-ignore
                                    corporateExpedition.totalExpeditions.quarter.series.total}
                                /> */}
                                {/*<ApexChart
                                    data={
                                        //@ts-ignore
                                        corporateExpedition.totalExpeditions.series
                                    } totalExpeditions={
                                        //@ts-ignore
                                        corporateExpedition.totalExpeditions.quarter.series ? corporateExpedition.totalExpeditions.quarter.series.total : 0}


                                />*/}
                            </Grid>
                        </CardContent>
                    </Card>}

                </Grid>
                <Grid md={12} xs={12}>
                    {loading ? <><Skeleton variant="rectangular" sx={{
                        margin: "0.4rem",
                        height: "245px",
                    }} /></> : <Card sx={{
                        margin: "1rem",
                        maxHeight: "245px",
                        boxShadow: "none",
                    }}>
                        <CardContent>
                            <Grid md={12}>

                            </Grid>
                        </CardContent>
                    </Card>}

                </Grid>
            </Grid>
            <ExpeditionsDurationsGrid />
            {/*<ExpeditionsRatesAnalysisCard*/}
            {/*    expeditions={{*/}
            {/*        totalExpeditions: corporateExpedition.totalExpeditions,*/}
            {/*        totalCompletedExpedition: corporateExpedition.totalCompletedExpedition,*/}
            {/*        totalUncompletedExpedition: corporateExpedition.totalUncompletedExpedition,*/}
            {/*        totalOverdueExpedition: corporateExpedition.totalOverdueExpedition,*/}
            {/*        others: corporateExpedition.others*/}
            {/*    }} />*/}
            <ExpeditionDistancesGrid />
            <Grid container md={12} xs={12}>
                <Box sx={{
                    margin:2,
                    width:"100%"
                }}>
                    <WaitingActivityList />
                </Box>
                {/* <Grid md={7} xs={12}>
                        <Card sx={{
                            marginLeft: "10px",
                            height: "calc(100vh - 120px)",
                            margin: "1rem",
                            padding: "0 !important"
                        }}>
                            <CardContent>
                                <ExpeditionsGrid />
                            </CardContent>
                        </Card>
                    </Grid> */}
            </Grid>
            <Grid container md={12}>
                <Grid md={6} >
                    {loading ? <><Skeleton variant="rectangular" sx={{
                        margin: "0.4rem",
                        height: "245px",
                    }} /></> : <Card sx={{
                        margin: "1rem"
                    }}>
                        <CardContent>
                            <Typography sx={{
                                color: "#535353",
                                fontSize: "18px",
                                fontWeight: "bold"
                            }}>
                                Bekleten
                            </Typography>
                            <HighlightChartComponent
                                data={HoldersData}
                                series={{
                                    innerRadius: 30,
                                    additionalRadius: -30,
                                    paddingAngle: 0,
                                    cx: 100,
                                    cy: 100,
                                    cornerRadius: 0,
                                    endAngle: 0,
                                    outerRadius: 0,
                                    startAngle: 0
                                }}
                                legend={{
                                    position: {
                                        vertical: "middle",
                                        horizontal: "right"
                                    },
                                    direction: "column",
                                    hidden: false,
                                    offset: {
                                        x: -150,
                                        y: 0
                                    },
                                }}
                            />
                        </CardContent>
                    </Card>}

                </Grid>
                <Grid md={6}>
                    {loading ? <><Skeleton variant="rectangular" sx={{
                        margin: "0.4rem",
                        height: "245px",
                    }} /></> : <Card sx={{
                        margin: "1rem"
                    }}>
                        <CardContent>
                            <Typography sx={{
                                color: "#535353",
                                fontSize: "18px",
                                fontWeight: "bold"
                            }}>
                                Bekleme Nedenleri
                            </Typography>
                            <HighlightChartComponent
                                data={CausesData}
                                series={{
                                    innerRadius: 30,
                                    additionalRadius: -30,
                                    paddingAngle: 0,
                                    cx: 100,
                                    cy: 100,
                                    cornerRadius: 0,
                                    endAngle: 0,
                                    outerRadius: 0,
                                    startAngle: 0
                                }}
                                legend={{
                                    position: {
                                        vertical: "middle",
                                        horizontal: "right"
                                    },
                                    direction: "column",
                                    hidden: false,
                                    offset: {
                                        x: -150,
                                        y: 0
                                    },
                                }}
                            />
                        </CardContent>
                    </Card>}

                </Grid>
            </Grid>
            {/*<Grid md={12} >*/}
            {/*    <Card sx={{*/}
            {/*        margin: "1rem"*/}
            {/*    }}>*/}
            {/*        <CardContent>*/}
            {/*            <Typography sx={{*/}
            {/*                color: "#1070CF",*/}
            {/*                fontSize: "18px",*/}
            {/*                fontWeight: 600*/}
            {/*            }}>*/}
            {/*                Firma Seferleri*/}
            {/*            </Typography>*/}
            {/*            <StackingChartComponent />*/}
            {/*        </CardContent>*/}
            {/*    </Card>*/}

            {/*</Grid>*/}
        </Grid>
    )
}
export default DashboardAdmin



import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from "./store";
import {LanguageProvider} from "./utils/locales/locale.index";
import {SnackbarWrapper} from "./components/snackbar/snackbar";
import {autoHideDuration, horizontal, severity, vertical} from "./types/enums/components/snackbar/snackbar.enums";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <LanguageProvider>
        <Provider store={store}>
            <App/>
        </Provider>
    </LanguageProvider>
);

reportWebVitals();

import React, { useEffect, useState } from "react"

import {
    Grid,
    Button,
    Box
} from "@mui/material";


import { ComponentView } from "../../../types/enums/components/component.enum";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface IExpeditionTypeSelector {
    fallback: (status: string) => void
    componentView: ComponentView
}

const rows = [
    { id: 1, name: 'Tamamlanan Seferler', status: "YES" },
    { id: 2, name: 'Tamamlanmamış Seferler', status: "NO" },
];

const ExpeditionStatusSelector = (props: IExpeditionTypeSelector) => {
    const [positionStatus, setPositionStatus] = useState([]);
    const [selectedButton, setSelectedButton] = useState(1);

    const handleButtonClick = (status: string, id: number) => {
        setSelectedButton(id);
        if (id === 3) {
            //@ts-ignore
            props.fallback(null);
        } else {
            props.fallback(status);
        }

    };

    const handleSelectChange = (event: SelectChangeEvent) => {
        props.fallback(event.target.value)

        //@ts-ignore
        const selectedId = event.target.value;
        // Check if the selected ID is 5 (corresponds to "hepsi"), and set props.fallback accordingly
        //@ts-ignore
        if (selectedId === 3) {
            //@ts-ignore
            props.fallback(null);
        } else {
            props.fallback(selectedId);
        }
    };

    useEffect(() => {
        //@ts-ignore
        setPositionStatus([{ id: 3, name: "Tümü" }, ...rows])
    }, [])

    switch (props.componentView) {
        case ComponentView.TAB: {
            return (
                <Grid container md={12}>
                    {
                        positionStatus.map((status, index) => (
                            <Button
                                key={index}
                                //@ts-ignore
                                variant={selectedButton === status.id ? "outlined" : "outlined"}
                                //@ts-ignore
                                onClick={() => handleButtonClick(status.status, status.id)}
                                sx={{
                                    width: "auto",
                                    height: "28px",
                                    //@ts-ignore
                                    border: selectedButton === status.id ? "3px solid gray" : "1px solid gray",
                                    fontSize: "14px",
                                }}
                            >
                                {  //@ts-ignore
                                    status.name
                                }
                            </Button>
                        ))
                    }
                </Grid>
            )
        }
        case ComponentView.SELECT: {
            return (
                <Box sx={{
                    m: 1
                }}>
                    <FormControl sx={{
                        display: "flex",
                        width: "120px",
                        justifyContent: "center"
                    }} size="small">
                        <InputLabel
                            id="demo-select-small-label"
                            sx={{
                                fontSize: "12px",
                                fontWeight: 600
                            }}
                        >Sefer Durumu</InputLabel>

                        {
                            <Select
                                onChange={handleSelectChange}
                                sx={{
                                    backgroundColor: "transparent",
                                    minHeight: 0,
                                    height: "35px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    borderRadius: "9px",
                                }}
                            >
                                {
                                    positionStatus.map((status, index) => (
                                        <MenuItem
                                            key={index}
                                            value={status}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            <InputLabel
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 600
                                                }}
                                            > {
                                                    //@ts-ignore
                                                    status.name
                                                }</InputLabel>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        }
                    </FormControl>
                </Box>
            )
        }
    }
}

export default ExpeditionStatusSelector

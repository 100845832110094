import * as React from 'react';
import {Box, Button, Stack} from '@mui/material';
import Menu from '@mui/material/Menu';
import {IconButton, Radio} from "@mui/material";
import Typography from "@mui/material/Typography";
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


const DFDSDiscountFiltersMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [selectedValue, setSelectedValue] = React.useState('');

    const handleRadioChange = (event:any) => {
        setSelectedValue(event.target.value);
    };

    return (
        <Box>
            <Button
                variant="outlined"
                sx={{
                    cursor: 'pointer',
                    border: 'none',
                    margin: 0,
                    width: '10px',
                    padding: 0,
                    minWidth: '20px',
                }}
                onClick={handleClick}
            >
                <ArrowDropDownIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{
                    width:"300px",
                    height:"auto"
                }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={0}
                        sx={{
                            padding: "10px",
                            borderBottom: '1px solid #E9E9E9',
                        }}
                    >
                        <Stack direction="row">
                            <TuneRoundedIcon/>
                            <Typography>
                                Filtre
                            </Typography>
                        </Stack>
                        <IconButton
                            size="small"
                            sx={{
                                backgroundColor:"rgba(217, 217, 217, 1)"
                            }}
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize={"small"} />
                        </IconButton>
                    </Stack>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                        sx={{
                            padding: "10px",
                            borderBottom: '1px solid #E9E9E9',
                        }}
                    >
                        <Typography>
                            Gönderme Durumu
                        </Typography>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Radio value="option1" />
                            <Typography> Gönderildi </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Radio value="option3" />
                            <Typography>Gönderilmedi</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Radio value="option3" />
                            <Typography>Tümü</Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                        sx={{
                            padding: "10px",
                            borderBottom: '1px solid #E9E9E9',
                        }}
                    >
                        <Typography>
                            Ödeme Durumu
                        </Typography>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Radio value="option1" />
                            <Typography> Ödendi </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Radio value="option3" />
                            <Typography>Ödenmedi</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Radio value="option3" />
                            <Typography>Tümü</Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={0}
                        sx={{
                            padding: "10px",
                            borderBottom: '1px solid #E9E9E9',
                        }}
                    >
                        <Button
                            variant="outlined"
                        >
                            Temizle
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "rgba(0, 153, 255, 1)"
                            }}
                        >
                            Filtrele
                        </Button>
                    </Stack>
                </Box>
            </Menu>
        </Box>
    );
}

export default DFDSDiscountFiltersMenu

import React, {useEffect, useState} from "react"
import {getStorageData} from "../../../utils/usePersistedState";
import ApiDFDSService from "../../../services/client/dfds/dfds.service";
import {DataGridPremium, GridColDef, GridColumnHeaderParams, GridToolbar} from "@mui/x-data-grid-premium";
import {Box, Card, CardContent, CardHeader, Chip, Grid, Stack, Typography} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchBar from "../../../components/form/controllers/search.input";
import {trTR} from "@mui/x-data-grid";
import search from "../../../components/search/search";
const DFDSBookingsPage = () => {

    const [ bookingsSearch, setBookingsSearch ] = useState<any>([])
    const [ loading, setLoading ] = useState<boolean>(true)
    const [ bookingsCount, setBookingsCount] = useState<number>(0)
    const [debouncedValue, setDebouncedValue] = useState<string>("");

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });

    const columns: GridColDef[] = [
        {
            field: "release_no", headerName: "release_no", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.formattedValue}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Release No'}
                </strong>
            )
        },
        {
            field: "unit_type", headerName: "Unit Type", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.formattedValue}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Unit Type'}
                </strong>
            )
        },
        {
            field: "truck", headerName: "Truck", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.formattedValue ? params.formattedValue : "-- -- ---"}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Truck'}
                </strong>
            )
        },
        {
            field: "trailer", headerName: "Trailer", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.formattedValue ? params.formattedValue : "-- -- ---"}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Trailer'}
                </strong>
            )
        },
        {
            field: "company", headerName: "Firma", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.row?.company?.name ? params.row.company.name : "Firma Bilgisi Bulunamadı!"}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Firma'}
                </strong>
            )
        },
        {
            field: "route", headerName: "Route", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.formattedValue ? params.formattedValue : "----"}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Route'}
                </strong>
            )
        },
        {
            field: "status", headerName: "Status", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.formattedValue ? params.formattedValue : "----"}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Status'}
                </strong>
            )
        },
        {
            field: "sailing_date", headerName: "Sailing Date", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.formattedValue ? params.formattedValue : "----"}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Sailing Date'}
                </strong>
            )
        },
        {
            field: "arrival_date", headerName: "Arrival Date", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {params.formattedValue ? params.formattedValue : "----"}
                </Box>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Arrival Date'}
                </strong>
            )
        },
        {
            field: "driver", headerName: "Driver", minWidth: 110, flex: 1,
            renderCell: (params: any) => (
                params.value ? <CheckCircleIcon sx={{color:"green"}}/> : <CancelIcon sx={{color:"red"}}/>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Driver'}
                </strong>
            )
        },
    ];


    useEffect(() => {
        const fetchBookings = (params:{
            accessToken:string,
            search:string,
            offset?: number,
            limit?: number,
        }) => {
            setLoading(true);
            setBookingsSearch([])
            ApiDFDSService.fetchBookings({
                accessToken:params.accessToken,
                search:params.search,
                offset:params.offset,
                limit:params.limit
            }).then((res) => {
                //@ts-ignore
                setBookingsSearch(prevBookings => [...prevBookings, ...res.results]);
                //@ts-ignore
                setBookingsCount(res.count)
                setLoading(false);
            });
        };

        getStorageData("authState", true).then((auth) => {
            fetchBookings({
                //@ts-ignore
                accessToken:  auth.status.access_token,
                search:debouncedValue,
                offset: (paginationModel.page * paginationModel.pageSize),
                limit: paginationModel.pageSize
            });
        });

    }, [paginationModel]);

    useEffect(() => {
        setBookingsSearch([])
        setPaginationModel({
            page:0,
            pageSize: 50
        })
    }, [debouncedValue]);



    const handleChange = (e: any) => {
        setDebouncedValue(e);
    };


    return(
        <Grid md={12}>
            <Card sx={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                boxShadow: "none"
            }}>
                <CardHeader sx={{
                    marginLeft:1,
                    marginRight:1
                }} title={
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography sx={{
                            fontSize: "18px",
                            fontWeight: 600
                        }}>
                            Biletler <Chip label={bookingsCount ? bookingsCount + " Bilet" : 0 + " Bilet"} color="primary" variant="outlined" />
                        </Typography>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <SearchBar tooltipTitle={"Firma veya Bilet Ara"} onChangeText={(text)=>handleChange(text)} placeHolder={"Ara"}/>
                        </Stack>
                    </Stack>
                }
                />
                <CardContent>
                    <DataGridPremium
                        columns={columns}
                        rows={bookingsSearch}
                        sx={{
                            height: "calc(100vh - 200px)",
                            width: "100%",
                            '& .MuiDataGrid-virtualScroller': {
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {height: 5, WebkitAppearance: 'none', width: 5},
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: 8,
                                    border: '1px solid',
                                    borderColor: 'white',
                                    backgroundColor: 'rgba(0 0 0 / 0.5)',
                                },
                            },
                        }}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                        pagination
                        paginationMode="server"
                        pageSizeOptions={[25, 50, 75, 100]}
                        rowCount={bookingsCount}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        loading={loading}
                    />
                </CardContent>
            </Card>
        </Grid>
    )
}

export default DFDSBookingsPage

import {createSlice, PayloadAction, createAsyncThunk} from "@reduxjs/toolkit";
import {IUserState, IUser} from "../types/interfaces/reducers/user/user.interface";
import {fetchUserInfo} from "../services/user/user.service";
import {getStorageData} from "../utils/usePersistedState";

const initialState: IUserState = {
    // @ts-ignore
    data: null,
    loading:false,
    error:""
}

const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(fetchUserInfo.pending, (state, action)=>{
            state.loading = true;
            state.error = "pending"
        })
        // @ts-ignore
        builder.addCase(fetchUserInfo.fulfilled, (state,action:PayloadAction<IUser>)=>{
            state.data = action.payload;
            state.loading = false;
        })
        builder.addCase(fetchUserInfo.rejected, (state, action)=>{
            state.loading = true;
            state.error = "error";

            getStorageData("authState",false).then(( authState) => {
                //@ts-ignore
                if (authState.token.length > 0){
                    throw null
                }})
        })
    }
})

export default userSlice.reducer

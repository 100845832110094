import React, {useEffect, useState} from "react"
import {DataGrid, GridColDef, GridRowId, GridRowModel, GridRowModes, GridRowModesModel} from "@mui/x-data-grid";
import {getStorageData} from "../../../utils/usePersistedState";
import {ApiIntegrationsCausesService} from "../../../services/client/integrations/integrations.causes.service";
import Box from "@mui/material/Box";

const IntegrationsCausesPage = () => {
    const [getLoading, setLoading] = useState<boolean>(true)

    const [getCauses, setCauses] = useState<any>([])

    const [getRows, setRows] = React.useState(getCauses);
    const [getRowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...getRowModesModel, [id]: {mode:GridRowModes.Edit} });
    }

    const handleCancelClick = (id:GridRowId) => () => {
        setRowModesModel({
            ...getRowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}
        })
    }

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel((oldModel)=>({
            ...oldModel,
            [id]: {mode: GridRowModes.View}
        }))
    }

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false};
        setRows(getRows.map((row:any) => ( row.id === newRow.id ? updatedRow : row )))
        return updatedRow;
    }

    useEffect(()=>{
        getStorageData('authState',false).then((auth)=>{
            //@ts-ignore
            ApiIntegrationsCausesService.fetchIntegrationsCausesService(auth.status.access_token).then((res:any)=>{
                setCauses(res.data.results)
                setLoading(false)
            })
        })
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Text',
            width: 200,
            editable: true
        },
        {
            field: 'slug',
            headerName: 'Slug',
            width: 200,
            editable: true
        },
        {
            field: 'descriptions',
            headerName: 'description',
            width: 200,
            editable: true
        }
    ]

    return(
        getLoading ? <>Veriler Yükleniyor</> :
            <Box>
                <DataGrid
                    sx={{
                        height: 400,
                        margin: "10px"
                    }}
                    columns={columns}
                    rows={getCauses}
                    editMode="row"
                    rowModesModel={getRowModesModel}
                    processRowUpdate={processRowUpdate}
                    />
            </Box>
    )
}

export default IntegrationsCausesPage

import React, {useEffect, useState} from "react";

import {useForm, SubmitHandler} from "react-hook-form";

//Inputs Type
import {CommentInputs} from "../../../types/types/components/forms/form.type";

//Services
import {createWaitReason} from "../../../services/client/comments/comment.service";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {getStorageData} from "../../../utils/usePersistedState";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// @ts-ignore
import {ApiIntegrationsHoldersService} from "../../../services/client/integrations/integrations.holders.service";


interface ICommentForm {
    id: number
    holderChoices: any
    fallbackStatus: (status: boolean) => void
    popupStatus: (status: boolean) => void
}

const CommentForm: React.FC<ICommentForm> = (props, context) => {
    //useForm Status
    const {
        register, handleSubmit,
        control, watch, formState: {errors}
    } = useForm<CommentInputs>()

    //dropDown Status
    const [getCause, setCause] = useState<number>(0);
    const [getHolder, setHolder] = useState<number>(0);


    const [getCauses, setCauses] = useState<any>();

    const [getHolderDataLoading, setHolderDataLoading] = useState<boolean>(true)
    const [getCauseDataLoading, setCauseDataLoading] = useState<boolean>(true);

    const onSubmitComment: SubmitHandler<CommentInputs> = (data) => {
        //@ts-ignore
        data.holder = getHolder.id
        //@ts-ignore
        data.cause = getCause.id
        getStorageData("authState", true).then(auth => {
            getStorageData("api", false).then(api => {
                //@ts-ignore
                createWaitReason(api.url, auth.status.access_token, data, props.id).then(res => {
                    console.log(data)
                    if (res.status == 201) {
                        window.location.reload()
                    }
                })
            })
        })
        console.log(data)
    }

    useEffect(() => {
        //@ts-ignore
        if (props.holderChoices != undefined) {
            setHolderDataLoading(false)
        }
    }, [props.holderChoices])

    const holderHandleChange = (event: SelectChangeEvent) => {
        //@ts-ignore
        setHolder(event.target.value)
        getStorageData("authState", true).then(auth => {
            //@ts-ignore
            ApiIntegrationsHoldersService.fetchIntegrationsHoldersService(auth.status.access_token, event.target.value.id).then((res, index) => {
                setCauseDataLoading(false)

                setCauses(res.causes)
            })
        })
    }

    const causesHandleChange = (event: SelectChangeEvent) => {
        //@ts-ignore
        setCause(event.target.value)
    }

    return (
        getHolderDataLoading ?
            <></>
            :
            <Card>
                <CardContent>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Bekleten</InputLabel>
                        <Select
                            sx={{
                                marginBottom: "1rem"
                            }}
                            onChange={holderHandleChange}
                        >
                            {
                                //@ts-ignore
                                props.holderChoices.map((item: never, index: number) => (
                                    <MenuItem
                                        key={index}
                                        value={item}
                                    >{
                                        //@ts-ignore
                                        item.name
                                    }
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Bekleme Nedeni</InputLabel>
                        {
                            getCauseDataLoading ?
                                <></>
                                :
                                <Select
                                    onChange={causesHandleChange}
                                >

                                    {
                                        //@ts-ignore
                                        getCauses.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item}
                                            >{
                                                //@ts-ignore
                                                item.name
                                            }
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                        }
                    </FormControl>
                </CardContent>
                <Box component="form" onSubmit={handleSubmit(onSubmitComment)}>
                    <TextField
                        sx={{
                            marginLeft: "5%",
                            marginRight: "5%"
                        }}
                        fullWidth
                        id="standard-multiline-static"
                        multiline
                        rows={2}
                        variant="standard"
                        placeholder={"Bir Yorum Giriniz"}
                        //@ts-ignore
                        {...register(`comment`)}
                    />
                    <Box sx={{
                        m: 1
                    }}>
                        <Button variant="contained" type="submit" onSubmit={handleSubmit(onSubmitComment)}>
                            Gönder
                        </Button>
                    </Box>
                </Box>
            </Card>
    )
}


export default CommentForm

import React from 'react';
import { VersionControlLoading } from "./components/loading/loading";

/**
 * Uygulama bileşeni.
 *
 * @function App
 * @returns {JSX.Element} - Uygulama bileşenini oluşturan JSX öğesi.
 */
const App = (): JSX.Element => {
  return (
    <VersionControlLoading />
  )
}

export default App;

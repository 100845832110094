import React, {useEffect, useState} from "react"
import {DataGrid, GridColDef, GridRowId, GridRowModel, GridRowModes, GridRowModesModel} from "@mui/x-data-grid";
import {getStorageData} from "../../../utils/usePersistedState";
import {ApiIntegrationsCurrenciesService} from "../../../services/client/integrations/integrations.currencies.service";
import Box from "@mui/material/Box";

const IntegrationsCurrenciesPage = () => {
    const [getLoading, setLoading] = useState<boolean>(true)

    const [getCurrencies, setCurrencies] = useState<any>([])

    const [getRows, setRows] = React.useState(getCurrencies);
    const [getRowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...getRowModesModel, [id]: {mode:GridRowModes.Edit} });
    }

    const handleCancelClick = (id:GridRowId) => () => {
        setRowModesModel({
            ...getRowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true}
        })
    }

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel((oldModel)=>({
            ...oldModel,
            [id]: {mode: GridRowModes.View}
        }))
    }

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false};
        setRows(getRows.map((row:any) => ( row.id === newRow.id ? updatedRow : row )))
        return updatedRow;
    }

    useEffect(() => {
        getStorageData("authState",false).then((auth) => {
            //@ts-ignore
            ApiIntegrationsCurrenciesService.fetchIntegrationsCurrenciesService(auth.status.access_token).then((item)=>{
                console.log(item)
                setCurrencies(item.data.results)
                setLoading(false)
            })
        })
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'currency_name',
            headerName: 'Para Birimi İsmi',
            minWidth: 200,
            editable: true
        },
        {
            field: 'currency_code',
            headerName: 'Para Birimi Kodu',
            minWidth: 200,
            editable: true
        },
        {
            field: 'currency_symbol',
            headerName: 'Para Birimi Sembol',
            minWidth: 200,
            editable: true
        }
    ]

    return(
        getLoading ? <>Veriler Yükleniyor</> :
            <Box>
                <DataGrid
                    sx={{
                        height: 400,
                        margin: "10px"
                    }}
                    columns={columns}
                    rows={getCurrencies}
                    editMode="row"
                    rowModesModel={getRowModesModel}
                    processRowUpdate={processRowUpdate}
                />
            </Box>
    )
}

export default IntegrationsCurrenciesPage

import React, { useEffect, useState } from "react"
import { Card, CardContent, Grid, Typography } from "@mui/material";
import HighlightChartComponent from "../../../../charts/highlight/highlight.chart";
import { useTheme } from "@mui/material/styles";
import { getStorageData } from "../../../../../utils/usePersistedState";
import ApiQuickDataExpeditionsService from "../../../../../services/client/reports/quickData/quickData.service";

type IExpeditionStatusCard = {
    data: {
        uncompleted_expeditions_count: number;
        completed_expeditions_count: number;
    }
}

const DashboardCustomerExpeditionsStatusCard = () => {

    const theme = useTheme();

    const [expeditionStatus, setExpeditionStatus] = useState<IExpeditionStatusCard>()

    useEffect(() => {
        getStorageData("authState", true).then((auth) => {
            getStorageData("api", false).then((api) => {
                ApiQuickDataExpeditionsService.fetchCompanyExpeditions({
                    //@ts-ignore
                    apiUrl: api.url, accessToken: auth.status.access_token
                }).then((res: IExpeditionStatusCard) => {
                    setExpeditionStatus({
                        data: {
                            //@ts-ignore
                            completed_expeditions_count: res.data.completed_expeditions_count,
                            //@ts-ignore
                            uncompleted_expeditions_count: res.data.uncompleted_expeditions_count
                        }
                    })
                })
            })
        })
    }, []);

    return (
        <Card sx={{
            margin: "1rem",
            maxHeight: "245px",
            boxShadow: "none",
        }}>
            <CardContent>
                <Grid container md={12}>
                    <Grid md={4}>
                        <Grid md={12}>
                            <Typography sx={{
                                fontSize: "25px",
                                fontWeight: 600,
                                color: `${theme.palette.text.secondary}`,
                            }}
                            >
                                Seferler
                            </Typography>
                            <Typography sx={{
                                fontSize: "14px",
                                color: `${theme.palette.info.main}`,
                            }}>
                                Toplam Sefer Sayısı : {
                                    //@ts-ignore
                                    expeditionStatus?.data.completed_expeditions_count + expeditionStatus?.data.uncompleted_expeditions_count
                                }
                            </Typography>
                            {/* <Typography sx={{
                                fontSize: "12px",
                                fontWeight: 300
                            }}>
                                Geçen Hafta Sefer Sayısından %20 daha fazla
                            </Typography> */}
                        </Grid>
                    </Grid>
                    <Grid md={8}>
                        <HighlightChartComponent
                            data={[
                                { id: 0, value: expeditionStatus?.data.uncompleted_expeditions_count, label: 'Aktif Seferler' },
                                { id: 1, value: expeditionStatus?.data.completed_expeditions_count, label: 'Tamamlanmış Seferler' },
                                { id: 1, value: 0, label: 'Planlanan Seferler' },
                            ]}
                            series={{
                                innerRadius: 30,
                                additionalRadius: -30,
                                paddingAngle: 0,
                                cx: 100,
                                cy: 100,
                                cornerRadius: 0,
                                endAngle: 0,
                                outerRadius: 0,
                                startAngle: 0
                            }}
                            legend={{
                                position: {
                                    vertical: "middle",
                                    horizontal: "middle"
                                },
                                direction: "column",
                                hidden: false,
                                offset: {
                                    x: 120,
                                    y: 0
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default DashboardCustomerExpeditionsStatusCard


export default {
    isLoggedIn: false,
    status : {
        access_token:"",
        expires_in:"",
        refresh_token:"",
        scope:"",
        token_type:"",
    }
}

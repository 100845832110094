import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Grid, IconButton, List, Tooltip, Typography} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PhoneIcon from '@mui/icons-material/Phone';

import { expeditionDetailComponentTypes } from "../../../../types/enums/pages/expeditions/expeditions.page.enum";
import { getStorageData } from "../../../../utils/usePersistedState";
import ApiLoadsService from "../../../../services/client/loads/loads.service";
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import Stack from "@mui/material/Stack";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

import {
    IOtsBottomDrawerNavigation
} from "../../../../types/interfaces/components/navigations/drawers/ots/ots.bottom.drawer.navigation.interface";
import { dateFormatting } from "../../../../utils/dateProvider/dateFormatting";
import { useTheme } from "@mui/material/styles";
import ApiExpeditionsService from "../../../../services/client/expeditions/expeditions.service";
import Divider from "@mui/material/Divider";
import { IExpeditionsModel } from "../../../../types/models/services/expeditions/expeditions.model";
import Documents from '../../../fileuploads/document';
import RatingCustomerModal from '../../../modal/rating.customer.modal';
import { autoHideDuration, horizontal, severity, vertical } from "../../../../types/enums/components/snackbar/snackbar.enums";
import { SnackbarWrapper } from "../../../snackbar/snackbar";
import ExpeditionPositionStepper from "../../../stepper/expedition.position.stepper";
import RatingRepresentativeModal from "../../../modal/rating.representative.modal";


const PageOtsBottomDrawer = (props: IOtsBottomDrawerNavigation) => {

    const theme = useTheme();

    const [tab, setTab] = useState('1');

    const [expedition, setExpedition] = useState<IExpeditionsModel>()

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };


    const [loading, setLoading] = useState<boolean>(true)

    const [alertOpen, setAlertOpen] = React.useState(false);
    const [severityType, setSeverityType] = React.useState(severity.success); // Initialize severity state with error
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const [reRender, setReRender] = useState<boolean>()

    const styleButton = {
        color: "white",
        padding: "auto",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        height: "22px",
        margin: "1rem",
        marginLeft: "auto",
        backgroundColor: "#0C9AF2",
        '&:hover': {
            background: "#0C9AF2",

        }
    }
    useEffect(() => {
        setLoading(true)
        if (props.data.expedition.results[0].id) {
            getStorageData("authState", true).then(auth => {
                getStorageData("api", false).then(api => {
                    ApiExpeditionsService.fetchExpeditions({
                        //@ts-ignore
                        accessToken: auth.status.access_token,
                        //@ts-ignore
                        expeditionId: props.data.expedition.results[0].id,
                        //@ts-ignore
                        apiUrl: api.url
                    }).then((expedition2) => {
                        console.log(expedition2.data, "FETCH EXPEDITIONS")
                        setExpedition(
                            {
                                //@ts-ignore
                                results: [expedition2.data]
                            }
                        )
                    })
                    setLoading(false)
                })
            })
        }
        else if (props.data.expedition.results[0].loads[0]?.id) {
            getStorageData("authState", true).then(auth => {
                getStorageData("api", false).then(api => {
                    ApiLoadsService.fetchLoadsService({
                        //@ts-ignore
                        url: api.url,
                        //@ts-ignore
                        accessToken: auth.status.access_token,
                        //@ts-ignore
                        loadID: props.data.expedition.results[0].loads[0]?.id
                    }).then((res:any) => {
                        setExpedition({
                            results: [{
                                truck: {
                                    id_no: null
                                },
                                trailer: {
                                    id_no: null
                                },
                                loads: [res.data],
                                votes: []
                            }]
                        })
                        setLoading(false)
                        //console.log(expedition?.results[0].loads[0].results[0], "PAGE OTS BOTTOM DRAWER")
                    })
                })
            })
        }
        console.log(props.data.expedition.results[0].status_histories,"HHEEEYY")
    }, [
        props.data.expedition,
        reRender
    ]);


    switch (props.type) {
        case expeditionDetailComponentTypes.expeditionDetail: {
            return (
                loading ? <></> :
                    <List sx={{
                        width: '100%',
                        position: 'relative',
                        overflow: "scroll",
                        maxHeight: '310px',
                        '&::-webkit-scrollbar': {
                            display: "none",
                            msOverflowStyle: "none"
                        }
                    }}>
                        <Grid container md={12} sx={{
                            display: props.style?.display
                        }}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={0}
                            >
                                <IconButton sx={{
                                    margin: 0,
                                    padding: 0
                                }}>
                                    <KeyboardArrowDownTwoToneIcon
                                        onClick={() => props.onClose()}
                                        sx={{
                                            margin: 0,
                                            padding: 0
                                        }}
                                    />
                                </IconButton>
                            </Stack>
                            <Grid container md={12}>
                                <Grid md={8}>
                                    <Box sx={{
                                        width: '100%',
                                        typography: 'body1'
                                    }}>
                                        <Grid container md={12}>
                                            <Grid md={11}>
                                                <TabContext value={tab}>
                                                    <Box sx={{
                                                        borderBottom: 1,
                                                        borderColor: 'divider',
                                                        margin: 0,
                                                        padding: 0,
                                                        '& .MuiTabs-flexContainer': {
                                                            justifyContent: "flex-start",
                                                        },
                                                    }}>
                                                        <TabList
                                                            onChange={handleTabChange}
                                                            sx={{
                                                                margin: 0,
                                                                padding: 0,
                                                                direction: "row",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <Tab label="Genel Bilgiler" value="1" />
                                                            <Tab label="Temsilci Bilgileri" value="2" />
                                                            <Tab label="Belgeler" value="3" />
                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value="1">
                                                        <Grid md={12}>
                                                            <Stack direction="row" spacing={2} sx={{
                                                                justifyContent: "space-between",
                                                                alignItems:"center"
                                                            }}>
                                                                <Stack direction="row" spacing={2}>
                                                                    <Stack direction="column">
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={2}
                                                                            alignItems={"center"}
                                                                        >
                                                                            <Typography sx={{
                                                                                fontWeight: 700,
                                                                                fontSize: "15px",
                                                                                lineHeight: "7px",
                                                                            }}>
                                                                                Sefer Numarası
                                                                            </Typography>
                                                                        </Stack>
                                                                        <Typography sx={{
                                                                            color: "#7E7E7E",
                                                                            fontSize: "13px",
                                                                            fontWeight: 500,
                                                                            marginTop:"1rem"
                                                                        }}>
                                                                            {
                                                                                expedition?.results[0].position_no ?
                                                                                    expedition?.results[0].position_no : "Sefer No Bilgileri Bulunamadı"
                                                                            }
                                                                        </Typography>
                                                                    </Stack>
                                                                    <Stack direction="column">
                                                                        <Typography sx={{
                                                                            fontWeight: 700,
                                                                            fontSize: "15px",
                                                                            lineHeight: "7px"
                                                                        }}>
                                                                            Sefer Tipi
                                                                        </Typography>
                                                                        <Typography sx={{
                                                                            margin: 0,
                                                                            color: "#7E7E7E",
                                                                            fontSize: "13px",
                                                                            fontWeight: 500,
                                                                            marginTop:"1rem"
                                                                        }}>
                                                                            {
                                                                                expedition?.results[0].position_type?.name ?
                                                                                    expedition?.results[0].position_type.name : "Sefer Tipi Bulunamadı"
                                                                            }
                                                                        </Typography>
                                                                    </Stack>
                                                                    </Stack>
                                                                <Box>
                                                                    <RatingCustomerModal
                                                                        expeditionId={props.data.expedition.results[0].id}
                                                                        isUserVoted={expedition?.results[0].is_user_voted}
                                                                        votes={expedition?.results[0].votes}
                                                                        setSnackbarMessage={setSnackbarMessage}
                                                                        setSeverityType={setSeverityType}
                                                                        setAlertOpen={setAlertOpen}
                                                                        toggleReRender={()=>setReRender(!reRender)}
                                                                        styleButton={styleButton}
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />
                                                            <Stack direction="row" spacing={2}>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Sefer Durumu
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            expedition?.results[0].position_status ?
                                                                                expedition?.results[0].position_status : "Sefer Tipi Bulunamadı"
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />
                                                            <Stack direction="row" spacing={2}>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Sefer Başlangıç Tarihi
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            dateFormatting(expedition?.results[0].start_date)
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Sefer Güncelleme Tarihi
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            dateFormatting(expedition?.results[0].updated_date)
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />
                                                            <Stack direction="row" spacing={2}>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Satış Temsilcisi
                                                                    </Typography>

                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        Bilgi Bulunamadı
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />
                                                            {/*<Stack direction="row" spacing={2}>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Sefer Açıklaması
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            expedition?.results[0].descriptions ?
                                                                                expedition?.results[0].descriptions : "Sefer Tipi Bulunamadı"
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />*/}
                                                        </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                        <Stack spacing={1}>
                                                            <Stack direction="row" alignItems="center">
                                                            <Typography sx={{
                                                                fontWeight:600
                                                            }}>
                                                                Satış Temsilcisi
                                                            </Typography>
                                                            <Box sx={{
                                                                margin:"10px"
                                                            }}>
                                                                {/**<RatingRepresentativeModal/>**/}
                                                            </Box>
                                                            </Stack>
                                                            <Grid md={12} container>
                                                                <Grid md={6} >
                                                                    <Typography sx={{
                                                                        color:"gray",
                                                                        fontWeight:500
                                                                    }}>
                                                                        Bilgi Bulunamadı!
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        color:"gray",
                                                                        margin:"0 !important",
                                                                    }}>
                                                                        Bilgi Bulunamadı!
                                                                        <IconButton>
                                                                            <ContentCopyIcon sx={{ color:"gray", fontSize:20 }}/>
                                                                        </IconButton>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid md={6}>
                                                                    <IconButton sx={{
                                                                        backgroundColor:"#4DCA68"
                                                                    }}>
                                                                        <PhoneIcon sx={{
                                                                            color:"white",
                                                                            fontSize:20
                                                                        }}/>
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                            <Divider/>
                                                            <Stack direction="row" alignItems="center">
                                                            <Typography sx={{
                                                                fontWeight:600
                                                            }}>
                                                                Müşteri Temsilcisi
                                                            </Typography>
                                                            <Box sx={{
                                                                margin:"10px"
                                                            }}>
                                                                {/**<RatingRepresentativeModal/>**/}
                                                            </Box>
                                                            </Stack>
                                                            <Grid md={12} container>
                                                                <Grid md={6} >
                                                                    <Typography sx={{
                                                                        color:"gray",
                                                                        fontWeight:500
                                                                    }}>
                                                                        Bilgi Bulunamadı!
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        color:"gray",
                                                                        margin:"0 !important",
                                                                    }}>
                                                                        Bilgi Bulunamadı!
                                                                        <IconButton>
                                                                            <ContentCopyIcon sx={{ color:"gray", fontSize:20 }}/>
                                                                        </IconButton>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid md={6}>
                                                                    <IconButton sx={{
                                                                        backgroundColor:"#4DCA68"
                                                                    }}>
                                                                        <PhoneIcon sx={{
                                                                            color:"white",
                                                                            fontSize:20
                                                                        }}/>
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                            <Divider/>
                                                        </Stack>
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                        <Typography>
                                                           Belgeler
                                                        </Typography>
                                                    </TabPanel>
                                                </TabContext>
                                            </Grid>
                                            <Grid md={1}>

                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid md={4}>
                                    <Stack spacing={2}>
                                        <Stack direction="row" spacing={2} sx={{
                                            justifyContent: "flex-start",
                                            alignItems: "center"
                                        }}>
                                            <CalendarMonthIcon fontSize={"small"} />
                                            <Typography sx={{
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                lineHeight: "12px",
                                                color: `${theme.palette.text.primary}`,
                                            }}>
                                                Tahmini Teslim Tarihi:
                                            </Typography>
                                            <Typography sx={{
                                                margin: 0,
                                                color: "#7E7E7E",
                                                fontSize: "13px",
                                                fontWeight: 500
                                            }}>
                                                {
                                                    expedition?.results[0].estimated_unloading_date ?
                                                        dateFormatting(expedition?.results[0].estimated_unloading_date) : "----------"
                                                }
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                justifyContent: "flex-start",
                                                alignItems: "center"
                                            }}
                                        >
                                            <RecentActorsIcon fontSize={"small"} />
                                            <Typography sx={{
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                lineHeight: "12px",
                                                color: `${theme.palette.text.primary}`,
                                            }}>
                                                Teslim Alacak Firma:
                                            </Typography>
                                            <Typography sx={{
                                                margin: 0,
                                                color: "#7E7E7E",
                                                fontSize: "13px",
                                                fontWeight: 500
                                            }}>
                                                ----------
                                            </Typography>
                                        </Stack>
                                        <Stack>
                                            {/**<ExpeditionPositionStepper/>**/}
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </List>
            )
        }
        case expeditionDetailComponentTypes.loadDetail: {
            return (
                loading ? <></> :
                    <List sx={{
                        width: '100%',
                        //backgroundColor: "rgba(0,0,0,0.7)",
                        position: 'relative',
                        //overflowY: 'scroll',
                        overflow: "scroll",
                        maxHeight: '310px',
                        //scrollbarWidth: 'none',
                        //scrollbarColor: 'transparent transparent',
                        '&::-webkit-scrollbar': {
                            display: "none",
                            msOverflowStyle: "none"
                        }
                        // '&::-webkit-scrollbar-thumb': {
                        //     backgroundColor: 'transparent',
                        // }
                    }}>
                        <Grid container md={12} sx={{
                            display: props.style?.display
                        }}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={0}
                            >
                                <IconButton sx={{
                                    margin: 0,
                                    padding: 0
                                }}>
                                    <KeyboardArrowDownTwoToneIcon
                                        onClick={() => props.onClose()}
                                        sx={{
                                            margin: 0,
                                            padding: 0
                                        }}
                                    />
                                </IconButton>
                            </Stack>
                            <Grid container md={12}>
                                <Grid md={8}>
                                    <Box sx={{
                                        width: '100%',
                                        typography: 'body1'
                                    }}>
                                        <Grid container md={12}>
                                            <Grid md={11}>
                                                <TabContext value={tab}>
                                                    <Box sx={{
                                                        borderBottom: 1,
                                                        borderColor: 'divider',
                                                        margin: 0,
                                                        padding: 0,
                                                        '& .MuiTabs-flexContainer': {
                                                            justifyContent: "flex-start",
                                                        },
                                                    }}>
                                                        <TabList
                                                            onChange={handleTabChange}
                                                            sx={{
                                                                margin: 0,
                                                                padding: 0,
                                                                direction: "row",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <Tab label="Genel Bilgiler" value="1" />
                                                            <Tab label="Temsilci Bilgileri" value="2" />
                                                            <Tab label="Belgeler" value="3" />
                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value="1">
                                                        <Grid md={12}>
                                                            <Stack direction="row" spacing={2} sx={{
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Malın Adı
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            expedition?.results[0].loads[0]?.goods_description ?
                                                                                expedition?.results[0].loads[0]?.goods_description : "Yük Bilgileri Bulunamadı"
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} sx={{
                                                                    alignItems: "baseline"
                                                                }}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                    }}>
                                                                        Toplam Ağırlık
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            //@ts-ignore
                                                                            expedition?.results[0].loads[0]?.total_weight ?
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.total_weight + " KG" : "Bilgi Bulunamadı"
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />
                                                            <Stack direction="row" spacing={2}>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Gönderici Firma Adı
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            //@ts-ignore
                                                                            expedition?.results[0].loads[0]?.sender?.name ?
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.sender?.name : "Bilgi Bulunamadı"
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />
                                                            <Stack direction="row" spacing={2}>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Yükleme Tarihi
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            dateFormatting(expedition?.results[0].loads[0]?.load_date)
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Boşaltma Tarihi
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            dateFormatting(expedition?.results[0].loads[0]?.updated_date)
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />
                                                            <Stack direction="row" spacing={2}>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Satış Temsilcisi
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            //@ts-ignore
                                                                            expedition?.results[0].loads[0]?.customer_representative.name ?
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.customer_representative.name : "Bilgi Bulunamadı"
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />
                                                            <Stack direction="row" spacing={2}>
                                                                <Stack direction="column" spacing={2}>
                                                                    <Typography sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: "15px",
                                                                        lineHeight: "7px"
                                                                    }}>
                                                                        Navlun
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        margin: 0,
                                                                        color: "#7E7E7E",
                                                                        fontSize: "13px",
                                                                        fontWeight: 500
                                                                    }}>
                                                                        {
                                                                            "Bilgi Bulunamadı"
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Divider sx={{
                                                                color: "red",
                                                                background: "#EDEDED",
                                                                height: "1px",
                                                                marginBottom: "1rem"
                                                            }} />
                                                        </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                        <Stack spacing={1}>
                                                            <Typography sx={{
                                                                fontWeight:600
                                                            }}>
                                                                Müşteri Temsilcisi
                                                            </Typography>
                                                            <Grid md={12} container>
                                                                <Grid md={6} >
                                                                    <Typography sx={{
                                                                        color:"gray",
                                                                        fontWeight:500
                                                                    }}>
                                                                        {
                                                                            //@ts-ignore
                                                                            expedition?.results[0].loads[0]?.customer_representative[0]?.first_name ?
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.customer_representative[0]?.first_name + " " : "Bilgi Bulunamadı"
                                                                        }

                                                                        {
                                                                            //@ts-ignore
                                                                            expedition?.results[0].loads[0]?.customer_representative[0]?.last_name ?
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.customer_representative[0]?.last_name : "Bilgi Bulunamadı"
                                                                        }
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        color:"gray",
                                                                        margin:"0 !important",
                                                                    }}>
                                                                        {
                                                                            //@ts-ignore
                                                                            expedition?.results[0].loads[0]?.customer_representative[0]?.email ?
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.customer_representative[0]?.email : ""
                                                                        }
                                                                        <IconButton>
                                                                            {
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.customer_representative[0]?.email ?  <ContentCopyIcon sx={{ color:"gray" }}/> : ""
                                                                            }
                                                                        </IconButton>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid md={6}>
                                                                    <Tooltip title={
                                                                        //@ts-ignore
                                                                        expedition?.results[0].loads[0]?.customer_representative[0]?.phone_numbers[0]?.number
                                                                    }>
                                                                        <IconButton sx={{
                                                                            backgroundColor:"#4DCA68",
                                                                            marginLeft:"3rem"
                                                                        }}>
                                                                            <PhoneIcon sx={{
                                                                                color:"white"
                                                                            }}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                            <Divider/>
                                                            <Typography sx={{
                                                                fontWeight:600
                                                            }}>
                                                                Satış Temsilcisi
                                                            </Typography>
                                                            <Grid md={12} container>
                                                                <Grid md={6} >
                                                                    <Typography sx={{
                                                                        color:"gray",
                                                                        fontWeight:500
                                                                    }}>
                                                                        {
                                                                            //@ts-ignore
                                                                            expedition?.results[0].loads[0]?.sales_representative[0]?.first_name ?
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.sales_representative[0]?.first_name + " " : "Bilgi Bulunamadı"
                                                                        }
                                                                        {
                                                                            //@ts-ignore
                                                                            expedition?.results[0].loads[0]?.sales_representative[0]?.last_name ?
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.sales_representative[0]?.last_name : "Bilgi Bulunamadı"
                                                                        }
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        color:"gray",
                                                                        margin:"0 !important",
                                                                    }}>
                                                                        {
                                                                            //@ts-ignore
                                                                            expedition?.results[0].loads[0]?.sales_representative[0]?.email ?
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.sales_representative[0]?.email : ""
                                                                        }
                                                                        <IconButton>
                                                                            {
                                                                                //@ts-ignore
                                                                                expedition?.results[0].loads[0]?.sales_representative[0]?.email ?  <ContentCopyIcon sx={{ color:"gray" }}/> : ""
                                                                            }
                                                                        </IconButton>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid md={6}>
                                                                    <Tooltip title={
                                                                        //@ts-ignore
                                                                        expedition?.results[0].loads[0]?.sales_representative[0]?.phone_numbers[0]?.number
                                                                    }>
                                                                        <IconButton sx={{
                                                                            backgroundColor:"#4DCA68",
                                                                            marginLeft:"3rem"
                                                                        }}>
                                                                            <PhoneIcon sx={{
                                                                                color:"white"
                                                                            }}/>
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                </Grid>
                                                            </Grid>
                                                            <Divider/>
                                                        </Stack>
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                        <Documents
                                                            //@ts-ignore
                                                            loadId={props?.data?.expedition?.results[0]?.id} />
                                                    </TabPanel>
                                                </TabContext>
                                            </Grid>
                                            <Grid md={1}>

                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid md={4}>
                                    <Stack spacing={2}>
                                        <Stack direction="row" spacing={2} sx={{
                                            justifyContent: "flex-start",
                                            alignItems: "center"
                                        }}>
                                            <CalendarMonthIcon fontSize={"small"} />
                                            <Typography sx={{
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                lineHeight: "12px",
                                                color: `${theme.palette.text.primary}`,
                                            }}>
                                                Tahmini Teslim Tarihi:
                                            </Typography>
                                            <Typography sx={{
                                                margin: 0,
                                                color: "#7E7E7E",
                                                fontSize: "13px",
                                                fontWeight: 500
                                            }}>
                                                ----------
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                justifyContent: "flex-start",
                                                alignItems: "center"
                                            }}
                                        >
                                            <RecentActorsIcon fontSize={"small"} />
                                            <Typography sx={{
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                lineHeight: "12px",
                                                color: `${theme.palette.text.primary}`,
                                            }}>
                                                Teslim Alacak Firma:
                                            </Typography>
                                            <Typography sx={{
                                                margin: 0,
                                                color: "#7E7E7E",
                                                fontSize: "13px",
                                                fontWeight: 500
                                            }}>
                                                {
                                                    //@ts-ignore
                                                    expedition?.results[0].loads[0]?.customer?.name ?
                                                        //@ts-ignore
                                                        expedition?.results[0].loads[0]?.customer?.name : "Bilgi Bulunamadı"
                                                }
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </List>
            )
        }
        default: {
            return (
                <>
                    {
                        <SnackbarWrapper
                            open={alertOpen}
                            autoHideDuration={autoHideDuration.ms6000}
                            severity={severityType}
                            message={snackbarMessage}
                            anchorOrigin={{ vertical: vertical.bottom, horizontal: horizontal.right }}
                            close={(status: any) => setAlertOpen(status)}
                        />
                    }
                </>
            )
        }
    }

}

export default PageOtsBottomDrawer

import axios, {AxiosResponse} from "axios"
import {slug} from "../../../../config";

interface IWarehouseStockService {
    fetchWarehouseStock(params:{accessToken:string, apiUrl:string, warehouseId:string}): Promise<AxiosResponse>
    createWarehouseStock(params:{accessToken:string, apiUrl:string,warehouseId:string, data:object}): Promise<AxiosResponse>
    updateWarehouseStock(params:{accessToken:string, apiUrl:string, data:object}): Promise<AxiosResponse>
    deleteWarehouseStock(params:{accessToken:string, apiUrl:string, id:number}): Promise<AxiosResponse>
}

const ApiWareHouseStockService: IWarehouseStockService = {
    fetchWarehouseStock: async (params: {
        accessToken: string;
        apiUrl: string,
        warehouseId:string
    }): Promise<AxiosResponse> => {
        const config = {
            headers:{
                Authorization: `Bearer ${params.accessToken}`
            },
        }
        try {
            const response = await axios.get(params.apiUrl+slug.client.warehouses.url+params.warehouseId+"/stock", config)
            return response
        }
        catch (error){
            throw error
        }
    },
    createWarehouseStock: async (params: {
        accessToken: string,
        apiUrl: string,
        warehouseId:string,
        data:object
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${params.accessToken}`
            }
        }
        try {
            const response = await axios.post(params.apiUrl+slug.client.warehouses.url+params.warehouseId+"/stock", params.data, config)
            return response
        }
        catch (e){
            throw e
        }
    },
    updateWarehouseStock: async (params: {
        accessToken: string;
        apiUrl: string;
        data:object;
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${params.accessToken}`
            }
        }
        try {
            const response = await axios.patch(params.apiUrl, params.data, config)
            return response
        }
        catch (e){
            throw e
        }
    },
    deleteWarehouseStock: async (params: {
        accessToken: string;
        apiUrl: string;
        id:number;
    }): Promise<AxiosResponse> => {
        const config = {
            headers: {
                Authorization: `Bearer ${params.accessToken}`
            }
        }
        try {
            const response = await axios.put(params.apiUrl, params.id, config)
            return response
        }
        catch (e){
            throw e
        }
    }
}

export default ApiWareHouseStockService

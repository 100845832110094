import React, { useEffect, useState } from "react";


//Services
import { fetchOAuthLogin } from "../../../services/auth/auth.service";

//Redux
import { useAppDispatch } from "../../../store";

//Hook Form
import { SubmitHandler, useForm } from "react-hook-form";

//Types
import { LoginInputs } from "../../../types/types/components/forms/form.type";

import { AuthLoading } from "../../loading/loading";
import { getStorageData, removeValue, setStorageData, usePersistedState } from "../../../utils/usePersistedState";
import oAuthInitialState from "../../../types/models/storage/auth/oAuth.default.model"


//Components
import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Button,
    Checkbox,
    CssBaseline,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Paper,
    TextField,
    Typography
} from "@mui/material";

//Icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import logo from "../../../assets/3.00_01_49_30.Still005.jpg";

import { SnackbarWrapper } from "../../snackbar/snackbar";
import {
    autoHideDuration,
    horizontal,
    severity,
    vertical
} from "../../../types/enums/components/snackbar/snackbar.enums";
import { setDefaultDynamicApi } from "../../../utils/dynamicApiProvider/dynamicApi.provider";

import { ILoginForm } from "../../../types/interfaces/components/forms/auth/component.form.auth.interface";

export const LoginForm = (props: ILoginForm) => {
    const [isLoading, setLoading] = useState<boolean>(true);

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: {
            errors
        }
    } = useForm<LoginInputs>();

    const dispatch = useAppDispatch();

    const [userRoleAlertOpen, setUserRoleAlertOpen] = React.useState(false);
    const [userPermissionAlertOpen, setUserPermissionAlertOpen] = React.useState(false);
    const [userCheckAlertOpen, setUserCheckAlert] = React.useState(false);

    const [showPassword, setShowPassword] = React.useState(false);

    const [rememberMe, setRememberMe] = useState(false);

    const [userName, setUserName] = useState<string>();

    const [password, setPassword] = useState<string>()

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(event.target.checked);
    };

    useEffect(() => {

        setDefaultDynamicApi()
        getStorageData("loginState", true).then((res:any) => {
            setLoading(false)
            //@ts-ignore
            if (res.rememberme !== undefined) {
                //@ts-ignore
                setPassword(res.password)
                //@ts-ignore
                setUserName(res.username)
            } else {

            }
            if (props.userRoleControl) {
                setUserRoleAlertOpen(true)
            } else if (props.userPermissionControl) {
                setUserPermissionAlertOpen(true)
            } else {
            }
        });
    }, [rememberMe])

    function getRandomImageURL(): string {
        const randomIndex = Math.floor(Math.random() * 4) + 1;
        const imageUrl =
            `https://cdn.abclojistik.com/login/login${randomIndex}.webp`
            ;
        return imageUrl;
    }

    const onSubmitLogin: SubmitHandler<LoginInputs> = (data) => {
        setLoading(true)
        dispatch(fetchOAuthLogin(data)).then(response => {
            if (response.meta.requestStatus == "rejected") {
                setLoading(false);
                setUserCheckAlert(true)
            }
            if (response.meta.requestStatus == "fulfilled") {
                oAuthInitialState.isLoggedIn = true
                oAuthInitialState.status = response.payload
                setStorageData("authState", oAuthInitialState, true).then((res:any) => {
                    console.log(res)
                })
                if (rememberMe) {
                    setStorageData(`loginState`, { username: data.username, password: data.password, rememberme: rememberMe }, true)
                } else {
                    removeValue(`loginState`)
                }
            }
        })
    }

    return (
        isLoading ?
            <AuthLoading />
            :
            <Grid container md={12} sx={{ height: "100vh" }}>
                <Grid
                    item
                    xs={false}
                    md={7}
                    sx={{
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    }}
                    display={{ xs: "none", md: "block" }}
                >
                    <img
                        src={getRandomImageURL()}
                        style={{
                            height: "100vh",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: 2,
                        }}
                    />

                </Grid>
                <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} sx={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "center",
                }}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: "center",
                        }}
                    >
                        <SnackbarWrapper
                            open={userCheckAlertOpen}
                            autoHideDuration={autoHideDuration.ms3000}
                            severity={severity.error}
                            message={"Kullanıcı adı veya şifre hatalı"}
                            anchorOrigin={{ vertical: vertical.bottom, horizontal: horizontal.right }}
                            close={(status) => setUserCheckAlert(status)}
                        />
                        <Avatar sx={{ m: 1, bgcolor: '#1070CF', }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography
                            component="h1"
                            variant="h5"
                            sx={{
                                fontFamily: 'Roboto',
                            }}
                        >
                            GİRİŞ
                        </Typography>
                        {
                            props.userRoleControl ?
                                <>
                                    <SnackbarWrapper
                                        open={userRoleAlertOpen}
                                        autoHideDuration={autoHideDuration.ms3000}
                                        severity={severity.warning}
                                        message={"Henüz bir role sahip değilsiniz. Desteğe başvurunuz"}
                                        anchorOrigin={{ vertical: vertical.top, horizontal: horizontal.right }}
                                        close={(status) => setUserRoleAlertOpen(status)}
                                    />
                                </>
                                : <></> && props.userPermissionControl ?
                                    <>
                                        <SnackbarWrapper
                                            open={userPermissionAlertOpen}
                                            autoHideDuration={autoHideDuration.ms3000}
                                            severity={severity.warning}
                                            message={"Henüz bir izne sahip değilsiniz.Desteğe başvurunuz."}
                                            anchorOrigin={{ vertical: vertical.top, horizontal: horizontal.right }}
                                            close={(status) => setUserPermissionAlertOpen(status)}
                                        />
                                    </>

                                    : <></>
                        }
                        <form onSubmit={handleSubmit(onSubmitLogin)}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Kullanıcı Adı"
                                autoComplete="email"
                                autoFocus
                                {...register(`username`)}
                                defaultValue={userName}
                            />
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Şifre</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    required={true}
                                    {...register(`password`)}
                                    defaultValue={password}
                                />
                            </FormControl>
                            <FormControlLabel
                                sx={{
                                    fontFamily: 'Roboto',
                                }}
                                control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={handleRememberMeChange}
                                        color="primary"
                                    />
                                }
                                label="Beni Hatırla"
                            />
                            <Button
                                type="submit" onSubmit={handleSubmit(onSubmitLogin)}
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    fontFamily: 'Roboto',
                                }}
                            >


                                GİRİŞ YAP
                            </Button>
                        </form>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
    )
}
